import { useEffect, useRef, useState } from "react"
import {
  blockquote,
  image,
  link,
  audio,
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
} from "suneditor/src/plugins"
import SunEditor from "suneditor-react"
import SunEditorCore from "suneditor/src/lib/core"
import "suneditor/dist/css/suneditor.min.css"
import { getAuthTokenFromLocalStorage } from "../PageLoadActions"
import {
  UploadBeforeHandler,
  UploadBeforeReturn,
} from "suneditor-react/dist/types/upload"
import { uploadImage } from "@/api/routes/image/image"
import axios from "axios"

import "./index.css"
import { defaultRichTextAreaStyle } from "../RichTextArea"

interface Props {
  observable: string
}

export default function RichTextRender(props: Props) {
  const editor = useRef<SunEditorCore>()

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor
    sunEditor.core.context.element.wysiwygFrame.classList.add(
      "rich-text-render-wysiwyg-frame",
    )
    sunEditor.core.context.element.topArea.classList.add(
      "rich-text-render-container",
    )
  }

  useEffect(() => {
    editor.current?.setContents(props.observable)
  }, [props.observable])

  return (
    <>
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        readOnly={true}
        defaultValue={props.observable}
        showController={() => {}}
        onResizeEditor={() => {}}
        disable={true}
        disableToolbar={true}
        hideToolbar={true}
        width="100%"
        height="auto"
        setOptions={{
          defaultStyle: `${defaultRichTextAreaStyle};background-color: transparent;`,
          fontSize: [12, 14, 16, 18, 20, 22, 24, 26, 28, 36],
          imageResizing: false,
          videoResizing: false,
          resizeEnable: false,
          resizingBar: false,
          showPathLabel: false,
          imageAlignShow: false,
          videoFileInput: false,
          audioFileInput: false,
          buttonList: [],
        }}
      />
    </>
  )
}
