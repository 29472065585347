import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  observable: any
  setObservable: React.Dispatch<React.SetStateAction<any>>
  index: number
}

export default function DeleteAnswerModal({
  isOpen,
  setIsOpen,
  observable,
  setObservable,
  index,
}: Props) {
  return (
    <ConfirmDeleteModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onConfirm={() => {
        setObservable((prev: any) => {
          const newObservable = [...prev]
          newObservable.splice(index, 1)
          return newObservable
        })
        setIsOpen(false)
      }}
    />
  )
}
