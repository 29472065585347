import BaseModal from "@/core/components/Modals/BaseModal"
import { isFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Spacer,
  Textarea,
  UseDisclosureProps,
  useDisclosure,
} from "@nextui-org/react"
import { useEffect, useState } from "react"
import TableSelectInput from "../components/TableSelectInput"
import AddLinkModal from "../components/Notes/EditNotesModal/AddLinkModal"
import EditLinkModal from "../components/Notes/EditNotesModal/EditLinkModal"
import SelectFromEnums from "@/core/components/SelectFromEnums"
import {
  CategoryAccessLevel,
  CategoryAccessLevelString,
} from "@/api/types/question/question"

interface Props {
  disclosure: UseDisclosureProps
  index: number
  selectedVideos: any[]
  onEditVideo: (val: any, vals: any) => Promise<void>
  selectedLinks: any[]
  setSelectedLinks: React.Dispatch<React.SetStateAction<any[]>>
}
export default function EditVideoModal({
  disclosure,
  index,
  selectedVideos,
  onEditVideo,
  selectedLinks,
  setSelectedLinks,
}: Props) {
  const [clonedSelectedVideos, setClonedSelectedVideos] = useState([] as any)

  const [editLinksIndex, setEditLinksIndex] = useState(-1)
  const addLinkModalDisclosure = useDisclosure()
  const editLinkModalDisclosure = useDisclosure()

  useEffect(() => {
    if (selectedVideos && selectedVideos.length > index) {
      setSelectedLinks(selectedVideos[index].Links)
      setClonedSelectedVideos(selectedVideos)
    }
  }, [selectedVideos])

  const formValidate = () => {
    if (
      isFieldFilledOut(clonedSelectedVideos[index].Title) &&
      isFieldFilledOut(clonedSelectedVideos[index].Description) &&
      isFieldFilledOut(clonedSelectedVideos[index].URL) &&
      isFieldFilledOut(clonedSelectedVideos[index].AccessLevel)
    ) {
      return false
    }
    return true
  }

  return clonedSelectedVideos &&
    Array.isArray(clonedSelectedVideos) &&
    clonedSelectedVideos[index] ? (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Add Video"
      size="full"
      body={
        <div>
          <AddLinkModal
            disclosure={addLinkModalDisclosure}
            addLink={(val: any) => {
              setSelectedLinks((prev: any) => [...prev, { ...val, _id: null }])

              addLinkModalDisclosure.onClose()
            }}
          />
          <EditLinkModal
            disclosure={editLinkModalDisclosure}
            index={editLinksIndex}
            selectedLinks={selectedLinks}
            onEditLink={(val: any, vals: any) => {
              let newLinks = [...selectedLinks]
              newLinks[val] = vals
              setSelectedLinks(newLinks)
            }}
          />

          <Input
            value={clonedSelectedVideos[index].Title}
            onChange={(e) =>
              setClonedSelectedVideos((prev: any) => {
                let newVal = [...prev]
                newVal[index].Title = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Video Title"
          />
          <Spacer y={2} />
          <Input
            value={clonedSelectedVideos[index].URL}
            onChange={(e) =>
              setClonedSelectedVideos((prev: any) => {
                let newVal = [...prev]
                newVal[index].URL = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Video URL"
          />
          <Spacer y={2} />
          <Textarea
            value={clonedSelectedVideos[index].Description}
            onChange={(e) =>
              setClonedSelectedVideos((prev: any) => {
                let newVal = [...prev]
                newVal[index].Description = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Video Description"
          />
          <Spacer y={2} />
          <div className="input-wrapper">
            <SelectFromEnums
              label="Access Level"
              placeholder="Select who can view this content"
              selectedKeys={clonedSelectedVideos[index].AccessLevel}
              onSelectionChange={(val: any) =>
                setClonedSelectedVideos((prev: any) => {
                  let newVal = [...prev]
                  newVal[index].AccessLevel = val
                  return newVal
                })
              }
              stringEnum={CategoryAccessLevelString}
              valuesEnum={CategoryAccessLevel}
            />
          </div>

          <Spacer y={2} />
          <div className="input-wrapper">
            <TableSelectInput
              aria-label="Add link"
              title={"Links"}
              addNewPlaceholder="+ Add a new link"
              onAddNew={addLinkModalDisclosure.onOpen}
              onEdit={(index: number) => {
                setEditLinksIndex(index)
                editLinkModalDisclosure.onOpen()
              }}
              onDelete={async (_id: string) => {
                setSelectedLinks((prev: any) =>
                  prev.filter((link: any) => link._id !== _id),
                )
              }}
              isLoading={false}
              selectedItems={selectedLinks}
              setSelectedItems={setSelectedLinks}
              hideMovementArrows={false}
              headers={["Title", "URL"]}
            />
          </div>
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await onEditVideo(
                clonedSelectedVideos[index],
                clonedSelectedVideos,
              )

              disclosure.onClose!()
            }}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
          >
            Edit Video
          </Button>
        </div>
      }
    />
  ) : null
}
