export const colorsList = [
  {
    label: "Blue",
    value: "--medcamps-primary-100",
  },
  {
    label: "Purple",
    value: "--medcamps-secondary-100",
  },
  {
    label: "Green",
    value: "--medcamps-success-100",
  },
  {
    label: "Orange",
    value: "--medcamps-warning-100",
  },
  {
    label: "Red",
    value: "--medcamps-danger-100",
  },
  {
    label: "Grey",
    value: "--medcamps-default-100",
  },
]
