import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"

export async function listUsers(pageData?: PageData, useTutorView?: boolean) {
  try {
    let urlParams = new URLSearchParams()
    if (pageData?.fetchMeta === true) {
      urlParams.append("meta", "1")
    }
    if (pageData?.page) {
      urlParams.append("page", pageData.page.toString())
    }
    if (pageData?.limit) {
      urlParams.append("limit", pageData.limit.toString())
    }
    if (useTutorView === true) {
      urlParams.append("tutor", "1")
    }

    const res = await axios.get("/admin/user?" + urlParams.toString())

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function createUser(userData: any) {
  try {
    const res = await axios.post("/admin/user/new", userData)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateUser(userId: string, userData: any) {
  try {
    const res = await axios.put("/admin/user/" + userId, userData)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteUser(userId: string) {
  try {
    const res = await axios.delete(`/admin/user/${userId}`)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getUserData(userId: string) {
  try {
    const res = await axios.get(`/admin/user/${userId}`)

    if (res.status === 200) {
      return res.data.body.user
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function searchTutorUsers(searchTerm: string) {
  try {
    if (!searchTerm) {
      return []
    }
    const res = await axios.get(`/admin/user/find-tutors/${searchTerm}`)

    if (res.status === 200) {
      return res.data.body.users
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getSubscriptionsForUser(
  userId: string,
  filter: string,
  pageData: PageData,
) {
  try {
    let urlParams = new URLSearchParams()
    if (pageData?.fetchMeta === true) {
      urlParams.append("meta", "1")
    }
    if (pageData?.page) {
      urlParams.append("page", pageData.page.toString())
    }
    if (pageData?.limit) {
      urlParams.append("limit", pageData.limit.toString())
    }
    urlParams.append("filter", filter)

    const res = await axios.get(
      "/admin/user/" + userId + "/subscriptions?" + urlParams.toString(),
    )

    if (res.status === 200) {
      return res.data.body.subscriptions
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function addManualSubscriptionToUser(
  userId: string,
  planId: string,
  startDate: Date,
  renewDate: Date,
) {
  try {
    const res = await axios.post(
      "/admin/user/" + userId + "/manual-subscription",
      {
        planId,
        startDate,
        renewDate,
      },
    )

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (err && err.response && err.response.status === 401) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateSubscriptionForUser(
  userId: string,
  subscriptionId: string,
  startDate: Date,
  renewDate: Date,
) {
  try {
    const res = await axios.put(
      "/admin/user/" + userId + "/subscription/" + subscriptionId + "/update",
      {
        startDate,
        renewDate,
      },
    )

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (err && err.response && err.response.status === 401) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function expireSubscriptionForUser(
  userId: string,
  subscriptionId: string,
) {
  try {
    const res = await axios.put(
      "/admin/user/" + userId + "/subscription/" + subscriptionId + "/expire",
    )

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (err && err.response && err.response.status === 401) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteSubscriptionForUser(
  userId: string,
  subscriptionId: string,
) {
  try {
    const res = await axios.delete(
      "/admin/user/" + userId + "/subscription/" + subscriptionId,
    )

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (err && err.response && err.response.status === 401) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function restoreDeletedSubscriptionForUser(
  userId: string,
  subscriptionId: string,
) {
  try {
    const res = await axios.put(
      "/admin/user/" + userId + "/subscription/" + subscriptionId + "/restore",
    )

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (err && err.response && err.response.status === 401) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function undoExpireSubscriptionForUser(
  userId: string,
  subscriptionId: string,
) {
  try {
    const res = await axios.put(
      "/admin/user/" +
        userId +
        "/subscription/" +
        subscriptionId +
        "/undo-expire",
    )

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (err && err.response && err.response.status === 401) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
