import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"

export async function createQuestion(question: any) {
  try {
    const res = await axios.post("/admin/question-bank/new", question)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateQuestion(id: string, question: any) {
  try {
    const res = await axios.put("/admin/question-bank/update/" + id, question)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteQuestion(id: string) {
  try {
    const res = await axios.delete("/admin/question-bank/delete/" + id)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getQuestionScenarios() {
  try {
    const res = await axios.get("/admin/question-bank/scenarios")

    if (res.status === 200) {
      return res.data?.body?.scenarios
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
