import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "@/app/store"
import { User } from "@/api/types/user/user"

// Reducer
export interface AuthState {
  user?: User
  loading: boolean
}

const initialState: AuthState = {
  loading: false,
}

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    set: (state, user: PayloadAction<User>) => {
      return {
        ...state,
        user: user.payload,
        loading: false,
      }
    },
    clear: (state) => {
      return {
        ...state,
        user: undefined,
        loading: false,
      }
    },
  },
})

export const { set, clear } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState): User | undefined =>
  state.user.user

export default userSlice.reducer
