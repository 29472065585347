import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.css"
import {
  faCheckCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons"
import React from "react"

interface Props {
  title: string
  subText: string | React.ReactElement
  footer: React.ReactElement[] | React.ReactElement
  icon: IconDefinition
  color: string
}
export default function Status(props: Props) {
  return (
    <div className="status-component-page">
      <div className="status-component-inner-content">
        <div className="status-component-status-wrapper">
          <FontAwesomeIcon size="4x" icon={props.icon} color={props.color} />
          <h1>{props.title}</h1>
          {typeof props.subText === "string" ? (
            <span>{props.subText}</span>
          ) : (
            props.subText
          )}
        </div>
        <div className="status-component-status-page-buttons">
          {props.footer}
        </div>
      </div>
    </div>
  )
}
