import React, { useEffect, useState } from "react"

import Page from "@/core/components/Page"
import {
  faEdit,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Chip,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"
import PageHeader from "@/core/components/Page/PageHeader"
import { listQuestions } from "@/api/routes/question-bank/questions"
import { useNavigate } from "react-router"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { deleteQuestion } from "@/api/routes/admin/question-bank"
import DeleteQuestionButton from "./DeleteQuestionButton"
import { PageLoader } from "@/components/PageLoader"
import { useAppSelector } from "@/app/hooks"

export enum AnswerAccessLevel {
  ADMIN_ONLY = 0,
  TUTOR_ONLY = 1,
  PAYWALL = 2,
  UNLOCKED = 3,
}

const answerAccessLevelToString = (answerAccessLevel: AnswerAccessLevel) => {
  switch (answerAccessLevel) {
    case AnswerAccessLevel.ADMIN_ONLY:
      return "Admin Only"
    case AnswerAccessLevel.TUTOR_ONLY:
      return "Tutor Only"
    case AnswerAccessLevel.PAYWALL:
      return "Paywall"
    case AnswerAccessLevel.UNLOCKED:
      return "Everyone"
  }
}

export default function ManageQuestionsPage() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [allQuestions, setAllQuestions] = useState([] as any)
  const [displayQuestions, setDisplayQuestions] = useState([] as any)
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user.user)

  let columns
  if (user?.role === "admin") {
    columns = [
      { name: "NAME", uid: "name" },
      { name: "ACCESS LEVEL", uid: "answerAccessLevel" },
      { name: "CREATOR", uid: "creator" },
      { name: "ACTIONS", uid: "actions" },
    ]
  } else if (user?.role === "content-creator") {
    columns = [
      { name: "NAME", uid: "name" },
      { name: "ACCESS LEVEL", uid: "answerAccessLevel" },
      { name: "ACTIONS", uid: "actions" },
    ]
  }

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)

  const loadQuestions = async (page?: number) => {
    setIsPageLoading(true)
    setCurrentPage(page || 1)

    const totalRecords: any = await listQuestions(
      undefined,
      undefined,
      {
        fetchMeta: true,
        limit: pageLimit,
      },
      false, // filter for only the current users questions
    )
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    const _allQuestions = await listQuestions(
      undefined,
      undefined,
      {
        page: page || currentPage,
        limit: pageLimit,
      },
      false, // filter for only the current users questions
    )
    if (_allQuestions != null) {
      setAllQuestions(_allQuestions)
      setDisplayQuestions(
        _allQuestions.map((question: any) => ({
          _id: question._id,
          name: question.name.enGB,
          answerAccessLevel: answerAccessLevelToString(
            question.answerAccessLevel,
          ),
          creator: question.authorName,
        })),
      )
    }
    setIsPageLoading(false)
  }

  useEffect(() => {
    if (!user) {
      return
    }
    loadQuestions()
  }, [user])

  const renderCell = React.useCallback(
    (question: any, columnKey: React.Key) => {
      const cellValue = question[columnKey as any]

      switch (columnKey) {
        case "answerAccessLevel":
          return (
            <Chip className="capitalize" size="sm" variant="flat">
              {cellValue}
            </Chip>
          )
        case "actions":
          return (
            <div className="actions-table-cell">
              <Tooltip aria-label="Details" content="Preview question">
                <Link
                  onClick={() => {
                    navigate(`/Admin/Question/Preview/${question._id}`)
                  }}
                >
                  <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                    <FontAwesomeIcon icon={faEye} />
                  </span>
                </Link>
              </Tooltip>
              <Tooltip aria-label="Details" content="Edit question">
                <Link
                  onClick={() => {
                    navigate(`/Admin/Question/Edit/${question._id}`)
                  }}
                >
                  <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                    <FontAwesomeIcon icon={faEdit} />
                  </span>
                </Link>
              </Tooltip>
              <DeleteQuestionButton
                questionId={question._id}
                questionTitle={question.name}
                listQuestions={loadQuestions}
              />
            </div>
          )
        default:
          return cellValue
      }
    },
    [],
  )

  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>Manage Questions</h1>
        <Button
          color="primary"
          variant="shadow"
          startContent={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            navigate("/Admin/Question/New")
          }}
        >
          New Question
        </Button>
      </PageHeader>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Table
          aria-label=""
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                total={totalPages}
                initialPage={currentPage}
                onChange={(page) => {
                  setCurrentPage(page)
                  loadQuestions(page)
                }}
              />
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                className={column.uid === "actions" ? "actions-table-cell" : ""}
                align={column.uid === "actions" ? "end" : "start"}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>

          <TableBody items={displayQuestions}>
            {(item: any) => (
              <TableRow key={item._id}>
                {(columnKey) => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Page>
  )
}
