import { Card, Spacer, Spinner } from "@nextui-org/react"
import { useEffect, useState } from "react"

interface Props {
  aiResponse: string | null | undefined
  isAiMarking: boolean | null | undefined
}

export default function AiResponse({ aiResponse, isAiMarking }: Props) {
  const [formattedAiResponse, setFormattedAiResponse] = useState<
    string[] | null
  >(null)

  useEffect(() => {
    if (aiResponse) {
      const _formattedAiResponse = aiResponse.split("\n")

      setFormattedAiResponse(_formattedAiResponse)
    } else {
      setFormattedAiResponse(null)
    }
  }, [aiResponse])

  return typeof aiResponse === "string" || isAiMarking === true ? (
    <>
      <Spacer y={2} />
      <Card shadow="none" className="in-progress-quiz-answer-card">
        <span className="question-attempt-card-title-text">
          AI Marker's Comment
        </span>
        <Spacer y={1} />
        {aiResponse && formattedAiResponse
          ? formattedAiResponse.map((line, index) => <p key={index}>{line}</p>)
          : null}

        {isAiMarking && (
          <div className="transcription-in-progress-message">
            <Spinner size="sm" color="primary" />
            <span className="transcription-title-text">
              AI is marking, please check back later...{" "}
            </span>
          </div>
        )}
      </Card>
      {isAiMarking && <Spacer y={1} />}
    </>
  ) : (
    <></>
  )
}
