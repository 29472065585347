import { addNotes } from "@/api/routes/admin/question-category/notes"
import { CategoryAccessLevel } from "@/api/types/question/question"
import { CategoryAccessLevelString } from "@/api/types/question/question"
import BaseModal from "@/core/components/Modals/BaseModal"
import RichTextArea from "@/core/components/RichTextArea"
import SelectFromEnums from "@/core/components/SelectFromEnums"
import { isFieldFilledOut } from "@/core/helpers/utils"
import { Button, Input, Spacer, UseDisclosureProps } from "@nextui-org/react"
import { Key, useState } from "react"

interface Props {
  currentCategoryPath: string[]
  setSubCategories: React.Dispatch<React.SetStateAction<any[]>>
  disclosure: UseDisclosureProps
  index: number
}
export default function CreateNotesModal({
  disclosure,
  currentCategoryPath,
  setSubCategories,
  index,
}: Props) {
  const [title, setTitle] = useState("")
  const [notes, setNotes] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [accessLevel, setAccessLevel] = useState<any>(new Set([]))

  const formValidate = () => {
    if (isFieldFilledOut(title) && isFieldFilledOut(notes)) {
      return false
    }
    return true
  }

  const closeModal = () => {
    setTitle("")
    setNotes("")
    disclosure.onClose!()
  }

  const internalAddSubCategoryNotes = async () => {
    setIsLoading(true)
    const notesId = await addNotes(
      title,
      notes,
      [],
      parseInt([...accessLevel].join("")),
      currentCategoryPath,
    )

    setSubCategories((prev) => {
      let next = [...prev]
      if (!next[index].notes || !Array.isArray(next[index].notes)) {
        next[index].notes = []
      }

      next[index].notes.push({
        _id: notesId,
        title,
        notes,
        accessLevel: new Set<Key>([accessLevel.toString()]),
      })

      return next
    })

    setIsLoading(false)
    closeModal()
  }

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Add Notes"
      size="full"
      body={
        <div>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Title"
          />
          <Spacer y={2} />
          <RichTextArea
            height="500px"
            observable={notes ?? ""}
            onChange={(contents: string) => {
              setNotes(contents)
            }}
            label={"Notes"}
            placeholder={"Add notes here..."}
          />
          <Spacer y={2} />
          <SelectFromEnums
            label="Access Level"
            placeholder="Select who can view this content"
            selectedKeys={accessLevel}
            onSelectionChange={setAccessLevel}
            stringEnum={CategoryAccessLevelString}
            valuesEnum={CategoryAccessLevel}
          />
          <Spacer y={2} />
          <p>
            <i>
              (You can add related links after you have created this set of
              notes - just click the edit icon).
            </i>
          </p>
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={internalAddSubCategoryNotes}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
            isLoading={isLoading}
          >
            Add Notes
          </Button>
        </div>
      }
    />
  )
}
