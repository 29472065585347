import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { User } from "@/api/types/user/user"

export async function getGeneralStatistics() {
  try {
    const res = await axios.get("/statistics/general")

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getCategoryStatistics() {
  try {
    const res = await axios.get("/statistics/categories")

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getQuestionPracticeCategoryStatistics() {
  try {
    const res = await axios.get("/statistics/question-practice-categories")

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
