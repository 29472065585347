import {
  Button,
  Card,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowDown,
  faArrowUp,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import CustomSelectSearchModal from "../../../../components/CustomSelectSearchModal"
import { listCategories } from "@/api/routes/category-bank"
import { listQuestions } from "@/api/routes/question-bank/questions"

interface Props {
  selectedQuestions: any[]
  setSelectedQuestions: (val: any) => void
}

// eslint-disable-next-line no-extend-native
;(Array.prototype as any).move = function (from: number, to: number) {
  this.splice(to, 0, this.splice(from, 1)[0])
}

export default function QuestionInput(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  async function fetchQuestions(
    setSearchResults: (val: any) => void,
    name?: string,
  ) {
    const questions = await listQuestions(name, 5)
    if (questions) {
      setSearchResults(
        questions.map((question: any) => ({
          ...question,
          name: question.name.enGB,
        })),
      )
    }
  }

  const [tableItems, setTableItems] = useState([] as any[])
  useEffect(() => {
    let newTableItems = [...props.selectedQuestions]
    newTableItems.push({ name: "+ Add new question", _id: "add-new-question" })
    setTableItems(newTableItems)
  }, [props.selectedQuestions])

  return (
    <>
      <h2>Questions</h2>
      <Table shadow="none">
        <TableHeader>
          <TableColumn>Name</TableColumn>
          <TableColumn className="actions-table-cell">Actions</TableColumn>
        </TableHeader>
        <TableBody items={tableItems}>
          {(question: any) => (
            <TableRow key={question._id}>
              <TableCell>
                {question._id !== "add-new-question" ? (
                  question.name
                ) : (
                  <Link className="link" onClick={onOpen}>
                    {question.name}
                  </Link>
                )}
              </TableCell>
              {question._id !== "add-new-question" ? (
                <TableCell>
                  <div className="actions-table-cell">
                    <Tooltip aria-label="Move up" content="Move up">
                      <Link
                        aria-label="Move up"
                        onClick={() => {
                          // Move the position of this question up in the selected questions array

                          props.setSelectedQuestions((prev: any[]) => {
                            const index = prev.findIndex(
                              (q) => q._id === question._id,
                            )
                            if (index > 0) {
                              const newQuestions: any = [...prev]
                              newQuestions.move(index, index - 1)
                              return newQuestions
                            } else {
                              return prev
                            }
                          })
                        }}
                      >
                        <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                          <FontAwesomeIcon color="primary" icon={faArrowUp} />
                        </span>
                      </Link>
                    </Tooltip>
                    <Tooltip aria-label="Move down" content="Move down">
                      <Link
                        aria-label="Move down"
                        onClick={() => {
                          // Move the position of this question up in the selected questions array
                          props.setSelectedQuestions((prev: any[]) => {
                            const index = prev.findIndex(
                              (q) => q._id === question._id,
                            )
                            if (index < prev.length - 1) {
                              const newQuestions: any = [...prev]
                              newQuestions.move(index, index + 1)
                              return newQuestions
                            } else {
                              return prev
                            }
                          })
                        }}
                      >
                        <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                          <FontAwesomeIcon icon={faArrowDown} />
                        </span>
                      </Link>
                    </Tooltip>
                    <Tooltip aria-label="Remove" content="Remove">
                      <Link
                        aria-label="Remove"
                        onClick={() => {
                          props.setSelectedQuestions((prev: any[]) => {
                            const index = prev.findIndex(
                              (q) => q._id === question._id,
                            )
                            const newQuestions = [...prev]
                            newQuestions.splice(index, 1)
                            return newQuestions
                          })
                        }}
                      >
                        <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </Link>
                    </Tooltip>
                  </div>
                </TableCell>
              ) : (
                <TableCell> </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <CustomSelectSearchModal
        isOpen={isOpen}
        onClose={onClose}
        fetchData={fetchQuestions}
        selectedData={props.selectedQuestions}
        setSelectedData={props.setSelectedQuestions}
        modalTitle="Select Questions"
        searchLabel="Search Questions"
        searchPlaceholder="Search for a question..."
      />
    </>
  )
}

/*
  const color = "--medcamps-primary-100"

*/
