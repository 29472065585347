import { getNoteByIdAndPath } from "@/api/routes/category-bank/notes"
import { IQuestionCategoryNotes } from "@/api/types/question/question"
import { PageLoader } from "@/components/PageLoader"
import BaseModal from "@/core/components/Modals/BaseModal"
import RichTextRender from "@/core/components/RichTextRender"
import {
  Button,
  Card,
  Input,
  Spacer,
  Spinner,
  Textarea,
  UseDisclosureProps,
} from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"
import { getUserNotes, setUserNotesReq } from "@/api/routes/user-notes"
import useDebounce from "@/core/helpers/debounce"
import { IUserNotes } from "@/api/types/user-notes"
import UserRichTextArea from "@/core/components/UserRichTextArea"
import PageHeader from "@/core/components/Page/PageHeader"
import { useNavigate } from "react-router"
import LinksList from "@/core/components/LinksList"

interface Props {
  currentCategoryPath: string[]
  disclosure: UseDisclosureProps
  notesId: string | undefined
  onModalClose?: () => void | undefined
}
export default function ViewNotesModal({
  disclosure,
  currentCategoryPath,
  notesId,
  onModalClose,
}: Props) {
  const navigate = useNavigate()
  const [currentNote, setCurrentNote] = useState<
    IQuestionCategoryNotes | undefined
  >()
  const [userNotesData, setUserNotesData] = useState<IUserNotes | undefined>()
  const [userNotes, setUserNotes] = useState<string>("") // This is the rendered observable
  const [updatedUserNotes, setUpdatedUserNotes] = useState<string | null>(null) // This will only populate if the user changes their notes (as this is what will be sent back to our server)
  const debouncedUserNotes = useDebounce(updatedUserNotes ?? "", 100)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    // reload notes when open state changes
    ;(async () => {
      if (!notesId) return

      setIsLoading(true)
      const currentNote = await getNoteByIdAndPath(notesId, currentCategoryPath)
      const userNotes = await getUserNotes(notesId, currentCategoryPath)
      setUpdatedUserNotes(null)
      setUserNotesData(userNotes)
      setUserNotes(userNotes?.notes ?? "")
      setCurrentNote(currentNote)
      setIsLoading(false)
    })()
  }, [disclosure.isOpen, notesId, currentCategoryPath])

  useEffect(() => {
    ;(async () => {
      if (!notesId || updatedUserNotes === null) return
      setIsSaving(true)
      await setUserNotesReq(notesId, currentCategoryPath, debouncedUserNotes)
      setIsSaving(false)
    })()
  }, [debouncedUserNotes])

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={() => {
        if (typeof onModalClose === "function") {
          onModalClose()
        }
        if (disclosure.onClose) {
          disclosure.onClose()
        }
      }}
      title={"Notes"}
      size="full"
      body={
        isLoading ? (
          <PageLoader />
        ) : (
          <div>
            <PageHeader>
              <h1>{currentNote?.title}</h1>
            </PageHeader>
            <Spacer y={2} />
            <div className="notes-content">
              <Card className="main-notes-content">
                <RichTextRender observable={currentNote?.notes ?? ""} />
              </Card>
              <div className="user-notes-content">
                <Card className="user-notes-card">
                  <div className="user-notes-header-container">
                    <h2>Your Notes</h2>
                    {isSaving ? (
                      <div className="user-notes-saving-spinner">
                        <span>
                          <i>Saving...</i>
                        </span>{" "}
                        <Spinner size="sm" />
                      </div>
                    ) : (
                      updatedUserNotes && (
                        <span>
                          <i>Saved</i>
                        </span>
                      )
                    )}
                  </div>
                  <UserRichTextArea
                    placeholder="Start typing your notes here..."
                    observable={userNotes}
                    onChange={(contents) => {
                      setUserNotes(contents)
                      setUpdatedUserNotes(contents)
                    }}
                    height="300px"
                  />
                </Card>
                <Spacer y={2} />
                <LinksList
                  title="Further Study"
                  links={currentNote?.links ?? []}
                />
              </div>
            </div>
          </div>
        )
      }
      buttons={<div className="add-video-modal-buttons"></div>}
    />
  )
}
