import {
  SubscriptionPlanFeature,
  subscriptionPlanFeaturesMap,
} from "@/core/constants/subscription-plan"
import ItemInput from "../ItemInput"

interface Props {
  observable: any
  setObservable: (val: any) => void
}

export interface MenuItem extends SubscriptionPlanFeature {
  key: string
  label: string
}

export default function SubscriptionPlanFeaturesInput({
  observable,
  setObservable,
}: Props) {
  // convert subscriptionPlanFeaturesMap to menuitem[]
  const menuItems = Object.keys(subscriptionPlanFeaturesMap).map((key) => {
    return {
      ...subscriptionPlanFeaturesMap[key],
      key: key,
      label: subscriptionPlanFeaturesMap[key].title,
    }
  })

  return (
    <ItemInput
      label={"Plan features"}
      placeholder={"Enter feature value"}
      observable={observable}
      setObservable={setObservable}
      buttonLabel="+ Add Feature"
      menuItems={menuItems}
    />
  )
}
