import { useAppSelector } from "@/app/hooks"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import "./index.css"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  Tab,
  Tabs,
  useDisclosure,
} from "@nextui-org/react"
import { useEffect, useState } from "react"
import {
  deleteMyAccount,
  resetQuizData,
  updateMyProfile,
} from "@/api/routes/user/user"
import { ICurrentUserEditModel } from "@/api/types/user/user"
import BaseModal from "@/core/components/Modals/BaseModal"
import { useNavigate } from "react-router"
import { useDispatch } from "react-redux"
import loadUserProfile from "@/core/helpers/load-user-profile"

export default function AccountSettingsPage() {
  const { user } = useAppSelector((state) => state.user)

  const [firstName, setFirstName] = useState<string>(user?.firstName || "")
  const [lastName, setLastName] = useState<string>(user?.lastName || "")
  const [email, setEmail] = useState<string>(user?.username || "")

  const deleteAccountDisclosure = useDisclosure()
  const resetDataDisclosure = useDisclosure()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    setFirstName(user?.firstName || "")
    setLastName(user?.lastName || "")
    setEmail(user?.username || "")
  }, [user])

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
  const buildAndSendUser = async () => {
    let changedEmail = false
    setIsSubmitLoading(true)
    let request: ICurrentUserEditModel = {}
    if (firstName !== user?.firstName) {
      request.firstName = firstName
    }
    if (lastName !== user?.lastName) {
      request.lastName = lastName
    }
    if (email !== user?.username) {
      request.username = email
      changedEmail = true
    }

    await updateMyProfile(request)

    // Changing email glitches out the session, so we need to log out
    if (changedEmail === true) {
      navigate("/Logout")
    } else {
      await loadUserProfile(dispatch)
    }

    setIsSubmitLoading(false)
  }

  const deleteAccount = async () => {
    deleteAccountDisclosure.onClose()
    setIsSubmitLoading(true)
    await deleteMyAccount()
    navigate("/Logout")
    setIsSubmitLoading(false)
  }

  const resetData = async () => {
    resetDataDisclosure.onClose()
    setIsSubmitLoading(true)
    await resetQuizData()
    setIsSubmitLoading(false)
  }

  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>Account Settings</h1>
      </PageHeader>

      <BaseModal
        title="Delete account"
        body="Are you sure you want to delete your account? This action cannot be undone."
        buttons={
          <>
            <Button
              color="primary"
              variant="flat"
              onClick={deleteAccountDisclosure.onClose}
            >
              Cancel
            </Button>
            <Button color="danger" variant="flat" onClick={deleteAccount}>
              Delete
            </Button>
          </>
        }
        isOpen={deleteAccountDisclosure.isOpen}
        onClose={deleteAccountDisclosure.onClose}
      />

      <BaseModal
        title="Confirm quiz data reset?"
        body="
          This action will remove all quizzes/interviews attached to your account and therefore also
          reset your statistics. You will not be able to view past quiz data/statistics if you continue.
          This action cannot be undone."
        buttons={
          <>
            <Button
              color="primary"
              variant="flat"
              onClick={resetDataDisclosure.onClose}
            >
              Cancel
            </Button>
            <Button color="danger" variant="flat" onClick={resetData}>
              Reset
            </Button>
          </>
        }
        isOpen={resetDataDisclosure.isOpen}
        onClose={resetDataDisclosure.onClose}
      />

      <Tabs aria-label="Options">
        <Tab key="general" title="General">
          <Card>
            <CardBody className="account-settings-body">
              <h1 className="account-settings-header">Basic Info</h1>
              <div className="input-wrapper row row-gap-10">
                <Input
                  type="text"
                  label="First Name"
                  placeholder={user?.firstName || "loading..."}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Input
                  type="text"
                  label="Last Name"
                  placeholder={user?.lastName || "loading..."}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <Input
                  type="email"
                  label="Email"
                  placeholder={user?.username || "loading..."}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="account-save-wrapper">
                <div className="account-danger-actions-wrapper">
                  <Button
                    onClick={deleteAccountDisclosure.onOpen}
                    variant="solid"
                    color="danger"
                    isLoading={isSubmitLoading}
                  >
                    Delete Account
                  </Button>
                  <Button
                    onClick={resetDataDisclosure.onOpen}
                    variant="bordered"
                    color="danger"
                    isLoading={isSubmitLoading}
                  >
                    Reset Quiz Data
                  </Button>
                </div>
                <Button
                  onClick={buildAndSendUser}
                  variant="solid"
                  color="primary"
                  isLoading={isSubmitLoading}
                >
                  Save
                </Button>
              </div>
            </CardBody>
          </Card>
        </Tab>
        {/*
        <Tab key="subscription" title="Subscription">
          <Card>
            <CardBody>Under Construction...</CardBody>
          </Card>
        </Tab>
        */}
      </Tabs>
    </Page>
  )
}
