import React, { useEffect, useRef, useState } from "react"

import Page from "@/core/components/Page"
import {
  faAlignJustify,
  faEdit,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Card,
  Chip,
  Link,
  Pagination,
  Spacer,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"
import PageHeader from "@/core/components/Page/PageHeader"
import {
  getQuestion,
  listQuestions,
} from "@/api/routes/question-bank/questions"
import { useNavigate, useParams } from "react-router"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { deleteQuestion } from "@/api/routes/admin/question-bank"
import { PageLoader } from "@/components/PageLoader"
import { useAppSelector } from "@/app/hooks"
import { IQuestion, IQuestionBankQuestion } from "@/api/types/question/question"
import QuestionCard from "@/pages/QuizAttempt/components/QuestionCard"
import SingleAccordion from "@/core/components/SingleAccordion"
import RichTextRender from "@/core/components/RichTextRender"
import TimerCard from "@/pages/QuizAttempt/components/TimerCard"
import TotalMarksCard from "@/pages/QuizAttempt/components/TotalMarksCard"
import QuestionCursor from "@/pages/QuizAttempt/components/QuestionCursor"
import BaseModal from "@/core/components/Modals/BaseModal"
import PreviewReviewQuestionPage from "../Review"

export enum AnswerAccessLevel {
  ADMIN_ONLY = 0,
  TUTOR_ONLY = 1,
  PAYWALL = 2,
  UNLOCKED = 3,
}

const answerAccessLevelToString = (answerAccessLevel: AnswerAccessLevel) => {
  switch (answerAccessLevel) {
    case AnswerAccessLevel.ADMIN_ONLY:
      return "Admin Only"
    case AnswerAccessLevel.TUTOR_ONLY:
      return "Tutor Only"
    case AnswerAccessLevel.PAYWALL:
      return "Paywall"
    case AnswerAccessLevel.UNLOCKED:
      return "Everyone"
  }
}
const lang = "enGB"
const formatAnswersForDisplay = (_answers: any) => {
  // This maps our answers under the correct language
  let answers = _answers.map((answer: any) => ({
    ...answer,
    answer: answer.answer[lang],
  }))

  // Group answers by their header
  const answersDict: any = {}
  answers.forEach((answer: any) => {
    if (!answersDict[answer.header]) {
      answersDict[answer.header] = []
    }
    answersDict[answer.header].push(answer)
  })

  // Remove the header from every answer bar the first in each group
  for (let key of Object.keys(answersDict)) {
    let answers = answersDict[key]
    for (let answerKey in answers) {
      if ((answerKey as any) == 0) {
        answers[answerKey].heading = key
      }
    }
  }

  // Merge answersDict into a single array
  answers = Object.values(answersDict).reduce(
    (acc: any, curr: any) => acc.concat(curr),
    [],
  )

  return answers
}

export default function PreviewQuestionPage() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const { questionId } = useParams()
  const [question, setQuestion] = useState({} as IQuestionBankQuestion)
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user.user)
  const timerRef: any = useRef()
  const [totalSeconds, setTotalSeconds] = useState(0)

  const markModalDisclosure = useDisclosure()
  const loadQuestions = async () => {
    setIsPageLoading(true)

    const question = await getQuestion(questionId!)

    const optionalAnswers = question.answers.filter(
      (answer: any) => answer.isOptional === true,
    )
    const nonOptionalAnswers = question.answers.filter(
      (answer: any) => !answer.isOptional,
    )

    const answers = formatAnswersForDisplay(nonOptionalAnswers).concat(
      formatAnswersForDisplay(optionalAnswers),
    )

    setQuestion({
      ...question,
      aiPrompt: question.aiPrompt,
      question: question.question[lang],
      name: question.name[lang],
      interviewerInstructions: question.interviewerInstructions
        ? question.interviewerInstructions[lang]
        : null,
      intervieweeInstructions: question.intervieweeInstructions
        ? question.intervieweeInstructions[lang]
        : null,
      actorInstructions: question.actorInstructions
        ? question.actorInstructions[lang]
        : null,
      marks: question.answers.reduce(
        (acc: number, curr: any) =>
          acc + (!curr.isOptional ? parseInt(curr.marks) : 0),
        0,
      ),
      answers,
    })

    setIsPageLoading(false)
  }

  useEffect(() => {
    if (!user) {
      return
    }
    loadQuestions()
  }, [user])

  markModalDisclosure.onOpenChange = () => {
    if (markModalDisclosure.isOpen) {
      timerRef.current.resume()
    }
  }

  return (
    <Page showSidebar={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <>
          <PageHeader>
            <h1>Preview Question</h1>
          </PageHeader>
          <div className="page-halves">
            <BaseModal
              size="full"
              isOpen={markModalDisclosure.isOpen}
              onClose={markModalDisclosure.onClose}
              onOpenChange={markModalDisclosure.onOpenChange}
            >
              <PreviewReviewQuestionPage
                question={question}
                timeTaken={totalSeconds}
              />
            </BaseModal>
            <div className="page-left-half w-full">
              {/* Question card */}
              <QuestionCard
                question={question!.question}
                name={question!.name}
              />

              <div>
                <>
                  {question?.interviewerInstructionsEnabled && (
                    <>
                      <SingleAccordion
                        variant="shadow"
                        title="Examiner Instructions (only visible in single question mode)"
                      >
                        <RichTextRender
                          observable={question?.interviewerInstructions ?? ""}
                        />
                      </SingleAccordion>
                      <Spacer y={5} />
                    </>
                  )}

                  {question?.intervieweeInstructionsEnabled && (
                    <>
                      <SingleAccordion
                        variant="shadow"
                        title="Candidate Instructions (only visible in single question mode)"
                      >
                        <RichTextRender
                          observable={question?.intervieweeInstructions ?? ""}
                        />
                      </SingleAccordion>
                      <Spacer y={5} />
                    </>
                  )}
                  {question?.actorModeEnabled && (
                    <>
                      <SingleAccordion
                        variant="shadow"
                        title="Actor Instructions (only visible in single question mode)"
                      >
                        <RichTextRender
                          observable={question?.actorInstructions ?? ""}
                        />
                      </SingleAccordion>
                      <Spacer y={5} />
                    </>
                  )}
                </>
              </div>

              {/* Answer card */}
              <Card shadow="none" className="in-progress-quiz-answer-card">
                <Tabs
                  className="answer-type-select-tabs"
                  aria-label="Options"
                  color="primary"
                  variant="solid"
                >
                  <Tab
                    title={
                      <div className="answer-type-select-tab">
                        <FontAwesomeIcon icon={faAlignJustify} />
                        <span>Type your answer</span>
                      </div>
                    }
                  >
                    <Textarea
                      variant="bordered"
                      label="Answer"
                      size="lg"
                      labelPlacement="inside"
                      placeholder="Enter your answer"
                      className="interview-answer-text-area"
                    />
                  </Tab>
                </Tabs>
              </Card>
            </div>
            <div className="page-right-half">
              <TimerCard
                ref={timerRef}
                key={question!._id + "_Timer"}
                hidePause={false}
                autostart={true}
                expectedTime={question!.timeLimit}
                setTotalSecondsObs={setTotalSeconds}
              />
              <Spacer y={5} />
              <TotalMarksCard totalMarks={question!.marks} />
              <Spacer y={5} />
              <Button
                variant="solid"
                color="primary"
                fullWidth={true}
                onClick={() => {
                  timerRef.current.pause()
                  markModalDisclosure.onOpen()
                }}
              >
                Mark Question
              </Button>
            </div>
          </div>
        </>
      )}
    </Page>
  )
}
