import { AccordionItem, Button, Card } from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle, faPlay } from "@fortawesome/free-solid-svg-icons"
import { IQuestionCategory } from "@/api/types/question/question"
import { startQuiz } from "@/api/routes/quiz/quiz"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import { useAppSelector } from "@/app/hooks"
import RichTextRender from "@/core/components/RichTextRender"
import { QuizAccessLevel } from "@/api/types/quiz/quizzes"

interface Props {
  id: string
  name: string
  shortDescription: string
  description: string
  categories: IQuestionCategory[]
  actorModeEnabled: boolean
  quizAccessLevel: QuizAccessLevel
}

export default function QuizCard(props: Props) {
  const navigate = useNavigate()
  const currentUser = useAppSelector((state) => state.user.user)
  let canStartQuiz = false

  if (currentUser?.role === "admin") {
    canStartQuiz = true
  } else if (
    props.quizAccessLevel === QuizAccessLevel.TUTOR_ONLY &&
    currentUser?.role === "tutor"
  ) {
    canStartQuiz = true
  } else if (
    (props.quizAccessLevel === QuizAccessLevel.PAYWALL &&
      currentUser?.subscriptionData?.interviews?.value > 0) ||
    currentUser?.subscriptionData["unlimited-interviews"]?.value === true
  ) {
    canStartQuiz = true
  } else if (props.quizAccessLevel === QuizAccessLevel.UNLOCKED) {
    canStartQuiz = true
  }

  const NotEnoughCreditsError = () => {
    toast.error(
      "Unable to start this interview. Please check that your subscription is valid or you have enough credits to complete this action.",
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      },
    )
  }

  const beginQuizAsInterviewer = async () => {
    const quiz = await startQuiz(props.id)
    if (quiz) {
      navigate("/Quiz/Instructions/Interviewer/" + quiz.id)
    } else {
      NotEnoughCreditsError()
    }
  }

  const beginQuiz = async () => {
    const quiz = await startQuiz(props.id)
    if (quiz) {
      navigate("/Quiz/Instructions/Candidate/" + quiz.id)
    } else {
      NotEnoughCreditsError()
    }
  }

  const beginQuizAsActor = async () => {
    const quiz = await startQuiz(props.id)
    if (quiz) {
      navigate("/Quiz/Instructions/Actor/" + quiz.id)
    } else {
      NotEnoughCreditsError()
    }
  }

  return (
    <>
      <div className="available-quiz-list-content">
        <div className="available-quiz-list-body">
          <p className="quiz-list-quiz-desc">
            <RichTextRender observable={props.description} />
          </p>
          <p className="content-covered-text">Content covered</p>
          <div className="available-quiz-list-category-container">
            {props.categories.map((category, index) => (
              <Card
                fullWidth={false}
                shadow="none"
                className="category-card"
                key={index}
                style={{ backgroundColor: "hsl(var(" + category.color + "))" }}
              >
                <h1>{category.name}</h1>
                <RichTextRender observable={category.description} />
              </Card>
            ))}
          </div>
        </div>
        <div className="available-quiz-list-footer">
          <div className="available-quiz-list-footer-item"></div>
          <div className="available-quiz-list-footer-item">
            <Button
              color="primary"
              variant="shadow"
              startContent={<FontAwesomeIcon icon={faPlay} />}
              onClick={beginQuizAsInterviewer}
              isDisabled={!canStartQuiz}
              className="available-quiz-list-button interviewer-button"
            >
              Start as examiner
            </Button>
            {props.actorModeEnabled && (
              <Button
                color="secondary"
                variant="shadow"
                startContent={<FontAwesomeIcon icon={faPlay} />}
                onClick={beginQuizAsActor}
                isDisabled={!canStartQuiz}
                className="available-quiz-list-button interviewer-button"
              >
                Start as actor
              </Button>
            )}
            <Button
              color="success"
              variant="shadow"
              startContent={<FontAwesomeIcon icon={faPlay} />}
              onClick={beginQuiz}
              isDisabled={!canStartQuiz}
              className="available-quiz-list-button"
            >
              Start as candidate
            </Button>
          </div>
        </div>
        {!canStartQuiz && (
          <div className="available-quiz-list-footer">
            <div className="available-quiz-list-footer-item"></div>
            <div className="available-quiz-list-footer-item">
              <p className="text-danger">
                <FontAwesomeIcon icon={faExclamationCircle} /> This requires an
                appropriate subscription or credits to start
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
