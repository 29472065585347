import { getGeneralStatistics } from "@/api/routes/statistics"
import CircularProgress from "@/components/CircularProgress"
import { PageLoader } from "@/components/PageLoader"
import { colorFromPercentage, roundTo2Dp } from "@/core/helpers/utils"
import { Card, Spacer } from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"

export default function GeneralStatsOverviewWidget() {
  const [loading, setLoading] = useState(true)

  const [totalQuestionsAnswered, setTotalQuestionsAnswered] = useState(0)
  const [averageQuizScore, setAverageQuizScore] = useState(0)
  const [totalTimeSpent, setTotalTimeSpent] = useState(0)

  const loadData = async () => {
    setLoading(true)
    const data = await getGeneralStatistics()
    setTotalQuestionsAnswered(data.totalQuestionsAnswered)
    setAverageQuizScore(data.quizAverageScore)
    setTotalTimeSpent(data.totalTimeSpent / 60)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const getTotalTimeSpent = () => {
    if (totalTimeSpent < 60) {
      return roundTo2Dp(totalTimeSpent) + "m"
    } else if (totalTimeSpent >= 60) {
      return roundTo2Dp(totalTimeSpent / 60) + "h"
    } else if (totalTimeSpent >= 1440) {
      return roundTo2Dp(totalTimeSpent / 1440) + "d"
    }
  }

  return (
    <Card shadow="none" className="widget-1x1 general-stats-overview-widget">
      {loading ? (
        <>
          <Spacer y={44} />
          <PageLoader />
          <Spacer y={44} />
        </>
      ) : (
        <>
          <CircularProgress
            percentage={averageQuizScore}
            radius={100}
            progressCircleStyles={{
              strokeWidth: "0.7rem",
              progressColour: `hsl(var(--medcamps-${colorFromPercentage(
                averageQuizScore,
              )}-500))`,
            }}
            backgroundCircleStyles={{
              strokeWidth: "0.5rem",
              progressColour: `hsl(var(--medcamps-${colorFromPercentage(
                averageQuizScore,
              )}-50))`,
            }}
            subtext="Avg Quiz %"
          />
          <CircularProgress
            percentage={100}
            radius={100}
            progressCircleStyles={{
              strokeWidth: "0.7rem",
              progressColour: "hsl(var(--medcamps-default-500))",
            }}
            backgroundCircleStyles={{
              strokeWidth: "0.5rem",
              progressColour: "hsl(var(--medcamps-default-50))",
            }}
            text={totalQuestionsAnswered.toString()}
            subtext="Questions Answered"
          />

          <CircularProgress
            percentage={100}
            radius={100}
            progressCircleStyles={{
              strokeWidth: "0.7rem",
              progressColour: "hsl(var(--medcamps-default-500))",
            }}
            backgroundCircleStyles={{
              strokeWidth: "0.5rem",
              progressColour: "hsl(var(--medcamps-default-50))",
            }}
            text={getTotalTimeSpent()}
            subtext="Time Spent"
          />
        </>
      )}
    </Card>
  )
}
