import CircularProgress from "@/components/CircularProgress"
import { Card } from "@nextui-org/react"
import SingleStatWidget from "../../SingleStatWidget"
import { useEffect, useState } from "react"
import { getCategoryStatistics } from "@/api/routes/statistics"
import { PageLoader } from "@/components/PageLoader"
import { colorFromPercentage } from "@/core/helpers/utils"

interface Props {
  preLoadedData?: any
  controlledLoading?: boolean
}

interface IDisplayCategory {
  name: string | null
  average: number | null
}

export default function WorstAnsweredCategoryWidget({
  preLoadedData,
  controlledLoading,
}: Props) {
  const [loading, setLoading] = useState(true)
  const [worstAnsweredCategory, setWorstAnsweredCategory] = useState(
    {} as IDisplayCategory,
  )

  const getData = async () => {
    if (preLoadedData) {
      return preLoadedData
    } else {
      // send network request to get this data
      return await getCategoryStatistics()
    }
  }

  useEffect(() => {
    if (
      typeof controlledLoading !== "undefined" &&
      controlledLoading !== null
    ) {
      setLoading(controlledLoading)
    }
  }, [controlledLoading])

  const getWorstAnsweredCategory = (data: any) => {
    let lowestPerformer: string | null = null
    let lowestPerformerAverage: number | null = null
    for (let categoryName in data) {
      const category = data[categoryName]
      if (category.totalQuestionsMarked === 0) continue

      const average = category.questionsScoreScaledAverage
      if (average < (lowestPerformerAverage ?? 9999)) {
        lowestPerformer = categoryName
        lowestPerformerAverage = average
      }
    }

    return { name: lowestPerformer, average: lowestPerformerAverage }
  }

  useEffect(() => {
    if (
      typeof controlledLoading !== "undefined" &&
      controlledLoading !== null
    ) {
      setLoading(controlledLoading)
    }
  }, [controlledLoading])

  const loadPage = async () => {
    const data = await getData()
    const { name, average } = getWorstAnsweredCategory(data)
    setWorstAnsweredCategory({ name, average })
    setLoading(false)
  }

  useEffect(() => {
    if (preLoadedData !== null && typeof preLoadedData !== "undefined") {
      loadPage()
    }
  }, [preLoadedData])

  useEffect(() => {
    ;(async () => {
      if (typeof preLoadedData === "undefined") {
        setLoading(true)
        await loadPage()
      }
    })()
  }, [])

  return (
    <SingleStatWidget
      loading={loading}
      header="Worst Performing Category"
      subtext={worstAnsweredCategory.name ?? "N/A"}
      percentage={(worstAnsweredCategory.average ?? 0) * 100}
      foregroundColor={`hsl(var(--medcamps-${colorFromPercentage(
        (worstAnsweredCategory.average ?? 0) * 100,
      )}-500))`}
      backgroundColor={`hsl(var(--medcamps-${colorFromPercentage(
        (worstAnsweredCategory.average ?? 0) * 100,
      )}-50))`}
    />
  )
}
