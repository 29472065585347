import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { ICurrentUserEditModel, User } from "@/api/types/user/user"

export default async function getProfile(): Promise<User | number> {
  try {
    const res = await axios.get("/user")

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return LoginEnum.UNAUTHORIZED
    } else {
      // Other error
      return LoginEnum.OTHER
    }
  }
}

export async function updateMyProfile(body: ICurrentUserEditModel) {
  try {
    const res = await axios.put("/user", body)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteMyAccount() {
  try {
    const res = await axios.delete("/user")

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function resetQuizData() {
  try {
    const res = await axios.put("/user/reset-quiz-data")

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function register(
  firstName: string,
  lastName: string,
  email: string,
) {
  try {
    const res = await axios.post("/user/register", {
      username: email,
      firstName,
      lastName,
    })

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function setPasswordWithToken(password: string, token: string) {
  try {
    const res = await axios.put("/user/set-password-with-token/" + token, {
      password,
    })

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function requestPasswordResetLink(email: string) {
  try {
    const res = await axios.post("/user/request-password-reset-link", {
      username: email,
    })

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function activateTutoring(token: string) {
  try {
    const res = await axios.put("/user/activate-tutoring/" + token)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function declineTutoring(token: string) {
  try {
    const res = await axios.put("/user/decline-tutoring/" + token)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getTutorInviteDetails(token: string) {
  try {
    const res = await axios.get("/user/get-tutor-invite-details/" + token)

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return LoginEnum.UNAUTHORIZED
    } else {
      // Other error
      return LoginEnum.OTHER
    }
  }
}
