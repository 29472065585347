import { Card } from "@nextui-org/react"
import "./index.css"

interface Props {
  className?: string
  greenProgress: number
  redProgress: number
  children: React.ReactNode
  shadow?: "none" | "sm" | "md" | "lg" | undefined
}

export default function ProgressCard(props: Props) {
  return (
    <Card
      className={`progress-card-main ${props.className ?? ""}`}
      shadow={props.shadow}
    >
      <div className="progress-card-background">
        <div
          className="progress-card-green-progress"
          style={{ width: `${props.greenProgress}%` }}
        ></div>
        <div
          className="progress-card-red-progress"
          style={{ width: `${props.redProgress}%` }}
        ></div>
      </div>
      <div className="progress-card-content">{props.children}</div>
    </Card>
  )
}
