import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import getProfile from "@/api/routes/user/user"
import { User } from "@/api/types/user/user"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { useLocation, useNavigate } from "react-router"
import loadUserProfile from "@/core/helpers/load-user-profile"
import axios from "@/core/helpers/axios"
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent"
import ReactGA from "react-ga4"

export const getAuthTokenFromLocalStorage = () => {
  return localStorage.getItem("auth-token")
}

// Ensures that any global states are restored with the latest version every refresh
export default function PageLoadActions() {
  const { user } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()

  const loadTokenFromLocalStorage = async () => {
    const token = getAuthTokenFromLocalStorage()
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    axios.defaults.headers.common["X-Organisation-Domain"] =
      window.location.hostname
  }

  const checkAuth = async () => {
    if (!user) {
      loadUserProfile(dispatch)
    }
  }

  useEffect(() => {
    loadTokenFromLocalStorage()
    checkAuth()
  }, [])

  useEffect(() => {
    const isConsent = getCookieConsentValue()
    if (isConsent === "true") {
      handleAcceptCookie()
    }
  }, [])

  const handleAcceptCookie = () => {
    if (import.meta.env.VITE_GA_ID) {
      ReactGA.initialize(import.meta.env.VITE_GA_ID)
      ReactGA.gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      })
    }
  }

  const handleDeclineCookie = () => {
    ReactGA.gtag("consent", "default", {
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      analytics_storage: "denied",
    })
  }

  return (
    <div>
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}
