import { PageData } from "@/api/types/general"
import axios from "@/core/helpers/axios"
import { IQuizBank } from "@/api/types/quiz/quiz-bank"

export async function getAvailableQuizzes(
  pageData?: PageData,
  name?: string,
): Promise<any | undefined> {
  try {
    let urlParams = new URLSearchParams()

    if (pageData?.fetchMeta === true) {
      urlParams.append("meta", "1")
    }
    if (pageData?.page) {
      urlParams.append("page", pageData.page.toString())
    }
    if (pageData?.limit) {
      urlParams.append("limit", pageData.limit.toString())
    }
    if (name) {
      urlParams.append("name", name)
    }

    const res = await axios.get("/quiz-bank?" + urlParams.toString())

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function getQuizMetaData(
  quizId: string,
): Promise<IQuizBank | undefined> {
  try {
    const res = await axios.get("/quiz-bank/" + quizId)

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}
