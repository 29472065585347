import React, { useEffect, useState } from "react"
import { Card, Spacer, Button, Input, Checkbox } from "@nextui-org/react"
import "./index.css"
import login from "@/api/routes/auth/login"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { useNavigate } from "react-router-dom"
import { requestPasswordResetLink } from "@/api/routes/user/user"
import { User } from "@/api/types/user/user"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
export default function ForgotPasswordPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [loadingLogin, setLoadingLogin] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<null | string>(null)

  const [email, setEmail] = useState<string>("")

  const [success, setSuccess] = useState<boolean>(false)

  const userSelector = useAppSelector((state) => state.user)

  useEffect(() => {
    if (userSelector.user) {
      navigate("/")
    }
  }, [userSelector])

  const handleLogin = async () => {
    setLoadingLogin(true)
    setLoginError(null)

    const res = await requestPasswordResetLink(email)
    if (res === LoginEnum.SUCCESS) {
      // Show success screen
      setSuccess(true)
    } else if (res === LoginEnum.UNAUTHORIZED) {
    }

    setLoadingLogin(false)
  }

  return (
    <div className="login-container">
      <Card style={{ minWidth: "420px", padding: "20px" }}>
        {!success ? (
          <>
            <h1>Forgot Password</h1>
            <Input
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            <Spacer y={5} />
            <Button
              onClick={handleLogin}
              color="primary"
              isLoading={loadingLogin}
            >
              Send Reset Link
            </Button>

            {loginError ? (
              <>
                <Spacer y={2.5} />
                <span className="text-danger">{loginError}</span>
              </>
            ) : null}
          </>
        ) : (
          <div className="register-success-container">
            <FontAwesomeIcon
              icon={faEnvelope}
              fontSize={60}
              color="hsl(var(--medcamps-success-500))"
            />
            <h1
              style={{
                color: "hsl(var(--medcamps-success-800))",
                textAlign: "center",
                margin: 0,
              }}
            >
              Success
            </h1>
            <p style={{ margin: 0 }}>
              Please check your email for a link to set your password
            </p>
          </div>
        )}
      </Card>
    </div>
  )
}
