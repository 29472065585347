import { deleteQuiz } from "@/api/routes/admin/quiz-bank"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Tooltip, useDisclosure } from "@nextui-org/react"

interface Props {
  quizId: string
  quizTitle: string
  listQuizzes: () => Promise<void>
}
export default function DeleteQuizButton({
  quizId,
  quizTitle,
  listQuizzes,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isOpen}
        onConfirm={async () => {
          await deleteQuiz(quizId)
          await listQuizzes()
          onClose()
        }}
        onClose={onClose}
        title="Delete Quiz"
        description={`Are you sure you want to delete '${quizTitle}'? This action cannot be reversed.`}
      />
      <Tooltip aria-label="Details" color="danger" content="Delete quiz">
        <Link onClick={onOpen}>
          <span className="text-lg text-danger cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </Link>
      </Tooltip>
    </>
  )
}
