import CircularProgress from "@/components/CircularProgress"
import { Card } from "@nextui-org/react"
import SingleStatWidget from "../../SingleStatWidget"
import { useEffect, useState } from "react"
import { getCategoryStatistics } from "@/api/routes/statistics"
import { PageLoader } from "@/components/PageLoader"
import { colorFromPercentage } from "@/core/helpers/utils"

// Allows us to pass through data to prevent excess network requests.
interface Props {
  preLoadedData?: any
  controlledLoading?: boolean
}

interface IDisplayCategory {
  name: string | null
  average: number | null
}

export default function BestAnsweredCategoryWidget({
  preLoadedData,
  controlledLoading,
}: Props) {
  let [loading, setLoading] = useState(true)
  const [bestAnsweredCategory, setBestAnsweredCategory] = useState(
    {} as IDisplayCategory,
  )

  const getData = async () => {
    if (preLoadedData) {
      return preLoadedData
    } else {
      // send network request to get this data
      return await getCategoryStatistics()
    }
  }

  const getBestAnsweredCategory = (data: any) => {
    let highestPerformer: string | null = null
    let highestPerformerAverage: number | null = null
    for (let categoryName in data) {
      const category = data[categoryName]
      const average = category.questionsScoreScaledAverage
      if (average > (highestPerformerAverage ?? -1)) {
        highestPerformer = categoryName
        highestPerformerAverage = average
      }
    }

    return { name: highestPerformer, average: highestPerformerAverage }
  }

  useEffect(() => {
    if (
      typeof controlledLoading !== "undefined" &&
      controlledLoading !== null
    ) {
      setLoading(controlledLoading)
    }
  }, [controlledLoading])

  const loadPage = async () => {
    setLoading(true)
    const data = await getData()
    const { name, average } = getBestAnsweredCategory(data)
    setBestAnsweredCategory({ name, average })
    setLoading(false)
  }

  useEffect(() => {
    if (preLoadedData !== null && typeof preLoadedData !== "undefined") {
      loadPage()
    }
  }, [preLoadedData])

  useEffect(() => {
    ;(async () => {
      if (typeof preLoadedData === "undefined") {
        await loadPage()
      }
    })()
  }, [])

  return (
    <SingleStatWidget
      loading={loading}
      header="Best Performing Category"
      subtext={bestAnsweredCategory.name ?? "N/A"}
      percentage={(bestAnsweredCategory.average ?? 0) * 100}
      foregroundColor={`hsl(var(--medcamps-${colorFromPercentage(
        (bestAnsweredCategory.average ?? 0) * 100,
      )}-500))`}
      backgroundColor={`hsl(var(--medcamps-${colorFromPercentage(
        (bestAnsweredCategory.average ?? 0) * 100,
      )}-50))`}
    />
  )
}
