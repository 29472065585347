import { Button } from "@nextui-org/react"
import BaseModal from "@/core/components/Modals/BaseModal"
import { useNavigate } from "react-router"

interface Props {
  quizId: string
  isOpen: boolean
  onClose: () => void
}

export default function ContinueAsInterviewerOrIntervieweeModal(props: Props) {
  const navigate = useNavigate()
  const resumeAsInterviewer = () => {
    navigate("/Quiz/Mark/" + props.quizId)
  }

  const resumeAsInterviewee = () => {
    navigate("/Quiz/Candidate/" + props.quizId)
  }

  return (
    <BaseModal
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Continue as examiner or candidate?"
      body={
        <span>
          Would you like to continue as the examiner or the candidate?
        </span>
      }
      buttons={
        <>
          <Button color="primary" variant="flat" onClick={resumeAsInterviewer}>
            Examiner
          </Button>
          <Button color="success" variant="flat" onClick={resumeAsInterviewee}>
            Candidate
          </Button>
        </>
      }
    />
  )
}
