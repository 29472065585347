import { IAnswerMetaData } from "@/api/types/question/question"
import SwitchBox from "@/core/components/SwitchBox"
import FullAnswerCheckbox from "@/pages/QuizAttempt/components/FullAnswerCheckbox"
import { Card, CheckboxGroup, Spacer } from "@nextui-org/react"

interface Props {
  question: any
  optionalAnswers: any
  optionalAnswersEnabled: any
  setOptionalAnswersEnabled: any
  optionalAnswersScored: any
  setOptionalAnswersScored: any
  asMarker: any
  setCanSave: any
}

export default function OptionalMarkAnswerCheckboxes({
  question,
  optionalAnswers,
  optionalAnswersEnabled,
  setOptionalAnswersEnabled,
  optionalAnswersScored,
  setOptionalAnswersScored,
  asMarker,
  setCanSave,
}: Props) {
  return (
    <Card shadow="none" className="in-progress-quiz-answer-card">
      <p className="rich-text-area-label">
        Select the optional answers scored by the interviewee
      </p>
      {optionalAnswers.map((answerMetaData: IAnswerMetaData, index: number) => {
        return (
          <div key={question!.id + answerMetaData.answer + index.toString()}>
            {answerMetaData.heading && (
              <>
                <h2>{answerMetaData.heading}</h2>
                <Spacer />
              </>
            )}
            <div>
              <SwitchBox
                title={answerMetaData.answer}
                hideHeaderOnOpen={true}
                isDisabled={!asMarker}
                checked={optionalAnswersEnabled.includes(answerMetaData._id)}
                setChecked={(val: boolean) => {
                  if (val) {
                    setOptionalAnswersEnabled((prev: any) => {
                      return [...prev, answerMetaData._id]
                    })
                  } else {
                    setOptionalAnswersEnabled((prev: any) => {
                      return prev.filter((x: any) => x !== answerMetaData._id)
                    })
                  }
                }}
              >
                <CheckboxGroup
                  label=""
                  color="success"
                  value={optionalAnswers
                    .map((x: any, i: number) =>
                      optionalAnswersScored.includes(x._id)
                        ? i.toString()
                        : null,
                    )
                    .filter((x: any) => x !== null)}
                  onChange={
                    asMarker
                      ? (scoredIndexes: any) => {
                          const scoredIds = scoredIndexes.map(
                            (x: any) => optionalAnswers[x]._id,
                          )
                          setOptionalAnswersScored(scoredIds)
                        }
                      : () => {}
                  }
                  classNames={{
                    base:
                      "w-full " + asMarker ? "" : "checkbox-silent-disabled",
                  }}
                >
                  <FullAnswerCheckbox
                    isSelected={optionalAnswersScored.includes(
                      answerMetaData._id,
                    )}
                    asMarker={asMarker}
                    answer={answerMetaData.answer}
                    index={index}
                    marksToAdd={answerMetaData.marks}
                    totalMarks={question!.marks}
                  />
                </CheckboxGroup>
              </SwitchBox>
              <Spacer />
            </div>
          </div>
        )
      })}
    </Card>
  )
}
