import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { NextUIProvider } from "@nextui-org/react"
import { store } from "./app/store"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./index.css"
import ErrorPage from "./ErrorPage"
import LoginPage from "./pages/Accounts/Login"
import HomePage from "./pages/Home"
import PageLoadActions from "./core/components/PageLoadActions"
import ChooseQuizPage from "./pages/ChooseQuiz"
import LogOutPage from "./pages/Accounts/LogOut"
import QuizzesPage from "./pages/Quizzes"
import InProgressQuizPage from "./pages/QuizAttempt/InProgress"
import ManageQuestionsPage from "./pages/Admin/ManageQuestions"
import ManageQuizzesPage from "./pages/Admin/ManageQuizzes"
import NewQuestionPage from "./pages/Admin/CreateOrEditQuestion/NewQuestion"
import EditQuestionPage from "./pages/Admin/CreateOrEditQuestion/EditQuestion"
import ManageCategoriesPage from "./pages/Admin/ManageCategories"
import CreateCategoryPage from "./pages/Admin/CreateOrEditCategory/CreateCategoryPage"
import EditCategoryPage from "./pages/Admin/CreateOrEditCategory/EditCategoryPage"
import CreateQuizPage from "./pages/Admin/CreateOrEditQuiz/CreateQuizPage"
import EditQuizPage from "./pages/Admin/CreateOrEditQuiz/EditQuizPage"
import { ManageUsersPage } from "./pages/Admin/ManageUsers"
import CreateUserPage from "./pages/Admin/CreateOrEditUser/CreateUserPage"
import EditUserPage from "./pages/Admin/CreateOrEditUser/EditUserPage"
import RegisterPage from "./pages/Accounts/Register"
import ForgotPasswordPage from "./pages/Accounts/ForgotPassword"
import SetPasswordPage from "./pages/Accounts/SetPassword"
import ReviewQuizPage from "./pages/QuizAttempt/Review"
import MyPerformancePage from "./pages/MyPerformance"
import AccountSettingsPage from "./pages/AccountSettings"
import QuizCompletePage from "./pages/QuizAttempt/InProgress/QuizComplete"
import { ManageStudentsPage } from "./pages/Tutor/ManageStudents"
import AcceptTutorInvite from "./pages/Accounts/AcceptTutorInvite"
import { ManageSubscriptionPlansPage } from "./pages/Admin/ManageSubscriptionPlans"
import CreateSubscriptionPlanPage from "./pages/Admin/CreateOrEditSubscriptionPlan/CreateSubscriptionPlanPage"
import EditSubscriptionPlanPage from "./pages/Admin/CreateOrEditSubscriptionPlan/EditSubscriptionPlanPage"
import ManageUserSubscriptionsPage from "./pages/Admin/CreateOrEditUser/ManageUserSubscriptions"
import QuizInstructionsPage from "./pages/QuizInstructions"
import PreviewQuestionPage from "./pages/Question/Preview/InProgress"
import QuestionsPage from "./pages/Questions"
import LearningPage from "./pages/Learning"
import SubscriptionFlow from "./pages/SubscriptionFlow"
import ManageSubscriptionsPage from "./pages/Subscriptions/Manage"
import CheckoutPage from "./pages/Checkout"

// Import bg
import "./assets/images/light-background.png"

// Import fonts
import "./assets/fonts/ltpanneaux-regular.ttf"
import "./assets/fonts/ltpanneaux-medium.ttf"
import "./assets/fonts/ltpanneaux-semibold.ttf"
import "./assets/fonts/ltpanneaux-bold.ttf"
import ManageOrganisationsPage from "./pages/Admin/ManageOrganisations"
import CreateOrganisationPage from "./pages/Admin/CreateOrEditOrganisation/CreateOrganisation"
import EditOrganisationPage from "./pages/Admin/CreateOrEditOrganisation/EditOrganisation"

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Home",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Register",
    element: <RegisterPage />,
  },
  {
    path: "/LogOut",
    element: <LogOutPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/ForgotPassword",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/AcceptTutorInvite/:token",
    element: <AcceptTutorInvite />,
  },
  {
    path: "/SetPassword/:token",
    element: <SetPasswordPage />,
  },
  {
    path: "/AccountSettings",
    element: <AccountSettingsPage />,
  },
  {
    path: "/Interviews",
    element: <QuizzesPage />,
  },
  {
    path: "/Questions",
    element: <QuestionsPage />,
  },
  {
    path: "/Learning",
    element: <LearningPage />,
  },
  {
    path: "/Learning/:route*",
    element: <LearningPage />,
  },
  {
    path: "/Performance",
    element: <MyPerformancePage />,
  },
  {
    path: "/ChooseQuiz",
    element: <ChooseQuizPage />,
  },
  {
    path: "/Quiz/Instructions/:instructionType/:quizId",
    element: <QuizInstructionsPage />,
  },
  {
    path: "/Quiz/Actor/:quizId",
    element: <InProgressQuizPage isActorMode={true} />,
  },
  {
    path: "/Quiz/Candidate/:quizId",
    element: <InProgressQuizPage />,
  },
  {
    path: "/Quiz/Candidate/:quizId/Complete",
    element: <QuizCompletePage />,
  },
  {
    path: "/Quiz/Mark/:quizId",
    element: <ReviewQuizPage asMarker={true} />,
  },
  {
    path: "/Quiz/Review/:quizId",
    element: <ReviewQuizPage asMarker={false} />,
  },
  {
    path: "/ManageQuestions",
    element: <ManageQuestionsPage />,
  },
  {
    path: "/Tutor/Students",
    element: <ManageStudentsPage />,
  },
  {
    path: "/Admin/Question/New",
    element: <NewQuestionPage />,
  },
  {
    path: "/Admin/Question/Preview/:questionId",
    element: <PreviewQuestionPage />,
  },
  {
    path: "/Admin/Question/Edit/:questionId",
    element: <EditQuestionPage />,
  },
  {
    path: "/Admin/Categories",
    element: <ManageCategoriesPage />,
  },
  {
    path: "/Admin/Category/New",
    element: <CreateCategoryPage />,
  },
  {
    path: "/Admin/Category/Edit/:categoryId",
    element: <EditCategoryPage />,
  },
  {
    path: "/Admin/Organisations",
    element: <ManageOrganisationsPage />,
  },
  {
    path: "/Admin/Organisation/New",
    element: <CreateOrganisationPage />,
  },
  {
    path: "/Admin/Organisation/Edit/:organisationId",
    element: <EditOrganisationPage />,
  },
  {
    path: "/Admin/Exams",
    element: <ManageQuizzesPage />,
  },
  {
    path: "/Admin/Exam/New",
    element: <CreateQuizPage />,
  },
  {
    path: "/Admin/Quiz/Edit/:quizId",
    element: <EditQuizPage />,
  },
  {
    path: "/Admin/Users",
    element: <ManageUsersPage />,
  },
  {
    path: "/Admin/User/New",
    element: <CreateUserPage />,
  },
  {
    path: "/Admin/User/:userId",
    element: <EditUserPage />,
  },
  {
    path: "/Admin/SubscriptionPlans",
    element: <ManageSubscriptionPlansPage />,
  },
  {
    path: "/Admin/SubscriptionPlan/New",
    element: <CreateSubscriptionPlanPage />,
  },
  {
    path: "/Admin/SubscriptionPlan/Edit/:subscriptionPlanId",
    element: <EditSubscriptionPlanPage />,
  },
  {
    path: "/Admin/User/:userId/Subscriptions",
    element: <ManageUserSubscriptionsPage />,
  },
  {
    path: "/Checkout",
    element: <CheckoutPage />,
  },
  {
    path: "/Subscriptions/Manage",
    element: <ManageSubscriptionsPage />,
  },
  {
    path: "/PurchaseSubscription/:subscriptionId",
    element: <SubscriptionFlow />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <NextUIProvider>
        <PageLoadActions />
        <div id="__next" className="light text-foreground light-background">
          <RouterProvider router={router} />
        </div>
      </NextUIProvider>
    </ReduxProvider>
  </React.StrictMode>,
)
