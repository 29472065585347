import {
  CategoryAccessLevel,
  CategoryAccessLevelString,
} from "@/api/types/question/question"
import BaseModal from "@/core/components/Modals/BaseModal"
import SelectFromEnums from "@/core/components/SelectFromEnums"
import { isFieldFilledOut, isSetFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Select,
  SelectItem,
  Spacer,
  Textarea,
  UseDisclosureProps,
} from "@nextui-org/react"
import { Key, useState } from "react"

interface Props {
  disclosure: UseDisclosureProps
  addVideo: (val: any) => void
}
export default function AddVideoModal({ disclosure, addVideo }: Props) {
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")
  const [description, setDescription] = useState("")
  const [categoryAccessLevel, setCategoryAccessLevel] = useState<any>(
    new Set<Key>([]),
  )

  const formValidate = () => {
    if (
      isFieldFilledOut(title) &&
      isFieldFilledOut(description) &&
      isFieldFilledOut(url) &&
      isSetFieldFilledOut(categoryAccessLevel)
    ) {
      return false
    }
    return true
  }

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Add Video"
      size="xl"
      body={
        <div>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Video Title"
          />
          <Spacer y={2} />
          <Input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Video URL"
          />
          <Spacer y={2} />
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Video Description"
          />
          <Spacer y={2} />
          <div className="input-wrapper">
            <SelectFromEnums
              label="Access Level"
              placeholder="Select who can view this content"
              selectedKeys={categoryAccessLevel}
              onSelectionChange={setCategoryAccessLevel}
              stringEnum={CategoryAccessLevelString}
              valuesEnum={CategoryAccessLevel}
            />
          </div>
          <Spacer y={2} />
          <p>
            <i>
              (You can add related links after you have created this set of
              notes - just click the edit icon).
            </i>
          </p>
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              addVideo({
                Title: title,
                URL: url,
                Description: description,
                AccessLevel: categoryAccessLevel,
              })
              setTitle("")
              setUrl("")
              setDescription("")
            }}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
          >
            Add Video
          </Button>
        </div>
      }
    />
  )
}
