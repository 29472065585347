import {
  IQuestionCategoryVideo,
  ISubQuestionCategory,
} from "@/api/types/question/question"
import TableSelectInput from "../../TableSelectInput"
import { Key, useEffect, useState } from "react"
import AddVideoModal from "@/pages/Admin/CreateOrEditCategory/AddVideoModal"
import EditVideoModal from "@/pages/Admin/CreateOrEditCategory/EditVideoModal"
import {
  addVideoRoute,
  deleteVideoRoute,
  updateVideoRoute,
} from "@/api/routes/admin/question-category/video"

interface Props {
  index: number
  subCategory: ISubQuestionCategory
  setSubCategories: React.Dispatch<React.SetStateAction<ISubQuestionCategory[]>>
  currentCategoryPath: string[]
  createVideoModal: any
  editVideoModal: any
}

export default function VideosList({
  index,
  subCategory,
  setSubCategories,
  currentCategoryPath,
  editVideoModal,
  createVideoModal,
}: Props) {
  const [selectedLinks, setSelectedLinks] = useState<any>([])
  const [editIndex, setEditIndex] = useState<number>(0)
  const [isVideosSectionLoading, setIsVideosSectionLoading] =
    useState<boolean>(false)

  const addVideo = async (video: any) => {
    setIsVideosSectionLoading(true)
    const videoId = await addVideoRoute(
      video.Title,
      video.Description,
      video.URL,
      [],
      parseInt([...video.AccessLevel].join("")),
      [...currentCategoryPath, subCategory._id],
    )

    setSubCategories((prev: any) => {
      let current = [...prev]
      if (!current[index].videos) {
        current[index].videos = []
      }
      current[index].videos.push({
        _id: videoId,
        title: video.Title,
        description: video.Description,
        url: video.URL,
        links: [],
        accessLevel: parseInt([...video.AccessLevel].join("")),
      })
      return current
    })

    setSelectedVideos((prev: any) => {
      return [
        ...prev,
        {
          _id: videoId,
          Title: video.Title,
          Description: video.Description,
          URL: video.URL,
          Links: [],
          AccessLevel: video.AccessLevel,
        },
      ]
    })

    createVideoModal.onClose()
    setIsVideosSectionLoading(false)
  }

  const onEditVideo = async (video: any, videos: any) => {
    setIsVideosSectionLoading(true)

    await updateVideoRoute(
      video._id,
      video.Title,
      video.Description,
      video.URL,
      selectedLinks.map((x: any) => ({
        _id: x._id ?? null,
        title: x.Title,
        url: x.URL,
        description: x.Description,
      })),
      parseInt([...video.AccessLevel].join("")),
      [...currentCategoryPath, subCategory._id],
    )

    setSubCategories((prev: any) => {
      let current = [...prev]
      current[index].videos = current[index].videos.map(
        (x: any, index: number) => {
          return {
            ...x,
            title: videos[index].Title,
            description: videos[index].Description,
            url: videos[index].URL,
            links: videos[index].Links,
            accessLevel: videos[index].AccessLevel,
          }
        },
      )
      return current
    })

    setSelectedVideos((prev: any) => {
      let newVal = [...prev]
      newVal[editIndex] = {
        _id: video._id,
        Title: videos[editIndex].Title,
        Description: videos[editIndex].Description,
        URL: videos[editIndex].URL,
        Links: videos[editIndex].Links,
        AccessLevel: videos[editIndex].AccessLevel,
      }
      return newVal
    })

    setIsVideosSectionLoading(false)
  }

  const onDelete = async (_id: string) => {
    setIsVideosSectionLoading(true)
    await deleteVideoRoute(_id, [...currentCategoryPath, subCategory._id])

    setSubCategories((prev: any) => {
      let newSubCategories = [...prev]
      newSubCategories[index].videos = newSubCategories[index].videos.filter(
        (x: any) => x._id !== _id,
      )
      return newSubCategories
    })
    setIsVideosSectionLoading(false)
  }

  const [selectedVideos, setSelectedVideos] = useState<any[]>([])

  useEffect(() => {
    const videos =
      subCategory.videos?.map((x) => ({
        _id: x._id,
        Title: x.title,
        URL: x.url,
        Description: x.description,
        Links:
          x.links?.map((y) => ({
            _id: y._id,
            Title: y.title,
            URL: y.url,
            Description: y.description,
          })) ?? [],
        AccessLevel: new Set<Key>([x.accessLevel.toString()]),
      })) ?? []

    setSelectedVideos(videos)
  }, [subCategory])

  return (
    <>
      <AddVideoModal addVideo={addVideo} disclosure={createVideoModal} />
      <EditVideoModal
        disclosure={editVideoModal}
        selectedLinks={selectedLinks}
        setSelectedLinks={setSelectedLinks}
        selectedVideos={selectedVideos}
        onEditVideo={onEditVideo}
        index={editIndex}
      />

      <div className="sub-category-notes">
        <div className="input-wrapper">
          <TableSelectInput
            aria-label="Add video"
            title={"Videos"}
            addNewPlaceholder="+ Add a new video"
            onAddNew={createVideoModal.onOpen}
            onEdit={(index: number) => {
              setEditIndex(index)
              editVideoModal.onOpen()
            }}
            isLoading={isVideosSectionLoading}
            selectedItems={selectedVideos}
            setSelectedItems={(videos) => {
              setSubCategories((prev) => {
                let newSubCategories = [...prev]
                newSubCategories[index].videos = videos
                return newSubCategories
              })
            }}
            onDelete={onDelete}
            headers={["Title", "URL"]}
            hideMovementArrows={true}
          />
        </div>
      </div>
    </>
  )
}
