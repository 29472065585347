import { useEffect } from "react"
import { PageLoader } from "@/components/PageLoader"
import logout from "@/api/routes/auth/logout"
import { useNavigate } from "react-router"
import { useAppDispatch } from "@/app/hooks"

export default function LogOutPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      await logout()
      dispatch({
        type: "user/clear",
      })

      navigate("/Login")
    })()
  }, [])
  return <PageLoader />
}
