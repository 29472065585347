import { deleteNotes } from "@/api/routes/admin/question-category/notes"
import { ISubQuestionCategory } from "@/api/types/question/question"
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Spacer } from "@nextui-org/react"

interface Props {
  index: number
  subCategory: ISubQuestionCategory
  setSubCategories: React.Dispatch<React.SetStateAction<ISubQuestionCategory[]>>
  currentCategoryPath: string[]

  setModalIndex: React.Dispatch<React.SetStateAction<number>>
  setModalNotesIndex: React.Dispatch<React.SetStateAction<number>>
  setModalNotesId: React.Dispatch<React.SetStateAction<string>>
  setModalPath: React.Dispatch<React.SetStateAction<string[]>>
  editNotesModal: any
  createNotesModal: any
}

export default function NotesList({
  index,
  subCategory,
  setSubCategories,
  currentCategoryPath,
  setModalIndex,
  setModalNotesIndex,
  setModalNotesId,
  setModalPath,
  editNotesModal,
  createNotesModal,
}: Props) {
  return (
    <>
      <div className="notes-header">
        Notes
        <Button
          variant="bordered"
          color="primary"
          onClick={() => {
            setModalPath([...currentCategoryPath, subCategory._id!])
            setModalIndex(index)
            createNotesModal.onOpen()
          }}
        >
          <FontAwesomeIcon fontSize={"1rem"} icon={faPlus} /> Add Notes
        </Button>
      </div>
      <Spacer y={3} />
      <div className="sub-category-notes">
        {subCategory.notes?.map((note, noteIndex) => (
          <div className="note-card">
            {note.title}
            <div className="note-card-buttons">
              <Button
                color="primary"
                variant="faded"
                radius="full"
                isIconOnly={true}
                onClick={() => {
                  setModalIndex(index)
                  setModalNotesIndex(noteIndex)
                  setModalNotesId(note._id!)
                  setModalPath([...currentCategoryPath, subCategory._id!])
                  editNotesModal.onOpen()
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Spacer x={2} />
              <Button
                color="danger"
                variant="faded"
                radius="full"
                isIconOnly={true}
                onClick={async () => {
                  await deleteNotes(note._id, [
                    ...currentCategoryPath,
                    subCategory._id!,
                  ])

                  setSubCategories((prev) => {
                    let newSubCategories = [...prev]
                    newSubCategories[index].notes?.splice(noteIndex, 1)
                    return newSubCategories
                  })
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
