export const colorFromPercentage = (percentage: number) => {
  if (percentage < 50) {
    return "danger"
  } else if (percentage < 70) {
    return "warning"
  } else {
    return "success"
  }
}

export const roundTo2Dp = (num: number) => {
  return Math.round(num * 100) / 100
}

export const isLanguageFieldFilledOut = (obs: any) => {
  if (obs && Object.keys(obs).length > 0) {
    for (let key of Object.keys(obs)) {
      if ((!obs[key] || obs[key] === "") && typeof obs[key] !== "boolean") {
        return false
      }
    }
  } else {
    return false
  }

  return true
}

export const isFieldFilledOut = (field: any) => {
  if (typeof field !== "undefined" && field !== "") {
    return true
  }

  return false
}

export const isSetFieldFilledOut = (field: any) => {
  return isFieldFilledOut([...field].join(""))
}
