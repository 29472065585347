import { useAppSelector } from "@/app/hooks"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import SubscriptionCard from "./SubscriptionCard"
import { useEffect, useState } from "react"
import { PageLoader } from "@/components/PageLoader"
import { listUserSubscriptions } from "@/api/routes/subscription"
import { IUserSubscriptionMetadata } from "@/api/types/user/user"

export default function ManageSubscriptionsPage() {
  const user = useAppSelector((x) => x.user?.user)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userSubscriptions, setUserSubscriptions] =
    useState<IUserSubscriptionMetadata[]>()

  const loadSubscriptions = async () => {
    setIsLoading(true)
    const subs = await listUserSubscriptions()
    setUserSubscriptions(subs)
    setIsLoading(false)
  }

  useEffect(() => {
    loadSubscriptions()
  }, [])

  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>My Subscriptions</h1>
      </PageHeader>
      {isLoading && <PageLoader />}
      {userSubscriptions && (
        <div className="widget-grid">
          {userSubscriptions.map((sub, i) => (
            <SubscriptionCard
              key={i}
              subscription={sub}
              reloadSubscriptions={loadSubscriptions}
            />
          ))}
        </div>
      )}
    </Page>
  )
}
