import { listUsers } from "@/api/routes/admin/user"
import { PageLoader } from "@/components/PageLoader"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import { faEdit, faNewspaper, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Chip,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from "@nextui-org/react"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import DeleteUserButton from "./DeleteUserButton"

const columns = [
  { name: "NAME", uid: "name" },
  { name: "EMAIL", uid: "email" },
  { name: "CURRENT SUBSCRIPTION", uid: "currentSubscription" },
  { name: "ROLE", uid: "role" },
  { name: "ACTIONS", uid: "actions" },
]

const toTitleCase = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export function ManageUsersPage() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [allUsers, setAllUsers] = useState([] as any)
  const [displayUsers, setDisplayUsers] = useState([] as any)
  const navigate = useNavigate()

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)

  const loadUsers = async (page?: number) => {
    setIsPageLoading(true)
    setCurrentPage(page || 1)

    const totalRecords: any = await listUsers({
      fetchMeta: true,
      limit: pageLimit,
    })
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    const _allUsers = await listUsers({
      fetchMeta: false,
      limit: pageLimit,
      page: page ?? currentPage,
    })
    if (_allUsers != null) {
      setAllUsers(_allUsers.users)

      setDisplayUsers(
        _allUsers.users.map((user: any) => ({
          _id: user._id,
          name: user.firstName + " " + user.lastName,
          email: user.username,
          role: toTitleCase(user.role.split("-").join(" ")),
          currentSubscription: user.currentSubscriptionPlan?.name ?? "None",
        })),
      )
    }
    setIsPageLoading(false)
  }

  useEffect(() => {
    loadUsers()
  }, [])

  const renderCell = React.useCallback((user: any, columnKey: React.Key) => {
    const cellValue = user[columnKey as any]

    switch (columnKey) {
      case "role":
        return (
          <Chip className="capitalize" size="sm" variant="flat">
            {cellValue}
          </Chip>
        )
      case "actions":
        return (
          <div className="actions-table-cell">
            <Tooltip aria-label="Details" content="Edit user">
              <Link
                onClick={() => {
                  navigate("/Admin/User/" + user._id)
                }}
              >
                <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                  <FontAwesomeIcon icon={faEdit} />
                </span>
              </Link>
            </Tooltip>
            <Tooltip aria-label="Subscriptions" content="Manage subscriptions">
              <Link
                onClick={() => {
                  navigate("/Admin/User/" + user._id + "/Subscriptions")
                }}
              >
                <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                  <FontAwesomeIcon icon={faNewspaper} />
                </span>
              </Link>
            </Tooltip>
            <DeleteUserButton
              userId={user._id}
              userName={user.name}
              listUsers={loadUsers}
            />
          </div>
        )
      default:
        return cellValue
    }
  }, [])

  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>Manage Users</h1>
        <Button
          color="primary"
          variant="shadow"
          startContent={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            navigate("/Admin/User/New")
          }}
        >
          New User
        </Button>
      </PageHeader>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Table
          aria-label=""
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                total={totalPages}
                initialPage={currentPage}
                onChange={(page) => {
                  setCurrentPage(page)
                  loadUsers(page)
                }}
              />
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                className={column.uid === "actions" ? "actions-table-cell" : ""}
                align={column.uid === "actions" ? "end" : "start"}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>

          <TableBody items={displayUsers}>
            {(item: any) => (
              <TableRow key={item._id}>
                {(columnKey) => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Page>
  )
}
