import { useEffect, useState } from "react"
import TableSelectInput from "../../components/TableSelectInput"
import "./index.css"
import EditAnswerModal from "./modals/EditAnswerModal/"
import AddAnswerModal from "./modals/AddAnswerModal"
import DeleteAnswerModal from "./modals/DeleteAnswerModal"

interface Props {
  type: string
  label: string
  placeholder: string

  observable: any
  setObservable: (val: any) => void

  addNewText?: string
}

export default function AnswerInputV2({
  type,
  label,
  placeholder,
  observable,
  setObservable,
  addNewText,
}: Props) {
  const [internalObservable, setInternalObservable] = useState<any>([])

  useEffect(() => {
    if (observable.length === 0) {
      setInternalObservable([])
    } else {
      setInternalObservable(
        observable.map((x: any) => ({
          _id: x._id ?? null,
          Header: x.header,
          Marks: x.marks,
          Answer: x.answer["enGB"],
          "Is Optional": x.isOptional ?? false,
        })),
      )
    }
  }, [observable])

  const [isEditAnswerModalOpen, setIsEditAnswerModalOpen] =
    useState<boolean>(false)
  const [editAnswerIndex, setEditAnswerIndex] = useState<number>(-1)

  const [isAddAnswerModalOpen, setIsAddAnswerModalOpen] =
    useState<boolean>(false)

  const [isDeleteAnswerModalOpen, setIsDeleteAnswerModalOpen] =
    useState<boolean>(false)

  return (
    <>
      <EditAnswerModal
        isOpen={isEditAnswerModalOpen}
        setIsOpen={setIsEditAnswerModalOpen}
        observable={observable}
        index={editAnswerIndex}
        setObservable={setObservable}
      />

      <AddAnswerModal
        isOpen={isAddAnswerModalOpen}
        setIsOpen={setIsAddAnswerModalOpen}
        observable={observable}
        setObservable={setObservable}
      />

      <DeleteAnswerModal
        isOpen={isDeleteAnswerModalOpen}
        setIsOpen={setIsDeleteAnswerModalOpen}
        observable={observable}
        index={editAnswerIndex}
        setObservable={setObservable}
      />

      <TableSelectInput
        title="Answers"
        addNewPlaceholder="+ Add a new answer"
        onAddNew={() => {
          setIsAddAnswerModalOpen(true)
        }}
        onEdit={(index: number) => {
          setEditAnswerIndex(index)
          setIsEditAnswerModalOpen(true)
        }}
        onDelete={(_id: string) => {
          const index = observable.findIndex((x: any) => x._id === _id)
          if (!index) return console.error("Cannot find index to delete.")
          setEditAnswerIndex(index)
          setIsDeleteAnswerModalOpen(true)
        }}
        onPositionChange={(indexToMoveFrom, indexToMoveTo) => {
          setObservable((prev: any) => {
            const next: any = [...prev]
            next.move(indexToMoveFrom, indexToMoveTo)

            return next
          })
        }}
        isLoading={false}
        selectedItems={internalObservable}
        setSelectedItems={setInternalObservable}
        headers={["Header", "Marks", "Answer", "Is Optional"]}
      />
    </>
  )
}
