import {
  faBook,
  faGear,
  faHome,
  faHouse,
  faPieChart,
  faPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Page from "@/core/components/Page"
import QuizList from "@/components/QuizList"
import PageHeader from "@/core/components/Page/PageHeader"
import { Button } from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import SubscriptionCreditsWidget from "@/components/Widgets/SubscriptionCreditsWidget"
import { useAppSelector } from "@/app/hooks"

export default function QuizzesPage() {
  const navigate = useNavigate()

  return (
    <Page showSidebar={true} overrideContentStyles={true}>
      <div className="unstyled-column-page">
        <PageHeader className="default-page-lr-padding">
          <h1>My Interviews</h1>
          <Button
            color="primary"
            variant="shadow"
            startContent={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              navigate("/ChooseQuiz")
            }}
          >
            New Interview
          </Button>
        </PageHeader>
        <QuizList />
      </div>
    </Page>
  )
}
