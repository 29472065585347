export interface IQuiz {
  id: string
  quizId: string
  userId: string
  questions: string[]
  currentQuestionPosition: number
  totalTime: number
  totalMarks: number

  // Question answers
  answers: string[]
  selectedRegion: string

  // Question timing
  startDate: Date
  endDate: Date

  quizAccessLevel: QuizAccessLevel
}

export interface IUserQuizView {
  _id: string
  name: string
  shortDescription: string
  description: string
  totalMarks: number
  totalTime: number
  score: number
  scaledScore: number
  startDate: Date
  endDate: Date
  interviewerInstructions: string
  intervieweeInstructions: string
  actorInstructions: string
  actorModeEnabled: boolean
  quizAccessLevel: QuizAccessLevel
}

export interface IUserQuizFilter {
  categories: string
  quizzes: string
}

export enum QuizAccessLevel {
  ADMIN_ONLY = 0,
  TUTOR_ONLY = 1,
  PAYWALL = 2,
  UNLOCKED = 3,
}

export enum QuizAccessLevelString {
  ADMIN_ONLY = "Admin Only",
  TUTOR_ONLY = "Tutor only",
  PAYWALL = "Paywall",
  UNLOCKED = "Unlocked",
}
