import { Accordion, AccordionItem, Pagination, Spacer } from "@nextui-org/react"
import "./index.css"
import { useEffect, useState } from "react"
import { getAvailableQuizzes } from "@/api/routes/quiz-bank/quiz"
import { IQuizBank } from "@/api/types/quiz/quiz-bank"
import QuizCard from "./QuizCard"
import { PageLoader } from "@/components/PageLoader"
import { IUserQuizFilter } from "@/api/types/quiz/quizzes"
import QuizSearchInput from "@/core/components/QuizSearchInput"

interface Props {
  listClassName?: string
  footerClassName?: string
}

export default function AvailableQuizList(props: Props) {
  const [loading, setLoading] = useState(true)
  const [quizzes, setQuizzes] = useState([] as IQuizBank[])

  // Filtering
  const [filter, setFilter] = useState<Partial<IUserQuizFilter>>({})
  const [selectedQuizzes, setSelectedQuizzes] = useState<any>([])

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)

  const loadQuizzes = async (page?: number) => {
    setLoading(true)

    const totalRecords: any = await getAvailableQuizzes(
      {
        fetchMeta: true,
        limit: pageLimit,
      },
      filter.quizzes,
    )
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    const quizzes: any = await getAvailableQuizzes(
      {
        page: page || currentPage,
        limit: pageLimit,
      },
      filter.quizzes,
    )
    if (quizzes) {
      setQuizzes(quizzes.quizzes)
    }
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await loadQuizzes()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      await loadQuizzes()
    })()
  }, [filter])

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      quizzes:
        selectedQuizzes.map((quiz: IQuizBank) => quiz.name).join(",") ||
        undefined,
    }))
  }, [selectedQuizzes])

  const renderQuizzes = (): JSX.Element => (
    <div className="interviews-container">
      <div className="quiz-list-filter-search-input default-page-lr-padding">
        <QuizSearchInput
          selectedQuizzes={selectedQuizzes}
          setSelectedQuizzes={setSelectedQuizzes}
        />
      </div>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className={`interviews ${props.listClassName ?? ""}`}>
            <Accordion variant="splitted">
              {quizzes.map((quiz, index) => (
                <AccordionItem
                  key={index}
                  title={quiz.name}
                  subtitle={quiz.shortDescription}
                >
                  <QuizCard
                    id={quiz._id}
                    name={quiz.name}
                    shortDescription={quiz.shortDescription}
                    description={quiz.description}
                    categories={quiz.categories}
                    actorModeEnabled={quiz.actorModeEnabled}
                    quizAccessLevel={quiz.quizAccessLevel}
                  />
                </AccordionItem>
              ))}
            </Accordion>
            <Spacer y={4} />
          </div>
          <div className={`interviews-footer ${props.footerClassName}`}>
            <Pagination
              style={{ marginTop: 10 }}
              total={totalPages}
              initialPage={currentPage}
              onChange={(page) => {
                setCurrentPage(page)
                loadQuizzes(page)
              }}
            />
          </div>
        </>
      )}
    </div>
  )

  return renderQuizzes()
}
