import { IQuestionCategory } from "@/api/types/question/question"

export enum AttemptTypeEnum {
  MOCK_QUIZ = "MOCK_QUIZ",
  QUESTION_PRACTICE = "QUESTION_PRACTICE",
}

export interface IQuizBank {
  _id: string
  name: string
  shortDescription: string
  description: string
  categories: IQuestionCategory[]
  totalMarks: number
  totalTime: number
  score: number
  scaledScore: number
  startDate: Date
  endDate: Date
  interviewerInstructions: string
  intervieweeInstructions: string
  actorInstructions: string
  actorModeEnabled: boolean
  attemptType: AttemptTypeEnum | null
  quizAccessLevel: number
}
