import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"

export async function listSubscriptionPlans(
  pageData?: PageData,
  showDeleted?: boolean,
) {
  try {
    let urlParams = new URLSearchParams()
    if (pageData?.fetchMeta === true) {
      urlParams.append("meta", "1")
    }
    if (pageData?.page) {
      urlParams.append("page", pageData.page.toString())
    }
    if (pageData?.limit) {
      urlParams.append("limit", pageData.limit.toString())
    }
    if (showDeleted === true) {
      urlParams.append("show-deleted", "1")
    }

    const res = await axios.get(
      "/admin/subscription-plan?" + urlParams.toString(),
    )

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function searchSubscriptionPlans(
  query: string,
  showDeleted?: boolean,
) {
  try {
    let urlParams = new URLSearchParams()

    if (showDeleted === true) {
      urlParams.append("show-deleted", "1")
    }

    urlParams.append("q", query)

    const res = await axios.get(
      "/admin/subscription-plan/search?" + urlParams.toString(),
    )

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function createSubscriptionPlan(data: any) {
  try {
    const res = await axios.post("/admin/subscription-plan/new", data)

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateSubscriptionPlan(id: string, data: any) {
  try {
    const res = await axios.put("/admin/subscription-plan/" + id, data)

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteSubscriptionPlan(id: string) {
  try {
    const res = await axios.delete("/admin/subscription-plan/" + id)

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function restoreSubscriptionPlan(id: string) {
  try {
    const res = await axios.put("/admin/subscription-plan/restore/" + id)

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
