import BaseModal from "@/core/components/Modals/BaseModal"
import LanguageTextArea from "@/pages/Admin/components/LanguageTextArea"
import { Button, Input, Spacer, Switch } from "@nextui-org/react"
import { useEffect, useState } from "react"

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  observable: any
  index: number
  setObservable: React.Dispatch<React.SetStateAction<any>>
}

export default function EditAnswerModal({
  isOpen,
  setIsOpen,
  observable,
  index,
  setObservable,
}: Props) {
  const [internalObservable, setInternalObservable] = useState<any>([])
  const item = internalObservable?.[index] ?? null

  useEffect(() => {
    setInternalObservable(observable)
  }, [observable])

  const editItem = (index: number, key: string, value: any) => {
    setInternalObservable((curr: any) => {
      const newObservable = [...curr]
      newObservable[index][key] = value
      return newObservable
    })
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Edit Answer"
      size="xl"
      body={
        item ? (
          <>
            {/* Header title */}
            <Input
              value={item.header}
              variant="bordered"
              onChange={(e: any) => {
                editItem(index, "header", e.target.value)
              }}
              placeholder="Enter the header"
            />

            {/* Answers */}
            <LanguageTextArea
              editorKey={"answer-" + index}
              observable={item.answer}
              setObservable={(val: any) => {
                editItem(index, "answer", val(item.answer))
              }}
              type="text"
              label="Answer"
              placeholder="Enter the question answer"
              disableRichText={true}
            />

            {/* Marks */}
            <Input
              type="number"
              variant="bordered"
              value={item.marks}
              onChange={(e: any) => {
                editItem(index, "marks", e.target.value)
              }}
              label="Answer Marks"
              placeholder="Enter the marks"
            />

            {/* Is Optional */}
            <Switch
              isSelected={item.isOptional}
              onValueChange={(val: any) => {
                if (val === true) {
                  // Set marks to 0 if the answer is optional
                  editItem(index, "marks", 0)
                }
                editItem(index, "isOptional", val)
              }}
            >
              Is Optional
            </Switch>
          </>
        ) : (
          <></>
        )
      }
      buttons={
        <>
          <Button
            variant="light"
            color="danger"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            color="primary"
            onClick={() => {
              setObservable(internalObservable)
              setIsOpen(false)
            }}
          >
            Save
          </Button>
        </>
      }
    />
  )
}
