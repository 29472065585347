import {
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react"
import { QuizActionsCell } from "./QuizActionsCell"
import { useEffect, useState } from "react"
import { IUserQuizView } from "@/api/types/quiz/quizzes"
import { getQuizzes } from "@/api/routes/quiz/quiz"
import {
  getCategoryStatistics,
  getGeneralStatistics,
} from "@/api/routes/statistics"

export default function OverallStatisticsWidget() {
  const [statsLoading, setStatsLoading] = useState(true)
  const [quizzes, setQuizzes] = useState([] as IUserQuizView[])
  const [stats, setStats] = useState([] as any)
  const [keys, setKeys] = useState([] as string[])

  const loadQuizzes = async () => {
    setStatsLoading(true)
    const generalStats = await getGeneralStatistics()
    if (generalStats) {
      setStats(generalStats)
    }

    setStatsLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await loadQuizzes()
    })()
  }, [])

  return (
    <Card className="widget-2x2 mini-table">
      <h1 className="widget-2x2-header">General Performance Stats</h1>
      {statsLoading ? (
        <Table
          removeWrapper
          hideHeader={statsLoading}
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn>NAME</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Table
          removeWrapper
          hideHeader={statsLoading}
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn>NAME</TableColumn>
            <TableColumn className="right-aligned-cell">#</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>No. Quizzes Taken</TableCell>
              <TableCell className="right-aligned-cell">
                {stats.totalQuizzesTaken}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>No. Questions Answered</TableCell>
              <TableCell className="right-aligned-cell">
                {stats.totalQuestionsAnswered}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Avg Quiz %</TableCell>
              <TableCell className="right-aligned-cell">
                {stats.quizAverageScore}%
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Avg Question %</TableCell>
              <TableCell className="right-aligned-cell">
                {stats.questionAverageScore}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Card>
  )
}
