import Page from "@/core/components/Page"
import {
  Button,
  Input,
  Link,
  Select,
  SelectItem,
  Selection,
  Spacer,
} from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import "./index.css"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import { Key, useEffect, useState } from "react"
import {
  createUser,
  getSubscriptionsForUser,
  getUserData,
  searchTutorUsers,
  updateUser,
} from "@/api/routes/admin/user"
import { PageLoader } from "@/components/PageLoader"
import { useAppSelector } from "@/app/hooks"
import loadUserProfile from "@/core/helpers/load-user-profile"
import { useDispatch } from "react-redux"
import {
  isFieldFilledOut,
  isLanguageFieldFilledOut,
} from "@/core/helpers/utils"
import TableSelectInput from "../components/TableSelectInput"

const roles = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Content Creator",
    value: "content-creator",
  },
  {
    label: "Tutor",
    value: "tutor",
  },
  {
    label: "User",
    value: "user",
  },
]

export default function EditUserPage() {
  const navigate = useNavigate()
  const { userId } = useParams()

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [userRole, setUserRole] = useState<Selection>(new Set([]))
  const [tutors, setTutors] = useState<any[]>([])

  const dispatch = useDispatch()

  const currentUser = useAppSelector((state) => state.user.user)

  const buildAndSendUser = async () => {
    setIsSubmitLoading(true)

    const user = {
      firstName,
      lastName,
      email,
      role: [...userRole].join(""),
      tutorIds: tutors.map((x) => x._id),
    }

    await updateUser(userId!, user)

    // If we're editing the current user, we need to reload the profile
    if (currentUser?._id === userId) {
      await loadUserProfile(dispatch)
    }

    navigate(-1)
  }

  const getUser = async () => {
    const userData = await getUserData(userId!)
    if (userData != null) {
      setFirstName(userData.firstName)
      setLastName(userData.lastName)
      setEmail(userData.username)
      setUserRole(new Set<Key>([userData.role.toLowerCase()]) as any)
      setTutors(
        userData.tutors.map((x: any) => ({
          _id: x._id,
          "Tutor Name": x.firstName + " " + x.lastName,
          Email: x.email,
        })) as any,
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      await getUser()

      setIsPageLoading(false)
    })()
  }, [])

  const formValidate = () => {
    if (
      isFieldFilledOut(firstName) &&
      isFieldFilledOut(lastName) &&
      isFieldFilledOut(email) &&
      isFieldFilledOut([...userRole].join(""))
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <>
          <Link
            className="link"
            onClick={() => {
              navigate(-1)
            }}
          >
            {"< Back"}
          </Link>
          <PageHeader>
            <h1>Edit User</h1>
          </PageHeader>

          <div className="page-grid">
            <div className="input-wrapper row row-gap-10">
              <Input
                variant="bordered"
                type="text"
                label="First Name"
                placeholder="Enter the first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Input
                variant="bordered"
                type="text"
                label="Last Name"
                placeholder="Enter the last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="input-wrapper">
              <Input
                variant="bordered"
                type="email"
                label="Email"
                placeholder="Enter the email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="input-wrapper">
              <Select
                variant="bordered"
                selectedKeys={userRole}
                onSelectionChange={setUserRole}
                label="Select a role"
                className="max-w-xs"
              >
                {roles.map((role) => (
                  <SelectItem key={role.value} value={role.value}>
                    {role.label}
                  </SelectItem>
                ))}
              </Select>
            </div>

            <div className="input-wrapper">
              <TableSelectInput
                title="Tutors"
                addNewPlaceholder="+ Add a tutor"
                selectedItems={tutors}
                setSelectedItems={setTutors}
                searchFn={async (searchTerm: string): Promise<any> => {
                  const users = await searchTutorUsers(searchTerm)
                  if (typeof users !== "object" || users === null) return []
                  return users.map((user: any) => ({
                    _id: user._id,
                    "Tutor Name": user.firstName + " " + user.lastName,
                    Email: user.username,
                    name: user.firstName + " " + user.lastName,
                  }))
                }}
                headers={["Tutor Name", "Email"]}
              />
            </div>

            <div className="input-wrapper create-question-wrapper">
              <Button
                onClick={buildAndSendUser}
                variant="solid"
                color="primary"
                isLoading={isSubmitLoading}
                isDisabled={formValidate()}
              >
                Update User
              </Button>
            </div>
          </div>
        </>
      )}
    </Page>
  )
}
