import { Button } from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRotateRight,
  faPause,
  faPlay,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { useStopwatch } from "react-timer-hook"
import SideWidget from "@/pages/QuizAttempt/components/SideWidget"

const pad = (n: number) => String(n).padStart(2, "0")

interface Props {
  hideReset?: boolean
  hidePlay?: boolean
  hidePause?: boolean
  onReset?: () => void
  startTimeTaken?: number
  offsetTimestamp?: Date
  autostart?: boolean
  inProgress?: boolean
  setTotalSecondsObs?: (totalSeconds: number) => void
  expectedTime?: number
}

const TimerCard = forwardRef((props: Props, ref: React.ForwardedRef<any>) => {
  let offsetDate = new Date()
  if (props.startTimeTaken) {
    offsetDate.setSeconds(offsetDate.getSeconds() + props.startTimeTaken)
  } else if (props.offsetTimestamp) {
    // get seconds difference between now and props.offsetTimestamp
    const secondsDiff =
      (new Date().getTime() - props.offsetTimestamp.getTime()) / 1000
    offsetDate.setSeconds(offsetDate.getSeconds() + secondsDiff)
  }

  let { totalSeconds, seconds, minutes, isRunning, start, pause, reset } =
    useStopwatch({
      autoStart: false,
      offsetTimestamp: offsetDate,
    })

  useImperativeHandle(ref, () => ({
    pause: () => {
      pause()
    },
    resume: () => {
      start()
    },
  }))

  const playTimer = () => {
    start()
  }

  const pauseTimer = () => {
    pause()
  }

  const resetTimer = () => {
    if (props.onReset) {
      props.onReset()
    }
    reset()
  }

  useEffect(() => {
    if (props.autostart) {
      start()
    }
  }, [])

  useEffect(() => {
    if (props.setTotalSecondsObs) {
      props.setTotalSecondsObs(totalSeconds)
    }
  }, [totalSeconds])

  return (
    <SideWidget>
      <FontAwesomeIcon
        size="lg"
        icon={faStopwatch}
        style={{
          color: props.inProgress
            ? "hsl(var(--medcamps-warning-500))"
            : "unset",
        }}
      />
      <div className="timer-card-content">
        <div
          className="timer-card-content-text"
          style={{
            color: props.inProgress
              ? "hsl(var(--medcamps-warning-500))"
              : "unset",
          }}
        >
          {!props.inProgress
            ? `${pad(minutes)}:${pad(seconds)}`
            : "In Progress"}
        </div>
        {props.expectedTime ? (
          <div className="timer-content-sub-text">
            (Recommended: {props.expectedTime}m)
          </div>
        ) : null}
        <div className="timer-card-content-buttons">
          {!isRunning ? (
            !props.hidePlay ? (
              <Button variant="flat" onClick={playTimer} color="primary">
                <FontAwesomeIcon icon={faPlay} />
              </Button>
            ) : null
          ) : !props.hidePause ? (
            <Button variant="flat" onClick={pauseTimer} color="warning">
              <FontAwesomeIcon icon={faPause} />
            </Button>
          ) : null}

          {!props.hideReset ? (
            <Button variant="flat" onClick={resetTimer} color="danger">
              <FontAwesomeIcon icon={faArrowRotateRight} />
            </Button>
          ) : null}
        </div>
      </div>
    </SideWidget>
  )
})

export default TimerCard
