import { Button, Card, Spacer, Spinner, Textarea } from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"
import { viewVoiceRecordingOriginalTranscriptionRoute } from "@/api/routes/voice/voice"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons"
import useDebounce from "@/core/helpers/debounce"

interface Props {
  aiResponse?: string | null | undefined
  isTranscribing?: boolean | null | undefined
  transcription?: string | null | undefined
  setTranscription?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >
  transcriptionId?: string | null | undefined
  voiceRecordingId: string | null | undefined
  voiceRecordingBlob: Blob | null

  hasTranscription?: boolean | null | undefined

  onTranscriptionChange?: (transcription: string) => void
  onClickMarkWithAi?: (isTranscription: boolean) => void
  isTranscriptionSaving?: boolean | null | undefined

  isAiMarking?: boolean | null | undefined
}

export default function ViewAndManageTranscriptionCard({
  isTranscribing,
  transcription,
  onTranscriptionChange,
  aiResponse,
  voiceRecordingId,
  voiceRecordingBlob,
  setTranscription,
  hasTranscription,
  isTranscriptionSaving,
  onClickMarkWithAi,
  isAiMarking,
}: Props) {
  const [hasRequestedAiMark, setHasRequestedAiMark] = useState(false)
  const [isRevertTranscriptionModalOpen, setIsRevertTranscriptionModalOpen] =
    useState(false)

  const [originalTranscription, setOriginalTranscription] = useState<
    string | null | undefined
  >(null)

  const getOriginalTranscription = async () => {
    if (voiceRecordingId) {
      const res =
        await viewVoiceRecordingOriginalTranscriptionRoute(voiceRecordingId)
      if (typeof res === "string") {
        setOriginalTranscription(res)
      } else {
        console.error("Failed to get original transcription")
      }
    }
  }

  useEffect(() => {
    getOriginalTranscription()
  }, [voiceRecordingId])

  const debouncedTranscriptionValue = useDebounce(transcription ?? "", 200)

  useEffect(() => {
    // Now we can call a func to save the transcription
    onTranscriptionChange?.(debouncedTranscriptionValue)
  }, [debouncedTranscriptionValue])

  const [formattedTranscription, setFormattedTranscription] = useState<
    string[] | null
  >()
  useEffect(() => {
    if (transcription) {
      const _formattedTranscription = transcription?.split("\n")
      setFormattedTranscription(_formattedTranscription)
    }
  }, [transcription])

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isRevertTranscriptionModalOpen}
        onConfirm={() => {
          if (typeof setTranscription === "function")
            setTranscription(originalTranscription)
          setIsRevertTranscriptionModalOpen(false)
        }}
        onClose={() => setIsRevertTranscriptionModalOpen(false)}
        title="Reset to original transcription"
        description="Are you sure you want to reset to the original transcription?"
        deleteTextOverride="Reset"
      />
      {voiceRecordingId && voiceRecordingBlob && (
        <>
          {isTranscribing && (
            <>
              <Spacer y={2} />
              <Card shadow="none" className="in-progress-quiz-answer-card">
                <div className="transcription-in-progress-message">
                  <Spinner size="sm" color="primary" />
                  <span className="transcription-title-text">
                    Transcribing, please check back later...
                    <br />
                    After your transcription has processed, you will be be able
                    to review and update it before sending it to your tutor/AI
                    examiner.
                  </span>
                </div>
              </Card>
            </>
          )}
          {hasTranscription && (
            <>
              <Spacer y={2} />
              <Card shadow="none" className="in-progress-quiz-answer-card">
                {typeof aiResponse === "string" || isAiMarking ? (
                  <div>
                    <span className="question-attempt-card-title-text">
                      Transcription of voice recorded answer
                    </span>
                    <Spacer y={1} />
                    {formattedTranscription?.map((val, index) => (
                      <p key={index}>{val}</p>
                    )) ?? ""}
                  </div>
                ) : (
                  <Textarea
                    label="Transcription of voice recorded answer"
                    value={transcription ?? ""}
                    onChange={(e) => {
                      if (typeof setTranscription === "function")
                        setTranscription(e.target.value)
                    }}
                    labelPlacement="inside"
                    variant="bordered"
                    size="lg"
                    placeholder="(No transcription available)"
                    className="interview-answer-text-area"
                  />
                )}

                <Spacer y={2} />

                <div className="transcription-footer">
                  {isTranscriptionSaving ? (
                    <>
                      <div className="transcription-saving-message">
                        Saving... <Spinner size="sm" color="primary" />
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  <div className="transcription-footer-buttons">
                    {!isAiMarking && typeof aiResponse !== "string" && (
                      <>
                        {originalTranscription !== transcription &&
                          typeof aiResponse !== "string" && (
                            <Button
                              onClick={() => {
                                setIsRevertTranscriptionModalOpen(true)
                              }}
                              color="warning"
                              variant="bordered"
                            >
                              Revert to original transcription
                            </Button>
                          )}
                        <Button
                          onClick={() => {
                            setHasRequestedAiMark(true)
                            onClickMarkWithAi?.(true)
                          }}
                          isDisabled={
                            isTranscriptionSaving || hasRequestedAiMark
                          }
                          isLoading={hasRequestedAiMark}
                          color="primary"
                          variant="shadow"
                        >
                          <FontAwesomeIcon icon={faGraduationCap} /> Mark with
                          AI
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </>
  )
}
