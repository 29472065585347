import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import LanguageTextArea from "../components/LanguageTextArea"
import { Key, useEffect, useState } from "react"
import {
  Button,
  Link,
  Select,
  SelectItem,
  Selection,
  Spacer,
  Spinner,
  Tab,
  Tabs,
  useDisclosure,
} from "@nextui-org/react"
import "./index.css"
import { useNavigate, useParams } from "react-router"
import {
  updateCategory,
  getCategoryById,
} from "@/api/routes/admin/question-category/category-bank"
import { colorsList } from "@/core/constants/colors"
import { PageLoader } from "@/components/PageLoader"
import QuestionInput from "../CreateOrEditQuiz/QuestionInput"
import TableSelectInput from "../components/TableSelectInput"
import { listQuestions } from "@/api/routes/question-bank/questions"
import AddVideoModal from "./AddVideoModal"
import EditVideoModal from "./EditVideoModal"
import {
  isLanguageFieldFilledOut,
  isSetFieldFilledOut,
} from "@/core/helpers/utils"
import SubCategoriesList from "../components/SubCategoriesList"
import {
  IQuestionCategoryLinks,
  ISubQuestionCategory,
} from "@/api/types/question/question"
import { getCategoriesByPath } from "@/api/routes/category-bank"
import {
  addVideoRoute,
  deleteVideoRoute,
  updateVideoRoute,
} from "@/api/routes/admin/question-category/video"
import {
  addNotes,
  deleteNotes,
  updateNotes,
} from "@/api/routes/admin/question-category/notes"
import AddNoteToRootModal from "./AddNoteToRootModal"
import EditRootNoteModal from "./EditRootNoteModal"

export default function EditCategoryPage() {
  const navigate = useNavigate()
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isVideosSectionLoading, setIsVideosSectionLoading] =
    useState<boolean>(false)
  const [isNotesSectionLoading, setIsNotesSectionLoading] =
    useState<boolean>(false)

  const [selectedLinks, setSelectedLinks] = useState([] as any)
  const [selectedNotes, setSelectedNotes] = useState([] as any)
  const [originalData, setOriginalData] = useState({} as any)

  const [title, setTitle] = useState({} as any)
  const [description, setDescription] = useState({} as any)
  const [color, setColor] = useState<Selection>(new Set([]))

  const { categoryId } = useParams()

  // This is a list to track how deep in sub categories we are.
  // Start this list with the current category id.
  const [currentCategoryPath, setCurrentCategoryPath] = useState<string[]>([
    categoryId!,
  ])
  const [currentCategoryNamePath, setCurrentCategoryNamePath] = useState<
    string[]
  >(["Current Category"])

  const [selectedVideos, setSelectedVideos] = useState([] as any)
  const [editIndex, setEditIndex] = useState(0)
  const [noteIndex, setNoteIndex] = useState(0)

  const addNewVideoModalDisclosure = useDisclosure()
  const editVideoModalDisclosure = useDisclosure()

  const addNewNoteModalDisclosure = useDisclosure()
  const editNoteModalDisclosure = useDisclosure()

  const getCurrentCategory = async () => {
    setIsPageLoading(true)
    const currentCategory = await getCategoryById(categoryId!)
    if (currentCategory) {
      setTitle(currentCategory.name)
      setDescription(currentCategory.description)
      setColor(new Set([currentCategory.color]))
      setSelectedVideos(
        currentCategory.videos.map((video: any, index: number) => ({
          _id: video._id,
          Title: video.title,
          Description: video.description,
          URL: video.url,
          Links:
            video.links?.map((link: any) => ({
              _id: link._id,
              Title: link.title,
              URL: link.url,
              Description: link.description,
            })) ?? [],
          AccessLevel: new Set<Key>([video.accessLevel?.toString()]),
        })),
      )
      setSelectedNotes(
        currentCategory.notes.map((note: any, index: number) => ({
          ...note,
          accessLevel: new Set<Key>([note.accessLevel?.toString()]),
        })),
      )

      setOriginalData(currentCategory)
    }
    setIsPageLoading(false)
  }

  const buildAndSendCategory = async () => {
    setIsLoading(true)

    const category = {
      name: title,
      description,
      color: [...color][0],
    }

    await updateCategory(categoryId!, category)

    // Navigate back
    navigate(-1)

    setIsLoading(false)
  }

  const addVideo = async (video: any) => {
    setIsVideosSectionLoading(true)
    const videoId = await addVideoRoute(
      video.Title,
      video.Description,
      video.URL,
      [],
      parseInt([...video.AccessLevel].join("")),
      [categoryId!],
    )
    setSelectedVideos((prev: any) => [
      ...prev,
      {
        ...video,
        _id: videoId,
      },
    ])
    addNewVideoModalDisclosure.onClose()
    setIsVideosSectionLoading(false)
  }

  const onEditVideo = async (video: any, videos: any) => {
    setIsVideosSectionLoading(true)
    await updateVideoRoute(
      video._id,
      video.Title,
      video.Description,
      video.URL,
      selectedLinks.map((x: any) => ({
        _id: x._id ?? null,
        title: x.Title,
        url: x.URL,
        description: x.Description,
      })),
      video.AccessLevel,
      [categoryId!],
    )

    setSelectedVideos(videos)
    setIsVideosSectionLoading(false)
  }

  const onAddNotes = async (title: any, notes: any, accessLevel: any) => {
    setIsNotesSectionLoading(true)
    const noteId = await addNotes(
      title,
      notes,
      [],
      parseInt([...accessLevel].join("")),
      [categoryId!],
    )
    setSelectedNotes((prev: any) => [
      ...prev,
      {
        _id: noteId,
        title,
        notes,
        accessLevel,
      },
    ])
    setIsNotesSectionLoading(false)
  }

  const onEditNote = async (
    title: string,
    notes: string,
    links: IQuestionCategoryLinks[],
    accessLevel: any,
    index: number,
  ) => {
    setIsNotesSectionLoading(true)
    await updateNotes(
      selectedNotes[index]._id,
      title,
      notes,
      links.map((x: any) => ({
        _id: x._id ?? null,
        title: x.Title,
        url: x.URL,
        description: x.Description,
      })),
      parseInt([...accessLevel].join("")),
      [categoryId!],
    )
    setSelectedNotes((prev: any) => {
      let next = [...prev]
      next[index] = {
        _id: selectedNotes[index]._id,
        title,
        notes,
        links: links.map((x: any) => ({
          _id: x._id ?? null,
          title: x.Title,
          url: x.URL,
          description: x.Description,
        })),
        accessLevel,
      }
      return next
    })
    setIsNotesSectionLoading(false)
  }

  const [subCategories, setSubCategories] = useState<ISubQuestionCategory[]>([])

  async function getCurrentSubCategories() {
    const categories = await getCategoriesByPath(currentCategoryPath)
    if (categories) {
      setSubCategories(categories.subCategories)
    }
  }

  useEffect(() => {
    getCurrentCategory()
    getCurrentSubCategories()
  }, [])

  const [isSubCategoriesLoading, setIsSubCategoriesLoading] = useState(false)
  useEffect(() => {
    ;(async () => {
      setIsSubCategoriesLoading(true)
      await getCurrentSubCategories()
      setIsSubCategoriesLoading(false)
    })()
  }, [currentCategoryPath])

  const formValidate = () => {
    if (
      isLanguageFieldFilledOut(title) &&
      isLanguageFieldFilledOut(description) &&
      isSetFieldFilledOut(color)
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <>
          <Link
            className="link"
            onClick={() => {
              navigate(-1)
            }}
          >
            {"< Back"}
          </Link>
          <PageHeader>
            <h1>Edit Category</h1>
          </PageHeader>
          <AddVideoModal
            addVideo={addVideo}
            disclosure={addNewVideoModalDisclosure}
          />
          <EditVideoModal
            selectedLinks={selectedLinks}
            setSelectedLinks={setSelectedLinks}
            disclosure={editVideoModalDisclosure}
            selectedVideos={selectedVideos}
            onEditVideo={onEditVideo}
            index={editIndex}
          />

          <AddNoteToRootModal
            disclosure={addNewNoteModalDisclosure}
            onAddNotes={onAddNotes}
          />
          <EditRootNoteModal
            disclosure={editNoteModalDisclosure}
            onEditNote={onEditNote}
            allNotes={selectedNotes}
            index={noteIndex}
          />

          <div className="page-grid">
            <Tabs>
              <Tab key="Basic Details" title="Basic Details">
                <Spacer y={2} />
                <div className="input-wrapper">
                  <LanguageInput
                    observable={title}
                    setObservable={setTitle}
                    type="text"
                    label="Category Title"
                    placeholder="Enter the category title"
                  ></LanguageInput>
                </div>
                <div className="input-wrapper">
                  <LanguageTextArea
                    editorKey={"1"}
                    observable={description}
                    setObservable={setDescription}
                    type="text"
                    label="Category Description"
                    placeholder="Enter the category description"
                  />
                </div>
                <div className="input-wrapper row">
                  <Select
                    variant="bordered"
                    label="Category Colour"
                    placeholder="Select a colour"
                    selectedKeys={color}
                    onSelectionChange={setColor}
                  >
                    {colorsList.map((colorItem) => (
                      <SelectItem key={colorItem.value} value={colorItem.value}>
                        {colorItem.label}
                      </SelectItem>
                    ))}
                  </Select>
                  <div
                    className="color-square"
                    style={{ backgroundColor: `hsl(var(${[...color][0]}))` }}
                  ></div>
                </div>
                <div className="input-wrapper create-question-wrapper">
                  <Button
                    onClick={buildAndSendCategory}
                    variant="solid"
                    color="primary"
                    isLoading={isLoading}
                    isDisabled={formValidate()}
                  >
                    Save Basic Category Details
                  </Button>
                </div>
              </Tab>

              <Tab key="Notes" title="Notes">
                <Spacer y={2} />

                <TableSelectInput
                  aria-label="Add notes"
                  title={"Notes"}
                  addNewPlaceholder="+ Add new notes"
                  onAddNew={addNewNoteModalDisclosure.onOpen}
                  onEdit={(index: number) => {
                    setNoteIndex(index)
                    editNoteModalDisclosure.onOpen()
                  }}
                  onDelete={async (_id: string) => {
                    setIsNotesSectionLoading(true)

                    await deleteNotes(_id, currentCategoryPath)
                    setSelectedNotes((prev: any) =>
                      prev.filter((note: any) => note._id !== _id),
                    )
                    setIsNotesSectionLoading(false)
                  }}
                  isLoading={isNotesSectionLoading}
                  selectedItems={selectedNotes.map((x: any) => ({
                    _id: x._id,
                    Title: x.title,
                  }))}
                  setSelectedItems={setSelectedNotes}
                  hideMovementArrows={true}
                  headers={["Title"]}
                />
              </Tab>

              <Tab key="Videos" title="Videos">
                <Spacer y={2} />

                <div className="input-wrapper">
                  <TableSelectInput
                    aria-label="Add video"
                    title={"Videos"}
                    addNewPlaceholder="+ Add a new video"
                    onAddNew={addNewVideoModalDisclosure.onOpen}
                    onEdit={(index: number) => {
                      setEditIndex(index)
                      editVideoModalDisclosure.onOpen()
                    }}
                    onDelete={async (_id: string) => {
                      setIsVideosSectionLoading(true)

                      await deleteVideoRoute(_id, currentCategoryPath)
                      setSelectedVideos((prev: any) =>
                        prev.filter((video: any) => video._id !== _id),
                      )
                      setIsVideosSectionLoading(false)
                    }}
                    isLoading={isVideosSectionLoading}
                    selectedItems={selectedVideos}
                    setSelectedItems={setSelectedVideos}
                    hideMovementArrows={true}
                    headers={["Title", "URL"]}
                  />
                </div>
              </Tab>

              <Tab key="Sub Categories" title="Sub Categories">
                <Spacer y={2} />
                <div className="input-wrapper">
                  {isSubCategoriesLoading ? (
                    <Spinner />
                  ) : (
                    <SubCategoriesList
                      currentCategoryNamePath={currentCategoryNamePath}
                      setCurrentCategoryNamePath={setCurrentCategoryNamePath}
                      currentCategoryPath={currentCategoryPath}
                      setCurrentCategoryPath={setCurrentCategoryPath}
                      subCategories={subCategories}
                      setSubCategories={setSubCategories}
                    />
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </>
      )}
    </Page>
  )
}
