import { deleteOrganisation } from "@/api/routes/admin/organisation"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Tooltip, useDisclosure } from "@nextui-org/react"

interface Props {
  organisationId: string
  organisationName: string
  listOrganisations: () => Promise<void>
}
export default function DeleteOrganisationButton({
  organisationId,
  organisationName,
  listOrganisations,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isOpen}
        onConfirm={async () => {
          await deleteOrganisation(organisationId)
          await listOrganisations()
          onClose()
        }}
        onClose={onClose}
        title="Delete Organisation"
        description={`Are you sure you want to delete '${organisationName}'? This action cannot be reversed.`}
      />
      <Tooltip
        aria-label="Details"
        color="danger"
        content="Delete organisation"
      >
        <Link onClick={onOpen}>
          <span className="text-lg text-danger cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </Link>
      </Tooltip>
    </>
  )
}
