import { Button, Card } from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { IQuestionCategory } from "@/api/types/question/question"
import { startQuestionPractice } from "@/api/routes/user-question-quiz"
import { useNavigate } from "react-router"
import {
  IQuestionBankQuestionWithSelectedProps,
  QuestionPracticeTab,
} from "../.."

interface Props {
  questionPracticeTab: QuestionPracticeTab
  disabled?: boolean
  questions?: IQuestionBankQuestionWithSelectedProps[] | null
}

export default function StartQuestionsCard(props: Props) {
  const navigate = useNavigate()

  return (
    <Card className="start-questions-card" shadow="none">
      <Button
        color="success"
        variant="solid"
        isDisabled={props.disabled}
        fullWidth={true}
        startContent={<FontAwesomeIcon icon={faPlay} />}
        onClick={async () => {
          const questionIdsToAttempt = props.questions
            ?.filter((q) => q.isSelected)
            .map((q) => q._id)

          if (!questionIdsToAttempt) {
            return
          }

          const quizId = await startQuestionPractice(
            questionIdsToAttempt!,
            false, // TODO: CHANGE
          )
          if (quizId !== null) {
            navigate("/Quiz/Candidate/" + quizId)
          }
        }}
      >
        Start Practice
      </Button>
    </Card>
  )
}
