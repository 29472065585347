import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { User } from "@/api/types/user/user"

export async function uploadImage(file: File) {
  try {
    const formData = new FormData()
    formData.append("file", file)
    const res = await axios.post("/image", formData)

    if (res.status === 200 && res.data.body?._id) {
      return res.data.body._id
    } else {
      throw new Error("Failed to upload image")
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
