import Menu from "@/core/components/Menu"
import "./index.css"
import BaseModal from "../Modals/BaseModal"
import { Button, useDisclosure } from "@nextui-org/react"
import { useNavigate } from "react-router"
import UserProfileWatch from "../UserProfileWatch"
import MenuToggleContainer from "@/components/Sidebar/MenuToggleContainer"
import { useState } from "react"

interface Props {
  showSidebar: boolean
  children?: React.ReactNode
  overrideContentStyles?: boolean
  disableUserProfileWatch?: boolean
  sidebarContentOverride?: React.ReactNode
}

export default function Page(props: Props) {
  const navigate = useNavigate()
  const logoutDisclosure = useDisclosure()

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  return (
    <div>
      {props.showSidebar && (
        <MenuToggleContainer
          setIsCollapsed={setIsCollapsed}
          isCollapsed={isCollapsed}
        />
      )}
      <div className="page">
        {!props.disableUserProfileWatch && <UserProfileWatch />}
        <BaseModal
          title="Confirm log out"
          body="Are you sure you want to log out?"
          isOpen={logoutDisclosure.isOpen}
          onClose={logoutDisclosure.onClose}
          buttons={
            <>
              <Button variant="ghost" onClick={logoutDisclosure.onClose}>
                Cancel
              </Button>
              <Button
                color="danger"
                variant="solid"
                onClick={() => navigate("/Logout")}
              >
                Log Out
              </Button>
            </>
          }
        />
        {props.showSidebar ? (
          <Menu
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            openConfirmLogoutModal={logoutDisclosure.onOpen}
            sidebarContentOverride={props.sidebarContentOverride}
          />
        ) : null}

        {props.overrideContentStyles ? (
          <>{props.children}</>
        ) : (
          <div
            className={`page-content ${props.showSidebar ? "page-content-with-sidebar-enabled" : ""}`}
          >
            {props.children}
          </div>
        )}
      </div>
    </div>
  )
}
