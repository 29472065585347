import { listUsers } from "@/api/routes/admin/user"
import { PageLoader } from "@/components/PageLoader"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import "./index.css"
import {
  faEdit,
  faEye,
  faPieChart,
  faPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Chip,
  Link,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import RemoveStudentButton from "./RemoveStudentButton"
import { getStudents } from "@/api/routes/tutor"
import { useDispatch } from "react-redux"
import InviteStudentModal from "./InviteStudentModal"

const columns = [
  { name: "NAME", uid: "name" },
  { name: "EMAIL", uid: "email" },
  { name: "# IN PROGRESS", uid: "totalQuizzesInProgress" },
  { name: "# NOT MARKED", uid: "totalQuizzesNotMarked" },
  { name: "# TOTAL", uid: "totalQuizzesTaken" },
  { name: "AVERAGE SCORE", uid: "averageScore" },
  { name: "ACTIONS", uid: "actions" },
]

const toTitleCase = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export function ManageStudentsPage() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [allUsers, setAllUsers] = useState([] as any)
  const [displayUsers, setDisplayUsers] = useState([] as any)
  const navigate = useNavigate()

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)

  const [adminGetAllUsers, setAdminGetAllUsers] = useState<boolean>(false)

  const loadUsers = async (page?: number) => {
    setIsPageLoading(true)

    let totalRecords = null
    const metaPagination = {
      fetchMeta: true,
      limit: pageLimit,
    }
    if (adminGetAllUsers) {
      totalRecords = await listUsers(metaPagination)
    } else {
      totalRecords = await getStudents(metaPagination)
    }
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    const dataPagination = {
      page: page || currentPage,
      limit: pageLimit,
    }

    let _allUsers = null

    if (adminGetAllUsers) {
      _allUsers = (await listUsers(dataPagination, true))?.users
    } else {
      _allUsers = await getStudents(dataPagination)
    }

    if (_allUsers != null) {
      setAllUsers(_allUsers)

      setDisplayUsers(
        _allUsers.map((user: any) => ({
          _id: user._id,
          name: user.firstName + " " + user.lastName,
          email: user.username,
          totalQuizzesNotMarked: user.statistics.totalQuizzesNotMarked,
          totalQuizzesInProgress: user.statistics.totalQuizzesInProgress,
          totalQuizzesTaken: user.statistics.totalQuizzesTaken,
          averageScore: user.statistics.quizAverageScore
            ? user.statistics.quizAverageScore + "%"
            : "N/A",
        })),
      )
    }
    setIsPageLoading(false)
  }

  useEffect(() => {
    loadUsers()
  }, [adminGetAllUsers])

  const dispatch = useDispatch()

  const inviteUserDisclosure = useDisclosure()

  const impersonateStudent = async (userId: string, name: string) => {
    dispatch({
      type: "tutorStudentImpersonation/set",
      payload: {
        userId,
        name,
      },
    })
  }

  useEffect(() => {
    loadUsers()
  }, [])

  const renderCell = React.useCallback(
    (user: any, columnKey: React.Key, getAllUsers: boolean) => {
      const cellValue = user[columnKey as any]

      switch (columnKey) {
        case "role":
          return (
            <Chip className="capitalize" size="sm" variant="flat">
              {cellValue}
            </Chip>
          )
        case "actions":
          return (
            <div className="actions-table-cell">
              <Tooltip aria-label="Details" content="View Interviews">
                <Button
                  onClick={() => {
                    impersonateStudent(user._id, user.name)
                    navigate("/Interviews")
                  }}
                  size="sm"
                  color="primary"
                >
                  <FontAwesomeIcon icon={faEye} /> Interviews
                </Button>
              </Tooltip>
              <Tooltip aria-label="Details" content="View Performance">
                <Button
                  onClick={() => {
                    impersonateStudent(user._id, user.name)
                    navigate("/Performance")
                  }}
                  size="sm"
                  color="secondary"
                >
                  <FontAwesomeIcon icon={faPieChart} /> Performance
                </Button>
              </Tooltip>
              {!getAllUsers && (
                <RemoveStudentButton
                  userId={user._id}
                  userName={user.name}
                  listUsers={loadUsers}
                />
              )}
            </div>
          )
        default:
          return cellValue
      }
    },
    [],
  )

  return (
    <Page showSidebar={true}>
      <InviteStudentModal disclosure={inviteUserDisclosure} />
      <PageHeader>
        <h1>Manage Students</h1>
        <Tabs
          defaultSelectedKey={adminGetAllUsers === true ? "all" : "students"}
          onSelectionChange={(key) => {
            setAdminGetAllUsers(key === "all")
          }}
        >
          <Tab title="My Students" key="students"></Tab>
          <Tab title="All Users" key="all"></Tab>
        </Tabs>
        <Button
          color="primary"
          variant="shadow"
          startContent={<FontAwesomeIcon icon={faPlus} />}
          onClick={inviteUserDisclosure.onOpen}
        >
          Invite Student
        </Button>
      </PageHeader>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Table
          aria-label=""
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                total={totalPages}
                initialPage={currentPage}
                onChange={(page) => {
                  setCurrentPage(page)
                  loadUsers(page)
                }}
              />
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                className={column.uid === "actions" ? "actions-table-cell" : ""}
                align={column.uid === "actions" ? "end" : "start"}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>

          <TableBody items={displayUsers}>
            {(item: any) => (
              <TableRow key={item._id}>
                {(columnKey) => (
                  <TableCell>
                    {renderCell(item, columnKey, adminGetAllUsers)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Page>
  )
}
