import { IUserSubscriptionMetadata } from "@/api/types/user/user"
import MySubscriptionWidgetFeatureRow from "@/components/Widgets/MySubscriptionWidget/FeatureRow"
import {
  keysToShowCreditBalanceFor,
  subscriptionPlanFeaturesMap,
} from "@/core/constants/subscription-plan"
import { Button, Card, Chip } from "@nextui-org/react"
import "./index.css"
import { zeroPad } from "react-countdown"
import { useEffect, useState } from "react"
import { cancelSubscription } from "@/api/routes/subscription"

interface Props {
  subscription: IUserSubscriptionMetadata
  reloadSubscriptions: () => Promise<void>
}

export default function SubscriptionCard({
  subscription,
  reloadSubscriptions,
}: Props) {
  const [renewDate, setRenewDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false)
  useEffect(() => {
    if (subscription.renewDate) {
      setRenewDate(new Date(subscription.renewDate))
    }
    if (subscription.endDate) {
      setEndDate(new Date(subscription.endDate))
    }
  }, [subscription])

  const formatDate = (date: Date | null | undefined) =>
    date
      ? `${zeroPad(date.getDate())}/${zeroPad(date.getMonth())}/${date.getFullYear()}`
      : ""

  const handleCancelSubscriptionClick = async () => {
    setIsCancellingSubscription(true)
    await cancelSubscription(subscription._id)
    await reloadSubscriptions()
    setIsCancellingSubscription(false)
  }

  return (
    <div className="subscription-card-container">
      <Card className="subscription-card">
        <div className="subscription-card-header">
          <div className="subscription-card-header-row">
            <h2>{subscription.name}</h2>
            <Chip variant="flat" color="success">
              £{subscription.price} / {subscription.length} days
            </Chip>
          </div>
        </div>

        <div className="subscription-card-features">
          {Object.keys(subscription.features ?? {}).map((key: any, i) =>
            keysToShowCreditBalanceFor.includes(key) ? (
              <MySubscriptionWidgetFeatureRow
                key={i}
                name={subscriptionPlanFeaturesMap[key].title}
                value={`${subscription.features[key]?.value ?? 0} remaining`}
              />
            ) : null,
          )}
        </div>
      </Card>
      <Card className="subscription-card-footer">
        {renewDate && (
          <div className="subscription-card-footer-inner">
            {renewDate && !subscription.endDate && (
              <>
                <span>Renews {formatDate(renewDate)}</span>
                <Button
                  variant="flat"
                  color="danger"
                  onClick={handleCancelSubscriptionClick}
                  isLoading={isCancellingSubscription}
                >
                  Cancel
                </Button>
              </>
            )}
            {subscription.endDate && (
              <span>Expires {formatDate(endDate!)}</span>
            )}
          </div>
        )}
      </Card>
    </div>
  )
}
