import React, { useEffect, useRef, useState } from "react"

import Page from "@/core/components/Page"
import {
  faAlignJustify,
  faEdit,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Card,
  CheckboxGroup,
  Chip,
  Link,
  Spacer,
  useDisclosure,
} from "@nextui-org/react"
import PageHeader from "@/core/components/Page/PageHeader"
import {
  getQuestion,
  listQuestions,
} from "@/api/routes/question-bank/questions"
import { useNavigate, useParams } from "react-router"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { deleteQuestion } from "@/api/routes/admin/question-bank"
import { PageLoader } from "@/components/PageLoader"
import { useAppSelector } from "@/app/hooks"
import {
  IAnswerMetaData,
  IQuestion,
  IQuestionBankQuestion,
} from "@/api/types/question/question"
import QuestionCard from "@/pages/QuizAttempt/components/QuestionCard"
import SingleAccordion from "@/core/components/SingleAccordion"
import RichTextRender from "@/core/components/RichTextRender"
import TimerCard from "@/pages/QuizAttempt/components/TimerCard"
import TotalMarksCard from "@/pages/QuizAttempt/components/TotalMarksCard"
import QuestionCursor from "@/pages/QuizAttempt/components/QuestionCursor"
import BaseModal from "@/core/components/Modals/BaseModal"
import FullAnswerCheckbox from "@/pages/QuizAttempt/components/FullAnswerCheckbox"
import SwitchBox from "@/core/components/SwitchBox"
import LinksList from "@/core/components/LinksList"

interface Props {
  timeTaken: number
  question: any
}

export default function PreviewReviewQuestionPage({
  timeTaken,
  question,
}: Props) {
  const navigate = useNavigate()
  const timerRef: any = useRef()

  const [mandatoryAnswersScored, setMandatoryAnswersScored] = useState<any>([])
  const [optionalAnswersScored, setOptionalAnswersScored] = useState<any>([])
  const [mandatoryAnswers, setMandatoryAnswers] = useState<any>([])
  const [optionalAnswers, setOptionalAnswers] = useState<any>([])
  const [optionalAnswersEnabled, setOptionalAnswersEnabled] = useState(
    [] as any,
  )
  const [totalMarks, setTotalMarks] = useState(question.marks)

  useEffect(() => {
    setMandatoryAnswers(question.answers.filter((x: any) => !x.isOptional))
    setOptionalAnswers(question.answers.filter((x: any) => x.isOptional))
  }, [question])

  useEffect(() => {
    const mandatoryTotalMarks = mandatoryAnswers.reduce(
      (acc: number, curr: any) => acc + parseInt(curr.marks),
      0,
    )

    const optionalAnswersEnabledTotalMarks = optionalAnswersEnabled.reduce(
      (acc: number, curr: any) => {
        const answer = optionalAnswers.find((x: any) => x._id === curr)
        return acc + parseInt(answer.marks)
      },
      0,
    )

    setTotalMarks(mandatoryTotalMarks + optionalAnswersEnabledTotalMarks)
  }, [
    question.marks,
    mandatoryAnswers,
    optionalAnswers,
    optionalAnswersEnabled,
  ])

  return (
    <Page showSidebar={false} disableUserProfileWatch={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      <Spacer y={5} />
      <>
        <div className="page-halves">
          <div className="page-left-half w-full">
            {/* Question card */}
            <QuestionCard question={question!.question} name={question!.name} />

            <div>
              <>
                {question?.interviewerInstructionsEnabled && (
                  <>
                    <SingleAccordion
                      variant="shadow"
                      title="Examiner Instructions (only visible in single question mode)"
                    >
                      <RichTextRender
                        observable={question?.interviewerInstructions ?? ""}
                      />
                    </SingleAccordion>
                    <Spacer y={5} />
                  </>
                )}

                {question?.intervieweeInstructionsEnabled && (
                  <>
                    <SingleAccordion
                      variant="shadow"
                      title="Candidate Instructions (only visible in single question mode)"
                    >
                      <RichTextRender
                        observable={question?.intervieweeInstructions ?? ""}
                      />
                    </SingleAccordion>
                    <Spacer y={5} />
                  </>
                )}
                {question?.actorModeEnabled && (
                  <>
                    <SingleAccordion
                      variant="shadow"
                      title="Actor Instructions (only visible in single question mode)"
                    >
                      <RichTextRender
                        observable={question?.actorInstructions ?? ""}
                      />
                    </SingleAccordion>
                    <Spacer y={5} />
                  </>
                )}
              </>
            </div>

            <Card shadow="none" className="in-progress-quiz-answer-card">
              <CheckboxGroup
                label="Select the answers scored by the interviewee"
                color="success"
                value={mandatoryAnswers
                  .map((x: any, i: number) =>
                    mandatoryAnswersScored.includes(x._id)
                      ? i.toString()
                      : null,
                  )
                  .filter((x: any) => x !== null)}
                onChange={(scoredIndexes: any) => {
                  const scoredIds = scoredIndexes.map(
                    (x: any) => mandatoryAnswers[x]._id,
                  )
                  setMandatoryAnswersScored(scoredIds)
                }}
                classNames={{
                  base: "w-full",
                }}
              >
                {mandatoryAnswers.map(
                  (answerMetaData: IAnswerMetaData, index: number) => (
                    <div
                      key={
                        question!.id + answerMetaData.answer + index.toString()
                      }
                    >
                      {answerMetaData.heading && (
                        <h2>{answerMetaData.heading}</h2>
                      )}
                      <FullAnswerCheckbox
                        isSelected={mandatoryAnswersScored.includes(
                          answerMetaData._id,
                        )}
                        asMarker={false}
                        answer={answerMetaData.answer}
                        index={index}
                        marksToAdd={answerMetaData.marks}
                        totalMarks={question!.marks}
                      />
                    </div>
                  ),
                )}
              </CheckboxGroup>
            </Card>
            <Spacer />

            {optionalAnswers.length > 0 && (
              <Card shadow="none" className="in-progress-quiz-answer-card">
                <p className="rich-text-area-label">
                  Select the optional answers scored by the interviewee
                </p>
                {optionalAnswers.map(
                  (answerMetaData: IAnswerMetaData, index: number) => {
                    return (
                      <div
                        key={
                          question!.id +
                          answerMetaData.answer +
                          index.toString()
                        }
                      >
                        {answerMetaData.heading && (
                          <>
                            <h2>{answerMetaData.heading}</h2>
                            <Spacer />
                          </>
                        )}
                        <div>
                          <SwitchBox
                            title={answerMetaData.answer}
                            hideHeaderOnOpen={true}
                            checked={optionalAnswersEnabled.includes(
                              answerMetaData._id,
                            )}
                            setChecked={(val: boolean) => {
                              if (val) {
                                setOptionalAnswersEnabled((prev: any) => {
                                  return [...prev, answerMetaData._id]
                                })
                              } else {
                                setOptionalAnswersEnabled((prev: any) => {
                                  return prev.filter(
                                    (x: any) => x !== answerMetaData._id,
                                  )
                                })
                              }
                            }}
                          >
                            <CheckboxGroup
                              label=""
                              color="success"
                              value={optionalAnswers
                                .map((x: any, i: number) =>
                                  optionalAnswersScored.includes(x._id)
                                    ? i.toString()
                                    : null,
                                )
                                .filter((x: any) => x !== null)}
                              onChange={(scoredIndexes: any) => {
                                const scoredIds = scoredIndexes.map(
                                  (x: any) => optionalAnswers[x]._id,
                                )
                                setOptionalAnswersScored(scoredIds)
                              }}
                              classNames={{
                                base: "w-full",
                              }}
                            >
                              <FullAnswerCheckbox
                                isSelected={mandatoryAnswersScored.includes(
                                  answerMetaData._id,
                                )}
                                asMarker={false}
                                answer={answerMetaData.answer}
                                index={index}
                                marksToAdd={answerMetaData.marks}
                                totalMarks={totalMarks}
                              />
                            </CheckboxGroup>
                          </SwitchBox>
                          <Spacer />
                        </div>
                      </div>
                    )
                  },
                )}
              </Card>
            )}
            <Spacer y={2} />

            {/* Quiz Links */}
            <LinksList
              hideShadow={true}
              className="quiz-links-list"
              title="Further Study"
              links={question?.associatedReadingLinks ?? []}
            />

            {/* Show answers here */}
          </div>
          <div className="page-right-half">
            <TimerCard
              ref={timerRef}
              key={question!._id + "_Timer"}
              hidePlay={true}
              hidePause={true}
              hideReset={true}
              autostart={false}
              expectedTime={question!.timeLimit}
              startTimeTaken={timeTaken}
            />
            <Spacer y={5} />
            <TotalMarksCard totalMarks={totalMarks} />
            <Spacer y={5} />
          </div>
        </div>
      </>
    </Page>
  )
}
