import Page from "@/core/components/Page"
import {
  Button,
  Checkbox,
  Input,
  Link,
  Select,
  SelectItem,
  Selection,
  Spacer,
  Textarea,
} from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import "./index.css"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import { Key, useEffect, useState } from "react"
import {
  isFieldFilledOut,
  isLanguageFieldFilledOut,
} from "@/core/helpers/utils"
import TableSelectInput from "../components/TableSelectInput"
import { getSubscriptionPlanData } from "@/api/routes/subscription-plan"
import { ItemInputObservable } from "../components/ItemInput"
import { updateSubscriptionPlan } from "@/api/routes/admin/subscription-plan"
import { IPlanFeatures } from "@/api/types/subscription-plan/subscription-plan"
import SubscriptionPlanFeaturesInput from "../components/SubscriptionPlanFeaturesInput"
import { PageLoader } from "@/components/PageLoader"

const roles = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Content Creator",
    value: "content-creator",
  },
  {
    label: "Tutor",
    value: "tutor",
  },
  {
    label: "User",
    value: "user",
  },
]

export default function EditSubscriptionPlanPage() {
  const navigate = useNavigate()
  const { subscriptionPlanId } = useParams()

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const [planName, setPlanName] = useState<string>("")
  const [planPrice, setPlanPrice] = useState<number>(1)
  const [planDuration, setPlanDuration] = useState<number>(0)
  const [features, setPlanFeatures] = useState<ItemInputObservable>({})
  const [planDescription, setPlanDescription] = useState<string>("")
  const [autoRenew, setAutoRenew] = useState<boolean>(false)

  const buildAndSendPlan = async () => {
    setIsSubmitLoading(true)

    // Convert item input observable to IPlanFeatures
    const formattedFeatures: IPlanFeatures = {}
    Object.keys(features).forEach((key) => {
      formattedFeatures[key] = {
        value: features[key],
      }
    })

    const plan = {
      name: planName,
      price: planPrice,
      duration: planDuration,
      features: formattedFeatures,
      description: planDescription,
      autoRenew: autoRenew,
    }

    await updateSubscriptionPlan(subscriptionPlanId!, plan)

    navigate("/Admin/SubscriptionPlans")

    setIsSubmitLoading(false)
  }

  const getSubscriptionPlan = async () => {
    const planData = await getSubscriptionPlanData(subscriptionPlanId!)
    if (planData != null) {
      setPlanName(planData.name)
      setPlanPrice(planData.price)
      setPlanDuration(planData.duration)
      setPlanDescription(planData.description)
      setAutoRenew(planData.autoRenew ?? false)

      const formattedFeatures: ItemInputObservable = {}
      Object.keys(planData.features).forEach((key) => {
        formattedFeatures[key] = planData.features[key].value
      })
      setPlanFeatures(formattedFeatures)
    }
  }

  useEffect(() => {
    ;(async () => {
      setIsPageLoading(true)
      await getSubscriptionPlan()
      setIsPageLoading(false)
    })()
  }, [])

  const formValidate = () => {
    if (
      isFieldFilledOut(planName) &&
      isFieldFilledOut(planDescription) &&
      isFieldFilledOut(planDuration) &&
      isFieldFilledOut(planPrice) &&
      isLanguageFieldFilledOut(features)
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <>
          <Link
            className="link"
            onClick={() => {
              navigate(-1)
            }}
          >
            {"< Back"}
          </Link>
          <PageHeader>
            <h1>Edit Subscription Plan</h1>
          </PageHeader>
          <div className="page-grid">
            <div className="input-wrapper row row-gap-10">
              <Input
                type="text"
                label="Plan Name"
                placeholder="Enter the plan name"
                value={planName}
                variant="bordered"
                onChange={(e) => setPlanName(e.target.value)}
              />
            </div>

            <div className="input-wrapper">
              <Textarea
                type="text"
                variant="bordered"
                label="Plan Description"
                placeholder="Enter the plan description"
                value={planDescription}
                onChange={(e) => setPlanDescription(e.target.value)}
              />
            </div>

            <div className="input-wrapper">
              <SubscriptionPlanFeaturesInput
                observable={features}
                setObservable={setPlanFeatures}
              />
            </div>

            <div className="input-wrapper row row-gap-10">
              <Input
                type="number"
                label="Price (GBP)"
                placeholder="Enter the price"
                variant="bordered"
                min={0}
                step=".01"
                value={planPrice.toString()}
                onChange={(e) => setPlanPrice(parseFloat(e.target.value))}
                startContent={
                  <div className="pointer-events-none flex items-center">
                    <span className="text-default-400 text-small">£</span>
                  </div>
                }
              />
              <Input
                type="number"
                label="Duration (days)"
                placeholder="Enter the duration"
                variant="bordered"
                min={0}
                value={planDuration.toString()}
                onChange={(e) => setPlanDuration(parseInt(e.target.value))}
              />
            </div>

            <div className="input-wrapper column">
              <Checkbox
                isSelected={autoRenew}
                onChange={(e: any) => setAutoRenew(e.target.checked)}
              >
                Subscription purchase
              </Checkbox>
              <span>
                <i>
                  Check this if this subscription should renew automatically. If
                  unchecked, this plan will be a one-time payment.
                  <br />
                  Updating this field will not change any existing
                  subscriptions.
                </i>
              </span>
            </div>

            <div className="input-wrapper create-question-wrapper">
              <Button
                isDisabled={formValidate()}
                onClick={buildAndSendPlan}
                variant="solid"
                color="primary"
                isLoading={isSubmitLoading}
              >
                Update Subscription Plan
              </Button>
            </div>
          </div>
        </>
      )}
    </Page>
  )
}
