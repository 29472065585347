import { Tab, Tabs } from "@nextui-org/react"
import { useNavigate } from "react-router"

interface Props {
  inProgress?: boolean
  asMarker: boolean
  quizId: string
}

export default function QuizModeTabs({ inProgress, asMarker, quizId }: Props) {
  const navigate = useNavigate()

  const defaultSelectedTab = () => {
    if (inProgress && !asMarker) {
      return "continue"
    } else if (!inProgress && !asMarker) {
      return "review"
    } else if (asMarker) {
      return "mark"
    }
  }

  return (
    <Tabs
      key="review-mode-tabs"
      classNames={{
        base: "review-mode-tabs",
        tabList: "review-mode-tabs",
      }}
      defaultSelectedKey={defaultSelectedTab()}
      onSelectionChange={(key: React.Key) => {
        if (key === "mark") {
          navigate("/Quiz/Mark/" + quizId!)
        } else if (key === "review") {
          navigate("/Quiz/Review/" + quizId!)
        } else if (key === "continue") {
          navigate("/Quiz/Candidate/" + quizId!)
        }
      }}
      size="lg"
      aria-label="Review Mode"
    >
      {inProgress && <Tab key="continue" title="Continue" />}
      {!inProgress && <Tab key="review" title="Review Mode" />}
      <Tab key="mark" title="Mark Mode" />
    </Tabs>
  )
}
