import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"

export async function listSubscriptionPlans() {
  try {
    const res = await axios.get("/subscription/list")

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function listUserSubscriptions() {
  try {
    const res = await axios.get("/subscription/")

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function cancelSubscription(subscriptionId: string) {
  try {
    const res = await axios.post("/subscription/cancel/" + subscriptionId)

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export interface CheckoutRegisterData {
  email: string
  firstName?: string
  lastName?: string
}

export async function createCheckoutSession(
  priceId: string,
  checkoutRegisterData: CheckoutRegisterData | null = null,
) {
  try {
    const res = await axios.post("/stripe-billing/create-checkout-session", {
      priceId,
      ...(checkoutRegisterData ?? {}),
    })

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
