import { getCategoriesByPath } from "@/api/routes/category-bank"
import {
  IQuestionCategory,
  ISubQuestionCategory,
} from "@/api/types/question/question"
import { SidebarLink } from "@/components/Sidebar"
import SidebarDropDownLink from "@/components/Sidebar/SidebarDropDownLink"
import { faFolder } from "@fortawesome/free-solid-svg-icons"
import React, { useEffect, useState } from "react"

interface Props {
  categories: IQuestionCategory[] | ISubQuestionCategory[]
  setCategories: any
  isRootCategory: boolean
  setSelectedDirectoriesNames: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedDirectoriesIds: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedDirectoriesPath: React.Dispatch<React.SetStateAction<string[]>>
  selectedDirectoriesIds: string[]
  selectedDirectoriesPath: string[]
  selectedDirectoriesNames: string[]
}
export default function SideBarNotesBrowser(props: Props) {
  const [fullPathIds, setFullPathIds] = useState<string[]>()
  const [fullPathPath, setFullPathPath] = useState<string[]>()
  const [fullPathNames, setFullPathNames] = useState<string[]>()
  useEffect(() => {
    // Store a full path to this dir
    setFullPathIds(props.selectedDirectoriesIds)
    setFullPathPath(props.selectedDirectoriesPath)
    setFullPathNames(props.selectedDirectoriesNames)
  }, [])

  return (
    <>
      {props.categories.map((subCategory, index) =>
        (subCategory.subCategories?.length ?? 0) > 0 ? (
          <SidebarDropDownLink title={subCategory.name} icon={faFolder}>
            <SideBarNotesBrowser
              categories={subCategory.subCategories}
              isRootCategory={false}
              setSelectedDirectoriesNames={props.setSelectedDirectoriesNames}
              setSelectedDirectoriesIds={props.setSelectedDirectoriesIds}
              setSelectedDirectoriesPath={props.setSelectedDirectoriesPath}
              selectedDirectoriesIds={props.selectedDirectoriesIds}
              setCategories={props.setCategories}
              selectedDirectoriesPath={props.selectedDirectoriesPath}
              selectedDirectoriesNames={props.selectedDirectoriesNames}
            />
          </SidebarDropDownLink>
        ) : (
          <SidebarLink
            key={subCategory._id}
            icon={faFolder}
            title={subCategory.name}
            isSelectedOverride={props.selectedDirectoriesIds?.includes(
              subCategory?._id ?? "n/a",
            )}
            onClick={async () => {
              let idsTrail = props.isRootCategory
                ? [subCategory._id!]
                : [...props.selectedDirectoriesIds, subCategory._id!]
              /*
              const childCat = await getCategoriesByPath(idsTrail)
              console.log(childCat)

              // setCurr index on categories
              props.setCategories((prev: any) => {
                // iterate through selectedDirectoriesPath til we arrive at our current cat
                let next = [...prev]
                let query = `[${index}]`
                console.log("Setting")
                for (let idx of props.selectedDirectoriesPath) {
                  query += `[${idx}]`
                }
                console.log(query)

                eval(`next${query} = childCat`)
                return next
              })
                */

              if (props.isRootCategory) {
                props.setSelectedDirectoriesNames((prev) => [subCategory.name])
                props.setSelectedDirectoriesIds((prev) => [subCategory._id!])
                props.setSelectedDirectoriesPath((prev) => [index.toString()])
              } else {
                props.setSelectedDirectoriesNames((prev) => [
                  ...(fullPathNames ?? []),
                  subCategory.name,
                ])
                props.setSelectedDirectoriesIds((prev) => [
                  ...(fullPathIds ?? []),
                  subCategory._id!,
                ])
                props.setSelectedDirectoriesPath((prev) => [
                  ...(fullPathPath ?? []),
                  index.toString(),
                ])
              }
            }}
          />
        ),
      )}
    </>
  )
}
