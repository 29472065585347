import { Accordion, AccordionItem } from "@nextui-org/react"

interface Props {
  variant: "light" | "shadow" | "bordered" | "splitted"
  title: string
  children: React.ReactNode
}
export default function SingleAccordion(props: Props) {
  return (
    <Accordion variant={props.variant}>
      <AccordionItem key="1" aria-label={props.title} title={props.title}>
        {props.children}
      </AccordionItem>
    </Accordion>
  )
}
