import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "@/app/store"
import axios from "../core/helpers/axios"

// Reducer
export interface TutorStudentImpersonationState {
  userId?: string
  name?: string
}

const initialState: TutorStudentImpersonationState = {}

export const TutorStudentImpersonationSlice = createSlice({
  name: "tutorStudentImpersonation",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    set: (state, user: PayloadAction<TutorStudentImpersonationState>) => {
      axios.defaults.headers.common["X-Tutor-Student-Impersonation"] =
        user.payload.userId

      return {
        ...state,
        userId: user.payload.userId,
        name: user.payload.name,
      }
    },
    clear: (state) => {
      axios.defaults.headers.common["X-Tutor-Student-Impersonation"] = undefined

      return {
        ...state,
        userId: undefined,
        name: undefined,
      }
    },
  },
})

export const { set, clear } = TutorStudentImpersonationSlice.actions

export default TutorStudentImpersonationSlice.reducer
