import { subscriptionPlanFeaturesMap } from "@/core/constants/subscription-plan"
import NumberWidget from "../NumberWidget"

interface Props {
  subscriptionData: any
  size?: string
  maxHeightOverride?: boolean
}

export default function SubscriptionCreditsWidget({
  size,
  subscriptionData,
  maxHeightOverride,
}: Props) {
  return (
    subscriptionData &&
    Object.keys(subscriptionData).length > 0 && (
      <div
        key={"subscription-credits-widget"}
        className="widget-full-width-unset-height widget-right-flex"
        style={{ height: maxHeightOverride ? "100%" : "unset" }}
      >
        {Object.keys(subscriptionData).map((key, i) =>
          /*
              The logic here:
              1. If the field is not a number, then don't continue.
              2. If there is no link for this plan feature and an unlimited field (i.e. a field which overrides this value to unlimited)
              then we can continue.
              3. If there is an unlimited field, but the user does not have a subscription with said unlimited field in, then we can continue.
              4. If there is an unlimited field, and it is set to false, then we can continue.
            */
          key != "voice-recording-storage-limit" &&
          subscriptionPlanFeaturesMap[key].type === "number" &&
          (!subscriptionPlanFeaturesMap[key].attachedUnlimitedField ||
            !subscriptionData[
              subscriptionPlanFeaturesMap[key].attachedUnlimitedField!
            ] ||
            subscriptionData[
              subscriptionPlanFeaturesMap[key].attachedUnlimitedField!
            ].value === false) ? (
            <NumberWidget
              key={"nw-" + key}
              title={subscriptionPlanFeaturesMap[key].title}
              value={subscriptionData[key].value}
              size={size}
            />
          ) : null,
        )}
      </div>
    )
  )
}
