import RichTextRender from "@/core/components/RichTextRender"
import { Card } from "@nextui-org/react"

interface Props {
  name: string
  question: string
}
export default function QuestionCard({ name, question }: Props) {
  return (
    <div className="in-progress-quiz-question-card">
      <Card className="in-progress-quiz-content-card">
        <h1>{name}</h1>
        <RichTextRender observable={question} />
      </Card>
    </div>
  )
}
