import { addSubCategory } from "@/api/routes/admin/question-category/category-bank"
import { addNotes } from "@/api/routes/admin/question-category/notes"
import {
  AnswerAccessLevel,
  CategoryAccessLevel,
  CategoryAccessLevelString,
  ISubQuestionCategory,
} from "@/api/types/question/question"
import BaseModal from "@/core/components/Modals/BaseModal"
import RichTextArea from "@/core/components/RichTextArea"
import SelectFromEnums from "@/core/components/SelectFromEnums"
import { isFieldFilledOut, isSetFieldFilledOut } from "@/core/helpers/utils"
import { Button, Input, Spacer, UseDisclosureProps } from "@nextui-org/react"
import { useState } from "react"

interface Props {
  disclosure: UseDisclosureProps
  onAddNotes: (title: any, notes: any, accessLevel: any) => Promise<void>
}
export default function AddNoteToRootModal({ disclosure, onAddNotes }: Props) {
  const [title, setTitle] = useState("")
  const [notes, setNotes] = useState("")
  const [accessLevel, setAccessLevel] = useState<any>(new Set([]))
  const [isLoading, setIsLoading] = useState(false)

  const formValidate = () => {
    if (
      isFieldFilledOut(title) &&
      isFieldFilledOut(notes) &&
      isSetFieldFilledOut(accessLevel)
    ) {
      return false
    }
    return true
  }

  const closeModal = () => {
    setTitle("")
    setNotes("")
    setAccessLevel(new Set([]))
    disclosure.onClose!()
  }

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Add Notes"
      size="full"
      body={
        <div>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Title"
          />
          <Spacer y={2} />
          <RichTextArea
            height="500px"
            observable={notes ?? ""}
            onChange={(contents: string) => {
              setNotes(contents)
            }}
            label={"Notes"}
            placeholder={"Add notes here..."}
          />
          <Spacer y={2} />
          <SelectFromEnums
            label="Access Level"
            placeholder="Select who can view this content"
            selectedKeys={accessLevel}
            onSelectionChange={setAccessLevel}
            stringEnum={CategoryAccessLevelString}
            valuesEnum={CategoryAccessLevel}
          />
          <Spacer y={2} />
          <p>
            <i>
              (You can add related links after you have created this set of
              notes - just click the edit icon).
            </i>
          </p>
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              closeModal()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await onAddNotes(title, notes, accessLevel)
              closeModal()
            }}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
            isLoading={isLoading}
          >
            Add Notes
          </Button>
        </div>
      }
    />
  )
}
