import Page from "@/core/components/Page"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Spinner,
} from "@nextui-org/react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import "./index.css"
import {
  activateTutoring,
  declineTutoring,
  getTutorInviteDetails,
} from "@/api/routes/user/user"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleXmark, faClose } from "@fortawesome/free-solid-svg-icons"

export default function AcceptTutorInvite() {
  const [tutorName, setTutorName] = useState("Example Tutor")
  const { token } = useParams()

  const [pageLoading, setPageLoading] = useState(true)
  const [buttonsLoading, setButtonsLoading] = useState(false)
  const [invalidInvite, setInvalidInvite] = useState(false)

  const navigate = useNavigate()

  const acceptTutorInvite = async () => {
    setButtonsLoading(true)
    activateTutoring(token!)
    setButtonsLoading(false)
    navigate("/")
  }

  const declineTutorInvite = async () => {
    setButtonsLoading(true)
    declineTutoring(token!)
    setButtonsLoading(false)
    navigate("/")
  }

  const getTutorDetails = async () => {
    const tutorDetails = await getTutorInviteDetails(token!)
    if (
      tutorDetails === LoginEnum.UNAUTHORIZED ||
      tutorDetails === LoginEnum.OTHER
    ) {
      setInvalidInvite(true)
    } else {
      setTutorName(
        tutorDetails.tutorFirstName + " " + tutorDetails.tutorLastName,
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      setPageLoading(true)
      await getTutorDetails()
      setPageLoading(false)
    })()
  }, [])

  return (
    <Page showSidebar={false}>
      <div className="center-align-content-on-page">
        <Card className="accept-tutoring-card">
          {pageLoading && <Spinner />}
          {!pageLoading && (
            <>
              {invalidInvite ? (
                <CardBody className="invalid-tutor-invite-container">
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    style={{ color: "hsl(var(--medcamps-danger-500))" }}
                    size="3x"
                  />
                  <h1 className="invalid-tutor-invite-text">
                    This invite does not exist or has already been used.
                  </h1>
                </CardBody>
              ) : (
                <>
                  <CardBody>
                    <h1 className="center-modal-title">
                      Accept Tutoring from {tutorName}
                    </h1>
                    <p className="center-modal-desc">
                      By accepting this invite, you give your tutor the ability
                      to view your statistics and quizzes, as well as manage
                      your quizzes as you.
                    </p>
                  </CardBody>
                  <CardFooter>
                    <div className="accept-tutoring-footer-content">
                      {buttonsLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          <Button
                            color="danger"
                            variant="light"
                            onClick={() => {
                              declineTutorInvite()
                            }}
                          >
                            Decline
                          </Button>
                          <Button
                            color="primary"
                            variant="solid"
                            onClick={() => {
                              acceptTutorInvite()
                            }}
                          >
                            Accept
                          </Button>
                        </>
                      )}
                    </div>
                  </CardFooter>
                </>
              )}
            </>
          )}
        </Card>
      </div>
    </Page>
  )
}
