import { startTutoringStudent } from "@/api/routes/tutor"
import BaseModal from "@/core/components/Modals/BaseModal"
import { Button, Input, UseDisclosureProps } from "@nextui-org/react"
import { useState } from "react"

interface Props {
  disclosure: UseDisclosureProps
}

export default function InviteStudentModal({ disclosure }: Props) {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  }

  const sendInvite = async () => {
    setLoading(true)
    await startTutoringStudent(email, firstName, lastName)
    setLoading(false)
    disclosure.onClose!()
  }

  return (
    <BaseModal
      title="Invite a new student"
      body={
        <>
          Enter the email of the student you would like to invite.{" "}
          <i>
            (If they do not have an account, they will be sent an email to
            create one.)
          </i>
          {/* first & last name */}
          <Input
            type="text"
            variant="bordered"
            value={firstName}
            onValueChange={setFirstName}
            placeholder="First Name"
          />
          <Input
            type="text"
            variant="bordered"
            value={lastName}
            onValueChange={setLastName}
            placeholder="Last Name"
          />
          <Input
            type="email"
            variant="bordered"
            value={email}
            onValueChange={setEmail}
            placeholder="Email"
          />
        </>
      }
      buttons={
        <>
          <Button color="default" variant="light" onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button
            isLoading={loading}
            isDisabled={
              !validateEmail(email) || firstName === "" || lastName === ""
            }
            color="success"
            variant="flat"
            onClick={sendInvite}
          >
            Invite
          </Button>
        </>
      }
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
    />
  )
}
