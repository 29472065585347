import { Spacer } from "@nextui-org/react"
import SideWidget from "@/pages/QuizAttempt/components/SideWidget"
import "./index.css"

interface Props {
  totalMarks?: number
  marksObtained?: string
  bracketsMarks?: string
  title?: string
}

export default function TotalMarksCard(props: Props) {
  return (
    <SideWidget>
      <h1>{props.title ?? "Marks"}</h1>
      <div className="total-marks-card-content">
        <div className="total-marks-card-content-text-container">
          <span className="total-marks-card-scored">
            {typeof props.marksObtained === "string"
              ? props.marksObtained +
                (typeof props.totalMarks === "number" ? "/" : "")
              : typeof props.totalMarks === "number"
              ? "/"
              : null}
          </span>
          {typeof props.totalMarks === "number" ? (
            <span className="total-marks-card-total">{props.totalMarks}</span>
          ) : null}
        </div>
        <Spacer y={0.5} />
        {props.bracketsMarks ? (
          <div className="total-marks-card-content-text-container">
            <span className="total-marks-card-brackets">
              {props.bracketsMarks}
            </span>
          </div>
        ) : null}
      </div>
    </SideWidget>
  )
}
