import {
  getSubscriptionPlanBasicData,
  getSubscriptionPlanData,
  listSubscriptionPlans,
} from "@/api/routes/subscription-plan"
import { WidgetGrid } from "@/components/Widgets"
import Page from "@/core/components/Page"
import { Avatar, Button, Card, Input } from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faBasketShopping,
} from "@fortawesome/free-solid-svg-icons"
import {
  CheckoutRegisterData,
  createCheckoutSession,
} from "@/api/routes/subscription"
import { useParams } from "react-router"
import { PageLoader } from "@/components/PageLoader"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import logout from "@/api/routes/auth/logout"
import getProfile from "@/api/routes/user/user"
import { User } from "@/api/types/user/user"

export default function SubscriptionFlow() {
  const { subscriptionId } = useParams()

  const [selectedPlan, setSelectedPlan] = useState<any>({})
  const [isStripePlan, setIsStripePlan] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [showRegistrationFlow, setShowRegistrationFlow] = useState(false)
  const [email, setEmail] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")

  const [isProceedingToCheckout, setIsProceedingToCheckout] =
    useState<boolean>(false)

  const dispatch = useAppDispatch()

  const pageFlow = async () => {
    if (!subscriptionId) return
    setIsLoadingPage(true)

    const foundUserProfile = await getProfile()
    const isUserLoggedIn = typeof foundUserProfile !== "number"
    setCurrentUser(isUserLoggedIn ? foundUserProfile : null)

    const data = await getSubscriptionPlanBasicData(subscriptionId)

    setIsStripePlan(
      typeof data.stripeData?.priceId === "string" &&
        typeof data.stripeData?.productId === "string",
    )
    setSelectedPlan(data)

    // If the user doesn't exist, checkout immediately
    if (!isUserLoggedIn) {
      setShowRegistrationFlow(true)
    }

    setIsLoadingPage(false)
  }

  useEffect(() => {
    pageFlow()
  }, [])

  const checkout = async (
    priceId: string,
    checkoutRegisterData: CheckoutRegisterData | null = null,
  ) => {
    const redirectLink = await createCheckoutSession(
      priceId,
      checkoutRegisterData,
    )

    window.location.href = redirectLink
  }

  const validateEmail = (email: string | null) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  const validateFirstName = (firstName: string | null) => {
    return (firstName?.length ?? 0) > 0
  }

  const validateLastName = (lastName: string | null) => {
    return (lastName?.length ?? 0) > 0
  }

  return (
    <Page showSidebar={false}>
      {isLoadingPage && <PageLoader fullScreen={true} />}
      {!isLoadingPage && selectedPlan && (
        <div className="purchase-subscription-area">
          <Card>
            {isStripePlan && (
              <div className="purchase-subscription-card">
                <div className="purchase-subscription-card-body">
                  {currentUser && (
                    <div className="purchase-subscription-card-signed-in">
                      <Avatar size="lg" />
                      <div className="continue-as-container">
                        <h1>
                          Check out as{" "}
                          {currentUser.firstName + " " + currentUser.lastName}?
                        </h1>
                        <span>
                          <i>({currentUser.username})</i>
                        </span>
                      </div>
                    </div>
                  )}
                  {showRegistrationFlow && (
                    <div className="purchase-subscription-card-email">
                      <h1>Check out</h1>
                      <p>Please enter your name and email to proceed.</p>
                      <Input
                        value={firstName}
                        isDisabled={isProceedingToCheckout}
                        onChange={(e) => setFirstName(e.target.value)}
                        label="First name(s)"
                        type="text"
                      />
                      <Input
                        value={lastName}
                        isDisabled={isProceedingToCheckout}
                        onChange={(e) => setLastName(e.target.value)}
                        label="Last name"
                        type="text"
                      />
                      <Input
                        label="Email Address"
                        value={email}
                        isDisabled={isProceedingToCheckout}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                      />
                      {!validateEmail(email) && email !== "" && (
                        <span
                          style={{ color: "hsl(var(--medcamps-danger-500))" }}
                        >
                          Please enter a valid email
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <Button
                  color="primary"
                  isLoading={isProceedingToCheckout}
                  isDisabled={
                    showRegistrationFlow &&
                    (!validateEmail(email) ||
                      !validateFirstName(firstName) ||
                      !validateLastName(lastName))
                  }
                  onClick={() => {
                    setIsProceedingToCheckout(true)
                    if (showRegistrationFlow) {
                      checkout(selectedPlan.stripeData.priceId, {
                        email,
                        firstName,
                        lastName,
                      })
                    } else {
                      // A user has to be defined to get to this point
                      checkout(selectedPlan.stripeData.priceId, {
                        email: currentUser!.username,
                      })
                    }
                  }}
                >
                  Proceed to checkout <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                {currentUser && (
                  <Button
                    variant="light"
                    color="secondary"
                    onClick={async () => {
                      setIsLoadingPage(true)
                      await logout()
                      dispatch({
                        type: "user/clear",
                      })
                      setCurrentUser(null)

                      setShowRegistrationFlow(true)
                      setIsLoadingPage(false)
                    }}
                  >
                    Checkout as guest
                  </Button>
                )}
              </div>
            )}

            {!isStripePlan && (
              <div className="purchase-subscription-card">
                <div className="purchase-subscription-card-header">
                  <h1>{selectedPlan.name}</h1>
                </div>
                <div className="purchase-subscription-card-body">
                  Unable to purchase this subscription plan. Please contact our
                  support team if you believe this is an error.
                </div>
              </div>
            )}
          </Card>
        </div>
      )}
    </Page>
  )
}
