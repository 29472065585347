import ItemInput from "../ItemInput"
import { MenuItem } from "../SubscriptionPlanFeaturesInput"

interface Props {
  type: string
  label: string
  placeholder: string

  observable: any
  setObservable: (val: any) => void
}

const totalLanguages: MenuItem[] = [
  {
    key: "enGB",
    label: "English",
    title: "English",
    type: "text",
  },
]

export default function LanguageInput({
  type,
  label,
  placeholder,
  observable,
  setObservable,
}: Props) {
  return (
    <ItemInput
      label={label}
      buttonLabel="+ Add Language"
      placeholder={placeholder}
      observable={observable}
      setObservable={setObservable}
      menuItems={totalLanguages}
    />
  )
}
