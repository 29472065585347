import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsis } from "@fortawesome/free-solid-svg-icons"
interface Props {
  className?: string
  name: string
  description: string
  dropdownContent: React.ReactElement[]
}

export default function UserWithMenu(props: Props) {
  return (
    <>
      <Dropdown placement="top">
        <DropdownTrigger>
          <div className={`user-with-menu ${props.className ?? ""}`}>
            <div className="user-with-menu-main-content">
              <Avatar size="sm" />
              <div className="user-info">
                <h2>{props.name}</h2>
                <p className="user-description">{props.description}</p>
              </div>
            </div>
            <FontAwesomeIcon
              className="user-with-menu-icon"
              size="sm"
              icon={faEllipsis}
            />
          </div>
        </DropdownTrigger>
        <DropdownMenu aria-label="Profile Actions" variant="flat">
          {props.dropdownContent}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}
