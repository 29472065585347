import AvailableQuizList from "@/components/AvailableQuizList"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import { Link } from "@nextui-org/react"
import { useNavigate } from "react-router"
import "./index.css"
import SubscriptionCreditsWidget from "@/components/Widgets/SubscriptionCreditsWidget"
import { useAppSelector } from "@/app/hooks"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function ChooseQuizPage() {
  const navigate = useNavigate()
  const currentUser = useAppSelector((state) => state.user.user)

  return (
    <Page showSidebar={true} overrideContentStyles={true}>
      <div className="unstyled-column-page default-top-padding">
        <div className="page-bar">
          <Link
            className="link"
            onClick={() => {
              navigate(-1)
            }}
          >
            {"< Back"}
          </Link>
          <div style={{ height: "100%" }}>
            <SubscriptionCreditsWidget
              size="fit-content"
              maxHeightOverride={true}
              subscriptionData={currentUser?.subscriptionData}
            />
          </div>
        </div>
        <PageHeader className="default-page-lr-padding">
          <h1>Choose Interview</h1>
        </PageHeader>
        <ToastContainer />
        <AvailableQuizList
          listClassName="default-page-lr-padding choose-quiz-list-top-padding"
          footerClassName="default-page-lr-padding"
        />
      </div>
    </Page>
  )
}
