import { Button, Card, Checkbox, Chip } from "@nextui-org/react"
import "./index.css"
import { useEffect, useState } from "react"

interface Props {
  question: any
  isSelected: boolean
  setIsSelected: (isSelected: boolean) => void
}

export default function InlineQuestionCard({
  question,
  isSelected,
  setIsSelected,
}: Props) {
  const hasBeenAttempted = typeof question["user-quiz"] === "object"
  const [questionAnswers, setQuestionAnswers] = useState<any>({})
  const [scaledScore, setScaledScore] = useState<number>(0)

  const toggleSelected = () => {
    setIsSelected(!isSelected)
  }

  return (
    <Button
      className={`inline-question-card ${
        hasBeenAttempted && "has-been-attempted"
      } ${isSelected && "selected"}`}
      onClick={toggleSelected}
    >
      <div className="inline-question-card-first-half">
        <Checkbox onClick={toggleSelected} isSelected={isSelected}></Checkbox>

        {question.name}
        {/*!props.hasBeenAttempted && (
          <>
            {" "}
            <Chip size="sm" color="primary" variant="flat">
              Category name
            </Chip>
          </>
        )*/}
      </div>

      <div className="inline-question-card-second-half">
        {hasBeenAttempted && (
          <Chip size="sm" color="primary" variant="flat">
            {(question.answer.scaledScore * 100).toFixed(0)}%
          </Chip>
        )}
      </div>
    </Button>
  )
}
