import React, { useEffect, useState } from "react"

import Page from "@/core/components/Page"
import {
  faEdit,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Chip,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"
import PageHeader from "@/core/components/Page/PageHeader"
import { listQuizzes } from "@/api/routes/admin/quiz-bank"
import { useNavigate } from "react-router"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { deleteQuiz } from "@/api/routes/admin/quiz-bank"
import DeleteQuizButton from "./DeleteQuizButton"
import { PageLoader } from "@/components/PageLoader"
import {
  QuizAccessLevel,
  QuizAccessLevelString,
} from "@/api/types/quiz/quizzes"

const columns = [
  { name: "NAME", uid: "name" },
  { name: "SHORT DESCRIPTION", uid: "shortDescription" },
  { name: "ACCESS LEVEL", uid: "quizAccessLevel" },
  { name: "ACTIONS", uid: "actions" },
]

const quizAccessLevel = (answerAccessLevel: QuizAccessLevel) => {
  switch (answerAccessLevel) {
    case QuizAccessLevel.ADMIN_ONLY:
      return "Admin Only"
    case QuizAccessLevel.TUTOR_ONLY:
      return "Tutor Only"
    case QuizAccessLevel.PAYWALL:
      return "Paywall"
    case QuizAccessLevel.UNLOCKED:
      return "Everyone"
  }
}

export default function ManageQuizzesPage() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [allQuizzes, setAllQuizzes] = useState([] as any)
  const [displayQuizzes, setDisplayQuizzes] = useState([] as any)
  const navigate = useNavigate()

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)

  const loadQuizzes = async (page?: number) => {
    setIsPageLoading(true)
    setCurrentPage(page || 1)

    const totalRecords: any = await listQuizzes({
      fetchMeta: true,
      limit: pageLimit,
    })
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    const _allQuizzes = await listQuizzes({
      limit: pageLimit,
      page: page || currentPage,
    })
    if (_allQuizzes != null) {
      setAllQuizzes(_allQuizzes.quizzes)
      setDisplayQuizzes(
        _allQuizzes.quizzes.map((quiz: any) => ({
          _id: quiz._id,
          name: quiz.name?.enGB ?? "",
          shortDescription: quiz.shortDescription?.enGB ?? "",
          quizAccessLevel: quizAccessLevel(
            quiz.quizAccessLevel as QuizAccessLevel,
          ),
        })),
      )
    }
    setIsPageLoading(false)
  }

  useEffect(() => {
    loadQuizzes()
  }, [])

  const renderCell = React.useCallback((quiz: any, columnKey: React.Key) => {
    const cellValue = quiz[columnKey as any]

    switch (columnKey) {
      case "quizAccessLevel":
        return (
          <Chip className="capitalize" size="sm" variant="flat">
            {cellValue}
          </Chip>
        )
      case "actions":
        return (
          <div className="actions-table-cell">
            <Tooltip aria-label="Details" content="Edit quiz">
              <Link
                onClick={() => {
                  navigate(`/Admin/Quiz/Edit/${quiz._id}`)
                }}
              >
                <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                  <FontAwesomeIcon icon={faEdit} />
                </span>
              </Link>
            </Tooltip>
            <DeleteQuizButton
              quizId={quiz._id}
              quizTitle={quiz.name}
              listQuizzes={loadQuizzes}
            />
          </div>
        )
      default:
        return cellValue
    }
  }, [])

  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>Manage Exams</h1>
        <Button
          color="primary"
          variant="shadow"
          startContent={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            navigate("/Admin/Exam/New")
          }}
        >
          New Quiz
        </Button>
      </PageHeader>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Table
          aria-label=""
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                total={totalPages}
                initialPage={currentPage}
                onChange={(page) => {
                  setCurrentPage(page)
                  loadQuizzes(page)
                }}
              />
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                className={column.uid === "actions" ? "actions-table-cell" : ""}
                align={column.uid === "actions" ? "end" : "start"}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>

          <TableBody items={displayQuizzes}>
            {(item: any) => (
              <TableRow key={item._id}>
                {(columnKey) => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Page>
  )
}
