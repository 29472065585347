import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"

export default async function logout() {
  try {
    const res = await axios.get("/auth/logout")
    localStorage.removeItem("auth-token")

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
