import { Progress } from "@nextui-org/react"
import "./index.css"

interface Props {
  currentQuestion: number
  totalQuestions: number
}
const pad = (n: number) => String(n).padStart(2, "0")

export default function QuestionProgressBar({
  currentQuestion,
  totalQuestions,
}: Props) {
  // style should be questionNumber/totalQuestions in Intl.NumberFormat
  var numberFormatStyle: Intl.NumberFormatOptions = { style: "decimal" }
  return (
    <Progress
      aria-label="Question Progress Bar"
      size="lg"
      value={currentQuestion}
      maxValue={totalQuestions}
      color="primary"
      formatOptions={numberFormatStyle}
      valueLabel=""
      showValueLabel={false}
      className="question-progress-bar"
    />
  )
}
