import { deleteQuestion } from "@/api/routes/admin/question-bank"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Tooltip, useDisclosure } from "@nextui-org/react"

interface Props {
  questionId: string
  questionTitle: string
  listQuestions: () => Promise<void>
}
export default function DeleteQuestionButton({
  questionId,
  questionTitle,
  listQuestions,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isOpen}
        onConfirm={async () => {
          await deleteQuestion(questionId)
          await listQuestions()
          onClose()
        }}
        onClose={onClose}
        title="Delete Question"
        description={`Are you sure you want to delete '${questionTitle}'? This action cannot be reversed.`}
      />
      <Tooltip aria-label="Details" color="danger" content="Delete question">
        <Link onClick={onOpen}>
          <span className="text-lg text-danger cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </Link>
      </Tooltip>
    </>
  )
}
