import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { User } from "@/api/types/user/user"

export async function uploadVoice(file: File) {
  try {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("temporary", "true")
    const res = await axios.post("/voice", formData)

    if (res.status === 200 && res.data.body?._id) {
      return res.data.body._id
    } else {
      throw new Error("Failed to upload image")
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function viewVoiceFile(voiceId: string) {
  try {
    const res = await axios.get(`/voice/${voiceId}`, { responseType: "blob" })

    if (res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return null
    } else {
      return null
    }
  }
}

export async function deleteVoiceFile(voiceId: string) {
  try {
    const res = await axios.delete(`/voice/${voiceId}`)

    if (res.status === 200 && res.data.body) {
      return res.data.body
    } else {
      throw new Error("Failed to delete voice file")
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function viewVoiceRecordingAllowance(
  userId: string | null = null,
) {
  try {
    const res = await axios.get("/voice/allowance/" + (userId ?? ""))

    if (res.status === 200 && res.data.body) {
      return res.data.body
    } else {
      throw new Error("Failed to get voice recording allowance")
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function viewVoiceRecordingOriginalTranscriptionRoute(
  attachmentId: string | null = null,
) {
  try {
    const res = await axios.get("/voice/transcription/" + (attachmentId ?? ""))

    if (res.status === 200 && res.data.body) {
      return res.data.body
    } else {
      throw new Error("Failed to get voice recording transcription")
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
