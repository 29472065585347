import { Button } from "@nextui-org/react"
import BaseModal from "@/core/components/Modals/BaseModal"
import { useNavigate } from "react-router"

interface Props {
  quizId: string
  isOpen: boolean
  onClose: () => void
}

export default function ViewCompleteQuizModal(props: Props) {
  const navigate = useNavigate()
  const markQuiz = () => {
    navigate("/Quiz/Mark/" + props.quizId)
  }

  const reviewQuiz = () => {
    navigate("/Quiz/Review/" + props.quizId)
  }

  return (
    <BaseModal
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Mark or review quiz?"
      body={<span>Would you like to mark or review this quiz?</span>}
      buttons={
        <>
          <Button color="primary" variant="flat" onClick={markQuiz}>
            Mark Quiz
          </Button>
          <Button color="success" variant="flat" onClick={reviewQuiz}>
            Review Quiz
          </Button>
        </>
      }
    />
  )
}
