import React, { useEffect } from "react"
import SidebarLink from "./SidebarLink"
import { Button, DropdownItem, User } from "@nextui-org/react"
import { useAppSelector } from "@/app/hooks"
import UserWithMenu from "./UserWithMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import "./index.css"
import "@/assets/fonts/ltpanneaux-extrabold.ttf"

import MenuToggleContainer, { menuToggleButton } from "./MenuToggleContainer"

interface Props {
  sideBarContent?: React.ReactNode
  sideBarFooter?: React.ReactNode
  sideBarDropdown?: React.ReactElement[]
  isCollapsed?: boolean
  setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>
  hideFooter?: boolean
}

export default function Sidebar(props: Props) {
  const { user } = useAppSelector((state) => state.user)

  const nonMobileTransition = (isCollapsed: boolean) => {
    return !props.isCollapsed
      ? "width 0.25s ease, padding 0.25s ease, opacity 0.125s ease, height 0s ease-out 0s"
      : "width 0.25s ease, padding 0.25s ease, opacity 0.125s ease, height 0s ease-out 0.25s"
  }

  const mobileTransition = () => {
    return !props.isCollapsed
      ? "width 0.25s ease, opacity 0.125s ease, height 0s ease-out 0s"
      : "width 0.25s ease, opacity 0.125s ease, height 0s ease-out 0.25s"
  }

  useEffect(() => {
    // set whether to collapse the sidebar based on the screen width
    const { innerWidth: width, innerHeight: height } = window
    props.setIsCollapsed?.(width <= 1000)
  }, [])

  return (
    <div
      className={`sidebar-full-container ${props.isCollapsed ? "" : "sidebar-full-container-expanded"}`}
    >
      <div
        className={`sidebar ${props.isCollapsed ? "sidebar-collapsed" : ""}`}
        style={{
          transition:
            window.innerWidth <= 1000
              ? mobileTransition()
              : nonMobileTransition(props.isCollapsed ?? false),
        }}
      >
        <div className="sidebar-expanded-mobile-menu-icon">
          {menuToggleButton(props.setIsCollapsed, props.isCollapsed)}
        </div>
        <div className="inner-sidebar">
          <div className="sidebar-content">{props.sideBarContent ?? <></>}</div>
          {!props.hideFooter && (
            <div className="sidebar-footer">
              {props.sideBarFooter ?? <></>}

              <UserWithMenu
                className="sidebar-user-info"
                name={user?.firstName + " " + user?.lastName}
                description={
                  user?.role === "admin" ? "Admin" : (user?.subscription ?? "")
                }
                dropdownContent={props.sideBarDropdown ?? [<></>]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export { SidebarLink }
