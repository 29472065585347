import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"
import axios from "@/core/helpers/axios"

export async function listCategories(
  name?: string,
  limit?: number,
  pageData?: PageData,
) {
  try {
    let urlParams = new URLSearchParams()
    if (pageData?.fetchMeta === true) {
      urlParams.append("meta", "1")
    }
    if (pageData?.page) {
      urlParams.append("page", pageData.page.toString())
    }
    if (pageData?.limit) {
      urlParams.append("limit", pageData.limit.toString())
    } else if (limit) {
      urlParams.append("limit", limit.toString())
    }
    if (name) {
      urlParams.append("name", name)
    }

    const res = await axios.get("/question-category?" + urlParams.toString())

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getCategoriesByPath(path: string[]) {
  try {
    const res = await axios.post("/question-category/get-category-by-path", {
      path,
    })

    if (res.status === 200) {
      return res.data.category
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getCategoriesByIndexes(indexes: number[]) {
  try {
    const res = await axios.post("/question-category/get-category-by-indexes", {
      indexes,
    })

    if (res.status === 200) {
      return res.data.category
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
