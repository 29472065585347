import { Button, Chip, Spinner, Tooltip } from "@nextui-org/react"
import { useEffect, useState } from "react"
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder"

import { ReactMic } from "react-mic"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircle,
  faMicrophone,
  faPause,
  faPlay,
  faStop,
} from "@fortawesome/free-solid-svg-icons"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { useAppSelector } from "@/app/hooks"
import { viewVoiceRecordingAllowance } from "@/api/routes/voice/voice"
import CircularProgress from "@/components/CircularProgress"

interface IAudioAllowance {
  currentStorage: number
  maxStorage: number
}

interface Props {
  recordedBlob: Blob | null
  setRecordedBlob: (blob: Blob) => void
  isSavingVoice: boolean
}

const colorFromPercentage = (percentage: number) => {
  if (percentage < 50) {
    return "success"
  } else if (percentage < 80) {
    return "warning"
  } else {
    return "danger"
  }
}

export default function QuizAttemptRecordVoice({
  recordedBlob,
  setRecordedBlob,
  isSavingVoice,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [blobUrl, setBlobUrl] = useState<string | null>(null)

  const [audioAllowance, setAudioAllowance] = useState<IAudioAllowance | null>(
    null,
  )

  const recorderControls = useAudioRecorder()

  const userSelector = useAppSelector((state) => state.user)

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder()

  useEffect(() => {
    if (!recordingBlob) return

    onRecordingComplete(recordingBlob)
  }, [recordingBlob])

  const onRecordingComplete = (blob: Blob) => {
    setRecordedBlob(blob)
  }

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)

  const [storageUtilPercentage, setStorageUtilPercentage] = useState<
    number | null
  >(null)

  const loadStorageAllowance = async () => {
    if (!userSelector?.user?._id) return

    setIsLoading(true)
    const audioAllowance: IAudioAllowance | null | undefined =
      await viewVoiceRecordingAllowance(userSelector!.user!._id)

    if (audioAllowance) {
      setAudioAllowance({
        currentStorage: audioAllowance.currentStorage,
        maxStorage: audioAllowance.maxStorage,
      })
      setStorageUtilPercentage(
        Math.round(
          (audioAllowance.currentStorage / audioAllowance.maxStorage) *
            100 *
            10,
        ) / 10,
      )
    }
    setIsLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await loadStorageAllowance()
    })()
  }, [])

  useEffect(() => {
    if (recordedBlob) {
      console.log("Recorded blob is")
      console.log(recordedBlob)
      setBlobUrl(URL.createObjectURL(recordedBlob))
    }
  }, [recordedBlob])

  return (
    <div>
      {/* code to show actual storage allowance ${audioAllowance.currentStorage / 1000000000} / ${audioAllowance.maxStorage / 1000000000} GB */}
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={() => {
          setIsConfirmDeleteModalOpen(false)
          startRecording()
        }}
        title="Are you sure you want to continue?"
        description="If you record yourself again, your previous attempt will be deleted."
        deleteTextOverride="Overwrite Existing Recording"
      />
      <div className="recorder-container">
        {isLoading || isSavingVoice ? (
          <>
            <Spinner />
            {isLoading ? <span>Loading...</span> : <span>Saving...</span>}
          </>
        ) : (
          <>
            {storageUtilPercentage && storageUtilPercentage >= 90 ? (
              <Chip
                variant="flat"
                color={colorFromPercentage(storageUtilPercentage)}
                className="recorder-timer"
              >
                {storageUtilPercentage}% storage used, please contact support
              </Chip>
            ) : null}
            <div className="recorder">
              <Tooltip
                color={isRecording ? "danger" : "primary"}
                content={
                  isRecording
                    ? "Stop and save recording"
                    : "Begin recording answer"
                }
              >
                <Button
                  variant="bordered"
                  size="lg"
                  className="recorder-play-pause-button"
                  isDisabled={(storageUtilPercentage ?? 0) >= 100}
                  color={isRecording ? "danger" : "primary"}
                  isIconOnly={true}
                  onClick={() => {
                    if (isRecording) {
                      stopRecording()
                    } else {
                      if (!blobUrl) {
                        startRecording()
                      } else {
                        setIsConfirmDeleteModalOpen(true)
                      }
                    }
                  }}
                >
                  <FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} />
                </Button>
              </Tooltip>
              {isRecording ? (
                <Tooltip
                  color={isPaused ? "success" : "warning"}
                  content={isPaused ? "Resume recording" : "Pause recording"}
                >
                  <Button
                    size="lg"
                    variant="ghost"
                    color={isPaused ? "success" : "warning"}
                    isIconOnly={true}
                    onClick={togglePauseResume}
                  >
                    <FontAwesomeIcon icon={isPaused ? faPlay : faPause} />
                  </Button>
                </Tooltip>
              ) : null}
            </div>
            <div className="recorder-footer-chips">
              {isRecording && (
                <Chip
                  variant="bordered"
                  color="danger"
                  className="recorder-timer"
                >
                  <FontAwesomeIcon size="2xs" icon={faCircle} color="red" />{" "}
                  Recording
                </Chip>
              )}
              {(recordingTime > 0 || isRecording) && (
                <Chip
                  variant="light"
                  color="default"
                  className="recorder-timer"
                >
                  {recordingTime} sec
                </Chip>
              )}
            </div>
          </>
        )}
      </div>
      {blobUrl && (
        <div className="recorder-audio-playback">
          <audio controls src={blobUrl} />
        </div>
      )}
    </div>
  )
}
