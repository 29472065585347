import { useEffect, useState } from "react"
import BestAnsweredCategoryWidget from "../BestAnsweredCategoryWidget"
import WorstAnsweredCategoryWidget from "../WorstAnsweredCategory"
import { getCategoryStatistics } from "@/api/routes/statistics"

export default function CombinedBestAndWorstCategoriesWidgets() {
  const [loading, setLoading] = useState(false)
  const [categoryStatistics, setCategoryStatistics] = useState(null)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const stats = await getCategoryStatistics()
      setCategoryStatistics(stats)
      setLoading(false)
    })()
  }, [])

  return (
    <>
      <BestAnsweredCategoryWidget
        controlledLoading={loading}
        preLoadedData={categoryStatistics}
      />
      <WorstAnsweredCategoryWidget
        controlledLoading={loading}
        preLoadedData={categoryStatistics}
      />
    </>
  )
}
