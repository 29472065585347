import Page from "@/core/components/Page"
import {
  Button,
  Checkbox,
  Input,
  Link,
  Select,
  SelectItem,
  Selection,
  Textarea,
} from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../../components/LanguageInput"
import { Key, useEffect, useState } from "react"
import { createUser } from "@/api/routes/admin/user"
import {
  isFieldFilledOut,
  isLanguageFieldFilledOut,
} from "@/core/helpers/utils"
import SubscriptionPlanFeaturesInput from "../../components/SubscriptionPlanFeaturesInput"
import { createSubscriptionPlan } from "@/api/routes/admin/subscription-plan"
import { ItemInputObservable } from "../../components/ItemInput"
import { IPlanFeatures } from "@/api/types/subscription-plan/subscription-plan"
import { createOrganisation } from "@/api/routes/admin/organisation"
import { IOrganisationFeature } from "@/api/types/organisation"

export default function CreateOrganisationPage() {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [organisationName, setOrganisationName] = useState<string>("")
  const [organisationDisplayName, setOrganisationDisplayName] =
    useState<string>("")
  const [organisationLogoUrl, setOrganisationLogoUrl] = useState<string>("")
  const [organisationFeatures, setOrganisationFeatures] =
    useState<ItemInputObservable>({})

  const buildAndSendOrganisation = async () => {
    setIsLoading(true)

    // Convert item input observable to IPlanFeatures
    const formattedFeatures: IOrganisationFeature[] = []

    await createOrganisation(
      organisationName,
      organisationDisplayName,
      organisationLogoUrl,
      formattedFeatures,
    )

    navigate("/Admin/Organisations")

    setIsLoading(false)
  }

  const formValidate = () => {
    if (
      isFieldFilledOut(organisationName) &&
      isFieldFilledOut(organisationDisplayName) &&
      isFieldFilledOut(organisationLogoUrl)
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      <PageHeader>
        <h1>Create Organisation</h1>
      </PageHeader>
      <div className="page-grid">
        <div className="input-wrapper row row-gap-10">
          <Input
            type="text"
            label="Organisation Name"
            placeholder="Enter the organisation name"
            value={organisationName}
            variant="bordered"
            onChange={(e) => setOrganisationName(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <Input
            type="text"
            label="Display Name"
            placeholder="Enter the organisation display name"
            value={organisationDisplayName}
            variant="bordered"
            onChange={(e) => setOrganisationDisplayName(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <Input
            type="text"
            label="Logo URL"
            placeholder="Enter the organisation logo URL"
            value={organisationLogoUrl}
            variant="bordered"
            onChange={(e) => setOrganisationLogoUrl(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          {/*<SubscriptionPlanFeaturesInput
            observable={organisationFeatures}
            setObservable={setOrganisationFeatures}
          />*/}
        </div>

        <div className="input-wrapper create-question-wrapper">
          <Button
            onClick={buildAndSendOrganisation}
            variant="solid"
            color="primary"
            isLoading={isLoading}
            isDisabled={formValidate()}
          >
            Create Organisation
          </Button>
        </div>
      </div>
    </Page>
  )
}
