import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { IUserNotes } from "@/api/types/user-notes"

export async function getUserNotes(
  noteId: string,
  path: string[],
): Promise<IUserNotes | undefined> {
  try {
    const res = await axios.post("/user-notes/" + noteId, {
      path,
    })

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function setUserNotesReq(
  noteId: string,
  path: string[],
  notes: string,
) {
  try {
    const res = await axios.put("/user-notes/set/" + noteId, {
      path,
      notes,
    })

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return LoginEnum.UNAUTHORIZED
    } else {
      // Other error
      return LoginEnum.OTHER
    }
  }
}
