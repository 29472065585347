import { Button, Spacer, useDisclosure } from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRotateRight,
  faMinus,
  faPause,
  faPencil,
  faPlay,
  faPlus,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import SideWidget from "@/pages/QuizAttempt/components/SideWidget"
import Countdown, {
  CountdownRenderProps,
  CountdownApi,
  zeroPad,
} from "react-countdown"
import EditTimeModal from "./EditTimeModal"

interface Props {
  hideReset?: boolean
  hidePlay?: boolean
  hidePause?: boolean
  onReset?: () => void
  startTimeTaken?: number
  offsetTimestamp?: Date
  autostart?: boolean
  inProgress?: boolean
  setTotalSecondsObs?: (totalSeconds: number) => void
  expectedTime: number
}

const CountdownTimerCard = forwardRef(
  (props: Props, ref: React.ForwardedRef<any>) => {
    const [isRunning, setIsRunning] = useState(props.autostart ?? true)
    const [countdownApi, setCountdownApi] = useState<CountdownApi | null>(null)
    const setCountdownRef = (countdown: Countdown | null): void => {
      if (countdown) {
        setCountdownApi(countdown.getApi())
      }
    }
    const [timeAllowed, setTimeAllowed] = useState<number>(props.expectedTime)
    const [originalDate, setOriginalDate] = useState<number>(
      Date.now() + props.expectedTime! * 60000,
    )
    const [currentTimeDelta, setCurrentTimeDelta] = useState<number>(0)

    const editTimeModal = useDisclosure()

    const start = () => {
      setIsRunning(true)
      countdownApi?.start()
    }

    const pause = () => {
      setIsRunning(false)
      countdownApi?.pause()
    }

    const reset = () => {
      setOriginalDate(Date.now() + props.expectedTime * 60000)
      setIsRunning(true)
      setTimeAllowed(props.expectedTime)
    }

    const setTime = (time: number) => {
      setOriginalDate(Date.now() + time * 60000)
    }

    const increaseTimeAllowed = () => {
      setTimeAllowed((prev) => prev + 1)
      setOriginalDate((prev) => prev + 60000)
    }

    const decreaseTimeAllowed = () => {
      setTimeAllowed((prev) => prev - 1)
      setOriginalDate((prev) => prev - 60000)
    }

    useImperativeHandle(ref, () => ({
      pause: () => {
        pause()
      },
      resume: () => {
        start()
      },
    }))

    const playTimer = () => {
      start()
    }

    const pauseTimer = () => {
      pause()
    }

    const resetTimer = () => {
      if (props.onReset) {
        props.onReset()
      }
      reset()
    }

    const renderer = ({
      minutes,
      seconds,
      completed,
      total,
    }: CountdownRenderProps) => {
      if (completed) {
        // Render a completed state
        return `${total < 0 ? "-" : ""}${zeroPad(minutes)}:${zeroPad(seconds)}`
      } else {
        // Render a countdown
        return `${zeroPad(minutes)}:${zeroPad(seconds)}`
      }
    }

    return (
      <SideWidget>
        <EditTimeModal
          pauseTimer={pause}
          disclosure={editTimeModal}
          setTimeDelta={setTime}
        />
        <FontAwesomeIcon
          size="lg"
          icon={faStopwatch}
          style={{
            color: props.inProgress
              ? "hsl(var(--medcamps-danger-500))"
              : "unset",
          }}
        />
        <div className="countdown-timer-card-content">
          <div className="countdown-area">
            <Button
              isIconOnly={true}
              variant="ghost"
              color="danger"
              onClick={decreaseTimeAllowed}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <div
              className={`countdown-timer-card-content-text ${currentTimeDelta < 0 ? "countdown-timer-card-content-text-expired" : ""}`}
            >
              {!props.inProgress ? (
                <Countdown
                  key={originalDate}
                  ref={setCountdownRef}
                  overtime={true}
                  renderer={renderer}
                  date={originalDate}
                  onTick={(timeDelta) => {
                    setCurrentTimeDelta(timeDelta.total)
                    props.setTotalSecondsObs?.(
                      timeAllowed * 60 - timeDelta.total / 1000,
                    )
                  }}
                />
              ) : (
                "In Progress"
              )}
            </div>
            <Button
              isIconOnly={true}
              variant="ghost"
              color="success"
              onClick={increaseTimeAllowed}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          {/* Only show this if the user has added/subtracted from the expected time set on the question */}
          {props.expectedTime != timeAllowed ? (
            <>
              <Spacer y={2} />
              <div className="countdown-timer-card-expected-content-text">
                Recommended: {props.expectedTime}m
              </div>
            </>
          ) : null}
          <div className="timer-card-content-buttons">
            {!isRunning ? (
              !props.hidePlay ? (
                <Button
                  isIconOnly={true}
                  variant="flat"
                  onClick={playTimer}
                  color="primary"
                >
                  <FontAwesomeIcon icon={faPlay} />
                </Button>
              ) : null
            ) : !props.hidePause ? (
              <Button
                isIconOnly={true}
                variant="flat"
                onClick={pauseTimer}
                color="warning"
              >
                <FontAwesomeIcon icon={faPause} />
              </Button>
            ) : null}

            {!props.hideReset ? (
              <Button
                isIconOnly={true}
                variant="flat"
                onClick={resetTimer}
                color="danger"
              >
                <FontAwesomeIcon icon={faArrowRotateRight} />
              </Button>
            ) : null}

            <Button
              isIconOnly={true}
              onClick={editTimeModal.onOpen}
              variant="flat"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </div>
        </div>
      </SideWidget>
    )
  },
)

export default CountdownTimerCard
