import getProfile from "@/api/routes/user/user"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { User } from "@/api/types/user/user"

export default async function loadUserProfile(
  dispatch: any,
  redirectOnUnauthorised = true,
) {
  const profile = await getProfile()
  if (typeof profile !== "number") {
    dispatch({
      type: "user/set",
      payload: profile as User,
    })
  } else if (profile === LoginEnum.UNAUTHORIZED) {
    // Clear our user state and log out
    dispatch({
      type: "user/set",
      payload: null,
    })

    if (
      redirectOnUnauthorised &&
      !(
        window.location.pathname === "/Login" ||
        window.location.pathname === "/Register" ||
        window.location.pathname === "/ForgotPassword" ||
        window.location.pathname.includes("/SetPassword") ||
        window.location.pathname.includes("/AcceptTutorInvite") ||
        window.location.pathname.startsWith("/PurchaseSubscription") ||
        window.location.pathname.startsWith("/Learning")
      )
    ) {
      window.location.href = "/Login"
    }
  }
}
