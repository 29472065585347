import Page from "@/core/components/Page"
import {
  Button,
  Checkbox,
  Input,
  Link,
  Select,
  SelectItem,
  Selection,
  Spacer,
  Textarea,
} from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import PageHeader from "@/core/components/Page/PageHeader"
import { useEffect, useState } from "react"
import {
  isFieldFilledOut,
  isLanguageFieldFilledOut,
} from "@/core/helpers/utils"
import { IOrganisationFeature } from "@/api/types/organisation"
import {
  getOrganisation,
  updateOrganisation,
} from "@/api/routes/admin/organisation"
import { PageLoader } from "@/components/PageLoader"

export default function EditOrganisationPage() {
  const navigate = useNavigate()
  const { organisationId } = useParams()

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const [organisationName, setOrganisationName] = useState<string>("")
  const [organisationDisplayName, setOrganisationDisplayName] =
    useState<string>("")
  const [organisationLogoUrl, setOrganisationLogoUrl] = useState<string>("")
  const [organisationFeatures, setOrganisationFeatures] = useState<
    IOrganisationFeature[]
  >([])

  const buildAndSendOrganisation = async () => {
    setIsSubmitLoading(true)

    await updateOrganisation(
      organisationId!,
      organisationName,
      organisationDisplayName,
      organisationLogoUrl,
      [],
    )

    navigate("/Admin/Organisations")

    setIsSubmitLoading(false)
  }

  const getOrganisationData = async () => {
    const organisationData = await getOrganisation(organisationId!)
    if (organisationData != null) {
      setOrganisationName(organisationData.name)
      setOrganisationDisplayName(organisationData.displayName)
      setOrganisationLogoUrl(organisationData.logoUrl)
      setOrganisationFeatures(organisationData.features)

      // const formattedFeatures: ItemInputObservable = {}
      // Object.keys(organisationData.features).forEach((key) => {
      //   formattedFeatures[key] = organisationData.features[key].value
      // })
      // setOrganisationFeatures(formattedFeatures)
    }
  }

  useEffect(() => {
    ;(async () => {
      setIsPageLoading(true)
      await getOrganisationData()
      setIsPageLoading(false)
    })()
  }, [])

  const formValidate = () => {
    if (
      isFieldFilledOut(organisationName) &&
      isFieldFilledOut(organisationDisplayName) &&
      isFieldFilledOut(organisationLogoUrl)
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <>
          <Link
            className="link"
            onClick={() => {
              navigate(-1)
            }}
          >
            {"< Back"}
          </Link>
          <PageHeader>
            <h1>Edit Organisation</h1>
          </PageHeader>
          <div className="page-grid">
            <div className="input-wrapper row row-gap-10">
              <Input
                type="text"
                label="Organisation Name"
                placeholder="Enter the organisation name"
                value={organisationName}
                variant="bordered"
                onChange={(e) => setOrganisationName(e.target.value)}
              />
            </div>

            <div className="input-wrapper">
              <Input
                type="text"
                variant="bordered"
                label="Organisation Display Name"
                placeholder="Enter the organisation display name"
                value={organisationDisplayName}
                onChange={(e) => setOrganisationDisplayName(e.target.value)}
              />
            </div>

            <div className="input-wrapper">
              <Input
                type="text"
                label="Logo URL"
                placeholder="Enter the organisation logo URL"
                value={organisationLogoUrl}
                variant="bordered"
                onChange={(e) => setOrganisationLogoUrl(e.target.value)}
              />
            </div>

            <div className="input-wrapper create-question-wrapper">
              <Button
                isDisabled={formValidate()}
                onClick={buildAndSendOrganisation}
                variant="solid"
                color="primary"
                isLoading={isSubmitLoading}
              >
                Update Organisation
              </Button>
            </div>
          </div>
        </>
      )}
    </Page>
  )
}
