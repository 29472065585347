import BaseModal from "@/core/components/Modals/BaseModal"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { Card, Divider, Input, Link, Spinner } from "@nextui-org/react"
import { useEffect, useMemo, useState } from "react"
import debounce from "lodash.debounce"
import { listCategories } from "@/api/routes/category-bank"
import { CheckIcon } from "./CheckIcon"

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedData: any[]
  setSelectedData: (val: any) => void
  fetchData: (setSearchTerm: any, term?: string) => Promise<void>
  modalTitle: string
  searchLabel: string
  searchPlaceholder: string
}

export default function CustomSelectSearchModal(props: any) {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const [searchResults, setSearchResults] = useState<any[]>([])

  const search = async (term: string) => {
    await props.fetchData(setSearchResults, term)
    setIsSearching(false)
  }

  const changeHandler = (event: any) => {
    setIsSearching(true)
    // handle the search
    search(event.target.value)
  }

  useEffect(() => {
    props.fetchData(setSearchResults)
  }, [])

  const isCategorySelected = (category: any) => {
    if (props.selectedData.map((cat: any) => cat._id).includes(category._id)) {
      return true
    } else {
      return false
    }
  }
  const onCategorySelect = (category: any) => {
    if (isCategorySelected(category)) {
      props.setSelectedData((prev: any) =>
        prev.filter((item: any) => item._id !== category._id),
      )
    } else {
      props.setSelectedData((prev: any) => [...prev, category])
    }
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [])

  return (
    <BaseModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.modalTitle}
      body={
        <div className="category-search">
          <Input
            type="text"
            variant="bordered"
            label={props.searchLabel}
            placeholder={props.searchPlaceholder}
            labelPlacement="inside"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
              debouncedChangeHandler(e)
            }}
            endContent={
              !isSearching ? (
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              ) : (
                <Spinner size="sm" />
              )
            }
          />
          <div className="category-search-results">
            {searchResults
              ? searchResults.map((category, index) => (
                  <div key={index} className="category-search-result">
                    <Link
                      className="link"
                      onClick={() => onCategorySelect(category)}
                    >
                      {isCategorySelected(category) ? (
                        <CheckIcon className="category-search-result-check-icon" />
                      ) : null}
                      {category.name}
                    </Link>
                    <Divider
                      className="category-search-result-divider"
                      orientation="horizontal"
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      }
    />
  )
}
