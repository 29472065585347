import BaseModal from "@/core/components/Modals/BaseModal"
import { isFieldFilledOut, isSetFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Select,
  SelectItem,
  Spacer,
  Textarea,
  UseDisclosureProps,
} from "@nextui-org/react"
import { useState } from "react"

interface Props {
  disclosure: UseDisclosureProps
  addLink: (val: any) => void
}
export default function AddQuestionBankLinkModal({
  disclosure,
  addLink,
}: Props) {
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")
  const [description, setDescription] = useState("")
  const [selectedType, setSelectedType] = useState(new Set<string>([]))

  const formValidate = () => {
    if (
      isFieldFilledOut(title) &&
      isFieldFilledOut(description) &&
      isFieldFilledOut(url) &&
      isSetFieldFilledOut(selectedType)
    ) {
      return false
    }
    return true
  }

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Add Link"
      size="xl"
      body={
        <div>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Link Title"
          />
          <Spacer y={2} />
          <Input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Link URL"
          />
          <Spacer y={2} />
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Link Description"
          />
          <Spacer y={2} />
          <Select
            label="Choose what this is linking to"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedType(new Set<string>(e.target.value.split(",")))
            }}
          >
            <SelectItem key="Notes">Notes</SelectItem>
            <SelectItem key="Video">Video</SelectItem>
            <SelectItem key="External">External</SelectItem>
          </Select>
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              addLink({
                Title: title,
                URL: url,
                Description: description,
                Type: selectedType.values().next().value,
              })
              setTitle("")
              setUrl("")
              setDescription("")
              setSelectedType(new Set<string>([]))
            }}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
          >
            Add Link
          </Button>
        </div>
      }
    />
  )
}
