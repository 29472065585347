import {
  Button,
  Card,
  Chip,
  Link,
  Spacer,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowDown,
  faArrowUp,
  faEdit,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import CustomSelectSearchModal from "../../../../components/CustomSelectSearchModal"

interface Props {
  title: string
  searchPlaceholder?: string
  addNewPlaceholder: string
  selectedItems?: any[]
  setSelectedItems: (val: any) => void
  searchFn?: (name: string) => Promise<any[]>
  onAddNew?: () => void
  onEdit?: (index: number) => void
  onDelete?: (_id: string) => void
  onPositionChange?: (indexToMoveFrom: number, indexToMoveTo: number) => void
  headers: string[]
  isLoading?: boolean
  hideMovementArrows?: boolean
}

;(Array.prototype as any).move = function (from: number, to: number) {
  this.splice(to, 0, this.splice(from, 1)[0])
}

export default function TableSelectInput(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tableKey, setTableKey] = useState(0)

  async function fetchData(
    setSearchResults: (val: any) => void,
    name?: string,
  ) {
    if (props.searchFn) {
      const res = await props.searchFn(name ?? "")
      if (res) {
        setSearchResults(res)
      }
    }
  }

  const getItems = () => [...(props.selectedItems ?? [])]

  useEffect(() => {
    // Used to re-render the table when we change the contents
    setTableKey((prev) => prev + 1)
  }, [props.selectedItems])

  const getHeaders = () => [...props.headers, "Actions"]

  const ActionsCell = (question: any) => (
    <div className="actions-table-cell">
      {!props.hideMovementArrows && (
        <>
          <Tooltip aria-label="Move up" content="Move up">
            <Link
              aria-label="Move up"
              isDisabled={props.isLoading}
              onClick={() => {
                if (typeof props.onPositionChange === "function") {
                  const index =
                    props.selectedItems?.findIndex(
                      (q) => q._id === question._id,
                    ) ?? -1

                  if (index < (props.selectedItems?.length ?? -1) - 1) {
                    props.onPositionChange(index, index - 1)
                  }
                } else {
                  // Move the position of this question up in the selected questions array
                  props.setSelectedItems((prev: any[]) => {
                    const index = prev.findIndex((q) => q._id === question._id)
                    if (index > 0) {
                      const newQuestions: any = [...prev]
                      newQuestions.move(index, index - 1)

                      return newQuestions
                    } else {
                      return prev
                    }
                  })
                }
              }}
            >
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <FontAwesomeIcon color="primary" icon={faArrowUp} />
              </span>
            </Link>
          </Tooltip>
          <Tooltip aria-label="Move down" content="Move down">
            <Link
              aria-label="Move down"
              isDisabled={props.isLoading}
              onClick={() => {
                // Move the position of this question up in the selected questions array
                if (typeof props.onPositionChange === "function") {
                  const index =
                    props.selectedItems?.findIndex(
                      (q) => q._id === question._id,
                    ) ?? -1

                  if (index < (props.selectedItems?.length ?? -1) - 1) {
                    props.onPositionChange(index, index + 1)
                  }
                } else {
                  props.setSelectedItems((prev: any[]) => {
                    const index = prev.findIndex((q) => q._id === question._id)
                    if (index < prev.length - 1) {
                      const newQuestions: any = [...prev]
                      newQuestions.move(index, index + 1)

                      return newQuestions
                    } else {
                      return prev
                    }
                  })
                }
              }}
            >
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <FontAwesomeIcon icon={faArrowDown} />
              </span>
            </Link>
          </Tooltip>
        </>
      )}
      <Tooltip aria-label="Edit" content="Edit">
        <Link
          aria-label="Edit"
          isDisabled={props.isLoading}
          onClick={() => {
            if (props.onEdit) {
              const index =
                props.selectedItems?.findIndex((q) => q._id === question._id) ??
                -1
              props.onEdit(index)
            }
          }}
        >
          <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faEdit} />
          </span>
        </Link>
      </Tooltip>
      <Tooltip aria-label="Remove" content="Remove">
        <Link
          aria-label="Remove"
          isDisabled={props.isLoading}
          onClick={() => {
            if (typeof props.onDelete === "function") {
              props.onDelete(question._id)
            } else {
              props.setSelectedItems((prev: any[]) => {
                const index = prev.findIndex((q) => q._id === question._id)
                const newQuestions = [...prev]
                newQuestions.splice(index, 1)
                return newQuestions
              })
            }
          }}
        >
          <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </Link>
      </Tooltip>
    </div>
  )

  const formatNormalCellContent = (item: any) => {
    if (typeof item === "undefined" || item === null) {
      return "-"
    }
    if (item instanceof Date) {
      return item.toLocaleDateString()
    } else if (typeof item === "boolean") {
      return item ? "Yes" : "No"
    } else {
      return item.toString()
    }
  }

  return (
    <>
      <div className="inline-title">
        <h2>{props.title}</h2>
        {props.isLoading && (
          <>
            <Spacer x={2} />
            <Spinner size="sm" />
          </>
        )}
      </div>
      <div className="table-select-table-container">
        <Table className="table-select-table" shadow="none" key={tableKey}>
          <TableHeader>
            {getHeaders().map((header: string) => (
              <TableColumn
                className={header === "Actions" ? "actions-table-cell" : ""}
              >
                {header}
              </TableColumn>
            ))}
          </TableHeader>
          <TableBody items={getItems()}>
            {(question: any) => (
              <TableRow key={question._id}>
                {getHeaders().map((header: string) =>
                  header === "Actions" ? (
                    /* These are the actions for a content row */
                    <TableCell>{ActionsCell(question)}</TableCell>
                  ) : (
                    /* This is the normal text for a content row */
                    <TableCell>
                      {formatNormalCellContent(question[header])}
                    </TableCell>
                  ),
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Link
          className="link table-select-table-add-link"
          onClick={props.onAddNew ?? onOpen}
        >
          {props.addNewPlaceholder}
        </Link>
      </div>
      <CustomSelectSearchModal
        isOpen={isOpen}
        onClose={onClose}
        fetchData={fetchData}
        selectedData={props.selectedItems}
        setSelectedData={props.setSelectedItems}
        modalTitle={"Select " + props.title}
        searchLabel={"Search " + props.title}
        searchPlaceholder={props.searchPlaceholder ?? ""}
      />
    </>
  )
}

/*
  const color = "--medcamps-primary-100"

*/
