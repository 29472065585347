import { PageLoader } from "@/components/PageLoader"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import { faEdit, faLink, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Chip,
  Link,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
} from "@nextui-org/react"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import DeleteSubscriptionButton from "./DeleteSubscriptionButton"
import { listSubscriptionPlans } from "@/api/routes/admin/subscription-plan"
import RestoreSubscriptionPlanButton from "./RestoreSubscriptionPlanButton"

const columns = [
  { name: "NAME", uid: "name" },
  { name: "PRICE", uid: "price", appendStart: "£" },
  { name: "DURATION", uid: "duration", appendEnd: " days" },
  { name: "INTERVIEWS", uid: "interviews" },
  { name: "ACTIONS", uid: "actions" },
]

const toTitleCase = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export function ManageSubscriptionPlansPage() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [allSubscriptionPlans, setAllSubscriptionPlans] = useState([] as any)
  const [displaySubscriptionPlans, setDisplaySubscriptionPlans] = useState(
    [] as any,
  )

  const [showDeleted, setShowDeleted] = useState(false)

  const navigate = useNavigate()

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)

  const loadSubscriptionPlans = async (
    page?: number,
    _showDeleted?: boolean,
  ) => {
    setIsPageLoading(true)
    setCurrentPage(page || 1)

    const totalRecords: any = await listSubscriptionPlans(
      {
        fetchMeta: true,
        limit: pageLimit,
      },
      _showDeleted,
    )
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    const _allSubscriptionPlans = await listSubscriptionPlans(
      {
        fetchMeta: false,
        limit: pageLimit,
        page: page ?? currentPage,
      },
      _showDeleted,
    )
    if (_allSubscriptionPlans != null) {
      setAllSubscriptionPlans(_allSubscriptionPlans.subscriptionPlans)

      setDisplaySubscriptionPlans(
        _allSubscriptionPlans.map((x: any) => ({
          ...x,
          interviews:
            x.features["unlimited-interviews"]?.value === true
              ? "Unlimited"
              : (x.features.interviews?.value ?? "N/A"),
        })),
      )
    }
    setIsPageLoading(false)
  }

  useEffect(() => {
    loadSubscriptionPlans(undefined, showDeleted)
  }, [])
  useEffect(() => {
    loadSubscriptionPlans(undefined, showDeleted)
  }, [showDeleted])

  const copyTextAreaRef = useRef<HTMLTextAreaElement>(null)

  const renderCell = React.useCallback(
    (subscriptionPlan: any, columnKey: React.Key) => {
      const cellValue = subscriptionPlan[columnKey as any]
      const column = columns.find((c) => c.uid === columnKey)

      const subscriptionPlanUrl =
        import.meta.env.VITE_FRONT_END_URL +
        "/PurchaseSubscription/" +
        subscriptionPlan._id

      switch (columnKey) {
        case "role":
          return (
            <Chip className="capitalize" size="sm" variant="flat">
              {cellValue}
            </Chip>
          )
        case "actions":
          return (
            <div className="actions-table-cell">
              <Tooltip
                aria-label="Copy Link"
                content="Copy link to subscription plan"
              >
                <Link
                  onClick={(e) => {
                    if (!navigator.clipboard) {
                      copyTextAreaRef?.current?.select()
                      document.execCommand("copy")
                      // This is just personal preference.
                      // I prefer to not show the whole text area selected.
                      copyTextAreaRef?.current?.blur()
                    } else {
                      navigator.clipboard.writeText(subscriptionPlanUrl)
                    }
                  }}
                >
                  <textarea
                    style={{
                      opacity: "0",
                      width: 1,
                      height: 1,
                      overflow: "hidden",
                    }}
                    ref={copyTextAreaRef}
                    value={subscriptionPlanUrl}
                  />
                  <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                    <FontAwesomeIcon icon={faLink} />
                  </span>
                </Link>
              </Tooltip>
              <Tooltip aria-label="Details" content="Edit subscription plan">
                <Link
                  onClick={() => {
                    navigate(
                      "/Admin/SubscriptionPlan/Edit/" + subscriptionPlan._id,
                    )
                  }}
                >
                  <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                    <FontAwesomeIcon icon={faEdit} />
                  </span>
                </Link>
              </Tooltip>
              {subscriptionPlan.isDeleted !== true ? (
                <DeleteSubscriptionButton
                  subscriptionPlanId={subscriptionPlan._id}
                  subscriptionPlanName={subscriptionPlan.name}
                  listSubscriptionPlans={() =>
                    loadSubscriptionPlans(undefined, false)
                  }
                />
              ) : (
                <RestoreSubscriptionPlanButton
                  subscriptionPlanId={subscriptionPlan._id}
                  subscriptionPlanName={subscriptionPlan.name}
                  listSubscriptionPlans={() =>
                    loadSubscriptionPlans(undefined, true)
                  }
                />
              )}
            </div>
          )
        default:
          return `${column?.appendStart ?? ""}${cellValue}${
            column?.appendEnd ?? ""
          }`
      }
    },
    [],
  )

  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>Manage Subscription Plans</h1>
        <Tabs
          defaultSelectedKey={showDeleted === true ? "deleted" : "active"}
          onSelectionChange={(key) => {
            setShowDeleted(key === "deleted")
          }}
        >
          <Tab title="Active" key="active"></Tab>
          <Tab title="Deleted" key="deleted"></Tab>
        </Tabs>
        <Button
          color="primary"
          variant="shadow"
          startContent={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            navigate("/Admin/SubscriptionPlan/New")
          }}
        >
          New Plan
        </Button>
      </PageHeader>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Table
          aria-label=""
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                total={totalPages}
                initialPage={currentPage}
                onChange={(page) => {
                  setCurrentPage(page)
                  loadSubscriptionPlans(page, showDeleted)
                }}
              />
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                className={column.uid === "actions" ? "actions-table-cell" : ""}
                align={column.uid === "actions" ? "end" : "start"}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>

          <TableBody items={displaySubscriptionPlans}>
            {(item: any) => (
              <TableRow key={item._id}>
                {(columnKey) => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Page>
  )
}
