import CheckboxProgressCard from "@/core/components/CheckboxProgressCard"
import "./index.css"
interface Props {
  greenProgress: number
  redProgress: number
  isSelected: boolean
  setIsSelected: (isSelected: boolean) => void
  categoryName: string
  score: string
  bold?: boolean
}

export default function CategorySelectCard(props: Props) {
  return (
    <CheckboxProgressCard
      isSelected={props.isSelected}
      setIsSelected={props.setIsSelected}
      greenProgress={props.greenProgress}
      redProgress={props.redProgress}
    >
      <div className="category-select-card-content">
        <div className={`category-select-card-title ${props.bold && "bold"}`}>
          {props.categoryName}
        </div>
        <div className={`category-select-card-score ${props.bold && "bold"}`}>
          {props.score}
        </div>
      </div>
    </CheckboxProgressCard>
  )
}
