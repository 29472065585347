import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Link,
  Textarea,
} from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import ListInput from "../ListInput"
import RichTextArea from "@/core/components/RichTextArea"

interface Props {
  type: string
  label: string
  placeholder: string
  editorKey: string

  observable: any
  setObservable: (val: any) => void

  disableRichText?: boolean
}

interface MenuItem {
  key: string
  label: string
}

const totalLanguages: MenuItem[] = [
  {
    key: "enGB",
    label: "English",
  },
]

export default function LanguageTextArea({
  type,
  label,
  placeholder,
  observable,
  setObservable,
  editorKey,
  disableRichText,
}: Props) {
  const [addableLanguages, setAddableLanguages] = useState(
    totalLanguages.splice(1),
  )
  const [selectedLanguages, setSelectedLanguages] = useState<MenuItem[]>([
    totalLanguages[0],
  ])

  const addLanguage = (lang: MenuItem) => {
    setSelectedLanguages([...selectedLanguages, lang])
    setAddableLanguages(addableLanguages.filter((x) => x.key !== lang.key))
    setObservable((prev: any) => ({
      ...prev,
      [lang.key]: "",
    }))
  }

  const removeLanguage = (lang: MenuItem) => {
    setSelectedLanguages(selectedLanguages.filter((x) => x.key !== lang.key))
    setAddableLanguages([...addableLanguages, lang])
    setObservable((obs: any) => {
      delete obs[lang.key]
      obs = { ...obs }
      return obs
    })
  }

  const drawInput = (item: any): React.ReactNode => {
    return (
      <div key={item.key} className="language-input">
        {disableRichText ? (
          <Textarea
            fullWidth={true}
            variant="bordered"
            className="language-input-question"
            type={type}
            value={(observable ?? {})[item.key]}
            onChange={(e) => {
              setObservable((prev: any) => ({
                ...prev,
                [item.key]: e.target.value,
              }))
            }}
            label={`${label} (${item.label})`}
            placeholder={placeholder}
          />
        ) : (
          <RichTextArea
            observable={(observable ?? {})[item.key]}
            onChange={(contents: string) => {
              setObservable((prev: any) => ({
                ...prev,
                [item.key]: contents,
              }))
            }}
            label={`${label} (${item.label})`}
            placeholder={placeholder}
          />
        )}
        {selectedLanguages.length !== 1 ? (
          <Button
            color="danger"
            variant="flat"
            radius="full"
            isIconOnly={true}
            className="language-input-delete"
            onClick={() => removeLanguage({ key: item.key, label: item.label })}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        ) : null}
      </div>
    )
  }

  return (
    <>
      <ListInput
        label={label}
        observable={selectedLanguages}
        availableItems={addableLanguages}
        addItem={addLanguage}
        removeItem={removeLanguage}
        drawInput={drawInput}
        hideAddIfEmpty={true}
      />
    </>
  )
}
