import Page from "@/core/components/Page"
import "./index.css"
import { useNavigate } from "react-router"
import PageHeader from "@/core/components/Page/PageHeader"
import { WidgetGrid } from "@/components/Widgets"
import GeneralStatsOverviewWidget from "@/components/Widgets/GeneralStatsOverviewWidget"
import BeginQuizWidget from "@/components/Widgets/BeginQuizWidget"
import LatestQuizzesWidget from "@/components/Widgets/LatestQuizzesWidget"
import CombinedBestAndWorstCategoriesWidgets from "@/components/Widgets/CategoryWidgets/CombinedBestAndWorstAnsweredCategoryWidgets"
import SubscriptionCreditsWidget from "@/components/Widgets/SubscriptionCreditsWidget"
import { useAppSelector } from "@/app/hooks"
import MySubscriptionWidget from "@/components/Widgets/MySubscriptionWidget"

export default function HomePage() {
  const navigate = useNavigate()
  const currentUser = useAppSelector((state) => state.user.user)

  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>Dashboard</h1>
      </PageHeader>
      <WidgetGrid>
        <GeneralStatsOverviewWidget />
        <BeginQuizWidget />
        <LatestQuizzesWidget />
        <CombinedBestAndWorstCategoriesWidgets />
        <MySubscriptionWidget />
      </WidgetGrid>
    </Page>
  )
}
