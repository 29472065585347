import { Card } from "@nextui-org/react"
import "./index.css"

interface Props {
  title: string
  value: number
  size?: string
}
export default function NumberWidget({ title, value, size }: Props) {
  return (
    <Card className={`number-widget ${size ?? "lg"}-width`}>
      <div className="number-widget-value-container">
        <h1>{value}</h1>
      </div>
      <div className="number-widget-title-container">
        <h2>{title}</h2>
      </div>
    </Card>
  )
}
