import { IUserQuizView } from "@/api/types/quiz/quizzes"
import ContinueAsInterviewerOrIntervieweeModal from "@/components/QuizList/QuizCard/Modals/ContinueAsInterviewerOrInterviewee"
import ViewCompleteQuizModal from "@/components/QuizList/QuizCard/Modals/ViewCompleteQuizModal"
import { faEye, faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, useDisclosure } from "@nextui-org/react"

interface Props {
  quiz: IUserQuizView
}
export function QuizActionsCell({ quiz }: Props) {
  const continueModalDisclosure = useDisclosure()
  const quizCompleteModalDisclosure = useDisclosure()

  const resumeQuiz = () => {
    continueModalDisclosure.onOpen()
  }

  const viewCompleteQuiz = () => {
    quizCompleteModalDisclosure.onOpen()
  }

  return (
    <>
      <ContinueAsInterviewerOrIntervieweeModal
        quizId={quiz._id}
        isOpen={continueModalDisclosure.isOpen}
        onClose={continueModalDisclosure.onClose}
      />
      <ViewCompleteQuizModal
        quizId={quiz._id}
        isOpen={quizCompleteModalDisclosure.isOpen}
        onClose={quizCompleteModalDisclosure.onClose}
      />
      {quiz.endDate ? (
        <Button
          onClick={viewCompleteQuiz}
          variant="flat"
          color="primary"
          isIconOnly={true}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ) : (
        <Button
          onClick={resumeQuiz}
          variant="flat"
          color="success"
          isIconOnly={true}
        >
          <FontAwesomeIcon icon={faPlay} />
        </Button>
      )}
    </>
  )
}
