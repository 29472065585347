import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"
import { IOrganisation, IOrganisationFeature } from "@/api/types/organisation"

export async function listOrganisations(name?: string, pageData?: PageData) {
  try {
    let urlParams = new URLSearchParams()
    if (pageData?.fetchMeta === true) {
      urlParams.append("meta", "1")
    }
    if (pageData?.page) {
      urlParams.append("page", pageData.page.toString())
    }
    if (pageData?.limit) {
      urlParams.append("limit", pageData.limit.toString())
    }
    if (name) {
      urlParams.append("name", name)
    }

    const res = await axios.get("/admin/organisation?" + urlParams.toString())

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getOrganisation(
  id: string,
): Promise<IOrganisation | null> {
  try {
    const res = await axios.get("/admin/organisation/" + id)

    if (res.status === 200) {
      return res.data?.body ?? null
    } else {
      return null
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return null
    } else {
      return null
    }
  }
}

export async function createOrganisation(
  name: string,
  displayName: string,
  logoUrl: string,
  features: IOrganisationFeature[],
) {
  try {
    const res = await axios.post("/admin/organisation/", {
      name,
      displayName,
      logoUrl,
      features,
    })

    if (res.status === 200) {
      return res.data?.body?._id ?? null
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateOrganisation(
  id: string,
  name: string,
  displayName: string,
  logoUrl: string,
  features: IOrganisationFeature[],
) {
  try {
    const res = await axios.put("/admin/organisation/" + id, {
      name,
      displayName,
      logoUrl,
      features,
    })

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteOrganisation(id: string) {
  try {
    const res = await axios.delete("/admin/organisation/" + id)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
