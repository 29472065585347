import { Card, Spacer, Textarea } from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"

interface Props {
  answer: string
}

export default function QuestionAnswerReview({ answer }: Props) {
  const [formattedAnswer, setFormattedAnswer] = useState<string[] | null>()
  useEffect(() => {
    if (answer) {
      const _formattedAnswer = answer?.split("\n")
      setFormattedAnswer(_formattedAnswer)
    }
  }, [answer])

  return (
    <>
      <Card shadow="none" className="in-progress-quiz-answer-card">
        <span className="question-attempt-card-title-text">Written Answer</span>
        <Spacer y={1} />
        {formattedAnswer?.map((val, index) => <p key={index}>{val}</p>) ??
          "(No written answer)"}
      </Card>
    </>
  )
}
