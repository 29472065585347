import BaseModal from "@/core/components/Modals/BaseModal"
import { useEffect, useState } from "react"
import "./index.css"
import { Button, DatePicker, Input } from "@nextui-org/react"
import { isFieldFilledOut } from "@/core/helpers/utils"
import { updateSubscriptionForUser } from "@/api/routes/admin/user"
import { DateValue, fromDate } from "@internationalized/date"
import { I18nProvider } from "@react-aria/i18n"

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedData: any[]
  setSelectedData: (val: any) => void
  userId: string
  getData: (page: number) => Promise<void>
  subscriptionId: string
}
export default function EditSubscriptionModal(props: Props) {
  const [selectedSubscription, setSelectedSubscription] = useState<any>()

  const [startDate, setStartDate] = useState<DateValue | undefined>()
  const [renewDate, setRenewDate] = useState<DateValue | undefined>()

  const [isLoading, setIsLoading] = useState<boolean>()

  useEffect(() => {
    setSelectedSubscription(
      props.selectedData.find((x) => x._id === props.subscriptionId),
    )
  }, [props.subscriptionId])

  useEffect(() => {
    if (selectedSubscription) {
      setStartDate(fromDate(selectedSubscription.startDate, "Europe/London"))
      setRenewDate(fromDate(selectedSubscription.renewalDate, "Europe/London"))
    }
  }, [selectedSubscription])

  const onAddSubscription = async () => {
    setIsLoading(true)

    const startDateObj = startDate!.toDate("Europe/London")
    const renewDateObj = renewDate!.toDate("Europe/London")

    await updateSubscriptionForUser(
      props.userId,
      props.subscriptionId,
      startDateObj,
      renewDateObj,
    )

    setIsLoading(false)
    await props.getData(1)
    props.onClose()
  }

  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(false)
  const checkFormValidation = (): void => {
    if (isFieldFilledOut(startDate) && isFieldFilledOut(renewDate)) {
      setIsFormInvalid(false)
    } else {
      setIsFormInvalid(true)
    }
  }

  useEffect(() => {
    checkFormValidation()
  }, [startDate, renewDate])

  return (
    <BaseModal isOpen={props.isOpen} onClose={props.onClose} size="xl">
      <div className="add-subscriptions-modal-wrapper">
        <div className="input-wrapper">
          <h1>Edit subscription</h1>
          <p>
            This will allow you to edit the basic details of this subscription.
          </p>
        </div>

        <div className="input-wrapper"></div>
        <div className="input-wrapper">
          <I18nProvider locale="en-GB">
            <DatePicker
              value={startDate!}
              onChange={setStartDate}
              label="Start Date"
              isDisabled={props.subscriptionId === null}
            />
          </I18nProvider>
        </div>
        <div className="input-wrapper">
          <I18nProvider locale="en-GB">
            <DatePicker
              label="Renewal Date"
              value={renewDate}
              onChange={setRenewDate}
              isDisabled={props.subscriptionId === null}
            />
          </I18nProvider>
        </div>
        <div className="create-question-wrapper">
          <Button
            isDisabled={isFormInvalid}
            onClick={onAddSubscription}
            isLoading={isLoading}
            color="primary"
            variant="solid"
          >
            Update Subscription
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}
