import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"

export default async function login(email: string, password: string) {
  try {
    const res = await axios.post("/auth/login", {
      username: email,
      password,
    })
    localStorage.setItem("auth-token", res.data.body.token)
    axios.defaults.headers.common = {
      authorization: `Bearer ${res.data.body.token}`,
      "X-Organisation-Domain": window.location.hostname,
    }

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
