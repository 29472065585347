import { Button, Card, Spacer, Spinner, Textarea } from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"
import {
  deleteVoiceFile,
  viewVoiceRecordingOriginalTranscriptionRoute,
} from "@/api/routes/voice/voice"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGraduationCap,
  faRotateLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import useDebounce from "@/core/helpers/debounce"

interface Props {
  voiceRecordingId: string | null | undefined
  voiceRecordingBlob: Blob | null
  setVoiceRecordingId: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >
  setVoiceRecordingBlob: React.Dispatch<React.SetStateAction<Blob | null>>
}

export default function ViewVoiceRecordedAnswer({
  voiceRecordingId,
  voiceRecordingBlob,
  setVoiceRecordingId,
  setVoiceRecordingBlob,
}: Props) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [voiceRecordingBlobUrl, setVoiceRecordingBlobUrl] = useState<
    string | null
  >(null)

  useEffect(() => {
    if (voiceRecordingBlob) {
      setVoiceRecordingBlobUrl(URL.createObjectURL(voiceRecordingBlob))
    }
  }, [voiceRecordingBlob])

  const deleteRecording = async () => {
    await deleteVoiceFile(voiceRecordingId!)

    setVoiceRecordingId(null)
    setVoiceRecordingBlob(null)

    setIsDeleteModalOpen(false)
  }

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onConfirm={deleteRecording}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      {voiceRecordingId && voiceRecordingBlob && (
        <>
          <Spacer y={2} />
          <Card shadow="none" className="in-progress-quiz-answer-card">
            <span className="question-attempt-card-title-text">
              Voice recorded answer
            </span>
            <div className="interview-audio-replay-area">
              <audio
                controls
                src={voiceRecordingBlobUrl!}
                className="interview-answer-audio"
              ></audio>
              <Button
                className="delete-interview-voice-recording-button"
                color="danger"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </Button>
            </div>
          </Card>
        </>
      )}
    </>
  )
}
