import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
} from "@nextui-org/react"
import "./index.css"

interface Props {
  label: string

  observable: any[]

  availableItems?: any[]

  addItem: Function
  removeItem: (item: any) => void

  drawInput: (item: any, index: any) => React.ReactNode

  addNewText?: string

  hideAddIfEmpty?: boolean
}

export default function ListInput(props: Props) {
  return (
    <>
      <div className="list-input-container">
        <div className="list-inputs">
          {props.observable.map((item, index) => props.drawInput(item, index))}
        </div>
      </div>
      {props.availableItems != null ? (
        props.hideAddIfEmpty !== true || props.availableItems.length > 0 ? (
          <Dropdown>
            <DropdownTrigger>
              <Link
                isDisabled={props.availableItems.length === 0}
                className="link"
              >
                {!props.addNewText ? "+ New Language" : props.addNewText}
              </Link>
            </DropdownTrigger>
            <DropdownMenu aria-label="Actions" items={props.availableItems}>
              {(item: any) => (
                <DropdownItem
                  key={item.key}
                  onClick={() => props.addItem(item)}
                >
                  {item.label}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        ) : (
          <></>
        )
      ) : (
        <Link className="link" onClick={() => props.addItem()}>
          {!props.addNewText ? "+ New Language" : props.addNewText}
        </Link>
      )}
    </>
  )
}
