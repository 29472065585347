import "./index.css"

interface Props {
  className?: string
}

export default function TypingDots(props: Props) {
  return (
    <div className={`typing ${props.className ?? ""}`}>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  )
}
