import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.css"
import { IconDefinition, faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link, NavLink } from "react-router-dom"

interface Props {
  title?: string
  to?: string
  onClick?: () => void
  icon?: IconDefinition
  onlyShowActive?: boolean
  isSelectedOverride?: boolean
}

export default function SidebarLink(props: Props) {
  const baseClass = `sidebar-link ${props.onlyShowActive ? "hide-unless-active" : ""}`
  const activeClass = "active"
  const secondaryActiveClass = "secondary-active"

  const linkActiveStyles = `${baseClass} ${activeClass}`
  const linkSecondaryActiveStyles = `${baseClass} ${secondaryActiveClass}`

  return (
    <NavLink
      to={props.to ?? ""}
      onClick={props.onClick}
      className={({ isActive, isPending }) => {
        if (isActive && !props.onClick) {
          return linkActiveStyles
        } else if (props.isSelectedOverride) {
          return linkSecondaryActiveStyles
        } else {
          return baseClass
        }
      }}
    >
      {props.icon ? (
        <FontAwesomeIcon icon={props.icon} className="menu-item-icon" />
      ) : null}
      {props.title}
    </NavLink>
  )
}
