import { getQuizMetaDataFromUserQuizId } from "@/api/routes/quiz/quiz"
import { PageLoader } from "@/components/PageLoader"
import Page from "@/core/components/Page"
import RichTextRender from "@/core/components/RichTextRender"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, CardBody, Tab, Tabs } from "@nextui-org/react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"

export default function QuizInstructionsPage() {
  const navigate = useNavigate()
  const { instructionType, quizId } = useParams()

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [quizMetaData, setQuizMetaData] = useState({} as any)
  const [selectedTab, setSelectedTab] = useState<string>(
    instructionType ?? "Candidate",
  )

  const goToQuiz = () => {
    let navigationLocation = selectedTab
    if (selectedTab === "Examiner") {
      navigationLocation = "Mark"
    }
    navigate("/Quiz/" + navigationLocation + "/" + quizId)
  }

  const loadQuizMeta = async () => {
    setIsPageLoading(true)
    const metadata = await getQuizMetaDataFromUserQuizId(quizId!)
    if (metadata) {
      setQuizMetaData({
        name: metadata.name,
        description: metadata.description,
        shortDescription: metadata.shortDescription,
        intervieweeInstructions: metadata.intervieweeInstructions,
        interviewerInstructions: metadata.interviewerInstructions,
        actorInstructions: metadata.actorInstructions,
        actorModeEnabled: metadata.actorModeEnabled,
      })
    }
    setIsPageLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await loadQuizMeta()
    })()
  }, [])

  return (
    <Page showSidebar={true}>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <div className="in-progress-quiz-question-card">
          <Tabs
            aria-label="Instructions"
            selectedKey={selectedTab}
            onSelectionChange={(x: any) => setSelectedTab(x as string)}
          >
            <Tab key="Candidate" title="Candidate">
              <Card className="in-progress-quiz-content-card">
                <CardBody>
                  <h1>Candidate instructions for {quizMetaData.name}</h1>
                  <RichTextRender
                    observable={quizMetaData.intervieweeInstructions}
                  />
                </CardBody>
              </Card>
            </Tab>
            <Tab key="Examiner" title="Examiner">
              <Card className="in-progress-quiz-content-card">
                <CardBody>
                  <h1>Examiner instructions for {quizMetaData.name}</h1>
                  <RichTextRender
                    observable={quizMetaData.interviewerInstructions}
                  />
                </CardBody>
              </Card>
            </Tab>
            {quizMetaData.actorModeEnabled && (
              <Tab key="Actor" title="Actor">
                <Card className="in-progress-quiz-content-card">
                  <CardBody>
                    <h1>Actor instructions for {quizMetaData.name}</h1>
                    <RichTextRender
                      observable={quizMetaData.actorInstructions}
                    />
                  </CardBody>
                </Card>
              </Tab>
            )}
          </Tabs>
          <div className="row space-between">
            <div></div>
            <Button
              color="primary"
              variant="shadow"
              onClick={goToQuiz}
              className="in-progress-quiz-button"
            >
              Begin Questions as {selectedTab}{" "}
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
      )}
    </Page>
  )
}
