import { Card, Switch } from "@nextui-org/react"
import "./index.css"
import { useState } from "react"

interface Props {
  checked?: boolean
  setChecked?: (checked: boolean) => void
  title: string
  children: React.ReactNode
  hideHeaderOnOpen?: boolean
  isDisabled?: boolean
}
export default function SwitchBox(props: Props) {
  const [checked, setChecked] = useState(false)
  return (
    <Card className="switchbox">
      <div className="switchbox-header">
        {!props.hideHeaderOnOpen ||
        (props.hideHeaderOnOpen && !props.checked && !checked) ? (
          <p>{props.title}</p>
        ) : (
          <p></p>
        )}
        <Switch
          isSelected={props.checked ?? checked}
          onValueChange={
            props.isDisabled ? undefined : props.setChecked ?? setChecked
          }
          isDisabled={props.isDisabled}
        />
      </div>
      {props.checked || checked ? (
        <div className="switchbox-content">{props.children}</div>
      ) : null}
    </Card>
  )
}
