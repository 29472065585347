import BaseModal from "@/core/components/Modals/BaseModal"
import { isFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Spacer,
  Textarea,
  UseDisclosureProps,
} from "@nextui-org/react"
import { useEffect, useState } from "react"

interface Props {
  disclosure: UseDisclosureProps
  index: number
  selectedLinks: any[]
  onEditLink: (val: any, vals: any) => void
}
export default function EditLinkModal({
  disclosure,
  index,
  selectedLinks,
  onEditLink,
}: Props) {
  const [clonedSelectedLinks, setClonedSelectedLinks] = useState([] as any)

  useEffect(() => {
    setClonedSelectedLinks(selectedLinks)
  }, [selectedLinks])

  const formValidate = () => {
    if (
      isFieldFilledOut(clonedSelectedLinks[index].Title) &&
      isFieldFilledOut(clonedSelectedLinks[index].Description) &&
      isFieldFilledOut(clonedSelectedLinks[index].URL)
    ) {
      return false
    }
    return true
  }

  return clonedSelectedLinks &&
    Array.isArray(clonedSelectedLinks) &&
    clonedSelectedLinks[index] ? (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Add Video"
      size="xl"
      body={
        <div>
          <Input
            value={clonedSelectedLinks[index].Title}
            onChange={(e) =>
              setClonedSelectedLinks((prev: any) => {
                let newVal = [...prev]
                newVal[index].Title = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Link Title"
          />
          <Spacer y={2} />
          <Input
            value={clonedSelectedLinks[index].URL}
            onChange={(e) =>
              setClonedSelectedLinks((prev: any) => {
                let newVal = [...prev]
                newVal[index].URL = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Link URL"
          />
          <Spacer y={2} />
          <Textarea
            value={clonedSelectedLinks[index].Description}
            onChange={(e) =>
              setClonedSelectedLinks((prev: any) => {
                let newVal = [...prev]
                newVal[index].Description = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Link Description"
          />
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onEditLink(clonedSelectedLinks[index], clonedSelectedLinks)

              disclosure.onClose!()
            }}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
          >
            Edit Link
          </Button>
        </div>
      }
    />
  ) : null
}
