import Page from "@/core/components/Page"
import {
  Button,
  Input,
  Link,
  Select,
  SelectItem,
  Selection,
  Switch,
  Textarea,
  useDisclosure,
} from "@nextui-org/react"
import { useNavigate } from "react-router"
import "./index.css"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import { Key, useEffect, useState } from "react"
import LanguageTextArea from "../components/LanguageTextArea"
import AnswerInput, { validateAnswers } from "./AnswerInput"
import CategoryCards from "./CategoryCards"
import { AnswerAccessLevel } from "../ManageQuestions"
import { AnswerAccessLevelString } from "@/api/types/question/question"
import {
  createQuestion,
  getQuestionScenarios,
} from "@/api/routes/admin/question-bank"
import CategoryInput from "./CategoryInput"
import {
  isFieldFilledOut,
  isLanguageFieldFilledOut,
  isSetFieldFilledOut,
} from "@/core/helpers/utils"
import TableSelectInput from "../components/TableSelectInput"
import AddQuestionBankLinkModal from "./Modals/AddQuestionBankLinkModal"
import EditQuestionBankLinkModal from "./Modals/EditQuestionBankLinkModal"
import AnswerInputV2 from "./AnswerInputV2"
import { PageLoader } from "@/components/PageLoader"

export default function NewQuestionPage() {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [scenarios, setScenarios] = useState([] as any)

  const [title, setTitle] = useState({} as any)
  const [questionBody, setQuestionBody] = useState({} as any)
  const [aiPrompt, setAiPrompt] = useState<string>("")
  const [isAiPromptOverrideEnabled, setIsAiPromptOverrideEnabled] =
    useState<boolean>(false)
  useEffect(() => {
    if (!isAiPromptOverrideEnabled) {
      setAiPrompt("")
    }
  }, [isAiPromptOverrideEnabled])

  const [isAiChatBotQuestion, setIsAiChatBotQuestion] = useState<boolean>(false)
  const [aiChatBotScenarioPrompt, setAiChatBotScenarioPrompt] = useState("")
  const [aiChatBotUserRole, setAiChatBotUserRole] = useState("")
  const [aiChatBotScenarioTemplateId, setAiChatBotScenarioTemplateId] =
    useState<any>(new Set<Key>([]))

  const [answers, setAnswers] = useState([] as any)
  const [categories, setCategories] = useState([] as any)
  const [timeLimit, setTimeLimit] = useState<string>()
  const [answerAccessLevel, setAnswerAccessLevel] = useState<Selection>(
    new Set([]),
  )

  const [actorModeEnabled, setActorModeEnabled] = useState(false)
  const [actorInstructions, setActorInstructions] = useState({} as any)

  const [interviewerInstructionsEnabled, setInterviewerInstructionsEnabled] =
    useState(false)
  const [interviewerInstructions, setInterviewerInstructions] = useState(
    {} as any,
  )

  const [intervieweeInstructionsEnabled, setIntervieweeInstructionsEnabled] =
    useState(false)
  const [intervieweeInstructions, setIntervieweeInstructions] = useState(
    {} as any,
  )

  const [associatedReadingLinksIndex, setAssociatedReadingLinksIndex] =
    useState(-1)
  const [associatedReadingLinks, setAssociatedReadingLinks] = useState(
    [] as any,
  )

  const [isPageLoading, setIsPageLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setIsPageLoading(true)
      const scenarios = await getQuestionScenarios()
      setScenarios(scenarios)
      setIsPageLoading(false)
    })()
  }, [])

  const addLinkModalDisclosure = useDisclosure()
  const editLinkModalDisclosure = useDisclosure()

  const buildAndSendQuestion = async () => {
    setIsLoading(true)

    const question = {
      name: title,
      question: questionBody,
      aiPrompt,
      answers,
      categories: categories.map((category: any) => category._id),
      supportedRegions: { enGB: true },
      timeLimit,
      answerAccessLevel: parseInt([...answerAccessLevel].join("")),
      actorModeEnabled,
      actorInstructions,
      interviewerInstructionsEnabled,
      interviewerInstructions,
      intervieweeInstructionsEnabled,
      intervieweeInstructions,
      associatedReadingLinks: associatedReadingLinks.map((link: any) => ({
        _id: link._id,
        title: link.Title,
        url: link.URL,
        type: link.Type,
        description: link.Description,
      })),
      isAiChatBotQuestion,
      aiChatBotScenarioPrompt,
      aiChatBotUserRole,
      aiChatBotScenarioTemplateId: [...aiChatBotScenarioTemplateId][0],
    }
    await createQuestion(question)

    // Navigate back
    navigate(-1)

    setIsLoading(false)
  }

  const formValidate = () => {
    if (
      isLanguageFieldFilledOut(title) &&
      isLanguageFieldFilledOut(questionBody) &&
      isFieldFilledOut(timeLimit) &&
      isSetFieldFilledOut(answerAccessLevel) &&
      validateAnswers(answers)
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      <PageHeader>
        <h1>New Question</h1>
      </PageHeader>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <div className="page-grid">
          <AddQuestionBankLinkModal
            disclosure={addLinkModalDisclosure}
            addLink={(val: any) => {
              setAssociatedReadingLinks((prev: any) => [
                ...prev,
                { ...val, _id: prev.length },
              ])

              addLinkModalDisclosure.onClose()
            }}
          />
          <EditQuestionBankLinkModal
            disclosure={editLinkModalDisclosure}
            index={associatedReadingLinksIndex}
            selectedLinks={associatedReadingLinks}
            onEditLink={(val: any, vals: any) => {
              let newLinks = [...associatedReadingLinks]
              newLinks[val] = vals
              setAssociatedReadingLinks(newLinks)
            }}
          />

          <div className="input-wrapper">
            <LanguageInput
              observable={title}
              setObservable={setTitle}
              type="text"
              label="Question Title"
              placeholder="Enter the question title"
            />
          </div>

          <div className="input-wrapper">
            <LanguageTextArea
              editorKey={"1"}
              observable={questionBody}
              setObservable={setQuestionBody}
              type="text"
              label="Question body"
              placeholder="Enter the body of the question"
            />
          </div>

          <div className="input-wrapper">
            <Switch
              isSelected={intervieweeInstructionsEnabled}
              onValueChange={setIntervieweeInstructionsEnabled}
            >
              Candidate Instructions
            </Switch>
          </div>

          {intervieweeInstructionsEnabled && (
            <div className="input-wrapper">
              <LanguageTextArea
                editorKey={"2"}
                observable={intervieweeInstructions}
                setObservable={setIntervieweeInstructions}
                type="text"
                label="Candidate Instructions"
                placeholder="Enter candidate instructions"
              />
            </div>
          )}

          <div className="input-wrapper">
            <Switch
              isSelected={interviewerInstructionsEnabled}
              onValueChange={setInterviewerInstructionsEnabled}
            >
              Examiner Instructions
            </Switch>
          </div>

          {interviewerInstructionsEnabled && (
            <div className="input-wrapper">
              <LanguageTextArea
                editorKey={"3"}
                observable={interviewerInstructions}
                setObservable={setInterviewerInstructions}
                type="text"
                label="Examiner Instructions"
                placeholder="Enter examiner instructions"
              />
            </div>
          )}

          <div className="input-wrapper">
            <Switch
              isSelected={actorModeEnabled}
              onValueChange={setActorModeEnabled}
            >
              Allow Actors
            </Switch>
          </div>

          {actorModeEnabled && (
            <div className="input-wrapper">
              <LanguageTextArea
                editorKey={"4"}
                observable={actorInstructions}
                setObservable={setActorInstructions}
                type="text"
                label="Actor Instructions"
                placeholder="Enter the quiz actor instructions"
              />
            </div>
          )}

          <AnswerInputV2
            observable={answers}
            setObservable={setAnswers}
            type="text"
            label="Answers"
            placeholder="Enter answers"
          />

          <div className="input-wrapper">
            <Switch
              isSelected={isAiPromptOverrideEnabled}
              onValueChange={setIsAiPromptOverrideEnabled}
            >
              Override AI Prompt
            </Switch>
          </div>

          {isAiPromptOverrideEnabled && (
            <div className="input-wrapper">
              <Textarea
                value={aiPrompt}
                onChange={(e: any) => {
                  setAiPrompt(e.target.value)
                }}
                type="text"
                label="AI Prompt"
                placeholder="Enter the AI prompt"
                variant="bordered"
              />
            </div>
          )}

          <div className="input-wrapper">
            <Switch
              isSelected={isAiChatBotQuestion}
              onValueChange={setIsAiChatBotQuestion}
            >
              Make this an AI Virtual Patient Question
            </Switch>
          </div>
          {isAiChatBotQuestion && (
            <>
              <div className="input-wrapper">
                <Select
                  variant="bordered"
                  label="Scenario"
                  placeholder="Select an AI chat bot scenario"
                  selectedKeys={aiChatBotScenarioTemplateId}
                  onSelectionChange={setAiChatBotScenarioTemplateId}
                >
                  {scenarios.map((scenario: any) => (
                    <SelectItem key={scenario._id} value={scenario._id}>
                      {scenario.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="input-wrapper">
                <Textarea
                  variant="bordered"
                  value={aiChatBotScenarioPrompt}
                  onChange={(e: any) => {
                    setAiChatBotScenarioPrompt(e.target.value)
                  }}
                  label="Scenario"
                  placeholder="Enter the scenario for this question"
                />
              </div>
              <div className="input-wrapper">
                <Textarea
                  variant="bordered"
                  value={aiChatBotUserRole}
                  onChange={(e: any) => {
                    setAiChatBotUserRole(e.target.value)
                  }}
                  label="Users role"
                  placeholder="Enter a description of the users role in this situation. For example, 'A junior doctor in the emergency department at their local hospital.'"
                />
              </div>
            </>
          )}

          <div className="input-wrapper">
            <CategoryInput
              selectedCategories={categories}
              setSelectedCategories={setCategories}
            />
          </div>

          {/* TODO: Add question time limit (recommended time: number) and answerAccessLevel (select with the enums) */}
          <div className="input-wrapper">
            <Input
              variant="bordered"
              type="number"
              value={timeLimit}
              onChange={(e: any) => {
                setTimeLimit(e.target.value)
              }}
              label="Question Time Limit"
              placeholder="Enter the recommended minutes to answer this question"
            />
          </div>

          <div className="input-wrapper">
            <Select
              variant="bordered"
              label="Access Level"
              placeholder="Select who can view the answer"
              selectedKeys={answerAccessLevel}
              onSelectionChange={setAnswerAccessLevel}
            >
              {Object.keys(AnswerAccessLevelString).map((key: any) => (
                <SelectItem
                  key={AnswerAccessLevel[key]}
                  value={AnswerAccessLevel[key]}
                >
                  {(AnswerAccessLevelString as any)[key]}
                </SelectItem>
              ))}
            </Select>
          </div>

          <div className="input-wrapper">
            <TableSelectInput
              aria-label="Associated reading links"
              title={"Associated Reading Links"}
              addNewPlaceholder="+ Add a new link"
              onAddNew={addLinkModalDisclosure.onOpen}
              onEdit={(index: number) => {
                setAssociatedReadingLinksIndex(index)
                editLinkModalDisclosure.onOpen()
              }}
              onDelete={async (_id: string) => {
                setAssociatedReadingLinks((prev: any) =>
                  prev.filter((link: any) => link._id !== _id),
                )
              }}
              isLoading={false}
              selectedItems={associatedReadingLinks}
              setSelectedItems={setAssociatedReadingLinks}
              hideMovementArrows={false}
              headers={["Title", "Type", "URL"]}
            />
          </div>

          <div className="input-wrapper create-question-wrapper">
            <Button
              onClick={buildAndSendQuestion}
              variant="solid"
              color="primary"
              isLoading={isLoading}
              isDisabled={formValidate()}
            >
              Create Question
            </Button>
          </div>
        </div>
      )}
    </Page>
  )
}
