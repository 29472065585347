import React from "react"
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button,
  Chip,
  useDisclosure,
} from "@nextui-org/react"

import "./index.css"
import {
  faEye,
  faPause,
  faPlay,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { useNavigate } from "react-router"
import { deleteQuiz } from "@/api/routes/quiz/quiz"
import ContinueAsInterviewerOrIntervieweeModal from "./Modals/ContinueAsInterviewerOrInterviewee"
import ViewCompleteQuizModal from "./Modals/ViewCompleteQuizModal"
import { colorFromPercentage } from "@/core/helpers/utils"

interface Props {
  id: string
  title: string
  shortDescription: string
  description: string
  scorePercentage?: number
  isFinished: boolean
  reloadQuizzes: () => void
  startDate: Date
}

export default function QuizCard(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const continueModalDisclosure = useDisclosure()
  const quizCompleteModalDisclosure = useDisclosure()

  var dateString =
    props.startDate.getDate().toString().padStart(2, "0") +
    "/" +
    (props.startDate.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    props.startDate.getFullYear() +
    " (" +
    props.startDate.getHours().toString().padStart(2, "0") +
    ":" +
    props.startDate.getMinutes().toString().padStart(2, "0") +
    ")"

  const confirmDelete = async () => {
    await deleteQuiz(props.id)
    props.reloadQuizzes()
    onClose()
  }

  const resumeQuiz = () => {
    continueModalDisclosure.onOpen()
  }

  const viewCompleteQuiz = () => {
    quizCompleteModalDisclosure.onOpen()
  }

  return (
    <Card className="quiz-card">
      <ConfirmDeleteModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={confirmDelete}
        description="Are you sure you want to delete this quiz? This action cannot be reversed and will affect the statistics for your account."
      />
      <ContinueAsInterviewerOrIntervieweeModal
        quizId={props.id}
        isOpen={continueModalDisclosure.isOpen}
        onClose={continueModalDisclosure.onClose}
      />
      <ViewCompleteQuizModal
        quizId={props.id}
        isOpen={quizCompleteModalDisclosure.isOpen}
        onClose={quizCompleteModalDisclosure.onClose}
      />
      <div className="widget-row">
        <div className="widget-left-content">
          <h2 className="widget-title">{props.title}</h2>
          <p className="widget-desc">{props.shortDescription}</p>
        </div>
        <div className="widget-right-content">
          <div className="widget-row no-padding">
            {typeof props.scorePercentage === "number" ? (
              <Chip
                size="sm"
                variant="flat"
                color={colorFromPercentage(props.scorePercentage)}
                className="score-chip"
              >
                {props.scorePercentage}%
              </Chip>
            ) : null}
            {props.isFinished ? (
              <Chip
                size="sm"
                variant="flat"
                color="success"
                className="score-chip"
              >
                COMPLETE
              </Chip>
            ) : (
              <Chip
                size="sm"
                variant="flat"
                color="primary"
                className="score-chip"
              >
                IN PROGRESS
              </Chip>
            )}
            {!props.isFinished ? (
              <Button
                className="widget-action-button"
                variant="flat"
                isIconOnly
                color="primary"
                aria-label="Resume"
                onClick={resumeQuiz}
              >
                <FontAwesomeIcon icon={faPlay} />
              </Button>
            ) : (
              <>
                <Divider orientation="vertical" />
                <Button
                  className="widget-action-button"
                  variant="flat"
                  isIconOnly
                  color="primary"
                  aria-label="View"
                  onClick={viewCompleteQuiz}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Button>
              </>
            )}
            {/*
            <Button
              className="widget-action-button"
              variant="flat"
              isIconOnly
              color="danger"
              aria-label="Delete mock"
              onClick={onOpen}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
            */}
          </div>
          <p className="widget-desc">
            <i>{dateString}</i>
          </p>
        </div>
      </div>
    </Card>
  )
}
