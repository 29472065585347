import "./index.css"

const cleanPercentage = (percentage: number) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0 // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100
  return isNegativeOrNaN
    ? 0
    : isTooHigh
    ? 100
    : Math.round(+percentage * 100) / 100
}

interface CircleStyles {
  progressColour?: string
  backgroundColour?: string
  strokeWidth?: string
}

interface CircleProps {
  percentage?: number
  radius: number
  styles?: CircleStyles
}
const Circle = ({ percentage, radius, styles }: CircleProps) => {
  const circ = 2 * Math.PI * radius
  const strokePct = ((100 - (percentage ?? 0)) * circ) / 100 // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      style={{ transform: "rotate(-90deg)", transformOrigin: "50% 50%" }}
      r={radius}
      cx={"50%"}
      cy={"50%"}
      fill={styles?.backgroundColour ?? "transparent"}
      stroke={strokePct !== circ ? styles?.progressColour ?? "transparent" : ""}
      strokeWidth={styles?.strokeWidth ?? "0.2rem"}
      strokeLinecap="round"
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    ></circle>
  )
}

interface TextProps {
  percentage: number
  text?: string
  subtext: string
}
const Text = ({ percentage, text, subtext }: TextProps) => {
  return (
    <div className="circle-progress-svg-text-container">
      <span className="circle-progress-svg-header">
        {text ?? percentage + "%"}
      </span>
      <span className="circle-progress-svg-subtitle">{subtext}</span>
    </div>
  )
}

interface Props {
  percentage: number
  radius: number
  progressCircleStyles?: CircleStyles
  backgroundCircleStyles?: CircleStyles
  text?: string
  subtext: string
}
const CircularProgress = ({
  percentage,
  radius,
  progressCircleStyles,
  backgroundCircleStyles,
  text,
  subtext,
}: Props) => {
  const pct = cleanPercentage(percentage)
  return (
    <div
      style={{
        width: radius * 3,
        height: radius * 3,
        display: "flex",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <svg width={"100%"} height={"100%"}>
        <g>
          <Circle
            radius={radius}
            styles={backgroundCircleStyles}
            percentage={100}
          />
          <Circle
            styles={progressCircleStyles}
            percentage={pct}
            radius={radius}
          />
        </g>
      </svg>
      <Text text={text} percentage={pct} subtext={subtext} />
    </div>
  )
}
export default CircularProgress
