import { faAlignJustify, faMicrophone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, Tab, Tabs, Textarea } from "@nextui-org/react"
import QuizAttemptRecordVoice from "../QuizAttemptRecordVoice"

interface Props {
  answer: any
  setAnswer: any
  setVoiceBlob: (blob: Blob) => void
  isSavingVoice: boolean
  recordedBlob: Blob | null
}

export default function QuestionAnswerInput({
  answer,
  setAnswer,
  setVoiceBlob,
  isSavingVoice,
  recordedBlob,
}: Props) {
  return (
    <Card shadow="none" className="in-progress-quiz-answer-card">
      <Tabs
        className="answer-type-select-tabs"
        aria-label="Options"
        color="primary"
        variant="solid"
      >
        <Tab
          title={
            <div className="answer-type-select-tab">
              <FontAwesomeIcon icon={faAlignJustify} />
              <span>Type your answer</span>
            </div>
          }
        >
          <Textarea
            variant="bordered"
            label="Answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            size="lg"
            labelPlacement="inside"
            placeholder="Enter your answer"
            className="interview-answer-text-area"
          />
        </Tab>
        <Tab
          title={
            <div className="answer-type-select-tab">
              <FontAwesomeIcon icon={faMicrophone} />
              <span>Record voice</span>
            </div>
          }
        >
          <QuizAttemptRecordVoice
            isSavingVoice={isSavingVoice}
            setRecordedBlob={setVoiceBlob}
            recordedBlob={recordedBlob}
          />
        </Tab>
      </Tabs>
    </Card>
  )
}
