import React, { useState, useEffect, useRef } from "react"

// callback is the function returning void or promise<void>
export const useInterval = (
  callback: () => void | Promise<void>,
  delay: number | null,
) => {
  const savedCallback = useRef<any>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
