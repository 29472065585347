import { PageData } from "@/api/types/general"
import axios from "@/core/helpers/axios"
import {
  IInProgressQuizQuestion,
  IQuestion,
} from "@/api/types/question/question"
import { IQuizBank } from "@/api/types/quiz/quiz-bank"
import { IQuiz, IUserQuizFilter, IUserQuizView } from "@/api/types/quiz/quizzes"

export async function getQuizzes(
  limit?: number,
  pageData?: PageData,
  filter?: Partial<IUserQuizFilter>,
): Promise<IUserQuizView[] | undefined> {
  try {
    let urlParams = new URLSearchParams()
    if (limit) {
      urlParams.append("limit", limit.toString())
    }
    if (pageData?.fetchMeta === true) {
      urlParams.append("meta", "1")
    }
    if (pageData?.page) {
      urlParams.append("page", pageData.page.toString())
    }
    if (pageData?.limit) {
      urlParams.append("limit", pageData.limit.toString())
    }
    if (pageData?.sort) {
      urlParams.append("sort", pageData.sort.toString())
    }
    if (filter) {
      for (let key in filter) {
        const filterContents = filter[key as keyof IUserQuizFilter]
        if (filterContents) {
          urlParams.append(key, filterContents)
        }
      }
    }

    const res = await axios.get("/user-quiz?" + urlParams.toString())

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function startQuiz(id: string): Promise<IQuiz | undefined> {
  try {
    const res = await axios.put("/user-quiz/start/" + id)

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function getIntervieweeInstructions(
  id: string,
): Promise<string | undefined> {
  try {
    const res = await axios.get("/user-quiz/interviewee/instructions/" + id)

    return res.data.body.instructions
  } catch (err: any) {
    console.log(err)
    return
  }
}

export async function getInterviewerInstructions(
  id: string,
): Promise<string | undefined> {
  try {
    const res = await axios.get("/user-quiz/interviweer/instructions/" + id)

    return res.data.body.instructions
  } catch (err: any) {
    console.log(err)
    return
  }
}

export async function currentQuestion(
  id: string,
  asCandidate: boolean,
): Promise<IInProgressQuizQuestion | undefined> {
  try {
    let query = ""
    if (asCandidate === false) {
      query += "?notAsCandidate=true"
    }
    const res = await axios.get("/user-quiz/current-question/" + id + query)

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function nextQuestion(
  id: string,
  asCandidate?: boolean,
): Promise<object | undefined> {
  try {
    let query = ""
    if (asCandidate === false) {
      query += "?notAsCandidate=true"
    }
    const res = await axios.put("/user-quiz/next-question/" + id + query)

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function getSpecificQuestion(
  id: string,
  questionNumber: number,
): Promise<IInProgressQuizQuestion | undefined> {
  try {
    const res = await axios.get(
      "/user-quiz/question/" + id + "/" + questionNumber,
    )

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function answerQuestion(
  id: string,
  data: any,
): Promise<object | undefined> {
  try {
    const res = await axios.put("/user-quiz/answer-question/" + id, data)

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function markQuestion(
  quizId: string,
  questionId: string,
  answersScored: string[],
  markersComment: string,
  optionalAnswersEnabled: string[],
): Promise<boolean | undefined> {
  try {
    await axios.put(`/user-quiz/mark-question/${quizId}/${questionId}`, {
      answers: answersScored,
      optionalAnswersEnabled: optionalAnswersEnabled,
      markersComment,
    })

    return true
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

export async function finishQuiz(id: string): Promise<boolean> {
  try {
    await axios.put("/user-quiz/finish/" + id)

    return true
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return false
    } else {
      // Other error
      return false
    }
  }
}

export async function deleteQuiz(id: string): Promise<boolean> {
  try {
    await axios.delete("/user-quiz/delete/" + id)

    return true
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return false
    } else {
      // Other error
      return false
    }
  }
}

export async function getQuizMetaDataFromUserQuizId(
  id: string,
): Promise<IQuizBank | undefined> {
  try {
    const res = await axios.get("/user-quiz/meta-data/" + id)

    return res.data.body
  } catch (err: any) {
    console.log(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      // Unauthorised
      return
    } else {
      // Other error
      return
    }
  }
}

// route to reset time on question
export async function resetTimerOnCurrentQuestion(
  userQuizId: string,
): Promise<boolean> {
  try {
    await axios.put("/user-quiz/reset-timer/" + userQuizId)

    return true
  } catch (err: any) {
    console.log(err)
    return false
  }
}

export async function transcribeQuestionAnswerRoute(
  userQuizId: string,
  questionIndex: number,
): Promise<boolean> {
  try {
    await axios.post(
      "/user-quiz/transcribe-answer/" + userQuizId + "/" + questionIndex,
    )

    return true
  } catch (err: any) {
    console.log(err)
    return false
  }
}

export async function markQuestionAnswerWithAiRoute(
  userQuizId: string,
  questionIndex: number,
  useTranscription: boolean,
): Promise<boolean> {
  try {
    await axios.post(
      "/user-quiz/mark-question-with-ai/" + userQuizId + "/" + questionIndex,
      {
        useTranscription,
      },
    )

    return true
  } catch (err: any) {
    console.log(err)
    return false
  }
}

export async function updateTranscriptionRoute(
  userQuizId: string,
  questionIndex: number,
  newTranscription: string,
): Promise<boolean> {
  try {
    await axios.put(
      "/user-quiz/update-transcription/" + userQuizId + "/" + questionIndex,
      {
        transcription: newTranscription,
      },
    )

    return true
  } catch (err: any) {
    console.log(err)
    return false
  }
}
