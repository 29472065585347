import { Button } from "@nextui-org/react"
import SideWidget from "@/pages/QuizAttempt/components/SideWidget"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import QuestionProgressBar from "@/pages/QuizAttempt/components/QuestionProgressBar"

const pad = (n: number) => String(n).padStart(2, "0")

interface Props {
  currentQuestion: number
  totalQuestions: number
  isMarker?: boolean
  hideBack?: boolean
  hideProgressBar?: boolean
  nextQuestion?: () => void
  prevQuestion?: () => void
  finishQuiz?: () => void
  saveMarks?: () => void
  saveMarksIsLoading?: boolean
}

export default function QuestionCursor(props: Props) {
  return (
    <SideWidget className="question-cursor-container">
      <div className="question-cursor-question-position-text">
        <h1 className="question-cursor-question-number">
          {pad(props.currentQuestion)}/
        </h1>
        <h1 className="question-cursor-total-questions">
          {pad(props.totalQuestions)}
        </h1>
      </div>
      {!props.hideProgressBar && (
        <QuestionProgressBar
          currentQuestion={props.currentQuestion}
          totalQuestions={props.totalQuestions}
        />
      )}
      <div className="question-cursor-content">
        {!props.hideBack ? (
          <>
            {props.prevQuestion && (
              <Button
                variant="flat"
                color="primary"
                isIconOnly={true}
                isDisabled={props.currentQuestion <= 1}
                onClick={props.prevQuestion}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            )}
            {props.nextQuestion && (
              <Button
                variant="flat"
                color="primary"
                isIconOnly={true}
                isDisabled={props.currentQuestion >= props.totalQuestions}
                onClick={props.nextQuestion}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            )}
            {props.saveMarks != null ? (
              <Button
                onClick={props.saveMarks}
                isLoading={props.saveMarksIsLoading}
                variant="solid"
                color="primary"
              >
                Save
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
      {!props.isMarker ? (
        <div className="question-cursor-finish-panel">
          {props.currentQuestion === props.totalQuestions &&
            props.finishQuiz != null && (
              <Button onClick={props.finishQuiz} color="danger" variant="solid">
                Finish Quiz
              </Button>
            )}
        </div>
      ) : null}
    </SideWidget>
  )
}
