import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Link,
} from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faX } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import ListInput from "../ListInput"
import { MenuItem } from "../SubscriptionPlanFeaturesInput"
import CategoryInput from "../../CreateOrEditQuestion/CategoryInput"

export interface ItemInputObservable {
  [key: string]: any
}

interface Props {
  label: string
  buttonLabel: string
  placeholder: string

  observable: ItemInputObservable
  setObservable: (val: any) => void

  menuItems: MenuItem[]
}

export default function ItemInput({
  label,
  buttonLabel,
  placeholder,
  observable,
  setObservable,
  menuItems,
}: Props) {
  const getInitialAddableItemsState = () => {
    // If the observable is empty, return all the items bar the first (slice(1))
    if (Object.keys(observable).length === 0) {
      return menuItems.slice(1)
    }

    // If the observable is not empty, return the items that are not in the observable
    return menuItems.filter((x) => !Object.keys(observable).includes(x.key))
  }

  const getInitialSelectedItemsState = () => {
    // If the observable is empty, return the first item [0]
    if (Object.keys(observable).length === 0) {
      return [menuItems[0]]
    }

    // If the observable is not empty, return all the items in the observable
    return menuItems.filter((x) => Object.keys(observable).includes(x.key))
  }

  const [addableItems, setAddableItems] = useState(
    getInitialAddableItemsState(),
  )
  const [selectedItems, setSelectedItems] = useState<MenuItem[]>(
    getInitialSelectedItemsState(),
  )

  const addItem = (item: MenuItem) => {
    setSelectedItems((prev: any) => [...prev, item])
    setAddableItems((prev: any) => prev.filter((x: any) => x.key !== item.key))
    setObservable((prev: any) => ({
      ...prev,
      [item.key]:
        item.type === "number" ? 0 : item.type === "boolean" ? false : "",
    }))
  }

  const removeItem = (item: MenuItem) => {
    setSelectedItems(selectedItems.filter((x) => x.key !== item.key))
    setAddableItems([...addableItems, item])
    setObservable((obs: any) => {
      delete obs[item.key]
      obs = { ...obs }
      return obs
    })
  }

  const typeFormat = (type: any, value: any) =>
    type === "number" ? parseInt(value) : value

  const drawInput = (item: any): React.ReactNode => {
    return (
      <div key={item.key} className="item-input">
        {item.type === "boolean" ? (
          <div className="item-input-other-item">
            <Checkbox
              defaultSelected={observable && observable[item.key]}
              onChange={(e) => {
                setObservable((prev: any) => ({
                  ...prev,
                  [item.key]: e.target.checked,
                }))
              }}
            >
              {item.label}
            </Checkbox>
            <Button
              color="danger"
              variant="light"
              radius="full"
              isIconOnly={true}
              isDisabled={selectedItems.length === 1}
              className="item-input-delete"
              onClick={() => removeItem(item)}
            >
              <FontAwesomeIcon icon={faX} size="xs" />
            </Button>
          </div>
        ) : item.type === "category-input" ? (
          <div className="item-input-category-input">
            <div className="item-input-category-input-inner">
              <span>{item.title}</span>
              <CategoryInput
                addCategoryButtonString="Select Categories"
                selectedCategories={
                  observable && observable[item.key] ? observable[item.key] : []
                }
                setSelectedCategories={(valFn: any) => {
                  setObservable((prev: any) => ({
                    ...prev,
                    [item.key]: valFn(prev[item.key] ?? []),
                  }))
                }}
              />
            </div>
            <Button
              color="danger"
              variant="light"
              radius="full"
              isIconOnly={true}
              isDisabled={selectedItems.length === 1}
              className="item-input-delete"
              onClick={() => removeItem(item)}
            >
              <FontAwesomeIcon icon={faX} size="xs" />
            </Button>
          </div>
        ) : (
          <>
            <Input
              fullWidth={true}
              variant="bordered"
              className="item-input-question"
              type={item.type}
              value={
                observable && observable[item.key] ? observable[item.key] : ""
              }
              onChange={(e) => {
                setObservable((prev: any) => ({
                  ...prev,
                  [item.key]: typeFormat(item.type, e.target.value),
                }))
              }}
              label={`${label} (${item.label})`}
              placeholder={`${placeholder} ${item.type === "number" ? "(number)" : ""}`}
            />
            {selectedItems.length !== 1 ? (
              <Button
                color="danger"
                variant="light"
                radius="full"
                isIconOnly={true}
                isDisabled={selectedItems.length === 1}
                className="item-input-delete"
                onClick={() => removeItem(item)}
              >
                <FontAwesomeIcon icon={faX} size="xs" />
              </Button>
            ) : null}
          </>
        )}
      </div>
    )
  }

  return (
    <ListInput
      label={label}
      observable={selectedItems}
      availableItems={addableItems}
      addItem={addItem}
      removeItem={removeItem}
      drawInput={drawInput}
      addNewText={buttonLabel}
    />
  )
}
