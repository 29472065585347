import React, { useEffect, useState } from "react"
import { Card, Spacer, Button, Input, Checkbox } from "@nextui-org/react"
import "./index.css"
import login from "@/api/routes/auth/login"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { useNavigate, useParams } from "react-router-dom"
import getProfile, {
  register,
  setPasswordWithToken,
} from "@/api/routes/user/user"
import { User } from "@/api/types/user/user"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
export default function SetPasswordPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [loadingLogin, setLoadingLogin] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<null | string>(null)

  const [password, setPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")

  const { token } = useParams()

  const userSelector = useAppSelector((state) => state.user)

  useEffect(() => {
    if (userSelector.user) {
      navigate("/")
    }
  }, [userSelector])

  const handleLogin = async () => {
    setLoadingLogin(true)
    setLoginError(null)

    const res = await setPasswordWithToken(password, token!)
    if (res === LoginEnum.SUCCESS) {
      // Show success screen
      navigate("/Login")
    } else if (res === LoginEnum.UNAUTHORIZED) {
    }

    setLoadingLogin(false)
  }

  return (
    <div className="login-container">
      <Card style={{ minWidth: "420px", padding: "20px" }}>
        <h1>Set Password</h1>
        <Input
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
        />
        <Spacer y={5} />
        <Input
          type="password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm your password"
        />
        <Spacer y={5} />
        <Button
          isDisabled={password !== confirmPassword}
          onClick={handleLogin}
          color="primary"
          isLoading={loadingLogin}
        >
          Continue
        </Button>

        {password !== confirmPassword ? (
          <>
            <Spacer y={2.5} />
            <span className="text-danger">Passwords do not match</span>
          </>
        ) : null}

        {loginError ? (
          <>
            <Spacer y={2.5} />
            <span className="text-danger">{loginError}</span>
          </>
        ) : null}
      </Card>
    </div>
  )
}
