import { useAppSelector } from "@/app/hooks"
import { Button, Card } from "@nextui-org/react"
import "./index.css"
import MySubscriptionWidgetFeatureRow from "./FeatureRow"
import {
  keysToShowCreditBalanceFor,
  subscriptionPlanFeaturesMap,
} from "@/core/constants/subscription-plan"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { zeroPad } from "react-countdown"
import { useNavigate } from "react-router"

interface Props {}
export default function MySubscriptionWidget({}: Props) {
  const currentUser = useAppSelector((state) => state.user?.user)
  const currentUserSubscription = currentUser?.subscription
  const currentUserSubscriptionData = currentUser?.subscriptionData
  const endDates = currentUser?.subscriptionEndDates
  const renewDates = currentUser?.subscriptionRenewDates

  const [renewDate, setRenewDate] = useState<Date | null | undefined>()
  const [endDate, setEndDate] = useState<Date | null | undefined>()

  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser && currentUser.subscriptionRenewDates) {
      if (renewDates?.length === 1) {
        setRenewDate(new Date(renewDates[0]))
      }
      if (endDates?.length === 1) {
        setEndDate(new Date(endDates[0]))
      }
    }
  }, [currentUser])

  return (
    <Card className="widget-2xauto">
      <div className="my-subscription-widget">
        <h2>{currentUserSubscription}</h2>

        {Object.keys(currentUserSubscriptionData ?? {}).map((key: any, i) =>
          keysToShowCreditBalanceFor.includes(key) ? (
            <MySubscriptionWidgetFeatureRow
              key={i}
              name={subscriptionPlanFeaturesMap[key].title}
              value={`${currentUserSubscriptionData[key]?.value ?? 0} remaining`}
            />
          ) : null,
        )}

        <div className="my-subscription-widget-footer">
          {renewDate ? (
            <i>
              Renews{" "}
              {zeroPad(renewDate.getDate()) +
                "/" +
                zeroPad(renewDate.getMonth()) +
                "/" +
                zeroPad(renewDate.getFullYear())}
            </i>
          ) : null}
          <Button
            onClick={() => navigate("/Subscriptions/Manage")}
            color="primary"
            variant="flat"
          >
            Manage Subscription <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </Card>
  )
}
