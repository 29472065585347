import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@nextui-org/react"
import LogoSvg from "@/assets/images/sidebar-logo.svg"
import React from "react"

interface Props {
  isCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const menuToggleButton = (
  setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>,
  isCollapsed?: boolean,
) => (
  <div className="sidebar-menu-toggle-button-container">
    <Button
      variant="light"
      isIconOnly={true}
      onClick={() => {
        setIsCollapsed?.(!isCollapsed)
      }}
      className="sidebar-menu-toggle-button"
    >
      <FontAwesomeIcon icon={faBars} />
    </Button>
    <div className="sidebar-logo">
      <img src={LogoSvg} alt="logo" />
    </div>
  </div>
)

export default function MenuToggleContainer({
  isCollapsed,
  setIsCollapsed,
}: Props) {
  return (
    <div
      className={`sidebar-menu-toggle-container ${isCollapsed ? "sidebar-menu-toggle-container-collapsed" : ""}`}
    >
      {menuToggleButton(setIsCollapsed, isCollapsed)}
    </div>
  )
}
