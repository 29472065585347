import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"

export async function getCategoryById(id: string) {
  try {
    const res = await axios.get("/admin/question-category/" + id)

    if (res.status === 200) {
      return res.data.body
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function createCategory(category: any) {
  try {
    const res = await axios.post("/admin/question-category/new", category)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateCategory(id: string, category: any) {
  try {
    const res = await axios.put(
      "/admin/question-category/update/" + id,
      category,
    )

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteCategory(id: string) {
  try {
    const res = await axios.delete("/admin/question-category/delete/" + id)

    if (res.status === 200) {
      return LoginEnum.SUCCESS
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function addSubCategory(
  name: string,
  description: string,
  path: string[],
) {
  try {
    const res = await axios.put("/admin/question-category/add-sub-category", {
      path,
      name,
      description,
    })

    if (res.status === 200) {
      return res.data.categoryId
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateSubCategory(
  name: string,
  description: string,
  path: string[],
) {
  try {
    const res = await axios.put(
      "/admin/question-category/update-sub-category",
      {
        name,
        description,
        path,
      },
    )

    if (res.status === 200) {
      return res.data.category
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteSubCategory(path: string[]) {
  try {
    const res = await axios.post(
      "/admin/question-category/delete-sub-category",
      {
        path,
      },
    )

    if (res.status === 200) {
      return res.data.category
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
