import React, { useEffect } from "react"
import { Card, Spacer, Button, Input, Checkbox, Link } from "@nextui-org/react"
import "./index.css"
import login from "@/api/routes/auth/login"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { useNavigate } from "react-router-dom"
import getProfile from "@/api/routes/user/user"
import { User } from "@/api/types/user/user"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
export default function LoginPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [loadingLogin, setLoadingLogin] = React.useState<boolean>(false)
  const [loginError, setLoginError] = React.useState<null | string>(null)

  const [email, setEmail] = React.useState<string>("")
  const [password, setPassword] = React.useState<string>("")

  const userSelector = useAppSelector((state) => state.user)

  useEffect(() => {
    if (userSelector.user) {
      navigate("/")
    }
  }, [userSelector])

  const handleLogin = async () => {
    setLoadingLogin(true)
    setLoginError(null)

    const res = await login(email, password)
    if (res === LoginEnum.SUCCESS) {
      const user = await getProfile()

      if (user) {
        dispatch({
          type: "user/set",
          payload: user as User,
        })
      }
    } else if (res === LoginEnum.UNAUTHORIZED) {
      setLoginError("Failed to log in, please check your username/password.")
    } else if (res === LoginEnum.OTHER) {
      setLoginError("An unexpected error has occurred.")
    }

    setLoadingLogin(false)
  }

  return (
    <div className="login-container">
      <Card style={{ minWidth: "420px", padding: "20px" }}>
        <h1>MedCamps Login</h1>
        <Input
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <Spacer y={5} />
        <Input
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
        />
        <Spacer y={5} />
        <div className="login-row">
          {/*
          <Checkbox>
            <span className="login-sub-buttons">Remember me</span>
          </Checkbox>
  */}
          <div></div>
          <Link href="/ForgotPassword">
            <span className="login-sub-buttons">Forgot password?</span>
          </Link>
        </div>

        <Spacer y={5} />
        <Button onClick={handleLogin} color="primary" isLoading={loadingLogin}>
          Sign in
        </Button>

        {loginError ? (
          <>
            <Spacer y={2.5} />
            <span className="text-danger">{loginError}</span>
          </>
        ) : null}
      </Card>
    </div>
  )
}
