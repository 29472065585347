import { Card, Checkbox, toggle, tv, useCheckbox } from "@nextui-org/react"
import "./index.css"
import ProgressCard from "../ProgressCard"
import { useState } from "react"

interface Props {
  greenProgress: number
  redProgress: number
  children: React.ReactNode
  isSelected: boolean
  setIsSelected: (isSelected: boolean) => void
}

export default function CheckboxProgressCard(props: Props) {
  const toggleSelected = () => props.setIsSelected(!props.isSelected)

  return (
    <button onClick={toggleSelected}>
      <ProgressCard
        shadow={"none"}
        className={`checkbox-progress-card ${props.isSelected && "selected"}`}
        greenProgress={props.greenProgress}
        redProgress={props.redProgress}
      >
        <div className="checkbox-progress-card-body">
          <div className="checkbox-progress-card-checkbox"></div>
          <div className="checkbox-progress-card-content">{props.children}</div>
        </div>
      </ProgressCard>
    </button>
  )
}
