import { Card } from "@nextui-org/react"
import "./index.css"

export interface Props {
  children: React.ReactNode
  className?: string
}

export default function SideWidget({ children, className }: Props) {
  return <Card className={`side-widget ${className ?? ""}`}>{children}</Card>
}
