import {
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react"
import { QuizActionsCell } from "./QuizActionsCell"
import { useEffect, useState } from "react"
import { IUserQuizView } from "@/api/types/quiz/quizzes"
import { getQuizzes } from "@/api/routes/quiz/quiz"

export default function LatestQuizzesWidget() {
  const [quizListLoading, setQuizListLoading] = useState(true)
  const [quizzes, setQuizzes] = useState([] as IUserQuizView[])

  const loadQuizzes = async () => {
    setQuizListLoading(true)
    const q = await getQuizzes(3, { sort: "-startDate" })
    if (q) {
      setQuizzes(q)
    }
    setQuizListLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await loadQuizzes()
    })()
  }, [])

  return (
    <Card className="widget-2x2 mini-table">
      <h1 className="widget-2x2-header">Latest Interviews</h1>
      {quizListLoading ? (
        <Table
          removeWrapper
          hideHeader={quizListLoading}
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn>NAME</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Table
          removeWrapper
          hideHeader={quizListLoading}
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn>NAME</TableColumn>
            <TableColumn> </TableColumn>
          </TableHeader>
          <TableBody items={quizzes}>
            {(quiz) => (
              <TableRow key={quiz._id}>
                <TableCell>{quiz.name}</TableCell>
                <TableCell className="right-aligned-cell">
                  <QuizActionsCell quiz={quiz} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Card>
  )
}
