import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@nextui-org/react"
import { useNavigate } from "react-router"
import "./index.css"

export default function BeginQuizWidget() {
  const navigate = useNavigate()
  const navigateToChooseQuiz = () => {
    navigate("/ChooseQuiz")
  }

  return (
    <div className="widget-2x2 begin-quiz-widget-button-container">
      <Button
        className="widget-button begin-quiz-widget-button"
        color="primary"
        variant="flat"
        onClick={navigateToChooseQuiz}
      >
        <FontAwesomeIcon fontSize={"2rem"} icon={faPlusCircle} />
        <span>Begin Interview</span>
      </Button>
    </div>
  )
}
