import Page from "@/core/components/Page"
import {
  Button,
  Checkbox,
  Input,
  Link,
  Select,
  SelectItem,
  Selection,
  Textarea,
} from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import "./index.css"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import { Key, useEffect, useState } from "react"
import { createUser } from "@/api/routes/admin/user"
import {
  isFieldFilledOut,
  isLanguageFieldFilledOut,
} from "@/core/helpers/utils"
import SubscriptionPlanFeaturesInput from "../components/SubscriptionPlanFeaturesInput"
import { createSubscriptionPlan } from "@/api/routes/admin/subscription-plan"
import { ItemInputObservable } from "../components/ItemInput"
import { IPlanFeatures } from "@/api/types/subscription-plan/subscription-plan"

export default function CreateSubscriptionPlanPage() {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [planName, setPlanName] = useState<string>("")
  const [planPrice, setPlanPrice] = useState<number>(1)
  const [planDuration, setPlanDuration] = useState<number>(0)
  const [features, setPlanFeatures] = useState<ItemInputObservable>({})
  const [planDescription, setPlanDescription] = useState<string>("")
  const [autoRenew, setAutoRenew] = useState<boolean>(false)

  const buildAndSendPlan = async () => {
    setIsLoading(true)

    // Convert item input observable to IPlanFeatures
    const formattedFeatures: IPlanFeatures = {}
    Object.keys(features).forEach((key) => {
      formattedFeatures[key] = {
        value: features[key],
      }
    })

    const plan = {
      name: planName,
      price: planPrice,
      duration: planDuration,
      features: formattedFeatures,
      description: planDescription,
      autoRenew: autoRenew,
    }

    await createSubscriptionPlan(plan)

    navigate("/Admin/SubscriptionPlans")

    setIsLoading(false)
  }

  const formValidate = () => {
    if (
      isFieldFilledOut(planName) &&
      isFieldFilledOut(planDescription) &&
      isFieldFilledOut(planDuration) &&
      isFieldFilledOut(planPrice) &&
      isLanguageFieldFilledOut(features)
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      <PageHeader>
        <h1>Create Subscription Plan</h1>
      </PageHeader>
      <div className="page-grid">
        <div className="input-wrapper row row-gap-10">
          <Input
            type="text"
            label="Plan Name"
            variant="bordered"
            placeholder="Enter the plan name"
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <Textarea
            type="text"
            label="Plan Description"
            variant="bordered"
            placeholder="Enter the plan description"
            value={planDescription}
            onChange={(e) => setPlanDescription(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <SubscriptionPlanFeaturesInput
            observable={features}
            setObservable={setPlanFeatures}
          />
        </div>

        <div className="input-wrapper row row-gap-10">
          <Input
            type="number"
            label="Price (GBP)"
            placeholder="Enter the price"
            min={0}
            step=".01"
            variant="bordered"
            value={planPrice.toString()}
            onChange={(e) => setPlanPrice(parseFloat(e.target.value))}
            startContent={
              <div className="pointer-events-none flex items-center">
                <span className="text-default-400 text-small">£</span>
              </div>
            }
          />
          <Input
            type="number"
            label="Duration (days)"
            placeholder="Enter the duration"
            min={0}
            variant="bordered"
            value={planDuration.toString()}
            onChange={(e) => setPlanDuration(parseInt(e.target.value))}
          />
        </div>

        <div className="input-wrapper column">
          <Checkbox
            isSelected={autoRenew}
            onChange={(e: any) => setAutoRenew(e.target.checked)}
          >
            Subscription purchase
          </Checkbox>
        </div>

        <div className="input-wrapper create-question-wrapper">
          <Button
            onClick={buildAndSendPlan}
            variant="solid"
            color="primary"
            isLoading={isLoading}
            isDisabled={formValidate()}
          >
            Create Subscription Plan
          </Button>
        </div>
      </div>
    </Page>
  )
}
