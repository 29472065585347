import { useNavigate, useParams } from "react-router"
import Page from "@/core/components/Page"
import {
  currentQuestion,
  getQuizMetaDataFromUserQuizId,
  getSpecificQuestion,
  markQuestion,
  markQuestionAnswerWithAiRoute,
  transcribeQuestionAnswerRoute,
  updateTranscriptionRoute,
} from "@/api/routes/quiz/quiz"
import { useEffect, useState } from "react"
import { PageLoader } from "@/components/PageLoader"
import {
  Button,
  Card,
  Chip,
  Spacer,
  Textarea,
  useDisclosure,
} from "@nextui-org/react"
import PageHeader from "@/core/components/Page/PageHeader"
import {
  IAnswerMetaData,
  IInProgressQuizQuestion,
} from "@/api/types/question/question"
import "./index.css"
import { AttemptTypeEnum, IQuizBank } from "src/api/types/quiz/quiz-bank"
import QuestionCard from "@/pages/QuizAttempt/components/QuestionCard"
import TimerCard from "@/pages/QuizAttempt/components/TimerCard"
import TotalMarksCard from "@/pages/QuizAttempt/components/TotalMarksCard"
import QuestionCursor from "@/pages/QuizAttempt/components/QuestionCursor"
import "../index.css"
import BaseModal from "@/core/components/Modals/BaseModal"
import QuizModeTabs from "../components/QuizModeTabs"
import QuestionAnswerReview from "../components/QuestionAnswerReview"
import MandatoryMarkAnswerCheckboxes from "./components/MarkAnswerCheckboxes/Mandatory"
import OptionalMarkAnswerCheckboxes from "./components/MarkAnswerCheckboxes/Optional"
import LinksList from "@/core/components/LinksList"
import { viewVoiceFile } from "@/api/routes/voice/voice"
import AiResponse from "../components/AiResponse"
import ViewVoiceRecordedAnswer from "../components/ViewVoiceRecordedAnswer"
import ViewAndManageTranscriptionCard from "../components/ViewAndManageTranscriptionCard"
import { useInterval } from "@/core/helpers/use-interval"
import MarkWithAiButtonsCard from "../components/MarkWithAiButtonsCard"
import AiChatbox from "@/components/AiChatbox"

interface Props {
  asMarker: boolean
  hideSidebar?: boolean
  offsetSeconds?: number
  asWidget?: boolean
  saveMarksExt?: () => Promise<void>
  answerWrittenOverride?: string
  questionNumberOverride?: number
}

export default function ReviewQuizPage({
  asMarker,
  hideSidebar,
  offsetSeconds,
  asWidget,
  saveMarksExt,
  answerWrittenOverride,
  questionNumberOverride,
}: Props) {
  const { quizId } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [saveMarksIsLoading, setSaveMarksIsLoading] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [questionContent, setQuestionContent] = useState("" as string)

  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false)
  const [unsavedProceedDirection, setUnsavedProceedDirection] =
    useState<number>(0) // -1 = prev, 1 = next
  const unsavedChangesDisclosure = useDisclosure()

  const [scoredMarks, setScoredMarks] = useState<number>(0)
  const [scoredScaledMarks, setScoredScaledMarks] = useState<number>(0)

  // Transcription
  const [transcription, setTranscription] = useState<string | null | undefined>(
    null,
  )
  const [isTranscribing, setIsTranscribing] = useState<
    boolean | null | undefined
  >(false)
  const [hasTranscription, setHasTranscription] = useState<
    boolean | null | undefined
  >(false)

  // AI marking
  const [isAiMarking, setIsAiMarking] = useState<boolean | null | undefined>(
    false,
  )
  const [aiResponse, setAiResponse] = useState<string | null | undefined>(null)
  const [isTranscriptionSaving, setIsTranscriptionSaving] = useState(false)

  const [question, setQuestion] = useState(
    null as IInProgressQuizQuestion | null,
  )
  const [quizData, setQuizData] = useState(null as IQuizBank | null)

  const [mandatoryAnswersScored, setMandatoryAnswersScored] = useState<any>([])
  const [optionalAnswersScored, setOptionalAnswersScored] = useState<any>([])

  const [voiceRecordingId, setVoiceRecordingId] = useState<
    string | null | undefined
  >(null)
  const [preLoadedVoiceBlob, setPreLoadedVoiceBlob] = useState<Blob | null>(
    null,
  )

  const [markersComment, setMarkersComment] = useState<string>("")

  const [questionUserAnswer, _setQuestionUserAnswer] = useState<string>("")
  const [startTime, setStartTime] = useState<Date>(new Date())
  const [totalSeconds, setTotalSeconds] = useState<number>(-1)
  const [showQuestionAnsweredChip, setShowQuestionAnsweredChip] =
    useState<boolean>(false)

  const [mandatoryAnswers, setMandatoryAnswers] = useState<any>([])
  const [optionalAnswers, setOptionalAnswers] = useState<any>([])
  const [optionalAnswersEnabled, setOptionalAnswersEnabled] = useState(
    [] as any,
  )

  const SOFT_REFRESH_POLL_DELAY = 5000 // 5s
  const [softRefreshPollDelay, setSoftResetPollDelay] = useState<number | null>(
    null,
  )

  useInterval(async () => {
    await getQuestionByNumber(question!.currentQuestionNumber)
  }, softRefreshPollDelay)

  const loadQuizData = async () => {
    let quizData = await getQuizMetaDataFromUserQuizId(quizId!)

    if (quizData) {
      setQuizData(quizData)
    } else {
      // TODO: Show error
    }

    return quizData
  }

  const setQuestionUserAnswer = (answer: string) => {
    if (answerWrittenOverride) {
      _setQuestionUserAnswer(answerWrittenOverride)
    } else {
      _setQuestionUserAnswer(answer)
    }
  }

  const onTranscriptionChange = async (transcription: string) => {
    if (question) {
      setTranscription(transcription)
      setIsTranscriptionSaving(true)
      await updateTranscriptionRoute(
        quizId!,
        question.currentQuestionNumber - 1,
        transcription,
      )
      setIsTranscriptionSaving(false)
    }
  }

  const queueQuestionForMarking = async (isTranscription: boolean) => {
    if (question) {
      const res = await markQuestionAnswerWithAiRoute(
        quizId!,
        question.currentQuestionNumber - 1,
        isTranscription,
      )

      // Now we need to soft refresh the question
      await getQuestionByNumber(question.currentQuestionNumber)
    }
  }

  const queueQuestionForTranscriptionGeneration = async () => {
    if (question) {
      const res = await transcribeQuestionAnswerRoute(
        quizId!,
        question.currentQuestionNumber - 1,
      )

      // Now we need to soft refresh the question
      await getQuestionByNumber(question.currentQuestionNumber)
    }
  }

  // This function is used to refresh the quiz data
  // Poll function has not been implemented yet (this will be unused for now)
  // const refreshQuizDataPoll = async () => {
  //   await getQuestionByNumber(question!.currentQuestionNumber)
  // }

  const setQuestionOnPage = async (question: any, questionNumber?: number) => {
    if (question) {
      let answers = [] as IAnswerMetaData[]
      setScoredMarks(0)
      setScoredScaledMarks(0)
      setStartTime(new Date())
      setTotalSeconds(-1)
      setShowQuestionAnsweredChip(false)
      setQuestionUserAnswer("")

      // This snippet organises our answers by heading.
      // Join our answers
      const keys = Object.keys(question.answers)
      for (let key of keys) {
        question.answers[key][0].heading = key
        answers = answers.concat(question.answers[key])
      }
      question.answers = answers

      // This snippet sets our question number if we have
      // requested a specific question (used for question cursing)
      if (questionNumber) {
        question.currentQuestionNumber = questionNumber
      }

      // This snippet automatically checks the previously selected correct answers
      // to this user's response.
      // Array of object id strings relating to the id of each answer
      const answersScored = question.userAnswer.answersScored
      const _mandatoryAnswersScored = answersScored.filter((x: any) => {
        const ansData = question.answers.find((y: any) => y._id === x)
        return !ansData?.isOptional
      })
      const _optionalAnswersScored = answersScored.filter((x: any) => {
        const ansData = question.answers.find((y: any) => y._id === x)
        return ansData?.isOptional
      })

      setMandatoryAnswersScored(_mandatoryAnswersScored)
      setOptionalAnswersScored(_optionalAnswersScored)

      setMarkersComment(question.userAnswer.markersComment)

      setOptionalAnswersEnabled(
        question.userAnswer.optionalAnswersEnabled ?? [],
      )

      // Set the scored marks to what the user has scored
      if (question.userAnswer.score) {
        setScoredMarks(question.userAnswer.score)
      }
      if (question.userAnswer.scaledScore) {
        setScoredScaledMarks(question.userAnswer.scaledScore * 100)
      }

      setQuestion(question)
      setQuestionContent(question.question)

      setMandatoryAnswers(question.answers.filter((x: any) => !x.isOptional))
      setOptionalAnswers(question.answers.filter((x: any) => x.isOptional))
      setQuestionUserAnswer(question!.userAnswer.answer ?? "")
      setStartTime(new Date(question.userAnswer.startTime))
      if (question.userAnswer.totalTime) {
        setTotalSeconds(question.userAnswer.totalTime)
      }

      setIsTranscribing(question.userAnswer.isTranscribing)

      if (question.userAnswer.voiceRecordingId) {
        const voiceRecording = await viewVoiceFile(
          question.userAnswer.voiceRecordingId,
        )

        if (voiceRecording) {
          setVoiceRecordingId(question.userAnswer.voiceRecordingId)
          setPreLoadedVoiceBlob(voiceRecording)
        }
      }

      if (question.userAnswer.hasTranscription) {
        setHasTranscription(question.userAnswer.hasTranscription)
      } else {
        setHasTranscription(false)
      }
      if (question.userAnswer.transcription) {
        setTranscription(question.userAnswer.transcription)
      } else {
        setTranscription(null)
      }

      if (question.userAnswer.aiResponse) {
        setAiResponse(question.userAnswer.aiResponse)
      } else {
        setAiResponse(null)
      }

      if (question.userAnswer.isAiMarking === true) {
        setIsAiMarking(question.userAnswer.isAiMarking)
      } else {
        setIsAiMarking(false)
      }

      // Polling side
      if (
        question.userAnswer.isAiMarking ||
        question.userAnswer.isTranscribing
      ) {
        // This will initiate our poll
        setSoftResetPollDelay(SOFT_REFRESH_POLL_DELAY)
      } else {
        // Now we need to null the poll delay (if it's already null, nothing will happen)
        setSoftResetPollDelay(null)

        // We're also now in a state where we are not marking/transcribing, therefore we should reload the quiz data
        // to refresh our total score.
        await loadQuizData()
      }
    } else {
      // TODO: Show error
    }
  }

  const getCurrentQuestion = async () => {
    let question = await currentQuestion(quizId!, false)
    await setQuestionOnPage(question)

    return question
  }

  const getQuestionByNumber = async (questionNo: number) => {
    let questionData = await getSpecificQuestion(quizId!, questionNo)
    await setQuestionOnPage(questionData, questionNo)

    return questionData
  }

  const getPrevQuestion = async () => {
    setLoading(true)
    const newQuestionNumber = question!.currentQuestionNumber - 1
    await getQuestionByNumber(newQuestionNumber)
    setLoading(false)
  }

  const getNextQuestion = async () => {
    setLoading(true)
    const newQuestionNumber = question!.currentQuestionNumber + 1
    await getQuestionByNumber(newQuestionNumber)
    setLoading(false)
  }

  const getCheckedAnswersAsObjectIdArray = () => {
    return mandatoryAnswersScored.concat(optionalAnswersScored)
  }

  const calculateMarksAndTotalMarks = () => {
    if (!question) return // If we don't have a question, don't calculate anything

    // This flatmap gets rid of our header sectioning on answers.
    const questionAnswers = question!.answers.flatMap((x: any) => x)

    let totalMarks = 0
    let scoredMarks = 0
    for (let answer of mandatoryAnswers) {
      totalMarks += parseInt(answer.marks)
      if (mandatoryAnswersScored.includes(answer._id)) {
        scoredMarks += parseInt(answer.marks)
      }
    }

    for (let answer of optionalAnswersEnabled) {
      const answerData = questionAnswers.find((x: any) => x._id === answer)
      if (answerData) {
        totalMarks += parseInt(answerData.marks)

        if (optionalAnswersScored.includes(answer)) {
          scoredMarks += parseInt(answerData.marks)
        }
      }
    }

    setScoredMarks(scoredMarks)
    setScoredScaledMarks((scoredMarks / totalMarks) * 100)
  }

  useEffect(calculateMarksAndTotalMarks, [
    mandatoryAnswers,
    mandatoryAnswersScored,
    optionalAnswersScored,
    optionalAnswersEnabled,
    question,
  ])

  const saveMarks = async () => {
    calculateMarksAndTotalMarks()

    setSaveMarksIsLoading(true)

    const selectedAnswers = getCheckedAnswersAsObjectIdArray()

    if (
      (await markQuestion(
        quizId!,
        question!.id,
        selectedAnswers,
        markersComment,
        optionalAnswersEnabled,
      )) === true
    ) {
      // Run our save marks extension if it exists
      if (saveMarksExt) {
        await saveMarksExt()
      }

      // set the current answer
      const checkedAnswers = getCheckedAnswersAsObjectIdArray()
      setQuestion((prev: any) => {
        return {
          ...prev,
          userAnswer: {
            ...prev.userAnswer,
            answersScored: checkedAnswers,
            optionalAnswersEnabled: optionalAnswersEnabled,
            score: scoredMarks,
          },
        }
      })

      // Refreshes general quiz data
      await loadQuizData()
    }

    setSaveMarksIsLoading(false)
  }

  const onMarkersCommentChange = (e: any) => {
    setMarkersComment(e.target.value)

    if (e.target.value !== question!.userAnswer?.markersComment) {
      setCanSave(true)
    }
  }

  useEffect(() => {
    if (question) {
      const originalAnswersScored = question?.userAnswer?.answersScored
      const selectedAnswers = getCheckedAnswersAsObjectIdArray()

      setCanSave(false)
      setShowUnsavedChangesAlert(false)

      // Compare the original answers scored to the new answers scored ignoring order
      if (
        JSON.stringify(originalAnswersScored?.sort()) !==
        JSON.stringify(selectedAnswers.sort())
      ) {
        setCanSave(true)
        setShowUnsavedChangesAlert(true)
      }

      // Compare optional answers enabled
      if (
        JSON.stringify(question.userAnswer?.optionalAnswersEnabled) !==
        JSON.stringify(optionalAnswersEnabled)
      ) {
        setCanSave(true)
        setShowUnsavedChangesAlert(true)
      }

      // Check if the user has scored any marks
      if (question.userAnswer?.score === null) {
        setCanSave(true)
      }
    }
  }, [
    mandatoryAnswersScored,
    optionalAnswersScored,
    optionalAnswersEnabled,
    question?.userAnswer?.answersScored,
  ])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      // Sets observable for quiz data & returns it for instant use
      const quizMetaData = await loadQuizData()

      if (asMarker) {
        // If an override is in place, use that instead of the current question number
        if (questionNumberOverride) {
          await getQuestionByNumber(questionNumberOverride)
        } else if (quizMetaData!.endDate) {
          // If the quiz is complete, switch to question 1
          await getQuestionByNumber(1)
        } else {
          await getCurrentQuestion()
        }
      } else {
        // If we're reviewing normally, start from 1
        await getQuestionByNumber(1)
      }
      setLoading(false)
    })()
  }, [])
  return (
    <Page showSidebar={!hideSidebar}>
      {loading ? (
        <>
          <PageLoader />
        </>
      ) : (
        <>
          <BaseModal
            title="Unsaved Changes"
            body="You have not saved these marks. Are you sure you want to switch question?"
            isOpen={unsavedChangesDisclosure.isOpen}
            onClose={unsavedChangesDisclosure.onClose}
            buttons={
              <>
                <Button
                  color="primary"
                  variant="flat"
                  onClick={unsavedChangesDisclosure.onClose}
                >
                  Cancel
                </Button>
                <Button
                  color="danger"
                  variant="solid"
                  onClick={() => {
                    unsavedChangesDisclosure.onClose()
                    setShowUnsavedChangesAlert(false)
                    if (unsavedProceedDirection === 1) {
                      getNextQuestion()
                    } else if (unsavedProceedDirection === -1) {
                      getPrevQuestion()
                    }
                  }}
                >
                  Proceed Anyway
                </Button>
              </>
            }
          />
          <PageHeader>
            {quizData?.attemptType === AttemptTypeEnum.MOCK_QUIZ && (
              <h1>{quizData!.name}</h1>
            )}
            {quizData?.attemptType === AttemptTypeEnum.QUESTION_PRACTICE && (
              <h1>Question Practice</h1>
            )}
          </PageHeader>
          <div className="page-halves">
            <div className="page-left-half w-full">
              {/* Question card */}
              <QuestionCard question={questionContent} name={question!.name} />

              {/* Answer card (only show if the written answer is not empty or no voice recording exists) */}
              {questionUserAnswer != "" &&
                typeof voiceRecordingId !== "string" && (
                  <QuestionAnswerReview answer={questionUserAnswer} />
                )}

              {/* Voice recording card */}
              <ViewVoiceRecordedAnswer
                voiceRecordingId={voiceRecordingId}
                voiceRecordingBlob={preLoadedVoiceBlob}
                setVoiceRecordingId={setVoiceRecordingId}
                setVoiceRecordingBlob={setPreLoadedVoiceBlob}
              />

              {/* AI Virtual Patient answer card */}
              {question!.isAiChatBotQuestion &&
                question?.userAnswer?.aiChatbotSessionIds &&
                question.userAnswer.aiChatbotSessionIds.length > 0 && (
                  <>
                    <AiChatbox
                      readOnly={true}
                      sessionId={
                        question.userAnswer.aiChatbotSessionIds[
                          question.userAnswer.aiChatbotSessionIds.length - 1
                        ]
                      }
                    />
                    <Spacer y={2} />
                  </>
                )}

              {/* Mark with AI card (only show if no AI response/transcription exists and neither is in progress) */}
              {typeof aiResponse !== "string" &&
              (typeof transcription !== "string" || transcription === "") &&
              (questionUserAnswer ||
                voiceRecordingId ||
                (question?.isAiChatBotQuestion &&
                  question?.userAnswer?.aiChatbotSessionIds &&
                  question.userAnswer.aiChatbotSessionIds.length > 0)) &&
              !isAiMarking &&
              !isTranscribing ? (
                <MarkWithAiButtonsCard
                  isAiChatBotQuestion={question?.isAiChatBotQuestion}
                  aiChatbotSessionIds={
                    question?.userAnswer?.aiChatbotSessionIds
                  }
                  hasVoiceRecording={typeof voiceRecordingId === "string"}
                  hasWrittenAnswer={questionUserAnswer != ""}
                  onSubmitMarkVoiceRecording={async () => {
                    if (
                      typeof transcription === "string" &&
                      transcription !== ""
                    ) {
                      // We already have a transcription, so we call the mark func with the transcription
                      await queueQuestionForMarking(true)
                    } else {
                      // We don't have a transcription, so now we need to generate one
                      await queueQuestionForTranscriptionGeneration()
                    }
                  }}
                  onSubmitMarkWrittenAnswer={async () => {
                    // Queue the question for marking (without a transcription)
                    await queueQuestionForMarking(false)
                  }}
                  onSubmitMarkChatSession={async () => {
                    // Queue the question for marking (without a transcription)
                    await queueQuestionForMarking(false)
                  }}
                />
              ) : null}

              {/* View and manage transcription card */}
              <ViewAndManageTranscriptionCard
                isTranscriptionSaving={isTranscriptionSaving}
                onTranscriptionChange={onTranscriptionChange}
                onClickMarkWithAi={queueQuestionForMarking}
                aiResponse={aiResponse}
                isTranscribing={isTranscribing}
                hasTranscription={hasTranscription}
                transcriptionId={question!.userAnswer?.transcriptionId}
                transcription={transcription}
                setTranscription={setTranscription}
                voiceRecordingId={voiceRecordingId}
                voiceRecordingBlob={preLoadedVoiceBlob}
                isAiMarking={isAiMarking}
              />

              {/* AI response card */}
              <AiResponse aiResponse={aiResponse} isAiMarking={isAiMarking} />

              {!isAiMarking && (
                <>
                  <Spacer y={2} />
                  <MandatoryMarkAnswerCheckboxes
                    mandatoryAnswers={mandatoryAnswers}
                    mandatoryAnswersScored={mandatoryAnswersScored}
                    setMandatoryAnswersScored={setMandatoryAnswersScored}
                    asMarker={asMarker}
                    question={question}
                  />

                  <Spacer />

                  {optionalAnswers.length > 0 && (
                    <OptionalMarkAnswerCheckboxes
                      optionalAnswers={optionalAnswers}
                      optionalAnswersScored={optionalAnswersScored}
                      setOptionalAnswersScored={setOptionalAnswersScored}
                      asMarker={asMarker}
                      question={question}
                      optionalAnswersEnabled={optionalAnswersEnabled}
                      setOptionalAnswersEnabled={setOptionalAnswersEnabled}
                      setCanSave={setCanSave}
                    />
                  )}
                </>
              )}

              <Spacer />

              {/* Markers comments */}
              <Card shadow="none" className="in-progress-quiz-answer-card">
                <Textarea
                  label="Markers comment"
                  isDisabled={!asMarker}
                  value={markersComment}
                  onChange={onMarkersCommentChange}
                  labelPlacement="inside"
                  variant="bordered"
                  size="lg"
                  placeholder="Add a comment for the interviewee"
                  className="interview-answer-text-area"
                />
              </Card>
              <Spacer y={2} />

              {/* Quiz Links */}
              <LinksList
                hideShadow={true}
                className="quiz-links-list"
                title="Further Study"
                links={question?.associatedReadingLinks ?? []}
              />
            </div>
            <div className="page-right-half">
              <TotalMarksCard
                title="Overall Marks"
                marksObtained={
                  (((quizData!.scaledScore ?? 0) * 100).toFixed(2) ?? 0) + "%"
                }
                bracketsMarks={`(${quizData!.score ?? 0}/${
                  quizData!.totalMarks ?? 0
                })`}
              />
              <Spacer y={5} />
              {!asWidget && (
                <QuizModeTabs
                  asMarker={asMarker}
                  quizId={quizId!}
                  inProgress={false}
                />
              )}
              <Spacer y={5} />
              {showQuestionAnsweredChip ? (
                <>
                  <div className="question-user-answered-container">
                    <Chip color="success" variant="flat" size="md">
                      This question has been answered
                    </Chip>
                  </div>
                  <Spacer y={5} />
                </>
              ) : null}
              <TimerCard
                startTimeTaken={
                  typeof offsetSeconds === "number"
                    ? offsetSeconds
                    : totalSeconds > 0
                      ? totalSeconds
                      : undefined
                }
                offsetTimestamp={
                  typeof offsetSeconds !== "number" && totalSeconds === -1
                    ? startTime
                    : undefined
                }
                autostart={!asWidget && totalSeconds === -1}
                hidePause={true}
                hideReset={true}
                hidePlay={true}
                inProgress={!question!.userAnswer?.totalTime}
                expectedTime={question!.timeLimit}
              />
              <Spacer y={5} />
              <TotalMarksCard
                title="This Question"
                marksObtained={scoredScaledMarks.toFixed(2) + "%"}
              />
              <Spacer y={5} />
              <QuestionCursor
                currentQuestion={question!.currentQuestionNumber}
                totalQuestions={question!.totalQuestions}
                hideProgressBar={asWidget}
                nextQuestion={
                  asWidget
                    ? undefined
                    : showUnsavedChangesAlert
                      ? () => {
                          setUnsavedProceedDirection(1)
                          unsavedChangesDisclosure.onOpen()
                        }
                      : getNextQuestion
                }
                prevQuestion={
                  asWidget
                    ? undefined
                    : showUnsavedChangesAlert
                      ? () => {
                          setUnsavedProceedDirection(-1)
                          unsavedChangesDisclosure.onOpen()
                        }
                      : getPrevQuestion
                }
                isMarker={true}
                saveMarks={asMarker && canSave ? saveMarks : undefined}
                saveMarksIsLoading={saveMarksIsLoading}
              />
            </div>
          </div>
        </>
      )}
    </Page>
  )
}
