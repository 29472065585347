import { IQuestionCategoryLinks } from "@/api/types/question/question"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card } from "@nextui-org/react"
import { useNavigate } from "react-router"
import "./index.css"

interface Props {
  title: string
  links: IQuestionCategoryLinks[]
  hideShadow?: boolean
  className?: string
}

export default function LinksList({
  title,
  links,
  hideShadow,
  className,
}: Props) {
  return links.length > 0 ? (
    <Card
      shadow={hideShadow ? "none" : "sm"}
      className={`links-list-card ${className ?? ""}`}
    >
      <h2>{title}</h2>
      {links.map((link, index) => (
        <Button
          key={index}
          variant="ghost"
          onClick={() => {
            window.open(link.url, "_blank")
          }}
          className="links-button"
        >
          <h2>
            {link.title} <FontAwesomeIcon size="xs" icon={faChevronRight} />
          </h2>
          <p>{link.description}</p>
          <div className="link-footer"></div>
        </Button>
      ))}
    </Card>
  ) : (
    <></>
  )
}
