import { undoExpireSubscriptionForUser } from "@/api/routes/admin/user"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Tooltip, useDisclosure } from "@nextui-org/react"
import { useState } from "react"

interface Props {
  userId: string
  subscriptionId: string
  subscriptionName: string
  setSelectedTab: (tab: string) => void
}
export default function UndoExpireUserSubscriptionButton({
  userId,
  subscriptionId,
  subscriptionName,
  setSelectedTab,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isDeleting, setIsDeleting] = useState(false)

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isOpen}
        onConfirm={async () => {
          setIsDeleting(true)
          await undoExpireSubscriptionForUser(userId, subscriptionId)
          setSelectedTab("active-and-inactive")
          onClose()
          setIsDeleting(false)
        }}
        onClose={onClose}
        isLoading={isDeleting}
        title="Undo Expiry for Subscription"
        deleteTextOverride="Undo Expire"
        description={`Are you sure you want to reverse the expiry for the subscription '${subscriptionName}' for this user?`}
      />
      <Tooltip aria-label="Details" color="success" content="Undo expire">
        <Link onClick={onOpen}>
          <span className="text-lg text-success cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faArrowRotateLeft} />
          </span>
        </Link>
      </Tooltip>
    </>
  )
}
