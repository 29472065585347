import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"
import {
  CategoryAccessLevel,
  IQuestionCategoryLinks,
} from "@/api/types/question/question"

export async function addNotes(
  title: string,
  notes: string,
  links: IQuestionCategoryLinks[],
  accessLevel: CategoryAccessLevel,
  path: string[],
) {
  try {
    const res = await axios.post("/admin/question-category/notes", {
      title,
      notes,
      links,
      accessLevel,
      path,
    })

    if (res.status === 200) {
      return res.data.notesId
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateNotes(
  _id: string,
  title: string,
  notes: string,
  links: IQuestionCategoryLinks[],
  accessLevel: CategoryAccessLevel,
  path: string[],
) {
  try {
    const res = await axios.put("/admin/question-category/notes", {
      _id,
      title,
      notes,
      links,
      accessLevel,
      path,
    })

    if (res.status === 200) {
      return true
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteNotes(noteId: string, path: string[]) {
  try {
    const res = await axios.post(
      "/admin/question-category/notes/delete/" + noteId,
      {
        path,
      },
    )

    if (res.status === 200) {
      return true
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
