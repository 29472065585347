import BaseModal from "@/core/components/Modals/BaseModal"
import { isFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Spacer,
  Textarea,
  TimeInput,
  TimeInputValue,
  UseDisclosureProps,
} from "@nextui-org/react"
import { useState } from "react"
import { zeroPad } from "react-countdown"

interface Props {
  disclosure: UseDisclosureProps
  setTimeDelta: (timeDelta: number) => void
  pauseTimer: () => void
}
export default function EditTimeModal({
  disclosure,
  setTimeDelta,
  pauseTimer,
}: Props) {
  const [mins, setMins] = useState<number>(0)
  const [secs, setSecs] = useState<number>(0)

  const formValidate = () => {
    if (
      isFieldFilledOut(mins.toString()) &&
      isFieldFilledOut(secs.toString())
    ) {
      return false
    }
    return true
  }

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Set Time Taken"
      size="sm"
      body={
        <div>
          <p>Manually change the time that you have spent on this question.</p>
          <Spacer y={1} />
          <Input
            label="Mins"
            placeholder="00"
            value={zeroPad(mins)}
            onChange={(e) => {
              setMins(parseInt(e.target.value))
            }}
          />
          <Spacer y={1} />
          <Input
            label="Secs"
            value={zeroPad(secs)}
            onChange={(e) => {
              setSecs(parseInt(e.target.value))
            }}
          />
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setTimeDelta(mins + secs / 60)
              disclosure.onClose!()
            }}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
          >
            Set time taken
          </Button>
        </div>
      }
    />
  )
}
