import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react"

interface Props {
  isOpen: boolean
  onConfirm: () => Promise<void> | void
  onClose: () => void
  title?: string
  description?: string
  deleteTextOverride?: string
  isLoading?: boolean
}

export default function ConfirmDeleteModal(props: Props) {
  return (
    <Modal isOpen={props.isOpen}>
      <ModalContent>
        <ModalHeader>
          {!props.title ? "Confirm delete" : props.title}
        </ModalHeader>
        <ModalBody>
          <span>
            {!props.description
              ? "Are you sure you want to delete this? This action cannot be reversed."
              : props.description}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color="default" variant="light" onPress={props.onClose}>
            Cancel
          </Button>
          <Button
            isLoading={props.isLoading}
            color="danger"
            onPress={props.onConfirm}
          >
            {props.deleteTextOverride ?? "Delete"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
