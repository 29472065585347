import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react"

import "./index.css"

interface Props {
  isOpen: boolean
  onClose: () => void
  title?: string
  body?: React.ReactNode
  buttons?: React.ReactNode
  children?: React.ReactNode
  onOpenChange?: () => void
  className?: string
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "full"
}

export default function BaseModal(props: Props) {
  return (
    <Modal
      size={props.size}
      aria-labelledby={props.title ?? "Modal"}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpenChange={props.onOpenChange ?? undefined}
      className={`${props.className ?? ""}`}
    >
      <ModalContent className="base-modal">
        {props.children ? (
          props.children
        ) : (
          <>
            {props.title ? <ModalHeader>{props.title}</ModalHeader> : null}
            <ModalBody>{props.body ?? null}</ModalBody>
            <ModalFooter>{props.buttons ?? null}</ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
