import { Card, Chip } from "@nextui-org/react"
import "./index.css"

interface Props {
  name: string
  value: string
}

export default function MySubscriptionWidgetFeatureRow({ name, value }: Props) {
  return (
    <div className="subscription-feature-container">
      <span>{name}</span>
      <Chip color="primary" variant="bordered" className="">
        <span>{value}</span>
      </Chip>
    </div>
  )
}
