import "./index.css"

interface Props {
  children: React.ReactNode
}

export default function WidgetRoot({ children }: Props) {
  return <>{children}</>
}

export function WidgetGrid({ children }: Props) {
  return <div className="widget-grid">{children}</div>
}
