import { PageLoader } from "@/components/PageLoader"
import BaseModal from "@/core/components/Modals/BaseModal"
import { Card, Spacer, UseDisclosureProps } from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"
import { IQuestionCategoryVideo } from "@/api/types/question/question"
import { getVideoRoute } from "@/api/routes/admin/question-category/video"
import { Stream } from "@cloudflare/stream-react"
import PageHeader from "@/core/components/Page/PageHeader"
import LinksList from "@/core/components/LinksList"

interface Props {
  currentCategoryPath: string[]
  disclosure: UseDisclosureProps
  videoId: string | undefined
  onModalClose?: () => void | undefined
}

enum VideoType {
  CLOUDFLARE = "CLOUDFLARE",
  YOUTUBE = "YOUTUBE",
  URL = "URL",
}

export default function ViewVideoModal({
  disclosure,
  currentCategoryPath,
  videoId,
  onModalClose,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [video, setVideo] = useState<IQuestionCategoryVideo>()
  const [videoUrl, setVideoUrl] = useState<string>()
  const [videoType, setVideoType] = useState<VideoType>()

  useEffect(() => {
    // reload notes when open state changes
    ;(async () => {
      setVideo(undefined)
      setVideoUrl(undefined)
      setVideoType(undefined)

      if (!videoId) return

      setIsLoading(true)

      const video = await getVideoRoute(videoId, currentCategoryPath)
      if (video) {
        let videoType

        if (video.url.startsWith("CLOUDFLARE_")) {
          setVideoUrl(video.url.replace("CLOUDFLARE_", ""))
          videoType = VideoType.CLOUDFLARE
        } else if (
          video.url.includes("youtube.com") ||
          video.url.includes("youtu.be")
        ) {
          setVideoUrl(video.url)
          videoType = VideoType.YOUTUBE
        } else {
          setVideoUrl(video.url)
          videoType = VideoType.URL
        }

        setVideoType(videoType)
        setVideo(video)

        if (videoType !== VideoType.CLOUDFLARE) {
          setIsLoading(false)
        }
      }
    })()
  }, [disclosure.isOpen, videoId, currentCategoryPath])

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={() => {
        if (typeof onModalClose === "function") {
          onModalClose()
        }
        if (disclosure.onClose) {
          disclosure.onClose()
        }
      }}
      title={"Video Learning"}
      size="full"
      body={
        video && video.url && video.url !== "" && videoUrl ? (
          <div className="video-modal-content">
            <Spacer y={2} />
            {isLoading && <PageLoader fullScreen={true} />}
            <div className="video-modal">
              <div className="video-container">
                <div className="video-header">
                  <div className="inner-video-header">
                    <h1>{video?.title}</h1>
                    <p>{video?.description}</p>
                  </div>
                </div>

                {videoUrl ? (
                  <Card className="video-card">
                    {videoType === VideoType.CLOUDFLARE ? (
                      <Stream
                        responsive={true}
                        onLoadedData={() => {
                          setIsLoading(false)
                        }}
                        controls
                        src={videoUrl}
                      />
                    ) : videoType === VideoType.YOUTUBE ? (
                      <iframe
                        className="video-widget-video-embed"
                        style={{ height: 500 }}
                        src={videoUrl}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    ) : videoType === VideoType.URL ? (
                      <video
                        src={videoUrl}
                        controls
                        className="video-widget-video-embed"
                      />
                    ) : (
                      <p className="video-not-supported">
                        Still processing video, try again later (if this
                        persists, please contact our support).
                      </p>
                    )}
                  </Card>
                ) : (
                  "No video set..."
                )}
                <Spacer y={2} />
                <LinksList title="Further Study" links={video?.links ?? []} />
              </div>
            </div>
          </div>
        ) : (
          <PageLoader />
        )
      }
      buttons={<div className="add-video-modal-buttons"></div>}
    />
  )
}
