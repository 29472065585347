import { Avatar, Card, Image } from "@nextui-org/react"
import ai from "../../../../assets/images/ai.png"
import "./index.css"
import TypingDots from "../TypingDots"

interface Props {
  isReceivedMessage: boolean
  aiIsReceiver: boolean
  message: string
  avatar: string
  isTyping?: boolean
}
export default function ChatboxLine(props: Props) {
  const normalMessage = (
    <>
      {props.aiIsReceiver && props.isReceivedMessage && (
        <Image alt="AI logo" height={20} radius="full" src={ai} width={20} />
      )}
      <Card
        className={`response-card ${props.isReceivedMessage ? "received-message-response-card" : "sent-message-response-card"}`}
        shadow="none"
      >
        {props.message}
      </Card>
      {!props.isReceivedMessage && (
        <Avatar name={props.avatar} className="chat-avatar" />
      )}
    </>
  )

  const typingMessage = (
    <>
      <Image alt="AI logo" height={20} radius="full" src={ai} width={20} />
      <TypingDots className="received-message-response-card" />
    </>
  )

  return (
    <div className="chat-response-line">
      {props.isTyping ? typingMessage : normalMessage}
    </div>
  )
}
