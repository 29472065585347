import Page from "@/core/components/Page"
import {
  Button,
  Input,
  Link,
  Select,
  SelectItem,
  Selection,
} from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import "./index.css"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import { Key, useEffect, useState } from "react"
import { createUser } from "@/api/routes/admin/user"
import { isFieldFilledOut } from "@/core/helpers/utils"

const roles = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Content Creator",
    value: "content-creator",
  },
  {
    label: "Tutor",
    value: "tutor",
  },
  {
    label: "User",
    value: "user",
  },
]

export default function CreateUserPage() {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [userRole, setUserRole] = useState<Selection>(new Set([]))

  const buildAndSendUser = async () => {
    setIsLoading(true)

    const user = {
      firstName,
      lastName,
      email,
      role: [...userRole].join(""),
    }

    await createUser(user)

    navigate(-1)
  }

  const formValidate = () => {
    if (
      isFieldFilledOut(firstName) &&
      isFieldFilledOut(lastName) &&
      isFieldFilledOut(email) &&
      isFieldFilledOut([...userRole].join(""))
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      <PageHeader>
        <h1>Create User</h1>
      </PageHeader>
      <div className="page-grid">
        <div className="input-wrapper row row-gap-10">
          <Input
            variant="bordered"
            type="text"
            label="First Name"
            placeholder="Enter the first name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            variant="bordered"
            type="text"
            label="Last Name"
            placeholder="Enter the last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <Input
            variant="bordered"
            type="email"
            label="Email"
            placeholder="Enter the email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <Select
            variant="bordered"
            selectedKeys={userRole}
            onSelectionChange={setUserRole}
            label="Select a role"
            className="max-w-xs"
          >
            {roles.map((role) => (
              <SelectItem key={role.value} value={role.value}>
                {role.label}
              </SelectItem>
            ))}
          </Select>
        </div>

        <div className="input-wrapper create-question-wrapper">
          <Button
            onClick={buildAndSendUser}
            variant="solid"
            color="primary"
            isLoading={isLoading}
            isDisabled={formValidate()}
          >
            Create User
          </Button>
        </div>
      </div>
    </Page>
  )
}
