import {
  IAnswerMetaData,
  IInProgressQuizQuestion,
} from "@/api/types/question/question"
import FullAnswerCheckbox from "@/pages/QuizAttempt/components/FullAnswerCheckbox"
import { Card, CheckboxGroup } from "@nextui-org/react"

interface Props {
  mandatoryAnswers: any
  mandatoryAnswersScored: any
  setMandatoryAnswersScored: React.Dispatch<any>
  asMarker: boolean
  question: IInProgressQuizQuestion | null
}

export default function MandatoryMarkAnswerCheckboxes({
  mandatoryAnswers,
  mandatoryAnswersScored,
  setMandatoryAnswersScored,
  asMarker,
  question,
}: Props) {
  return (
    <Card shadow="none" className="in-progress-quiz-answer-card">
      <CheckboxGroup
        label="Select the answers scored by the interviewee"
        color="success"
        value={mandatoryAnswers
          .map((x: any, i: number) =>
            mandatoryAnswersScored.includes(x._id) ? i.toString() : null,
          )
          .filter((x: any) => x !== null)}
        onChange={
          asMarker
            ? (scoredIndexes: any) => {
                const scoredIds = scoredIndexes.map(
                  (x: any) => mandatoryAnswers[x]._id,
                )
                setMandatoryAnswersScored(scoredIds)
              }
            : () => {}
        }
        classNames={{
          base: "w-full " + asMarker ? "" : "checkbox-silent-disabled",
        }}
      >
        {mandatoryAnswers.map(
          (answerMetaData: IAnswerMetaData, index: number) => (
            <div key={question!.id + answerMetaData.answer + index.toString()}>
              {answerMetaData.heading && <h2>{answerMetaData.heading}</h2>}
              <FullAnswerCheckbox
                isSelected={mandatoryAnswersScored.includes(answerMetaData._id)}
                asMarker={asMarker}
                answer={answerMetaData.answer}
                index={index}
                marksToAdd={answerMetaData.marks}
                totalMarks={question!.marks}
              />
            </div>
          ),
        )}
      </CheckboxGroup>
    </Card>
  )
}
