import { Button, Card, Chip, useDisclosure } from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import CustomSelectSearchModal from "../../../../components/CustomSelectSearchModal"
import { listCategories } from "@/api/routes/category-bank"

interface Props {
  selectedCategories?: any[]
  setSelectedCategories?: (val: any[]) => void
  addCategoryButtonString?: string
}

export default function CategoryInput(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  async function fetchCategories(
    setSearchResults: (val: any) => void,
    name?: string,
  ) {
    const categories = await listCategories(name, 5)
    if (categories) {
      setSearchResults(categories)
    }
  }

  return (
    <div className="category-input-chips">
      {props.selectedCategories &&
        props.selectedCategories.map((category, index) => (
          <Chip key={index} variant="faded" color="primary">
            {category.name}
          </Chip>
        ))}
      <Button
        variant="bordered"
        radius="full"
        color="primary"
        fullWidth={false}
        className="category-input"
        key={0}
        onClick={onOpen}
      >
        <FontAwesomeIcon className="add-category-icon" icon={faPlus} />
        <span className="add-category-text">
          {props.addCategoryButtonString ?? "Add Category"}
        </span>
      </Button>
      <CustomSelectSearchModal
        isOpen={isOpen}
        onClose={onClose}
        fetchData={fetchCategories}
        selectedData={props.selectedCategories}
        setSelectedData={props.setSelectedCategories}
        modalTitle="Select Categories"
        searchLabel="Search Categories"
        searchPlaceholder="Search for a category..."
      />
    </div>
  )
}

/*
  const color = "--medcamps-primary-100"
    <Card
      fullWidth={false}
      shadow="none"
      className="category-card"
      key={0}
      style={{ backgroundColor: "hsl(var(" + color + "))" }}
    >
      <h1>Test Name</h1>
      <p>Test Desc</p>
    </Card>
*/
