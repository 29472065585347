import Page from "@/core/components/Page"
import "./index.css"
import { useNavigate, useParams } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Button, Spacer } from "@nextui-org/react"
import Status from "@/components/Status"

export default function QuizCompletePage() {
  const { quizId } = useParams()
  const navigate = useNavigate()

  return (
    <Page showSidebar={false}>
      <Status
        icon={faCheckCircle}
        color="hsl(var(--medcamps-success-500))"
        title="Interview Complete"
        subText=""
        footer={[
          <Button
            onClick={() => {
              navigate("/Interviews")
            }}
            variant="bordered"
            color="default"
          >
            {"<"} Back to Interview List
          </Button>,
          <Button
            onClick={() => {
              navigate(`/Quiz/Review/${quizId}`)
            }}
            className="quiz-complete-button"
            variant="solid"
            color="primary"
          >
            Review or Mark Interview
          </Button>,
        ]}
      />
    </Page>
  )
}
