import { AnswerCheckBox } from "../AnswerCheckbox"

interface Props {
  onChange?: (isSelected: boolean) => void
  isSelected: boolean
  asMarker: boolean
  answer: string
  index: number
  marksToAdd: string
  totalMarks: number
}
export default function FullAnswerCheckbox(props: Props) {
  return (
    <AnswerCheckBox
      answer={props.answer}
      value={props.index}
      className={props.asMarker ? "" : "checkbox-silent-disabled"}
      onChange={(isSelected: boolean) => {
        if (!props.asMarker) return
        if (typeof props.onChange === "function") props.onChange(isSelected)
      }}
    />
  )
}
