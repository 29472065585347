import Page from "@/core/components/Page"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import "./index.css"
import { Button, Card } from "@nextui-org/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons"
import Status from "@/components/Status"

export default function CheckoutPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (searchParams.get("success") === "true") {
      setShowSuccess(true)
      setShowError(false)
    } else {
      setShowError(true)
      setShowSuccess(false)
    }
  }, [searchParams])

  return (
    <Page showSidebar={false}>
      {showSuccess && (
        <Status
          icon={faCheckCircle}
          color={"hsl(var(--medcamps-success-500))"}
          title="Payment approved"
          subText={
            <span>
              Your payment has succeeded, please log in to access the benefits
              included in your subscription.
              <br />
              <i>
                (If you did not have an account prior to checkout, press the
                link sent to your email to complete your registration)
              </i>
            </span>
          }
          footer={
            <Button
              onClick={() => {
                navigate("/Home")
              }}
              variant="solid"
              color="primary"
            >
              Continue <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          }
        />
      )}
      {showError && (
        <Status
          icon={faCircleXmark}
          color="hsl(var(--medcamps-danger-500))"
          title="Payment failed"
          subText={<span>Your payment has failed, please try again.</span>}
          footer={
            <Button
              onClick={() => {
                window.location.href = import.meta.env.VITE_MAIN_SITE_URL
              }}
              variant="solid"
              color="primary"
            >
              Try Again <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          }
        />
      )}
    </Page>
  )
}
