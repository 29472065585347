import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Link,
  Switch,
} from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import ListInput from "../../components/ListInput"
import LanguageTextArea from "../../components/LanguageTextArea"
import {
  isFieldFilledOut,
  isLanguageFieldFilledOut,
} from "@/core/helpers/utils"
interface Props {
  type: string
  label: string
  placeholder: string

  observable: any
  setObservable: (val: any) => void

  addNewText?: string
}

export const validateAnswers = (answers: any) => {
  if (answers.length === 0) return false
  for (let answer of answers) {
    if (
      !(
        isFieldFilledOut(answer.header) &&
        isFieldFilledOut(answer.marks) &&
        isLanguageFieldFilledOut(answer.answer)
      )
    ) {
      return false
    }
  }

  return true
}

export default function AnswerInput({
  type,
  label,
  placeholder,
  observable,
  setObservable,
  addNewText,
}: Props) {
  const addItem = () => {
    setObservable((curr: any) => [
      ...curr,
      {
        header: "",
        marks: null,
        answer: [],
        isOptional: false,
      },
    ])
  }

  const removeItem = (itemIndex: number) => {
    setObservable((obs: any) => {
      let clonedObs = [...obs]
      clonedObs.splice(itemIndex, 1)
      return clonedObs
    })
  }

  const editItem = (index: any, key: string, value: any) => {
    setObservable((obs: any) => {
      let clonedObs = [...obs]
      clonedObs[index][key] = value
      return clonedObs
    })
  }

  const drawInput = (item: any, index: any): React.ReactNode => {
    return (
      <div key={index} className="answers-input">
        {/* Header title */}
        <div className="input-wrapper">
          <Input
            value={item.header}
            variant="bordered"
            onChange={(e: any) => {
              editItem(index, "header", e.target.value)
            }}
            placeholder="Enter the header"
          />
        </div>

        {/* Answers */}
        <div className="input-wrapper">
          <LanguageTextArea
            editorKey={"answer-" + index}
            observable={item.answer}
            setObservable={(val: any) =>
              editItem(index, "answer", val(item.answer))
            }
            type="text"
            label="Answer"
            placeholder="Enter the question answer"
            disableRichText={true}
          />
        </div>

        {/* Marks */}
        <div className="input-wrapper">
          <Input
            type="number"
            variant="bordered"
            value={item.marks}
            onChange={(e: any) => {
              editItem(index, "marks", e.target.value)
            }}
            label="Answer Marks"
            placeholder="Enter the marks"
          />
        </div>

        {/* Is Optional */}
        <div className="input-wrapper">
          <Switch
            isSelected={item.isOptional}
            onValueChange={(val: any) => {
              if (val === true) {
                // Set marks to 0 if the answer is optional
                editItem(index, "marks", 0)
              }
              editItem(index, "isOptional", val)
            }}
          >
            Is Optional
          </Switch>
        </div>

        <div className="delete-answers-container">
          <Button
            color="danger"
            variant="flat"
            className="link delete-answers"
            onClick={() => removeItem(index)}
          >
            Delete Answer
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <ListInput
        label={label}
        observable={observable}
        addItem={addItem}
        removeItem={removeItem}
        drawInput={drawInput}
        addNewText={addNewText ?? "+ Add Answer"}
      />
    </>
  )
}
