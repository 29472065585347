import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Divider,
  Image,
  Input,
  Spinner,
} from "@nextui-org/react"
import ai from "../../assets/images/ai.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircle,
  faMicrophone,
  faPaperPlane,
  faPause,
  faPlay,
  faSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { useAppSelector } from "@/app/hooks"
import "./index.css"
import ChatboxLine from "./components/ChatboxLine"
import { useCallback, useEffect, useRef, useState } from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { useAudioRecorder } from "react-audio-voice-recorder"
import { transcribeQuestionAnswerRoute } from "@/api/routes/quiz/quiz"
import { uploadVoice } from "@/api/routes/voice/voice"

interface Props {
  sessionId: string
  readOnly?: boolean
}

interface Message {
  isTyping?: boolean
  isReceivedMessage: boolean
  aiIsReceiver: boolean
  message: string
  avatar: string
}

export default function AiChatbox(props: Props) {
  const [typedMessage, setTypedMessage] = useState<string>("")
  const [aiTyping, setAiTyping] = useState<boolean>(false)

  const [socketUrl, setSocketUrl] = useState(import.meta.env.VITE_SOCKET_URL)
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)

  const getHeaders = () => {
    return {
      auth: "Bearer " + localStorage.getItem("auth-token"),
      route: "ai-chat",
    }
  }

  const sendFormattedMessage = (args: any) => {
    sendMessage(
      JSON.stringify({
        ...getHeaders(),
        args: {
          ...args,
          sessionId: props.sessionId,
        },
      }),
    )
  }

  const handleSyncedMessage = async (message: any) => {
    const currentStatus = message?.status ?? ""

    // Update our message history
    setMessages(
      (prevMessages) =>
        message?.history?.map(
          (x: any) =>
            ({
              isReceivedMessage: x.from === "ai",
              aiIsReceiver: true,
              message: x.message,
              avatar: "",
            }) as Message,
        ) ?? [],
    )

    // Handle status changes and typing status changes
    if (
      currentStatus === "INITIALISE" ||
      currentStatus === "WAITING_TO_SEND_TO_AI"
    ) {
      setAiTyping(true)
      sendFormattedMessage({ initialise: true, sync: true })
    } else if (currentStatus === "WAITING_ON_USER") {
      setAiTyping(false)
    }
  }

  useEffect(() => {
    const message = JSON.parse(lastMessage?.data ?? "{}")
    console.log("Message received", message)

    if (message?.action === "SYNCED") {
      handleSyncedMessage(message.data)
    } else if (message?.action === "TRANSCRIPTION_GENERATED") {
      setTypedMessage(message?.data?.text ?? "")
      setIsTranscribing(false)
    }
  }, [lastMessage])

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendFormattedMessage({ sync: true })
    }
  }, [readyState])

  const [messages, setMessages] = useState<Message[]>([])

  useEffect(() => {
    if (aiTyping) {
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages.filter((x) => !x.isTyping),
          {
            isReceivedMessage: true,
            aiIsReceiver: true,
            message: "",
            avatar: "",
            isTyping: true,
          },
        ])
      }, 200)
    } else {
      setMessages((prevMessages) => [
        ...prevMessages.filter((x) => !x.isTyping),
      ])
    }
  }, [aiTyping])

  const sendMessageToAi = () => {
    if (!typedMessage || typedMessage === "") return

    sendFormattedMessage({ message: typedMessage, sync: true })

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        isReceivedMessage: false,
        aiIsReceiver: false,
        message: typedMessage,
        avatar: "",
      },
    ])
    setTypedMessage("")
    setAiTyping(true)
  }

  const messagesAreaRef = useRef<null | HTMLDivElement>(null)
  useEffect(() => {
    if (messagesAreaRef.current) {
      messagesAreaRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [messages])

  const [discardRecording, setDiscardRecording] = useState(false)
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder()

  const [isTranscribing, setIsTranscribing] = useState(false)

  const stopRecordingAndTranscribe = async () => {
    stopRecording()
    setIsTranscribing(true)
  }

  const stopRecordingAndDiscard = () => {
    setDiscardRecording(true)
    stopRecording()
  }

  useEffect(() => {
    ;(async () => {
      if (recordingBlob && !discardRecording) {
        // Send the recording to the AI
        const voiceRecordingId = await uploadVoice(
          new File([recordingBlob], "voice-upload"),
        )

        sendFormattedMessage({
          transcribe: true,
          voiceRecordingId,
        })
      } else if (discardRecording) {
        setDiscardRecording(false)
      }
    })()
  }, [recordingBlob])

  return (
    <Card className="min-h-[600px]">
      <CardHeader className="flex gap-3">
        <Image alt="nextui logo" height={40} radius="sm" src={ai} width={40} />
        <div className="flex flex-col">
          <p className="text-md">AI Virtual Patient</p>
        </div>
      </CardHeader>
      <Divider />
      <CardBody className="chatbox-body flex flex-col">
        <div className="flex flex-col">
          {messages.map((message, index) => (
            <ChatboxLine
              key={index}
              isReceivedMessage={message.isReceivedMessage}
              aiIsReceiver={message.aiIsReceiver}
              message={message.message}
              avatar={message.avatar}
              isTyping={message.isTyping ?? false}
            />
          ))}
          <div
            style={{ float: "left", clear: "both" }}
            ref={messagesAreaRef}
          ></div>
        </div>
      </CardBody>
      {!props.readOnly && (
        <>
          <Divider />
          <CardFooter>
            <Input
              type="text"
              placeholder={
                isTranscribing
                  ? "Transcribing..."
                  : isRecording
                    ? "Listening..."
                    : "Type a message..."
              }
              labelPlacement="outside"
              isDisabled={aiTyping}
              value={!isRecording && !isTranscribing ? typedMessage : ""}
              onChange={(e) =>
                !isRecording &&
                !isTranscribing &&
                setTypedMessage(e.target.value)
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMessageToAi()
                }
              }}
              endContent={
                <>
                  {isRecording && (
                    <>
                      <Chip variant="light" className="inline-recorder-timer">
                        {recordingTime} sec
                      </Chip>

                      <Button
                        variant="light"
                        size="md"
                        radius="full"
                        className="chatbox-button-icon"
                        isIconOnly={true}
                        onClick={togglePauseResume}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          icon={isPaused ? faPlay : faPause}
                          color={
                            isPaused
                              ? "hsl(var(--medcamps-success-500))"
                              : "hsl(var(--medcamps-warning-500))"
                          }
                        />
                      </Button>

                      <Button
                        variant="light"
                        size="md"
                        radius="full"
                        className="chatbox-button-icon"
                        isIconOnly={true}
                        onClick={stopRecordingAndTranscribe}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          icon={faSquare}
                          color="red"
                        />
                      </Button>

                      <Button
                        variant="light"
                        size="md"
                        radius="full"
                        className="chatbox-button-icon"
                        isIconOnly={true}
                        onClick={stopRecordingAndDiscard}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          icon={faTrash}
                          color="hsl(var(--medcamps-default-600))"
                        />
                      </Button>
                    </>
                  )}
                  {!isRecording && !isTranscribing && (
                    <Button
                      className="chatbox-button-icon faded-icon"
                      isIconOnly={true}
                      variant="light"
                      radius="full"
                      size="md"
                      onClick={() => {
                        startRecording()
                      }}
                    >
                      <FontAwesomeIcon icon={faMicrophone} />
                    </Button>
                  )}

                  {isTranscribing && <Spinner size="sm" color="primary" />}
                </>
              }
            />

            <Button
              className="chatbox-send-icon"
              isIconOnly={true}
              variant="light"
              radius="full"
              size="md"
              color="primary"
              isDisabled={aiTyping || isTranscribing}
              onClick={sendMessageToAi}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </CardFooter>
        </>
      )}
    </Card>
  )
}
