import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { useLocation } from "react-router"
import loadUserProfile from "@/core/helpers/load-user-profile"

// Ensures that any global states are restored with the latest version every refresh
export default function UserProfileWatch() {
  const { user } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  let location = useLocation()

  useEffect(() => {
    if (user) {
      loadUserProfile(dispatch, false)
    }
  }, [location])

  return <div></div>
}
