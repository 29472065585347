import { updateNotes } from "@/api/routes/admin/question-category/notes"
import { getNoteByIdAndPath } from "@/api/routes/category-bank/notes"
import { PageLoader } from "@/components/PageLoader"
import BaseModal from "@/core/components/Modals/BaseModal"
import RichTextArea from "@/core/components/RichTextArea"
import { isFieldFilledOut, isSetFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Spacer,
  UseDisclosureProps,
  useDisclosure,
} from "@nextui-org/react"
import { Key, useEffect, useState } from "react"
import TableSelectInput from "../../TableSelectInput"
import AddLinkModal from "./AddLinkModal"
import EditLinkModal from "./EditLinkModal"
import { CategoryAccessLevelString } from "@/api/types/question/question"
import { CategoryAccessLevel } from "@/api/types/question/question"
import SelectFromEnums from "@/core/components/SelectFromEnums"

interface Props {
  currentCategoryPath: string[]
  setSubCategories: React.Dispatch<React.SetStateAction<any[]>>
  disclosure: UseDisclosureProps
  index: number
  notesIndex: number
  notesId: string
}
export default function EditNotesModal({
  disclosure,
  currentCategoryPath,
  setSubCategories,
  index,
  notesIndex,
  notesId,
}: Props) {
  const [title, setTitle] = useState("")
  const [notes, setNotes] = useState("")
  const [initialNotes, setInitialNotes] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [editLinksIndex, setEditLinksIndex] = useState(-1)

  const [selectedLinks, setSelectedLinks] = useState<any>([])
  const [accessLevel, setAccessLevel] = useState<any>(new Set([]))
  const addLinkModalDisclosure = useDisclosure()
  const editLinkModalDisclosure = useDisclosure()

  const formValidate = () => {
    if (
      isFieldFilledOut(title) &&
      isFieldFilledOut(notes) &&
      isSetFieldFilledOut(accessLevel)
    ) {
      return false
    }
    return true
  }

  const closeModal = () => {
    setTitle("")
    setNotes("")
    setInitialNotes("")
    disclosure.onClose!()
  }

  const internalAddSubCategoryNotes = async () => {
    setIsSaving(true)
    await updateNotes(
      notesId,
      title,
      notes,
      selectedLinks.map((x: any) => ({
        _id: x._id ?? null,
        title: x.Title,
        url: x.URL,
        description: x.Description,
      })),
      parseInt([...accessLevel].join("")),
      currentCategoryPath,
    )

    setSubCategories((prev) => {
      let next = [...prev]

      next[index].notes[notesIndex] = {
        _id: notesId,
        title,
        notes,
        links: selectedLinks,
        accessLevel: parseInt([...accessLevel].join("")),
      }

      return next
    })

    setIsSaving(false)
    closeModal()
  }

  useEffect(() => {
    // reload notes when open state changes
    ;(async () => {
      setIsLoading(true)
      const currentNote = await getNoteByIdAndPath(notesId, currentCategoryPath)
      setTitle(currentNote.title)
      setNotes(currentNote.notes)
      setInitialNotes(currentNote.notes)
      setSelectedLinks(
        currentNote.links?.map((x: any) => ({
          _id: x._id,
          Title: x.title,
          URL: x.url,
          Description: x.description,
        })) ?? [],
      )
      setAccessLevel(new Set<Key>([currentNote.accessLevel?.toString()]))
      setIsLoading(false)
    })()
  }, [disclosure.isOpen, notesId, currentCategoryPath])

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Update Notes"
      size="full"
      body={
        isLoading ? (
          <PageLoader />
        ) : (
          <div className="edit-notes-modal-body">
            <AddLinkModal
              disclosure={addLinkModalDisclosure}
              addLink={(val: any) => {
                setSelectedLinks((prev: any) => [
                  ...prev,
                  { ...val, _id: prev.length },
                ])

                addLinkModalDisclosure.onClose()
              }}
            />
            <EditLinkModal
              disclosure={editLinkModalDisclosure}
              index={editLinksIndex}
              selectedLinks={selectedLinks}
              onEditLink={(val: any, vals: any) => {
                let newLinks = [...selectedLinks]
                newLinks[val] = vals
                setSelectedLinks(newLinks)
              }}
            />

            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              variant="bordered"
              placeholder="Title"
            />
            <Spacer y={2} />
            <RichTextArea
              height="500px"
              observable={notes ?? ""}
              onChange={(contents: string) => {
                setNotes(contents)
              }}
              initialValue={initialNotes ?? ""}
              label={"Notes"}
              placeholder={"Add notes here..."}
            />
            <Spacer y={2} />
            <SelectFromEnums
              label="Access Level"
              placeholder="Select who can view this content"
              selectedKeys={accessLevel}
              onSelectionChange={setAccessLevel}
              stringEnum={CategoryAccessLevelString}
              valuesEnum={CategoryAccessLevel}
            />
            <Spacer y={2} />
            <div className="input-wrapper">
              <TableSelectInput
                aria-label="Add link"
                title={"Links"}
                addNewPlaceholder="+ Add a new link"
                onAddNew={addLinkModalDisclosure.onOpen}
                onEdit={(index: number) => {
                  setEditLinksIndex(index)
                  editLinkModalDisclosure.onOpen()
                }}
                onDelete={async (_id: string) => {
                  setSelectedLinks((prev: any) =>
                    prev.filter((link: any) => link._id !== _id),
                  )
                }}
                isLoading={false}
                selectedItems={selectedLinks}
                setSelectedItems={setSelectedLinks}
                hideMovementArrows={false}
                headers={["Title", "URL"]}
              />
            </div>
          </div>
        )
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={internalAddSubCategoryNotes}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
            isLoading={isSaving}
          >
            Update Notes
          </Button>
        </div>
      }
    />
  )
}
