import {
  faBook,
  faBuilding,
  faChalkboard,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleQuestion,
  faClipboardList,
  faClipboardQuestion,
  faClose,
  faCog,
  faCreditCard,
  faGear,
  faGraduationCap,
  faHome,
  faHouse,
  faLayerGroup,
  faList,
  faNoteSticky,
  faPieChart,
  faQuestion,
  faRightFromBracket,
  faToolbox,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import Sidebar, { SidebarLink } from "@/components/Sidebar"
import SidebarContent from "@/components/Sidebar/SidebarContent/SidebarContent"
import SidebarFooter from "@/components/Sidebar/SidebarFooter"
import SidebarDropDownLink from "@/components/Sidebar/SidebarDropDownLink"
import { Button, Card, DropdownItem, Spacer } from "@nextui-org/react"
import { useAppSelector } from "@/app/hooks"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import SidebarDropdown from "@/components/Sidebar/SidebarDropdown"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

interface Props {
  openConfirmLogoutModal: () => void
  isCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  sidebarContentOverride?: React.ReactNode
}

export default function Menu({
  openConfirmLogoutModal,
  isCollapsed,
  setIsCollapsed,
  sidebarContentOverride,
}: Props) {
  const { user, tutorStudentImpersonation } = useAppSelector((state) => state)
  const userData = user.user

  const originalOnlyShowActive =
    typeof sidebarContentOverride != "undefined" &&
    sidebarContentOverride != null

  const [onlyShowActive, setOnlyShowActive] = useState<boolean>(
    originalOnlyShowActive,
  )

  const interviewsAndPerformanceLinks = (
    <>
      <SidebarLink
        title="Interviews"
        to="/Interviews"
        icon={faBook}
        onlyShowActive={onlyShowActive}
      />
      {/* Feature whitelisted to admins & specific email */}
      <SidebarLink
        title="Question Practice"
        to="/Questions"
        icon={faCircleQuestion}
        onlyShowActive={onlyShowActive}
      />
      <SidebarLink
        title="Performance"
        to="/Performance"
        icon={faPieChart}
        onlyShowActive={onlyShowActive}
      />
    </>
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(true)

  const endImpersonation = () => {
    dispatch({ type: "tutorStudentImpersonation/clear" })
    navigate("/Tutor/Students")
  }

  return (
    <Sidebar
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
      hideFooter={onlyShowActive}
      sideBarContent={
        <>
          {onlyShowActive && (
            <SidebarLink
              icon={faHome}
              title="Main Menu"
              onClick={() => setOnlyShowActive(false)}
            />
          )}
          {originalOnlyShowActive && !onlyShowActive && (
            <SidebarLink
              icon={faChevronLeft}
              title="Back"
              onClick={() => setOnlyShowActive(true)}
            />
          )}

          <SidebarLink
            title="Home"
            to="/Home"
            icon={faHouse}
            onlyShowActive={onlyShowActive}
          />

          {tutorStudentImpersonation.userId ? (
            <>
              <Spacer y={1} />
              <Card className="tutor-impersonation-window">
                <div className="tutor-impersonation-window-header">
                  <h1>{tutorStudentImpersonation.name}</h1>
                  <Button
                    onClick={endImpersonation}
                    isIconOnly={true}
                    size="sm"
                    color="danger"
                    variant="flat"
                    radius="full"
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                </div>
                <Spacer y={2} />
                {interviewsAndPerformanceLinks}
              </Card>
              <Spacer y={3} />
            </>
          ) : (
            interviewsAndPerformanceLinks
          )}

          <SidebarLink
            title="Learning"
            to="/Learning"
            icon={faBook}
            onlyShowActive={onlyShowActive}
          />

          {onlyShowActive && sidebarContentOverride}
        </>
      }
      sideBarFooter={
        <>
          {userData?.role === "tutor" || userData?.role === "admin" ? (
            <>
              <SidebarDropDownLink title="Tutor" icon={faGraduationCap}>
                <SidebarLink
                  icon={faUsers}
                  title="Manage Students"
                  to="/Tutor/Students"
                />
              </SidebarDropDownLink>
              <Spacer y={3} />
            </>
          ) : null}
          {userData?.role === "admin" ||
          userData?.role === "content-creator" ? (
            <>
              <SidebarDropDownLink title="Admin" icon={faToolbox}>
                <SidebarLink
                  icon={faLayerGroup}
                  title="Categories"
                  to="/Admin/Categories"
                />
                <SidebarLink
                  icon={faList}
                  title="Questions"
                  to="/ManageQuestions"
                />
                <SidebarLink
                  icon={faChalkboard}
                  title="Exams"
                  to="/Admin/Exams"
                />
                {userData?.role === "admin" && (
                  <>
                    <SidebarLink
                      icon={faUsers}
                      title="Users"
                      to="/Admin/Users"
                    />
                    <SidebarLink
                      icon={faClipboardList}
                      title="Subscription Plans"
                      to="/Admin/SubscriptionPlans"
                    />
                  </>
                )}
                <SidebarLink
                  icon={faBuilding}
                  title="Organisations"
                  to="/Admin/Organisations"
                />
              </SidebarDropDownLink>
            </>
          ) : null}
        </>
      }
      sideBarDropdown={[
        <DropdownItem
          key="settings"
          onClick={() => {
            navigate("/Subscriptions/Manage")
          }}
        >
          <FontAwesomeIcon style={{ marginRight: 8 }} icon={faCreditCard} />
          Subscriptions
        </DropdownItem>,
        <DropdownItem
          key="settings"
          onClick={() => {
            navigate("/AccountSettings")
          }}
        >
          <FontAwesomeIcon style={{ marginRight: 8 }} icon={faCog} />
          My Settings
        </DropdownItem>,
        <DropdownItem
          key="logout"
          color="danger"
          onClick={openConfirmLogoutModal}
        >
          <span style={{ color: "hsl(var(--medcamps-danger-500))" }}>
            <FontAwesomeIcon
              style={{ marginRight: 8 }}
              icon={faRightFromBracket}
            />
            Log Out
          </span>
        </DropdownItem>,
      ]}
    ></Sidebar>
  )
}
