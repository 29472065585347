import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"
import axios from "@/core/helpers/axios"

export async function listQuestions(
  categories: string[],
  limit: number,
  pageData: PageData,
  filters: any,
) {
  try {
    let urlParams = new URLSearchParams()
    if (pageData?.fetchMeta === true) {
      urlParams.append("meta", "1")
    }
    if (pageData?.page) {
      urlParams.append("page", pageData.page.toString())
    }
    if (pageData?.limit) {
      urlParams.append("limit", pageData.limit.toString())
    } else if (limit) {
      urlParams.append("limit", limit.toString())
    }

    const res = await axios.post(
      "/user-question-quiz/question-data-for-categories?" +
        urlParams.toString(),
      {
        categoryIds: categories,
        filters,
      },
    )

    if (res.status === 200) {
      if (pageData?.fetchMeta === true) {
        return res.data.body.total
      } else {
        return res.data.body
      }
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function startQuestionPractice(
  questionIds: string[],
  fullMarks: boolean,
) {
  try {
    const res = await axios.post("/user-question-quiz/start-quiz", {
      questionIds,
      fullMarks: fullMarks,
    })

    if (res.status === 200) {
      return res.data.body._id
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
