import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import userReducer from "@/reducers/user.reducer"
import tutorStudentImpersonationReducer from "@/reducers/tutor-impersonation.reducer"

export const store = configureStore({
  reducer: {
    user: userReducer,
    tutorStudentImpersonation: tutorStudentImpersonationReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
