import { Select, SelectItem } from "@nextui-org/react"
import { Key } from "react"

interface Props {
  selectedKeys: any
  onSelectionChange: any

  label: string
  placeholder: string

  stringEnum: any
  valuesEnum: any
}

export default function SelectFromEnums({
  selectedKeys,
  onSelectionChange,
  label,
  placeholder,
  stringEnum,
  valuesEnum,
}: Props) {
  return (
    <Select
      variant="bordered"
      label={label}
      placeholder={placeholder}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
    >
      {Object.keys(stringEnum).map((key: any) => (
        <SelectItem key={valuesEnum[key]} value={valuesEnum[key]}>
          {stringEnum[key]}
        </SelectItem>
      ))}
    </Select>
  )
}
