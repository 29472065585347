import { addSubCategory } from "@/api/routes/admin/question-category/category-bank"
import { addNotes } from "@/api/routes/admin/question-category/notes"
import {
  CategoryAccessLevel,
  CategoryAccessLevelString,
  IQuestionCategoryLinks,
  ISubQuestionCategory,
} from "@/api/types/question/question"
import BaseModal from "@/core/components/Modals/BaseModal"
import RichTextArea from "@/core/components/RichTextArea"
import { isFieldFilledOut, isSetFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Spacer,
  useDisclosure,
  UseDisclosureProps,
} from "@nextui-org/react"
import { Key, useEffect, useState } from "react"
import TableSelectInput from "../components/TableSelectInput"
import AddLinkModal from "../components/Notes/EditNotesModal/AddLinkModal"
import EditLinkModal from "../components/Notes/EditNotesModal/EditLinkModal"
import SelectFromEnums from "@/core/components/SelectFromEnums"

interface Props {
  disclosure: UseDisclosureProps
  onEditNote: (
    title: string,
    notes: string,
    links: IQuestionCategoryLinks[],
    accessLevel: any,
    index: number,
  ) => Promise<void>
  allNotes: any[]
  index: number
}
export default function EditRootNoteModal({
  disclosure,
  onEditNote,
  allNotes,
  index,
}: Props) {
  const [title, setTitle] = useState("")
  const [initialNotes, setInitialNotes] = useState("")
  const [notes, setNotes] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [editLinksIndex, setEditLinksIndex] = useState(-1)
  const [selectedLinks, setSelectedLinks] = useState<IQuestionCategoryLinks[]>(
    [],
  )
  const [accessLevel, setAccessLevel] = useState<any>(new Set([]))
  const addLinkModalDisclosure = useDisclosure()
  const editLinkModalDisclosure = useDisclosure()

  useEffect(() => {
    if (allNotes[index]) {
      setTitle(allNotes[index].title)
      setInitialNotes(allNotes[index].notes)
      setNotes(allNotes[index].notes)
      setSelectedLinks(
        allNotes[index].links?.map((x: any) => ({
          _id: x._id,
          Title: x.title,
          URL: x.url,
          Description: x.description,
        })) ?? [],
      )
      setAccessLevel(allNotes[index].accessLevel)
    }
  }, [index])

  const formValidate = () => {
    if (
      isFieldFilledOut(title) &&
      isFieldFilledOut(notes) &&
      isSetFieldFilledOut(accessLevel)
    ) {
      return false
    }
    return true
  }

  const closeModal = () => {
    disclosure.onClose!()
  }

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={closeModal}
      title="Edit Notes"
      size="full"
      body={
        <div>
          <AddLinkModal
            disclosure={addLinkModalDisclosure}
            addLink={(val: any) => {
              setSelectedLinks((prev: any) => [
                ...prev,
                { ...val, _id: prev.length },
              ])

              addLinkModalDisclosure.onClose()
            }}
          />
          <EditLinkModal
            disclosure={editLinkModalDisclosure}
            index={editLinksIndex}
            selectedLinks={selectedLinks}
            onEditLink={(val: any, vals: any) => {
              let newLinks = [...selectedLinks]
              newLinks[val] = vals
              setSelectedLinks(newLinks)
            }}
          />
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Title"
          />
          <Spacer y={2} />
          {/* force these observables to update properly */}
          <RichTextArea
            height="500px"
            observable={notes ?? ""}
            initialValue={initialNotes ?? ""}
            onChange={(contents: string) => {
              setNotes(contents)
            }}
            label={"Notes"}
            placeholder={"Add notes here..."}
          />
          <Spacer y={2} />
          <SelectFromEnums
            label="Access Level"
            placeholder="Select who can view this content"
            selectedKeys={accessLevel}
            onSelectionChange={setAccessLevel}
            stringEnum={CategoryAccessLevelString}
            valuesEnum={CategoryAccessLevel}
          />
          <Spacer y={2} />
          <div className="input-wrapper">
            <TableSelectInput
              aria-label="Add link"
              title={"Links"}
              addNewPlaceholder="+ Add a new link"
              onAddNew={addLinkModalDisclosure.onOpen}
              onEdit={(index: number) => {
                setEditLinksIndex(index)
                editLinkModalDisclosure.onOpen()
              }}
              onDelete={async (_id: string) => {
                setSelectedLinks((prev: any) =>
                  prev.filter((link: any) => link._id !== _id),
                )
              }}
              isLoading={false}
              selectedItems={selectedLinks}
              setSelectedItems={setSelectedLinks}
              hideMovementArrows={false}
              headers={["Title", "URL"]}
            />
          </div>
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              closeModal()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await onEditNote(title, notes, selectedLinks, accessLevel, index)

              closeModal()
            }}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
            isLoading={isLoading}
          >
            Edit Notes
          </Button>
        </div>
      }
    />
  )
}
