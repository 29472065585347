import { deleteQuiz } from "@/api/routes/admin/quiz-bank"
import { deleteUser } from "@/api/routes/admin/user"
import { stopTutoringStudent } from "@/api/routes/tutor"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Tooltip, useDisclosure } from "@nextui-org/react"

interface Props {
  userId: string
  userName: string
  listUsers: () => Promise<void>
}
export default function RemoveStudentButton({
  userId,
  userName,
  listUsers,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isOpen}
        onConfirm={async () => {
          // Remove user from students being tutored
          await stopTutoringStudent(userId)
          await listUsers()
          onClose()
        }}
        onClose={onClose}
        title="End Tutoring for User"
        description={`Are you sure you want to stop tutoring the user '${userName}'?`}
        deleteTextOverride="Stop tutoring"
      />
      <Tooltip aria-label="Details" color="danger" content="Stop tutoring user">
        <Link onClick={onOpen}>
          <span className="text-lg text-danger cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </Link>
      </Tooltip>
    </>
  )
}
