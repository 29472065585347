import {
  IQuestionCategory,
  ISubQuestionCategory,
} from "@/api/types/question/question"
import LargeButtonWidget from "@/components/Widgets/LargeButtonWidget"
import {
  faChevronRight,
  faEdit,
  faPlus,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  Input,
  Spacer,
  Spinner,
  Textarea,
  useDisclosure,
} from "@nextui-org/react"
import CreateSubCategoryModal from "../CreateSubCategoryModal"
import "./index.css"
import { useEffect, useState } from "react"
import { getCategoriesByPath } from "@/api/routes/category-bank"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { deleteSubCategory } from "@/api/routes/admin/question-category/category-bank"
import CreateNotesModal from "../Notes/CreateNotesModal"
import EditNotesModal from "../Notes/EditNotesModal"
import { deleteNotes } from "@/api/routes/admin/question-category/notes"
import NotesList from "./NotesList"
import VideosList from "./VideosList"
import AddVideoModal from "../../CreateOrEditCategory/AddVideoModal"
import EditVideoModal from "../../CreateOrEditCategory/EditVideoModal"
import {
  addVideoRoute,
  updateVideoRoute,
} from "@/api/routes/admin/question-category/video"
import useDebounce from "@/core/helpers/debounce"

interface Props {
  // Category cursing
  currentCategoryPath: string[]
  setCurrentCategoryPath: React.Dispatch<React.SetStateAction<string[]>>
  currentCategoryNamePath: string[] // for breadcrumbs
  setCurrentCategoryNamePath: React.Dispatch<React.SetStateAction<string[]>> // for breadcrumbs

  subCategories: ISubQuestionCategory[]
  setSubCategories: React.Dispatch<React.SetStateAction<ISubQuestionCategory[]>>
}

export default function SubCategoriesList({
  subCategories,
  setSubCategories,
  setCurrentCategoryPath,
  currentCategoryPath,
  currentCategoryNamePath,
  setCurrentCategoryNamePath,
}: Props) {
  const createSubCategoryModal = useDisclosure()
  const createNotesModal = useDisclosure()
  const editNotesModal = useDisclosure()
  const editVideoModal = useDisclosure()
  const createVideoModal = useDisclosure()
  const [modalIndex, setModalIndex] = useState<number>(0)

  const [modalNotesIndex, setModalNotesIndex] = useState<number>(0)
  const [modalNotesId, setModalNotesId] = useState<string>("")

  const [modalVideoIndex, setModalVideoIndex] = useState<number>(0)
  const [modalVideoId, setModalVideoId] = useState<string>("")

  const [modalPath, setModalPath] = useState<string[]>([])

  const setSubCategoryValueAtIndex = (
    index: number,
    key: keyof ISubQuestionCategory,
    value: any,
  ) => {
    setSubCategories((prev) => {
      const newSubCategories = [...prev]
      newSubCategories[index][key] = value
      return newSubCategories
    })
  }

  async function getSubCategoriesForSuppliedSubCategories() {
    if (!(subCategories && subCategories.length > 0)) return

    const suppliedSubCategories = subCategories
    for (const [
      subCategoryIndex,
      subCategory,
    ] of suppliedSubCategories.entries()) {
      if (!subCategory._id || subCategory._id === "") continue
      const currSubCategory = await getCategoriesByPath([
        ...currentCategoryPath,
        subCategory._id,
      ])
      if (currSubCategory) {
        setSubCategories((prev) => {
          const newSubCategories = [...prev]
          newSubCategories[subCategoryIndex] = currSubCategory
          return newSubCategories
        })
      }
    }
  }

  useEffect(() => {
    getSubCategoriesForSuppliedSubCategories()
  }, [])
  useEffect(() => {
    getSubCategoriesForSuppliedSubCategories()
  }, [currentCategoryPath])

  const [isDeleting, setIsDeleting] = useState(false)

  return (
    <div className="sub-categories-container">
      <p>Sub Categories</p>
      <Spacer y={2} />
      <Breadcrumbs
        variant="solid"
        radius="full"
        onAction={(key) => {
          // cut everything off the array after key
          setCurrentCategoryPath((prev) => {
            const newPath = [...prev]
            newPath.splice(parseInt(key.toString()) + 1)
            return newPath
          })
          setCurrentCategoryNamePath((prev) => {
            const newPath = [...prev]
            newPath.splice(parseInt(key.toString()) + 1)
            return newPath
          })
        }}
      >
        {currentCategoryNamePath &&
          currentCategoryNamePath.map((category, index) => (
            <BreadcrumbItem key={index}>{category}</BreadcrumbItem>
          ))}
      </Breadcrumbs>
      <Spacer y={2} />
      <CreateSubCategoryModal
        setSubCategories={setSubCategories}
        currentCategoryPath={currentCategoryPath}
        disclosure={createSubCategoryModal}
      />
      <CreateNotesModal
        currentCategoryPath={modalPath}
        setSubCategories={setSubCategories}
        index={modalIndex}
        disclosure={createNotesModal}
      />
      <EditNotesModal
        currentCategoryPath={modalPath}
        setSubCategories={setSubCategories}
        index={modalIndex}
        notesIndex={modalNotesIndex}
        disclosure={editNotesModal}
        notesId={modalNotesId}
      />
      <Spacer y={2} />
      <div className="sub-categories-list">
        {subCategories.map((subCategory, index) => (
          <Card key={index} className="sub-category-card" shadow="sm">
            <CardBody>
              <div className="sub-categories-header">
                <Button
                  variant="ghost"
                  color="primary"
                  onClick={() => {
                    setCurrentCategoryNamePath([
                      ...currentCategoryNamePath,
                      subCategory.name,
                    ])
                    setCurrentCategoryPath([
                      ...currentCategoryPath,
                      subCategory._id!,
                    ])
                  }}
                >
                  Sub Categories{" "}
                  <FontAwesomeIcon size="xs" icon={faChevronRight} />
                </Button>
                {isDeleting && <Spinner />}
                {!isDeleting && (
                  <Button
                    color="danger"
                    radius="full"
                    variant="ghost"
                    isIconOnly={true}
                    onClick={async () => {
                      setIsDeleting(true)
                      await deleteSubCategory([
                        ...currentCategoryPath,
                        subCategory._id!,
                      ])
                      setIsDeleting(false)
                      setSubCategories((prev) => {
                        let newSubCategories = [...prev]
                        newSubCategories.splice(index, 1)
                        return newSubCategories
                      })
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </div>
              <Spacer y={3} />
              <Input
                label="Title"
                placeholder="Sub Category Title..."
                type="text"
                variant="bordered"
                value={subCategory.name ?? ""}
                onChange={(e) =>
                  setSubCategoryValueAtIndex(index, "name", e.target.value)
                }
              />
              <Spacer y={3} />
              <Textarea
                label="Description"
                placeholder="Sub Category Description..."
                type="text"
                variant="bordered"
                value={subCategory.description ?? ""}
                onChange={(e) =>
                  setSubCategoryValueAtIndex(
                    index,
                    "description",
                    e.target.value,
                  )
                }
              />
              <Spacer y={3} />
              <NotesList
                index={index}
                subCategory={subCategory}
                setSubCategories={setSubCategories}
                currentCategoryPath={currentCategoryPath}
                setModalIndex={setModalIndex}
                setModalNotesIndex={setModalNotesIndex}
                setModalNotesId={setModalNotesId}
                setModalPath={setModalPath}
                editNotesModal={editNotesModal}
                createNotesModal={createNotesModal}
              />

              <Spacer y={3} />
              <VideosList
                index={index}
                subCategory={subCategory}
                setSubCategories={setSubCategories}
                currentCategoryPath={currentCategoryPath}
                editVideoModal={editVideoModal}
                createVideoModal={createVideoModal}
              />
            </CardBody>
          </Card>
        ))}

        <Button
          className="sub-category-card-button"
          color="primary"
          variant="flat"
          onClick={() => {
            createSubCategoryModal.onOpen()
          }}
        >
          <FontAwesomeIcon fontSize={"2rem"} icon={faPlusCircle} />
          <span>Add Sub Category</span>
        </Button>
      </div>
    </div>
  )
}
