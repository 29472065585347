import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"

export async function StartAiChat(userQuizId: string, questionId: string) {
  try {
    const res = await axios.post("/ai-chat/start-session/", {
      userQuizId,
      questionId,
    })

    if (res.status === 200) {
      return res.data?.body?.sessionId ?? null
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
