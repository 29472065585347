import BaseModal from "@/core/components/Modals/BaseModal"
import { isFieldFilledOut, isSetFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Select,
  SelectItem,
  Spacer,
  Textarea,
  UseDisclosureProps,
} from "@nextui-org/react"
import { s } from "node_modules/vite/dist/node/types.d-aGj9QkWt"
import { useEffect, useState } from "react"

interface Props {
  disclosure: UseDisclosureProps
  index: number
  selectedLinks: any[]
  onEditLink: (val: any, vals: any) => void
}
export default function EditQuestionBankLinkModal({
  disclosure,
  index,
  selectedLinks,
  onEditLink,
}: Props) {
  const [clonedSelectedLinks, setClonedSelectedLinks] = useState([] as any)
  const [selectedType, setSelectedType] = useState(new Set<string>([]))

  useEffect(() => {
    setClonedSelectedLinks(selectedLinks)
  }, [selectedLinks])

  useEffect(() => {
    console.log("Setting type")
    if (clonedSelectedLinks && clonedSelectedLinks[index]) {
      console.log(clonedSelectedLinks[index].Type)
      setSelectedType(
        clonedSelectedLinks[index].Type
          ? new Set<string>([clonedSelectedLinks[index].Type])
          : new Set<string>([]),
      )
    }
  }, [clonedSelectedLinks, index])

  const formValidate = () => {
    if (
      isFieldFilledOut(clonedSelectedLinks[index].Title) &&
      isFieldFilledOut(clonedSelectedLinks[index].Description) &&
      isFieldFilledOut(clonedSelectedLinks[index].URL) &&
      isSetFieldFilledOut(selectedType)
    ) {
      return false
    }
    return true
  }

  return clonedSelectedLinks &&
    Array.isArray(clonedSelectedLinks) &&
    clonedSelectedLinks[index] ? (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Add Video"
      size="xl"
      body={
        <div>
          <Input
            value={clonedSelectedLinks[index].Title}
            onChange={(e) =>
              setClonedSelectedLinks((prev: any) => {
                let newVal = [...prev]
                newVal[index].Title = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Link Title"
          />
          <Spacer y={2} />
          <Input
            value={clonedSelectedLinks[index].URL}
            onChange={(e) =>
              setClonedSelectedLinks((prev: any) => {
                let newVal = [...prev]
                newVal[index].URL = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Link URL"
          />
          <Spacer y={2} />
          <Textarea
            value={clonedSelectedLinks[index].Description}
            onChange={(e) =>
              setClonedSelectedLinks((prev: any) => {
                let newVal = [...prev]
                newVal[index].Description = e.target.value
                return newVal
              })
            }
            type="text"
            variant="bordered"
            placeholder="Link Description"
          />
          <Spacer y={2} />
          <Select
            label="Choose what this is linking to"
            selectedKeys={clonedSelectedLinks[index].Type.split(",")}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setClonedSelectedLinks((prev: any) => {
                let newVal = [...prev]

                newVal[index].Type = e.target.value
                return newVal
              })
            }}
          >
            <SelectItem key="Notes">Notes</SelectItem>
            <SelectItem key="Video">Video</SelectItem>
            <SelectItem key="External">External</SelectItem>
          </Select>
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onEditLink(clonedSelectedLinks[index], clonedSelectedLinks)

              disclosure.onClose!()
            }}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
          >
            Edit Link
          </Button>
        </div>
      }
    />
  ) : null
}
