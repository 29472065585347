import { WidgetGrid } from "@/components/Widgets"
import CategoryBreakdownsWidget from "@/components/Widgets/CategoryBreakdowns"
import CategoryBreakdownsBarChartWidget from "@/components/Widgets/CategoryBreakdownsBarChartWidget"
import CombinedBestAndWorstCategoriesWidgets from "@/components/Widgets/CategoryWidgets/CombinedBestAndWorstAnsweredCategoryWidgets"
import OverallStatisticsWidget from "@/components/Widgets/OverallStatisticsWidget"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"

export default function MyPerformancePage() {
  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>My Performance</h1>
      </PageHeader>
      <WidgetGrid>
        <CombinedBestAndWorstCategoriesWidgets />
        <CategoryBreakdownsBarChartWidget />
        <OverallStatisticsWidget />
        <CategoryBreakdownsWidget />
      </WidgetGrid>
    </Page>
  )
}
