import { Spinner } from "@nextui-org/react"
import "./index.css"

export interface Props {
  fullScreen?: boolean
}
export function PageLoader({ fullScreen }: Props) {
  return (
    <div className={`loader-page ${fullScreen && "over-content"}`}>
      <Spinner label="Loading..." color="primary" />
    </div>
  )
}
