import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@nextui-org/react"
import { useNavigate } from "react-router"
import "./index.css"

interface Props {
  onClick: () => void
  title: string
  icon?: IconProp
  variant?:
    | "ghost"
    | "flat"
    | "solid"
    | "bordered"
    | "light"
    | "faded"
    | "shadow"
  color?: "warning" | "primary" | "default" | "secondary" | "success" | "danger"
}
export default function LargeButtonWidget(props: Props) {
  return (
    <div className="widget-button-container">
      <Button
        className="widget-3x widget-button"
        color={props.color || "primary"}
        variant={props.variant || "flat"}
        onClick={props.onClick}
      >
        {props.icon && <FontAwesomeIcon fontSize={"2rem"} icon={props.icon} />}
        <span>{props.title}</span>
      </Button>
    </div>
  )
}
