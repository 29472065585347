import CircularProgress from "@/components/CircularProgress"
import { PageLoader } from "@/components/PageLoader"
import { Card } from "@nextui-org/react"

interface Props {
  header?: string
  subtext?: string
  percentage: number
  foregroundColor?: string
  backgroundColor?: string
  loading?: boolean
}

export default function SingleStatWidget(props: Props) {
  return (
    <Card className="widget-2x2 mini-table">
      {props.loading ? (
        <PageLoader />
      ) : (
        <>
          {props.header && (
            <h1 className="widget-2x2-header no-margin">{props.header}</h1>
          )}

          <div className="widget-sub-center-aligned">
            <CircularProgress
              percentage={props.percentage}
              radius={90}
              progressCircleStyles={{
                strokeWidth: "0.7rem",
                progressColour:
                  props.foregroundColor ?? "hsl(var(--medcamps-default-500))",
              }}
              backgroundCircleStyles={{
                strokeWidth: "0.5rem",
                progressColour:
                  props.backgroundColor ?? "hsl(var(--medcamps-default-50))",
              }}
              subtext={props.subtext ?? ""}
            />
          </div>
        </>
      )}
    </Card>
  )
}
