import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import LanguageTextArea from "../components/LanguageTextArea"
import { useState } from "react"
import {
  Button,
  Link,
  Select,
  SelectItem,
  Selection,
  useDisclosure,
} from "@nextui-org/react"
import "./index.css"
import { useNavigate } from "react-router"
import { createCategory } from "@/api/routes/admin/question-category/category-bank"
import { colorsList } from "@/core/constants/colors"
import TableSelectInput from "../components/TableSelectInput"
import AddVideoModal from "./AddVideoModal"
import {
  isLanguageFieldFilledOut,
  isSetFieldFilledOut,
} from "@/core/helpers/utils"
import EditVideoModal from "./EditVideoModal"

export default function CreateCategoryPage() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const [title, setTitle] = useState({} as any)
  const [description, setDescription] = useState({} as any)
  const [color, setColor] = useState<Selection>(new Set([]))
  const [selectedVideos, setSelectedVideos] = useState<any>([] as any)
  const [editIndex, setEditIndex] = useState(0)

  const addNewVideoModalDisclosure = useDisclosure()
  const editVideoModalDisclosure = useDisclosure()

  const buildAndSendCategory = async () => {
    setIsLoading(true)

    const category = {
      name: title,
      description,
      color: [...color][0],
      videos: selectedVideos.map((video: any) => ({
        name: video.Name,
        description: video.Description,
        url: video.URL,
      })),
    }

    await createCategory(category)

    // Navigate back
    navigate(-1)

    setIsLoading(false)
  }

  const addVideo = (video: any) => {
    setSelectedVideos((prev: any) => [
      ...prev,
      {
        _id:
          prev.length === 0
            ? 1
            : prev.reduce(
                (maxId: number, video: any) => Math.max(video._id, maxId),
                0,
              ) + 1,
        ...video,
      },
    ])
    addNewVideoModalDisclosure.onClose()
  }

  const formValidate = () => {
    if (
      isLanguageFieldFilledOut(title) &&
      isLanguageFieldFilledOut(description) &&
      isSetFieldFilledOut(color)
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      <PageHeader>
        <h1>Create Category</h1>
      </PageHeader>
      <AddVideoModal
        addVideo={addVideo}
        disclosure={addNewVideoModalDisclosure}
      />
      {/*
      TODO: Fix this
      <EditVideoModal
        disclosure={editVideoModalDisclosure}
        selectedVideos={selectedVideos}
        index={editIndex}
      />
      */}
      <div className="page-grid">
        <div className="input-wrapper">
          <LanguageInput
            observable={title}
            setObservable={setTitle}
            type="text"
            label="Category Title"
            placeholder="Enter the category title"
          ></LanguageInput>
        </div>
        <div className="input-wrapper">
          <LanguageTextArea
            editorKey={"1"}
            observable={description}
            setObservable={setDescription}
            type="text"
            label="Category Description"
            placeholder="Enter the category description"
          />
        </div>
        <div className="input-wrapper row">
          <Select
            variant="bordered"
            label="Category Colour"
            placeholder="Select a colour"
            selectedKeys={color}
            onSelectionChange={setColor}
          >
            {colorsList.map((colorItem) => (
              <SelectItem key={colorItem.value} value={colorItem.value}>
                {colorItem.label}
              </SelectItem>
            ))}
          </Select>
          <div
            className="color-square"
            style={{ backgroundColor: `hsl(var(${[...color][0]}))` }}
          ></div>
        </div>
        {/*
        <div className="input-wrapper">
          <div className="input-wrapper">
            <TableSelectInput
              aria-label="Add video"
              title={"Videos"}
              addNewPlaceholder="+ Add a new video"
              onAddNew={addNewVideoModalDisclosure.onOpen}
              onEdit={(index: number) => {
                setEditIndex(index)
                editVideoModalDisclosure.onOpen()
              }}
              selectedItems={selectedVideos}
              setSelectedItems={setSelectedVideos}
              headers={["Name", "URL"]}
            />
          </div>
        </div>
            */}
        <div className="input-wrapper create-question-wrapper">
          <Button
            onClick={buildAndSendCategory}
            variant="solid"
            color="primary"
            isLoading={isLoading}
            isDisabled={formValidate()}
          >
            Create Category
          </Button>
        </div>
      </div>
    </Page>
  )
}
