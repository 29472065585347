import { getAvailableQuizzes } from "@/api/routes/quiz-bank/quiz"
import { PageData } from "@/api/types/general"
import useDebounce from "@/core/helpers/debounce"
import { Autocomplete, AutocompleteItem } from "@nextui-org/react"
import { useAsyncList } from "@react-stately/data"
import { useEffect, useState } from "react"

interface Props {
  selectedQuizzes: any[]
  setSelectedQuizzes: (val: any[]) => void
}

export default function QuizSearchInput(props: Props) {
  const [inputValue, setInputValue] = useState("")
  const debouncedInputValue = useDebounce(inputValue, 200)

  const quizList = useAsyncList<any>({
    async load({ filterText, signal }) {
      if (signal.aborted) return Promise.reject()

      let res =
        (await getAvailableQuizzes(
          {
            page: 1,
            limit: 10,
          } as PageData,
          filterText ?? "",
        )) ?? []

      const items = res.quizzes.map((quiz: any) => ({
        key: quiz._id,
        index: quiz._id,
        name: quiz.name,
        ...quiz,
      }))

      return {
        items,
      }
    },
  })

  useEffect(() => {
    quizList.setFilterText(debouncedInputValue)
  }, [debouncedInputValue])

  const getSelectedItem = () => {
    return Array.isArray(quizList?.items)
      ? (quizList?.items?.find((x: any) => x.name === quizList?.filterText) ??
          null)
      : null
  }

  useEffect(() => {
    const item = getSelectedItem()
    if (item) {
      props.setSelectedQuizzes([item])
    } else {
      props.setSelectedQuizzes([])
    }
  }, [quizList.filterText])

  return (
    <Autocomplete
      inputValue={inputValue}
      isLoading={quizList.isLoading}
      items={quizList.items}
      onInputChange={setInputValue}
      label="Interview Name"
      placeholder="Search by interview name"
    >
      {(quiz: any) => (
        <AutocompleteItem key={quiz._id}>{quiz.name}</AutocompleteItem>
      )}
    </Autocomplete>
  )
}
