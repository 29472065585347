import { Button, Card, Link, Spacer, Spinner } from "@nextui-org/react"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faComments,
  faFile,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons"
import BaseModal from "@/core/components/Modals/BaseModal"
import { useState } from "react"
import { useAppSelector } from "@/app/hooks"

export interface Props {
  hasVoiceRecording: boolean
  hasWrittenAnswer: boolean
  onSubmitMarkWrittenAnswer: () => Promise<void>
  onSubmitMarkVoiceRecording: () => Promise<void>
  onSubmitMarkChatSession: () => Promise<void>
  isAiChatBotQuestion?: boolean
  aiChatbotSessionIds?: string[]
}
export default function MarkWithAiButtonsCard({
  hasVoiceRecording,
  hasWrittenAnswer,
  onSubmitMarkWrittenAnswer,
  onSubmitMarkVoiceRecording,
  onSubmitMarkChatSession,
  isAiChatBotQuestion,
  aiChatbotSessionIds,
}: Props) {
  const [isMarkWrittenAnswerModalOpen, setIsMarkWrittenAnswerModalOpen] =
    useState(false)
  const [isMarkVoiceRecordingModalOpen, setIsMarkVoiceRecordingModalOpen] =
    useState(false)
  const [isMarkChatSessionModalOpen, setIsMarkChatSessionModalOpen] =
    useState(false)

  const userSelector = useAppSelector((state) => state.user)?.user
  const subscriptionData = userSelector?.subscriptionData
  const aiMarkingCredits = subscriptionData?.["ai-marks"]?.value ?? 0

  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <BaseModal
        isOpen={isMarkChatSessionModalOpen}
        onClose={() => setIsMarkChatSessionModalOpen(false)}
        title="Mark chat session with AI"
        size="lg"
        body="Please confirm whether you would like to spend one AI marking credit to mark your chat session with AI."
        buttons={
          <>
            <Button
              color="danger"
              variant="light"
              onClick={() => setIsMarkChatSessionModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="shadow"
              color="primary"
              onClick={async () => {
                setIsLoading(true)
                setIsMarkChatSessionModalOpen(false)
                await onSubmitMarkChatSession()
                setIsLoading(false)
              }}
            >
              Spend one credit
            </Button>
          </>
        }
      />

      <BaseModal
        isOpen={isMarkWrittenAnswerModalOpen}
        onClose={() => setIsMarkWrittenAnswerModalOpen(false)}
        title="Mark written answer with AI"
        size="lg"
        body="Please confirm whether you would like to spend one AI marking credit to mark your written answer with AI."
        buttons={
          <>
            <Button
              color="danger"
              variant="light"
              onClick={() => setIsMarkWrittenAnswerModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="shadow"
              color="primary"
              onClick={async () => {
                setIsLoading(true)
                setIsMarkWrittenAnswerModalOpen(false)
                await onSubmitMarkWrittenAnswer()
                setIsLoading(false)
              }}
            >
              Spend one credit
            </Button>
          </>
        }
      />

      <BaseModal
        isOpen={isMarkVoiceRecordingModalOpen}
        onClose={() => setIsMarkVoiceRecordingModalOpen(false)}
        title="Mark voice recorded answer with AI"
        size="lg"
        body={
          "Please confirm whether you would like to spend one AI marking credit to mark your voice recorded answer " +
          "with our AI. As part of this, your voice recording will first be transcribed into text. " +
          "You will be given the option to review and modify this text prior to sending this to our AI examiner."
        }
        buttons={
          <>
            <Button
              color="danger"
              variant="light"
              onClick={() => setIsMarkVoiceRecordingModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="shadow"
              color="primary"
              onClick={async () => {
                setIsLoading(true)
                setIsMarkVoiceRecordingModalOpen(false)
                onSubmitMarkVoiceRecording()
                setIsLoading(false)
              }}
            >
              Spend one credit
            </Button>
          </>
        }
      />

      <Spacer y={2} />
      <Card shadow="none" className="in-progress-quiz-answer-card">
        <span className="question-attempt-card-title-text">Mark with AI</span>
        {isLoading ? (
          <div className="mark-with-ai-loading-spinner">
            <Spinner size="sm" color="primary" />
            Processing...
          </div>
        ) : (
          <>
            <span className="question-attempt-card-description-text">
              {hasWrittenAnswer && hasVoiceRecording
                ? "Choose to mark either your written answer or voice recording with our " +
                  "AI examiner. If you choose to mark your voice recording, you will be " +
                  "given a transcription of your answer to review and edit prior to " +
                  "submission."
                : hasWrittenAnswer
                  ? "Choose to mark your written answer with our AI examiner."
                  : hasVoiceRecording
                    ? "Choose to mark your voice recording with our AI examiner. You will be " +
                      "given a transcription of your answer to review and edit prior to " +
                      "submission."
                    : ""}
            </span>
            <Spacer y={2} />
            <div className="mark-with-ai-buttons">
              {hasWrittenAnswer && (
                <Button
                  variant="flat"
                  color="primary"
                  className="mark-with-ai-button"
                  onClick={() => setIsMarkWrittenAnswerModalOpen(true)}
                  isDisabled={aiMarkingCredits === 0}
                >
                  <FontAwesomeIcon size="xl" icon={faFile} />
                  Mark Written Answer with AI
                </Button>
              )}
              {hasVoiceRecording && (
                <Button
                  variant="flat"
                  color="secondary"
                  className="mark-with-ai-button"
                  onClick={() => setIsMarkVoiceRecordingModalOpen(true)}
                  isDisabled={aiMarkingCredits === 0}
                >
                  <FontAwesomeIcon size="xl" icon={faMicrophone} />
                  Mark Voice Recording with AI
                </Button>
              )}
              {isAiChatBotQuestion &&
                (aiChatbotSessionIds && aiChatbotSessionIds.length > 0 ? (
                  <Button
                    variant="flat"
                    color="primary"
                    className="mark-with-ai-button"
                    onClick={() => setIsMarkChatSessionModalOpen(true)}
                    isDisabled={aiMarkingCredits === 0}
                  >
                    <FontAwesomeIcon size="xl" icon={faComments} />
                    Mark chat session with AI
                  </Button>
                ) : (
                  <span>No chat session</span>
                ))}
            </div>
            <div>
              <Spacer y={2} />
              {aiMarkingCredits > 0 ? (
                <span className="question-attempt-card-description-text">
                  ({aiMarkingCredits} AI examiner{" "}
                  {aiMarkingCredits === 1 ? "credit" : "credits"} remaining){" "}
                </span>
              ) : (
                <span className="no-more-ai-marking-credits-text">
                  You have no more AI examiner credits, please purchase some{" "}
                  <Link href="/account/subscription">
                    here <Spacer x={1} />
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                  .
                </span>
              )}
            </div>
          </>
        )}
      </Card>
    </>
  )
}
