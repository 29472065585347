import { deleteQuiz } from "@/api/routes/admin/quiz-bank"
import { deleteSubscriptionPlan } from "@/api/routes/admin/subscription-plan"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Tooltip, useDisclosure } from "@nextui-org/react"

interface Props {
  subscriptionPlanId: string
  subscriptionPlanName: string
  listSubscriptionPlans: () => Promise<void>
}
export default function DeleteSubscriptionButton({
  subscriptionPlanId,
  subscriptionPlanName,
  listSubscriptionPlans,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isOpen}
        onConfirm={async () => {
          await deleteSubscriptionPlan(subscriptionPlanId)
          // Call route to soft delete subscription plan
          await listSubscriptionPlans()
          onClose()
        }}
        onClose={onClose}
        title="Delete Subscription Plan"
        description={`Are you sure you want to delete the subscription plan '${subscriptionPlanName}'? This action cannot be reversed.`}
      />
      <Tooltip
        aria-label="Details"
        color="danger"
        content="Delete subscription plan"
      >
        <Link onClick={onOpen}>
          <span className="text-lg text-danger cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </Link>
      </Tooltip>
    </>
  )
}
