import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.css"
import {
  IconDefinition,
  faGear,
  faHouse,
} from "@fortawesome/free-solid-svg-icons"
import { Link, NavLink } from "react-router-dom"
import { Accordion, AccordionItem } from "@nextui-org/react"

interface Props {
  title?: string
  icon?: IconDefinition
  children: React.ReactNode
}

export default function SidebarDropDownLink(props: Props) {
  const baseClass = "sidebar-link"
  const activeClass = "active"

  const linkActiveStyles = `${baseClass} ${activeClass}`
  return (
    <Accordion
      className="sidebar-dropdown-link-base"
      itemClasses={{
        base: "sidebar-dropdown-link-base",
        heading: "sidebar-dropdown-link",
        title: "sidebar-dropdown-link-text",
        content: "sidebar-dropdown-link-text",
      }}
      showDivider={false}
    >
      <AccordionItem
        key="1"
        aria-label={props.title}
        title={
          <div className="sidebar-accordion-dropdown-header">
            {props.icon ? (
              <FontAwesomeIcon icon={props.icon} className="menu-item-icon" />
            ) : null}
            {props.title}
          </div>
        }
      >
        {props.children}
      </AccordionItem>
    </Accordion>
  )
}
/*

*/
