import "./index.css"

interface Props {
  children: React.ReactNode
  className?: string
}

export default function PageHeader(props: Props) {
  return (
    <div className={`page-header ${props.className ?? ""}`}>
      {props.children}
    </div>
  )
}
