import { useEffect, useState } from "react"
import QuizCard from "./QuizCard"
import "./index.css"
import { getQuizzes } from "@/api/routes/quiz/quiz"
import { IUserQuizFilter, IUserQuizView } from "@/api/types/quiz/quizzes"
import { PageLoader } from "@/components/PageLoader"
import { Pagination, PaginationItem, PaginationCursor } from "@nextui-org/react"
import CategoryInput from "@/pages/Admin/CreateOrEditQuestion/CategoryInput"
import QuizSearchInput from "@/core/components/QuizSearchInput"
import { IQuizBank } from "@/api/types/quiz/quiz-bank"

export default function QuizList() {
  const [loading, setLoading] = useState(true)
  const [interviews, setInterviews] = useState<IUserQuizView[]>([])

  // Filtering
  const [filter, setFilter] = useState<Partial<IUserQuizFilter>>({})
  const [selectedCategories, setSelectedCategories] = useState<any>([])
  const [selectedQuizzes, setSelectedQuizzes] = useState<any>([])

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)

  const loadQuizzes = async (page?: number) => {
    setLoading(true)
    const totalRecords: any = await getQuizzes(
      undefined,
      {
        fetchMeta: true,
        limit: pageLimit,
      },
      filter,
    )
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    const interviews = await getQuizzes(
      undefined,
      {
        page: page || currentPage,
        limit: pageLimit,
        sort: "-startDate",
      },
      filter,
    )
    if (interviews) {
      setInterviews(interviews)
    }
    setLoading(false)
  }

  useEffect(() => {
    loadQuizzes()
  }, [])

  useEffect(() => {
    loadQuizzes()
  }, [filter])

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      categories:
        selectedCategories.map((category: any) => category._id).join(",") ||
        undefined,
    }))
  }, [selectedCategories])

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      quizzes:
        selectedQuizzes.map((quiz: IQuizBank) => quiz._id).join(",") ||
        undefined,
    }))
  }, [selectedQuizzes])

  const renderInterviews = (): JSX.Element => (
    <div className="interviews-container">
      <div className="input-wrapper default-page-lr-padding">
        <div className="quiz-list-filters">
          <div className="quiz-list-filter-search-input">
            <QuizSearchInput
              selectedQuizzes={selectedQuizzes}
              setSelectedQuizzes={setSelectedQuizzes}
            />
          </div>
          <CategoryInput
            addCategoryButtonString="Filter categories"
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </div>
      </div>
      {!loading ? (
        <>
          <div className="interviews default-page-lr-padding">
            {interviews.map((interview, index) => (
              <QuizCard
                key={index}
                reloadQuizzes={loadQuizzes}
                id={interview._id}
                title={interview.name}
                shortDescription={interview.shortDescription}
                description={interview.description}
                startDate={new Date(interview.startDate)}
                scorePercentage={
                  typeof interview.scaledScore === "number"
                    ? parseInt((interview.scaledScore * 100).toFixed(1))
                    : undefined
                }
                isFinished={interview.endDate ? true : false}
              />
            ))}
          </div>
          <div className="interviews-footer">
            <Pagination
              style={{ marginTop: 10 }}
              total={totalPages}
              initialPage={currentPage}
              onChange={(page) => {
                setCurrentPage(page)
                loadQuizzes(page)
              }}
            />
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  )

  return <div className="quiz-list">{renderInterviews()}</div>
}
