import BaseModal from "@/core/components/Modals/BaseModal"
import LanguageTextArea from "@/pages/Admin/components/LanguageTextArea"
import { Button, Input, Spacer, Switch } from "@nextui-org/react"
import { useEffect, useState } from "react"

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  observable: any
  setObservable: React.Dispatch<React.SetStateAction<any>>
}

export default function AddAnswerModal({
  isOpen,
  setIsOpen,
  observable,
  setObservable,
}: Props) {
  const [newItem, setNewItem] = useState({
    header: "",
    answer: "",
    marks: "0",
    isOptional: false,
  })

  const resetItem = () => {
    setNewItem({
      header: "",
      answer: "",
      marks: "0",
      isOptional: false,
    })
  }

  useEffect(() => {
    resetItem()
  }, [isOpen])

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Add Answer"
      size="xl"
      body={
        <>
          {/* Header title */}
          <Input
            value={newItem.header}
            variant="bordered"
            onChange={(e: any) => {
              setNewItem((prev: any) => ({
                ...prev,
                header: e.target.value,
              }))
            }}
            placeholder="Enter the header"
          />

          {/* Answers */}
          <LanguageTextArea
            editorKey={"answer-" + observable.length}
            observable={newItem.answer}
            setObservable={(val: any) => {
              setNewItem((prev: any) => ({
                ...prev,
                answer: val({}),
              }))
            }}
            type="text"
            label="Answer"
            placeholder="Enter the question answer"
            disableRichText={true}
          />

          {/* Marks */}
          <Input
            type="number"
            variant="bordered"
            value={newItem.marks}
            onChange={(e: any) => {
              setNewItem((prev: any) => ({
                ...prev,
                marks: e.target.value,
              }))
            }}
            label="Answer Marks"
            placeholder="Enter the marks"
          />

          {/* Is Optional */}
          <Switch
            isSelected={newItem.isOptional}
            onValueChange={(val: any) => {
              if (val === true) {
                // Set marks to 0 if the answer is optional
                setNewItem((prev: any) => ({
                  ...prev,
                  marks: "0",
                }))
              }
              setNewItem((prev: any) => ({
                ...prev,
                isOptional: val,
              }))
            }}
          >
            Is Optional
          </Switch>
        </>
      }
      buttons={
        <>
          <Button
            variant="light"
            color="danger"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            color="primary"
            onClick={() => {
              setObservable((prev: any) => [...prev, newItem])
              setIsOpen(false)
            }}
          >
            Save
          </Button>
        </>
      }
    />
  )
}
