import React, { useEffect, useState } from "react"

import Page from "@/core/components/Page"
import {
  faEdit,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Chip,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"
import PageHeader from "@/core/components/Page/PageHeader"
import { listCategories } from "@/api/routes/category-bank"
import { useNavigate } from "react-router"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import { deleteCategory } from "@/api/routes/admin/question-category/category-bank"
import DeleteOrganisationButton from "./DeleteOrganisationButton"
import { PageLoader } from "@/components/PageLoader"
import { useAppSelector } from "@/app/hooks"
import { listOrganisations } from "@/api/routes/admin/organisation"

export default function ManageOrganisationsPage() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [allOrganisations, setAllOrganisations] = useState([] as any)
  const [displayOrganisations, setDisplayOrganisations] = useState([] as any)
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user.user)

  let columns = []
  if (user?.role === "admin") {
    columns = [
      { name: "NAME", uid: "name" },
      { name: "ACTIONS", uid: "actions" },
    ]
  } else {
    columns = [{ name: "NAME", uid: "name" }]
  }

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)

  const loadOrganisations = async (page?: number) => {
    setCurrentPage(page || 1)
    setIsPageLoading(true)

    const totalRecords: any = await listOrganisations(undefined, {
      fetchMeta: true,
      limit: pageLimit,
    })
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    const _allOrganisations = await listOrganisations(undefined, {
      page: page || currentPage,
      limit: pageLimit,
    })
    if (_allOrganisations != null) {
      setAllOrganisations(_allOrganisations)
      setDisplayOrganisations(
        _allOrganisations.map((organisation: any) => ({
          _id: organisation._id,
          name: organisation.name,
        })),
      )
    }
    setIsPageLoading(false)
  }

  useEffect(() => {
    loadOrganisations()
  }, [])

  const renderCell = React.useCallback(
    (organisation: any, columnKey: React.Key) => {
      const cellValue = organisation[columnKey as any]

      switch (columnKey) {
        case "actions":
          return (
            <div className="actions-table-cell">
              <Tooltip aria-label="Details" content="Edit category">
                <Link
                  onClick={() => {
                    navigate(`/Admin/Organisation/Edit/${organisation._id}`)
                  }}
                >
                  <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                    <FontAwesomeIcon icon={faEdit} />
                  </span>
                </Link>
              </Tooltip>
              <DeleteOrganisationButton
                organisationId={organisation._id}
                organisationName={organisation.name}
                listOrganisations={loadOrganisations}
              />
            </div>
          )
        default:
          return cellValue
      }
    },
    [],
  )

  return (
    <Page showSidebar={true}>
      <PageHeader>
        <h1>Manage Organisations</h1>
        {user?.role === "admin" && (
          <Button
            color="primary"
            variant="shadow"
            startContent={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              navigate("/Admin/Organisation/New")
            }}
          >
            New Organisation
          </Button>
        )}
      </PageHeader>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Table
          aria-label=""
          bottomContent={
            <div className="flex w-full justify-center">
              {totalPages > 0 && (
                <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="primary"
                  total={totalPages}
                  initialPage={currentPage}
                  onChange={(page) => {
                    loadOrganisations(page)
                  }}
                />
              )}
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                className={column.uid === "actions" ? "actions-table-cell" : ""}
                align={column.uid === "actions" ? "center" : "start"}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>

          <TableBody items={displayOrganisations}>
            {(item: any) => (
              <TableRow key={item._id}>
                {(columnKey) => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Page>
  )
}
