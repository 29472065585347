import Page from "@/core/components/Page"
import {
  Button,
  Card,
  Input,
  Link,
  Select,
  SelectItem,
  Selection,
  Switch,
  Textarea,
  useDisclosure,
} from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import "./index.css"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import { Key, useEffect, useState } from "react"
import LanguageTextArea from "../components/LanguageTextArea"
import AnswerInput, { validateAnswers } from "./AnswerInput"
import CategoryCards from "./CategoryCards"
import { AnswerAccessLevel } from "../ManageQuestions"
import {
  AnswerAccessLevelString,
  IQuestionBankLink,
} from "@/api/types/question/question"
import {
  getQuestionScenarios,
  updateQuestion,
} from "@/api/routes/admin/question-bank"
import { getQuestion } from "@/api/routes/question-bank/questions"
import CategoryInput from "./CategoryInput"
import {
  isFieldFilledOut,
  isLanguageFieldFilledOut,
} from "@/core/helpers/utils"
import { PageLoader } from "@/components/PageLoader"
import TableSelectInput from "../components/TableSelectInput"
import AddLinkModal from "../components/Notes/EditNotesModal/AddLinkModal"
import EditLinkModal from "../components/Notes/EditNotesModal/EditLinkModal"
import AddQuestionBankLinkModal from "./Modals/AddQuestionBankLinkModal"
import EditQuestionBankLinkModal from "./Modals/EditQuestionBankLinkModal"
import AnswerInputV2 from "./AnswerInputV2"

export default function EditQuestionPage() {
  const navigate = useNavigate()
  const { questionId } = useParams()

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const [scenarios, setScenarios] = useState([] as any)
  const [originalData, setOriginalData] = useState({} as any)

  const [title, setTitle] = useState({} as any)
  const [questionBody, setQuestionBody] = useState({} as any)
  const [aiPrompt, setAiPrompt] = useState<string>("")
  const [isAiPromptOverrideEnabled, setIsAiPromptOverrideEnabled] =
    useState<boolean>(false)
  useEffect(() => {
    // Clear our AI prompt if we disable the override
    if (!isAiPromptOverrideEnabled) {
      setAiPrompt("")
    }
  }, [isAiPromptOverrideEnabled])

  const [isAiChatBotQuestion, setIsAiChatBotQuestion] = useState<boolean>(false)
  const [aiChatBotScenarioPrompt, setAiChatBotScenarioPrompt] = useState("")
  const [aiChatBotUserRole, setAiChatBotUserRole] = useState("")

  const [aiChatBotScenarioTemplateId, setAiChatBotScenarioTemplateId] =
    useState<any>(new Set<Key>([]))

  const [answers, setAnswers] = useState([] as any)
  const [categories, setCategories] = useState([] as any)
  const [timeLimit, setTimeLimit] = useState<string>()
  const [supportedRegions, setSupportedRegions] = useState({} as any)
  const [answerAccessLevel, setAnswerAccessLevel] = useState<any>(
    new Set<Key>([]),
  )
  const [associatedReadingLinksIndex, setAssociatedReadingLinksIndex] =
    useState(-1)
  const [associatedReadingLinks, setAssociatedReadingLinks] = useState(
    [] as any,
  )

  const addLinkModalDisclosure = useDisclosure()
  const editLinkModalDisclosure = useDisclosure()

  const [actorModeEnabled, setActorModeEnabled] = useState(false)
  const [actorInstructions, setActorInstructions] = useState({} as any)

  const [interviewerInstructionsEnabled, setInterviewerInstructionsEnabled] =
    useState(false)
  const [interviewerInstructions, setInterviewerInstructions] = useState(
    {} as any,
  )

  const [intervieweeInstructionsEnabled, setIntervieweeInstructionsEnabled] =
    useState(false)
  const [intervieweeInstructions, setIntervieweeInstructions] = useState(
    {} as any,
  )

  const getQuestionData = async (questionId: string) => {
    setIsPageLoading(true)
    const scenarios = await getQuestionScenarios()
    setScenarios(scenarios)
    const data = await getQuestion(questionId)
    if (data != null) {
      let formattedData = {
        ...data,
        timeLimit: (data.timeLimit ?? 0).toString(),
        categories: data.categories.map((category: any) => ({
          ...category,
          name: category.name.enGB,
        })),
        answerAccessLevel: new Set<Key>([data.answerAccessLevel.toString()]),
      }
      formattedData.answerAccessLevel.anchorKey =
        data.answerAccessLevel.toString()
      formattedData.answerAccessLevel.currentKey =
        data.answerAccessLevel.toString()

      setTitle(formattedData.name)
      setQuestionBody(formattedData.question)
      setAiPrompt(formattedData.aiPrompt)
      if (formattedData.aiPrompt === "") {
        setIsAiPromptOverrideEnabled(false)
      } else {
        setIsAiPromptOverrideEnabled(true)
      }
      setAnswers(formattedData.answers)
      setTimeLimit(formattedData.timeLimit)
      setCategories(formattedData.categories)
      setAnswerAccessLevel(formattedData.answerAccessLevel)
      setSupportedRegions(formattedData.supportedRegions)
      setActorModeEnabled(formattedData.actorModeEnabled)
      setActorInstructions(formattedData.actorInstructions)
      setInterviewerInstructionsEnabled(
        formattedData.interviewerInstructionsEnabled,
      )
      setInterviewerInstructions(formattedData.interviewerInstructions)
      setIntervieweeInstructionsEnabled(
        formattedData.intervieweeInstructionsEnabled,
      )
      setIntervieweeInstructions(formattedData.intervieweeInstructions)
      setAssociatedReadingLinks(
        formattedData.associatedReadingLinks?.map((x: IQuestionBankLink) => ({
          _id: x._id,
          Title: x.title,
          URL: x.url,
          Type: x.type,
          Description: x.description,
        })) ?? [],
      )

      if (formattedData.isAiChatBotQuestion) {
        setIsAiChatBotQuestion(true)
        setAiChatBotScenarioPrompt(formattedData.aiChatBotScenarioPrompt)
        setAiChatBotUserRole(formattedData.aiChatBotUserRole)
        setAiChatBotScenarioTemplateId(
          new Set([formattedData.aiChatBotScenarioTemplateId]),
        )
      }

      setOriginalData({
        ...formattedData,
        answers: JSON.parse(
          JSON.stringify(
            formattedData.answers.map((answer: any) => ({
              ...answer,
              marks: answer.marks.toString(),
            })),
          ),
        ),
      })
    }

    setIsPageLoading(false)
  }

  const isEnteredDataDifferentToOriginalData = () => {
    const newData = {
      name: title,
      question: questionBody,
      aiPrompt,
      answers: answers.map((answer: any) => ({
        ...answer,
        marks: answer.marks.toString(),
      })),
      categories,
      timeLimit,
      answerAccessLevel,
      supportedRegions,
      actorModeEnabled,
      actorInstructions,
      interviewerInstructionsEnabled,
      interviewerInstructions,
      intervieweeInstructionsEnabled,
      intervieweeInstructions,
      associatedReadingLinks,
      isAiChatBotQuestion,
      aiChatBotScenarioPrompt,
      aiChatBotUserRole,
      aiChatBotScenarioTemplateId: [...aiChatBotScenarioTemplateId][0],
    }

    const comparisonData = {
      name: originalData.name,
      question: originalData.question,
      aiPrompt: originalData.aiPrompt,
      answers: originalData.answers,
      categories: originalData.categories,
      timeLimit: originalData.timeLimit,
      answerAccessLevel: originalData.answerAccessLevel,
      supportedRegions: originalData.supportedRegions,
      actorModeEnabled: originalData.actorModeEnabled,
      actorInstructions: originalData.actorInstructions,
      interviewerInstructionsEnabled:
        originalData.interviewerInstructionsEnabled,
      interviewerInstructions: originalData.interviewerInstructions,
      intervieweeInstructionsEnabled:
        originalData.intervieweeInstructionsEnabled,
      intervieweeInstructions: originalData.intervieweeInstructions,
      associatedReadingLinks: originalData.associatedReadingLinks,
      isAiChatBotQuestion: originalData.isAiChatBotQuestion,
      aiChatBotScenarioPrompt: originalData.aiChatBotScenarioPrompt,
      aiChatBotUserRole: originalData.aiChatBotUserRole,
      aiChatBotScenarioTemplateId: originalData.aiChatBotScenarioTemplateId,
    }

    return JSON.stringify(newData) !== JSON.stringify(comparisonData)
  }

  const allowSave = () => !formValidate()

  const buildAndSendQuestion = async () => {
    setIsSubmitLoading(true)

    const question = {
      name: title,
      question: questionBody,
      aiPrompt,
      answers,
      categories: categories.map((category: any) => category._id),
      supportedRegions: { enGB: true },
      timeLimit,
      answerAccessLevel: parseInt([...answerAccessLevel].join("")),
      actorModeEnabled,
      actorInstructions,
      interviewerInstructionsEnabled,
      interviewerInstructions,
      intervieweeInstructionsEnabled,
      intervieweeInstructions,
      associatedReadingLinks: associatedReadingLinks.map((link: any) => ({
        _id: link._id,
        title: link.Title,
        url: link.URL,
        type: link.Type,
        description: link.Description,
      })),
      isAiChatBotQuestion,
      aiChatBotScenarioPrompt,
      aiChatBotUserRole,
      aiChatBotScenarioTemplateId:
        [...aiChatBotScenarioTemplateId]?.[0] ?? null,
    }
    await updateQuestion(questionId!, question)

    // Navigate back
    navigate(-1)

    setIsSubmitLoading(false)
  }

  // Load in our question
  useEffect(() => {
    ;(async () => {
      setIsPageLoading(true)

      await getQuestionData(questionId!)

      setIsPageLoading(false)
    })()
  }, [])

  const formValidate = () => {
    if (
      isLanguageFieldFilledOut(title) &&
      isLanguageFieldFilledOut(questionBody) &&
      isFieldFilledOut(timeLimit) &&
      isFieldFilledOut(parseInt([...answerAccessLevel].join(""))) &&
      validateAnswers(answers)
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      <PageHeader>
        <h1>Edit Question</h1>
      </PageHeader>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <div className="page-grid">
          <AddQuestionBankLinkModal
            disclosure={addLinkModalDisclosure}
            addLink={(val: any) => {
              setAssociatedReadingLinks((prev: any) => [
                ...prev,
                { ...val, _id: prev.length },
              ])

              addLinkModalDisclosure.onClose()
            }}
          />
          <EditQuestionBankLinkModal
            disclosure={editLinkModalDisclosure}
            index={associatedReadingLinksIndex}
            selectedLinks={associatedReadingLinks}
            onEditLink={(val: any, vals: any) => {
              let newLinks = [...associatedReadingLinks]
              newLinks[val] = vals
              setAssociatedReadingLinks(newLinks)
            }}
          />
          <div className="input-wrapper">
            <LanguageInput
              observable={title}
              setObservable={setTitle}
              type="text"
              label="Question Title"
              placeholder="Enter the question title"
            />
          </div>

          <div className="input-wrapper">
            <LanguageTextArea
              editorKey={"1"}
              observable={questionBody}
              setObservable={setQuestionBody}
              type="text"
              label="Question body"
              placeholder="Enter the body of the question"
            />
          </div>

          <div className="input-wrapper">
            <Switch
              isSelected={intervieweeInstructionsEnabled}
              onValueChange={setIntervieweeInstructionsEnabled}
            >
              Candidate Instructions
            </Switch>
          </div>

          {intervieweeInstructionsEnabled && (
            <div className="input-wrapper">
              <LanguageTextArea
                editorKey={"2"}
                observable={intervieweeInstructions}
                setObservable={setIntervieweeInstructions}
                type="text"
                label="Candidate Instructions"
                placeholder="Enter candidate instructions"
              />
            </div>
          )}

          <div className="input-wrapper">
            <Switch
              isSelected={interviewerInstructionsEnabled}
              onValueChange={setInterviewerInstructionsEnabled}
            >
              Examiner Instructions
            </Switch>
          </div>

          {interviewerInstructionsEnabled && (
            <div className="input-wrapper">
              <LanguageTextArea
                editorKey={"3"}
                observable={interviewerInstructions}
                setObservable={setInterviewerInstructions}
                type="text"
                label="Examiner Instructions"
                placeholder="Enter examiner instructions"
              />
            </div>
          )}

          <div className="input-wrapper">
            <Switch
              isSelected={actorModeEnabled}
              onValueChange={setActorModeEnabled}
            >
              Allow Actors
            </Switch>
          </div>

          {actorModeEnabled && (
            <div className="input-wrapper">
              <LanguageTextArea
                editorKey={"4"}
                observable={actorInstructions}
                setObservable={setActorInstructions}
                type="text"
                label="Actor Instructions"
                placeholder="Enter the quiz actor instructions"
              />
            </div>
          )}

          <AnswerInputV2
            observable={answers}
            setObservable={setAnswers}
            type="text"
            label="Answers"
            placeholder="Enter answers"
          />

          <div className="input-wrapper">
            <Switch
              isSelected={isAiPromptOverrideEnabled}
              onValueChange={setIsAiPromptOverrideEnabled}
            >
              Override AI Prompt
            </Switch>
          </div>

          {isAiPromptOverrideEnabled && (
            <div className="input-wrapper">
              <Textarea
                value={aiPrompt}
                onChange={(e: any) => {
                  setAiPrompt(e.target.value)
                }}
                type="text"
                label="AI Prompt"
                placeholder="Enter the AI prompt"
                variant="bordered"
              />
            </div>
          )}

          <div className="input-wrapper">
            <Switch
              isSelected={isAiChatBotQuestion}
              onValueChange={setIsAiChatBotQuestion}
            >
              Make this an AI Virtual Patient Question
            </Switch>
          </div>
          {isAiChatBotQuestion && (
            <>
              <div className="input-wrapper">
                <Select
                  variant="bordered"
                  label="Scenario"
                  placeholder="Select an AI chat bot scenario"
                  selectedKeys={aiChatBotScenarioTemplateId}
                  onSelectionChange={setAiChatBotScenarioTemplateId}
                >
                  {scenarios.map((scenario: any) => (
                    <SelectItem key={scenario._id} value={scenario._id}>
                      {scenario.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="input-wrapper">
                <Textarea
                  variant="bordered"
                  value={aiChatBotScenarioPrompt}
                  onChange={(e: any) => {
                    setAiChatBotScenarioPrompt(e.target.value)
                  }}
                  label="Scenario"
                  placeholder="Enter the scenario for this question"
                />
              </div>
              <div className="input-wrapper">
                <Textarea
                  variant="bordered"
                  value={aiChatBotUserRole}
                  onChange={(e: any) => {
                    setAiChatBotUserRole(e.target.value)
                  }}
                  label="Users role"
                  placeholder="Enter a description of the users role in this situation. For example, 'A junior doctor in the emergency department at their local hospital.'"
                />
              </div>
            </>
          )}

          <div className="input-wrapper">
            <CategoryInput
              selectedCategories={categories}
              setSelectedCategories={setCategories}
            />
          </div>

          <div className="input-wrapper">
            <Input
              type="number"
              variant="bordered"
              value={timeLimit}
              onChange={(e: any) => {
                setTimeLimit(e.target.value)
              }}
              label="Question Time Limit"
              placeholder="Enter the recommended minutes to answer this question"
            />
          </div>

          <div className="input-wrapper">
            <Select
              variant="bordered"
              label="Access Level"
              placeholder="Select who can view the answer"
              selectedKeys={answerAccessLevel}
              onSelectionChange={setAnswerAccessLevel}
            >
              {Object.keys(AnswerAccessLevelString).map((key: any) => (
                <SelectItem
                  key={AnswerAccessLevel[key]}
                  value={AnswerAccessLevel[key]}
                >
                  {(AnswerAccessLevelString as any)[key]}
                </SelectItem>
              ))}
            </Select>
          </div>

          <div className="input-wrapper">
            <TableSelectInput
              aria-label="Associated reading links"
              title={"Associated Reading Links"}
              addNewPlaceholder="+ Add a new link"
              onAddNew={addLinkModalDisclosure.onOpen}
              onEdit={(index: number) => {
                setAssociatedReadingLinksIndex(index)
                editLinkModalDisclosure.onOpen()
              }}
              onDelete={async (_id: string) => {
                setAssociatedReadingLinks((prev: any) =>
                  prev.filter((link: any) => link._id !== _id),
                )
              }}
              isLoading={false}
              selectedItems={associatedReadingLinks}
              setSelectedItems={setAssociatedReadingLinks}
              hideMovementArrows={false}
              headers={["Title", "Type", "URL"]}
            />
          </div>

          <div className="input-wrapper create-question-wrapper">
            <Button
              onClick={buildAndSendQuestion}
              variant="solid"
              color="primary"
              isDisabled={!allowSave()}
            >
              Update Question
            </Button>
          </div>
        </div>
      )}
    </Page>
  )
}
