import {
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react"
import { QuizActionsCell } from "./QuizActionsCell"
import { useEffect, useState } from "react"
import { IUserQuizView } from "@/api/types/quiz/quizzes"
import { getQuizzes } from "@/api/routes/quiz/quiz"
import { getCategoryStatistics } from "@/api/routes/statistics"
import { ResponsiveBar } from "@nivo/bar"

export default function CategoryBreakdownsBarChartWidget() {
  const [categoryStatsLoading, setCategoryStatsLoading] = useState(true)
  const [quizzes, setQuizzes] = useState([] as IUserQuizView[])
  const [stats, setStats] = useState([] as any)
  const [chartStats, setChartStats] = useState([] as any)
  const [keys, setKeys] = useState([] as string[])

  const loadQuizzes = async () => {
    setCategoryStatsLoading(true)
    const categoryStats = await getCategoryStatistics()
    if (categoryStats) {
      let stats = []
      for (let key of Object.keys(categoryStats)) {
        stats.push({
          name: key,
          ...categoryStats[key],
          average:
            Math.round(categoryStats[key].questionsScoreScaledAverage * 10000) /
            100,
        })
      }

      const sortedStats = stats.sort((a, b) => b.average - a.average)
      setStats(sortedStats)

      const chartStats = sortedStats.map((stat) => ({
        categoryName: stat.name,
        Average: !isNaN(stat.average) ? stat.average : 0,
        AverageColor: "hsl(var(--medcamps-primary-500))",
      }))
      setChartStats(chartStats)
      console.log(chartStats)
    }

    setCategoryStatsLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await loadQuizzes()
    })()
  }, [])

  const unTypedProps: any = {
    width: 1000 + (chartStats.length > 5 ? (chartStats.length - 5) * 60 : 0),
  }

  return (
    <Card className="widget-chart">
      <h1 className="widget-2x2-header">Average % by category</h1>
      <div className="widget-chart-content">
        <ResponsiveBar
          data={chartStats}
          keys={["Average"]}
          indexBy="categoryName"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          {...unTypedProps}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderRadius={6}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.8]],
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          valueFormat={(e) => e + "%"}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 130,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 17,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Average % in each category"
          barAriaLabel={(e) =>
            e.id + ": " + e.formattedValue + " in country: " + e.indexValue
          }
          animate={true}
        />
      </div>
    </Card>
  )
}
