import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { getSubscriptionsForUser, getUserData } from "@/api/routes/admin/user"
import { PageLoader } from "@/components/PageLoader"
import { PageData } from "@/api/types/general"
import {
  Button,
  Card,
  Link,
  Pagination,
  Spacer,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons"
import AddSubscriptionModal from "./UserSubscriptions/AddSubscriptionModal"
import DeleteUserSubscriptionButton from "./DeleteUserSubscriptionButton"
import RestoreUserSubscriptionButton from "./RestoreUserSubscriptionButton"
import EditSubscriptionModal from "./UserSubscriptions/EditSubscriptionModal"
import ExpireUserSubscriptionButton from "./ExpireUserSubscriptionButton"
import UndoExpireUserSubscriptionButton from "./UndoExpireUserSubscriptionButton"
import SubscriptionCreditsWidget from "@/components/Widgets/SubscriptionCreditsWidget"

const columns = [
  { name: "NAME", uid: "planName" },
  { name: "START DATE", uid: "startDate", type: "date" },
  { name: "END DATE", uid: "endDate", type: "date" },
  { name: "RENEWAL DATE", uid: "renewalDate", type: "date" },
  { name: "STATUS", uid: "status" },
  { name: "MANUAL", uid: "manual", type: "boolean" },
  { name: "ACTIONS", uid: "actions" },
]

export default function ManageUserSubscriptionsPage() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [userFullName, setUserFullName] = useState<string>("")
  const [subscriptionToEdit, setSubscriptionToEdit] = useState<string>("")
  const addSubscriptionDisclosure = useDisclosure()
  const editSubscriptionDisclosure = useDisclosure()
  const navigate = useNavigate()
  const { userId } = useParams()

  const [selectedTab, setSelectedTab] = useState<string>("active")
  const [userData, setUserData] = useState<any>(null)

  const getData = async (page: number = 1, limit: number = 8) => {
    const userData = await getUserData(userId!)

    const userSubscriptionsData = await getSubscriptionsForUser(
      userId!,
      selectedTab,
      {
        page,
        limit,
      },
    )
    if (userData != null && userSubscriptionsData != null) {
      setUserFullName(userData.firstName + " " + userData.lastName)
      setUserData(userData)
      setSelectedItems(
        userSubscriptionsData.map((x: any) => ({
          _id: x._id,
          name: x.name,
          planName: x.plan.name,
          startDate: new Date(x.startDate),
          endDate: x.endDate ? new Date(x.endDate) : null,
          renewalDate: new Date(x.renewDate),
          status: x.isActive ? "Active" : "Expired",
          manual: x.manualPayment,
          deleted: x.deleted,
        })) as any,
      )
    }
  }

  const getPaginationData = async (limit: number) => {
    return await getSubscriptionsForUser(userId!, selectedTab, {
      fetchMeta: true,
      limit: limit,
    })
  }

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)

  const loadData = async (page: number = 1) => {
    setIsPageLoading(true)
    setCurrentPage(page)
    const totalRecords: any = await getPaginationData(pageLimit)
    if (totalRecords) {
      setTotalPages(totalRecords.total)
    }

    await getData(page, pageLimit)
    setIsPageLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const renderCell = useCallback((data: any, columnKey: React.Key) => {
    const cellValue = data[columnKey as any]
    const column = columns.find((x) => x.uid === columnKey)

    switch (columnKey) {
      case "actions":
        return (
          <div className="actions-table-cell">
            {data.manual && (
              <Tooltip aria-label="Details" content="Edit subscription">
                <Link
                  onClick={() => {
                    setSubscriptionToEdit(data._id)
                    editSubscriptionDisclosure.onOpen()
                  }}
                >
                  <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                    <FontAwesomeIcon icon={faEdit} />
                  </span>
                </Link>
              </Tooltip>
            )}

            {!data.deleted && !data.endDate && (
              <ExpireUserSubscriptionButton
                userId={userId!}
                subscriptionId={data._id}
                subscriptionName={data.planName}
                listSubscriptions={getData}
              />
            )}
            {/*
              We cannot restore a susbcription that has exceeded it's end date.
            */}
            {!data.deleted && data.endDate && new Date() < data.endDate && (
              <UndoExpireUserSubscriptionButton
                setSelectedTab={setSelectedTab}
                userId={userId!}
                subscriptionId={data._id}
                subscriptionName={data.planName}
              />
            )}

            {!data.deleted && (
              <DeleteUserSubscriptionButton
                userId={userId!}
                subscriptionId={data._id}
                subscriptionName={data.planName}
                listSubscriptions={getData}
              />
            )}
            {data.deleted && new Date() < data.endDate && (
              <RestoreUserSubscriptionButton
                setSelectedTab={setSelectedTab}
                userId={userId!}
                subscriptionId={data._id}
                subscriptionName={data.planName}
              />
            )}
          </div>
        )
      default:
        if (cellValue === null) {
          return "-"
        } else if (column?.type === "date") {
          return cellValue.toLocaleDateString("en-GB")
        } else if (column?.type === "boolean") {
          return cellValue ? "Yes" : "No"
        } else {
          return cellValue
        }
    }
  }, [])

  useEffect(() => {
    loadData(1)
  }, [selectedTab])

  return (
    <Page showSidebar={true}>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <>
          <AddSubscriptionModal
            userId={userId!}
            isOpen={addSubscriptionDisclosure.isOpen}
            onClose={addSubscriptionDisclosure.onClose}
            selectedData={selectedItems}
            setSelectedData={setSelectedItems}
            getData={getData}
          />
          <EditSubscriptionModal
            userId={userId!}
            subscriptionId={subscriptionToEdit}
            isOpen={editSubscriptionDisclosure.isOpen}
            onClose={editSubscriptionDisclosure.onClose}
            selectedData={selectedItems}
            setSelectedData={setSelectedItems}
            getData={getData}
          />
          <PageHeader>
            <h1>{userFullName}'s Subscriptions</h1>
            <Tabs
              defaultSelectedKey={selectedTab}
              onSelectionChange={(x: any) => setSelectedTab(x as string)}
            >
              {/*<Tab title="Active & Inactive" key="active-and-inactive"></Tab>*/}
              <Tab title="Active" key="active"></Tab>
              <Tab title="Inactive/Will not renew" key="inactive"></Tab>
              <Tab title="Deleted" key="deleted"></Tab>
            </Tabs>
            <Button
              color="primary"
              variant="shadow"
              startContent={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => {
                addSubscriptionDisclosure.onOpen()
              }}
            >
              Add Subscription
            </Button>
          </PageHeader>

          <div className="input-wrapper row">
            {userData &&
            userData.subscriptionData &&
            typeof userData.subscriptionData === "object" ? (
              <div className="input-wrapper w-full">
                <Spacer y={4} />
                <SubscriptionCreditsWidget
                  size="lg"
                  maxHeightOverride={true}
                  subscriptionData={userData.subscriptionData}
                />
              </div>
            ) : null}
          </div>

          <div className="input-wrapper">
            <Table
              aria-label=""
              bottomContent={
                <div className="flex w-full justify-center">
                  <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    total={totalPages}
                    initialPage={currentPage}
                    onChange={(page) => {
                      getData(page)
                    }}
                  />
                </div>
              }
            >
              <TableHeader columns={columns}>
                {(column) => (
                  <TableColumn
                    key={column.uid}
                    className={
                      column.uid === "actions" ? "actions-table-cell" : ""
                    }
                    align={column.uid === "actions" ? "center" : "start"}
                  >
                    {column.name}
                  </TableColumn>
                )}
              </TableHeader>

              <TableBody items={selectedItems}>
                {(item: any) => (
                  <TableRow key={item._id}>
                    {(columnKey) => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </Page>
  )
}
