import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import LanguageInput from "../components/LanguageInput"
import LanguageTextArea from "../components/LanguageTextArea"
import { useEffect, useState } from "react"
import {
  Button,
  Link,
  Select,
  SelectItem,
  Selection,
  Switch,
} from "@nextui-org/react"
import { useNavigate, useParams } from "react-router"
import {
  updateCategory,
  getCategoryById,
} from "@/api/routes/admin/question-category/category-bank"
import { colorsList } from "@/core/constants/colors"
import { PageLoader } from "@/components/PageLoader"
import { createQuiz } from "@/api/routes/admin/quiz-bank"
import ListInput from "../components/ListInput"
import QuestionInput from "./QuestionInput"
import {
  isLanguageFieldFilledOut,
  isSetFieldFilledOut,
} from "@/core/helpers/utils"
import {
  QuizAccessLevel,
  QuizAccessLevelString,
} from "@/api/types/quiz/quizzes"

export default function CreateQuizPage() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const [title, setTitle] = useState({} as any)
  const [shortDescription, setShortDescription] = useState({} as any)
  const [description, setDescription] = useState({} as any)

  const [interviewerInstructions, setInterviewerInstructions] = useState(
    {} as any,
  )
  const [intervieweeInstructions, setIntervieweeInstructions] = useState(
    {} as any,
  )

  const [selectedQuestions, setSelectedQuestions] = useState([] as any)

  const [actorModeEnabled, setActorModeEnabled] = useState(false)
  const [actorInstructions, setActorInstructions] = useState({} as any)

  const [quizAccessLevel, setQuizAccessLevel] = useState<Selection>(new Set([]))

  const buildAndSendQuiz = async () => {
    setIsLoading(true)

    const quiz = {
      name: title,
      shortDescription,
      description,
      interviewerInstructions,
      intervieweeInstructions,
      questions: selectedQuestions.map((question: any) => question._id),
      supportedRegions: { enGB: true },
      actorModeEnabled,
      actorInstructions,
      quizAccessLevel: parseInt([...quizAccessLevel].join("")),
    }

    await createQuiz(quiz)

    // Navigate back
    navigate(-1)

    setIsLoading(false)
  }

  const formValidate = () => {
    if (
      isLanguageFieldFilledOut(title) &&
      isLanguageFieldFilledOut(shortDescription) &&
      isLanguageFieldFilledOut(description) &&
      isLanguageFieldFilledOut(interviewerInstructions) &&
      isLanguageFieldFilledOut(intervieweeInstructions) &&
      isSetFieldFilledOut(quizAccessLevel) &&
      selectedQuestions &&
      selectedQuestions.length > 0
    ) {
      return false
    }
    return true
  }

  return (
    <Page showSidebar={true}>
      <Link
        className="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        {"< Back"}
      </Link>
      <PageHeader>
        <h1>Create Quiz</h1>
      </PageHeader>
      <div className="page-grid">
        <div className="input-wrapper">
          <LanguageInput
            observable={title}
            setObservable={setTitle}
            type="text"
            label="Quiz Title"
            placeholder="Enter the quiz title"
          ></LanguageInput>
        </div>
        <div className="input-wrapper">
          <LanguageInput
            observable={shortDescription}
            setObservable={setShortDescription}
            type="text"
            label="Quiz Short Description"
            placeholder="Enter a short description for the quiz"
          ></LanguageInput>
        </div>
        <div className="input-wrapper">
          <Select
            variant="bordered"
            label="Access Level"
            placeholder="Select who can view the quiz"
            selectedKeys={quizAccessLevel}
            onSelectionChange={setQuizAccessLevel}
          >
            {Object.keys(QuizAccessLevelString).map((key: any) => (
              <SelectItem
                key={QuizAccessLevel[key]}
                value={QuizAccessLevel[key]}
              >
                {(QuizAccessLevelString as any)[key]}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <LanguageTextArea
            editorKey={"1"}
            observable={description}
            setObservable={setDescription}
            type="text"
            label="Quiz Description"
            placeholder="Enter the quiz description"
          />
        </div>
        <div className="input-wrapper">
          <LanguageTextArea
            editorKey={"2"}
            observable={interviewerInstructions}
            setObservable={setInterviewerInstructions}
            type="text"
            label="Examiner Instructions"
            placeholder="Enter the quiz examiner instructions"
          />
        </div>
        <div className="input-wrapper">
          <LanguageTextArea
            editorKey={"3"}
            observable={intervieweeInstructions}
            setObservable={setIntervieweeInstructions}
            type="text"
            label="Candidate Instructions"
            placeholder="Enter the quiz candidate instructions"
          />
        </div>

        <div className="input-wrapper">
          <Switch
            isSelected={actorModeEnabled}
            onValueChange={setActorModeEnabled}
          >
            Allow Actors
          </Switch>
        </div>

        {actorModeEnabled && (
          <div className="input-wrapper">
            <LanguageTextArea
              editorKey={"4"}
              observable={actorInstructions}
              setObservable={setActorInstructions}
              type="text"
              label="Actor Instructions"
              placeholder="Enter the quiz actor instructions"
            />
          </div>
        )}

        {/* Add that custom select component for questions */}
        <div className="input-wrapper">
          <QuestionInput
            selectedQuestions={selectedQuestions}
            setSelectedQuestions={setSelectedQuestions}
          />
        </div>

        <div className="input-wrapper create-question-wrapper">
          <Button
            onClick={buildAndSendQuiz}
            variant="solid"
            color="primary"
            isLoading={isLoading}
            isDisabled={formValidate()}
          >
            Create Quiz
          </Button>
        </div>
      </div>
    </Page>
  )
}
