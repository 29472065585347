import {
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react"
import { QuizActionsCell } from "./QuizActionsCell"
import { useEffect, useState } from "react"
import { IUserQuizView } from "@/api/types/quiz/quizzes"
import { getQuizzes } from "@/api/routes/quiz/quiz"
import { getCategoryStatistics } from "@/api/routes/statistics"

export default function CategoryBreakdownsWidget() {
  const [categoryStatsLoading, setCategoryStatsLoading] = useState(true)
  const [quizzes, setQuizzes] = useState([] as IUserQuizView[])
  const [stats, setStats] = useState([] as any)
  const [keys, setKeys] = useState([] as string[])

  const loadQuizzes = async () => {
    setCategoryStatsLoading(true)
    const categoryStats = await getCategoryStatistics()
    if (categoryStats) {
      let stats = []
      for (let key of Object.keys(categoryStats)) {
        stats.push({
          name: key,
          ...categoryStats[key],
          average:
            Math.round(categoryStats[key].questionsScoreScaledAverage * 10000) /
            100,
        })
      }

      setStats(stats.sort((a, b) => b.average - a.average))
    }

    setCategoryStatsLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await loadQuizzes()
    })()
  }, [])

  return (
    <Card className="widget-2x2 mini-table">
      <h1 className="widget-2x2-header">Performance by Category</h1>
      {categoryStatsLoading ? (
        <Table
          removeWrapper
          hideHeader={categoryStatsLoading}
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn>NAME</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton className="skeleton-row"></Skeleton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Table
          removeWrapper
          hideHeader={categoryStatsLoading}
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn>NAME</TableColumn>
            <TableColumn className="right-aligned-cell">Avg %</TableColumn>
          </TableHeader>
          <TableBody items={stats}>
            {(stat: any) => (
              <TableRow key={stat._id}>
                <TableCell>{stat.name}</TableCell>
                <TableCell className="right-aligned-cell">
                  {isNaN(stat.average)
                    ? "Not Attempted"
                    : stat.average.toString() + "%"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Card>
  )
}
