export enum CategoryAccessLevel {
  UNLOCKED = 0,
  SUBSCRIPTION = 1,
}

export interface IQuestionCategoryVideo {
  _id: string
  title: string
  url: string
  description: string
  links?: IQuestionCategoryLinks[]
  accessLevel: CategoryAccessLevel
}

export interface IQuestionCategory {
  _id?: string
  index?: number
  name: string
  description: string
  color: string
  videos: IQuestionCategoryVideo[]
  subCategories: ISubQuestionCategory[]
  notes: IQuestionCategoryNotes[]
}

export enum CategoryAccessLevelString {
  UNLOCKED = "Unlocked",
  SUBSCRIPTION = "Subscription Only",
}

export interface IQuestionCategoryLinks {
  _id: string
  title: string
  url: string
  description: string
}

export interface IQuestionCategoryNotes {
  _id: string
  title: string
  notes: string
  links?: IQuestionCategoryLinks[]
  accessLevel: CategoryAccessLevel
}

export interface ISubQuestionCategory {
  _id: string
  name: string
  description: string
  notes?: IQuestionCategoryNotes[]
  videos?: IQuestionCategoryVideo[]
  subCategories: ISubQuestionCategory[]
}

export interface IQuestion {
  _id: string
  name: string
  question: string
  answers: string
  marks: number
  timeLimit: number
  categories: IQuestionCategory[]
}

export interface IQuestionBankLink {
  _id: string
  title: string
  url: string
  type: QuestionBankLinkType
  description: string
}

export interface IQuestionBankQuestion extends IQuestion {
  actorInstructions?: string
  actorModeEnabled: boolean
  intervieweeInstructions?: string
  intervieweeInstructionsEnabled: boolean
  interviewerInstructions?: string
  interviewerInstructionsEnabled: boolean
}

export enum AnswerAccessLevel {
  ADMIN_ONLY = 0,
  TUTOR_ONLY = 1,
  PAYWALL = 2,
  UNLOCKED = 3,
}

export enum AnswerAccessLevelString {
  ADMIN_ONLY = "Admin Only",
  TUTOR_ONLY = "Tutor only",
  PAYWALL = "Paywall",
  UNLOCKED = "Unlocked",
}

export interface IInProgressQuizQuestionQuizData {
  score: number
  totalMarks: number
  totalTime: number
  startDate: Date
  endDate: Date
}

export enum QuestionBankLinkType {
  VIDEO = "Video",
  NOTES = "Notes",
  EXTERNAL = "External",
}

export interface IQuestionBankLink {
  _id: string
  title: string
  url: string
  type: QuestionBankLinkType
  description: string
}

export interface IUserAnswer {
  aiChatbotSessionIds?: string[] // ID
  answer?: string
  voiceRecordingId?: string // ID
  answersScored: string[] // ID
  totalTime: number // Time in minutes; will replace startDate and endDate to allow users to pause.
  startTime: Date
  endTime: Date
  score: number
  scaledScore: number
  markersComment: string
  optionalAnswersEnabled: string[] // ID

  // AI feature
  transcription?: string // If a transcription exists, we can get an AI response
  isTranscribing?: boolean // If this is true, the transcription is currently being processed
  hasTranscription?: boolean // If this is true, a transcription exists (show the transcription box)
  transcriptionDate?: Date // The date the transcription was made
  transcriptionId?: string // ID

  isAiMarking?: boolean // If this is true, the AI is currently marking the answer
  aiResponse?: string // The AI response to the answer
}

export interface IInProgressQuizQuestion {
  quizBankQuizId: string
  _id: string // Both _id and id are the same. Need to phase out id in favour of _id
  id: string
  name: string
  question: string
  marks: number
  timeLimit: number
  currentQuestionNumber: number
  totalQuestions: number
  answerAccessLevel: AnswerAccessLevel
  answers: any
  userAnswer?: IUserAnswer
  quizData?: IInProgressQuizQuestionQuizData
  actorInstructions?: string
  actorModeEnabled: boolean
  intervieweeInstructions?: string
  intervieweeInstructionsEnabled: boolean
  interviewerInstructions?: string
  interviewerInstructionsEnabled: boolean
  associatedReadingLinks?: IQuestionBankLink[]
  isAiChatBotQuestion?: boolean
}

export interface IAnswerMetaData {
  _id: string
  heading: string
  answer: string
  marks: string // TODO: Change to number
  scaledMarks: number
  isOptional?: boolean
  optionalEnabled?: boolean
}
