import { searchSubscriptionPlans } from "@/api/routes/admin/subscription-plan"
import BaseModal from "@/core/components/Modals/BaseModal"
import { useEffect, useState } from "react"
import "./index.css"
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  DatePicker,
} from "@nextui-org/react"
import { useAsyncList } from "@react-stately/data"
import { isFieldFilledOut } from "@/core/helpers/utils"
import { addManualSubscriptionToUser } from "@/api/routes/admin/user"
import useDebounce from "@/core/helpers/debounce"
import { DateValue, fromDate } from "@internationalized/date"
import { I18nProvider } from "@react-aria/i18n"

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedData: any[]
  setSelectedData: (val: any) => void
  userId: string
  getData: (page: number) => Promise<void>
}
export default function AddSubscriptionModal(props: Props) {
  const [inputValue, setInputValue] = useState("")
  const debouncedInputValue = useDebounce(inputValue, 200)

  const selectedPlanList = useAsyncList<any>({
    async load({ filterText, signal }) {
      if (signal.aborted) return Promise.reject()

      let res = await searchSubscriptionPlans(filterText ?? "")

      return {
        items: res.map((plan: any) => ({
          key: plan._id,
          index: plan._id,
          name: plan.name,
          ...plan,
        })),
      }
    },
  })

  useEffect(() => {
    selectedPlanList.setFilterText(debouncedInputValue)
  }, [debouncedInputValue])

  const [selectedItem, setSelectedItem] = useState<any>()

  const [startDate, setStartDate] = useState<DateValue | undefined>()
  const [renewDate, setRenewDate] = useState<DateValue | undefined>()

  const getSelectedItem = () => {
    return Array.isArray(selectedPlanList?.items)
      ? selectedPlanList?.items?.find(
          (x: any) => x.name === selectedPlanList?.filterText,
        ) ?? null
      : null
  }

  const [isLoading, setIsLoading] = useState<boolean>()
  const onAddSubscription = async () => {
    setIsLoading(true)

    const startDateObj = startDate!.toDate("Europe/London")
    const renewDateObj = renewDate!.toDate("Europe/London")

    await addManualSubscriptionToUser(
      props.userId,
      selectedItem._id,
      startDateObj,
      renewDateObj,
    )

    setIsLoading(false)
    await props.getData(1)
    props.onClose()
  }

  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(false)
  const checkFormValidation = (): void => {
    if (
      isFieldFilledOut(selectedItem) &&
      isFieldFilledOut(startDate) &&
      isFieldFilledOut(renewDate)
    ) {
      setIsFormInvalid(false)
    } else {
      setIsFormInvalid(true)
    }
  }

  useEffect(() => {
    const item = getSelectedItem()
    setSelectedItem(item)
    if (item) {
      const _startDate = new Date()
      const _renewDate = new Date()
      _renewDate.setDate(_renewDate.getDate() + item.duration)
      if (startDate === null) {
        setStartDate(fromDate(_startDate, "Europe/London"))
      }
      setRenewDate(fromDate(_renewDate, "Europe/London"))
    }
  }, [selectedPlanList.filterText])

  useEffect(() => {
    checkFormValidation()
  }, [selectedItem, startDate, renewDate])

  return (
    <BaseModal isOpen={props.isOpen} onClose={props.onClose} size="xl">
      <div className="add-subscriptions-modal-wrapper">
        <div className="input-wrapper">
          <h1>Add subscription</h1>
          <p>
            Please note, that any subscriptions added through this part of the
            menu will NOT automatically bill the user. The price will be ignored
            and the user will either receive it for free, or you will have to
            bill them manually.
          </p>
        </div>

        <div className="input-wrapper">
          <Autocomplete
            inputValue={inputValue}
            isLoading={selectedPlanList.isLoading}
            items={selectedPlanList.items}
            onInputChange={setInputValue}
            label="Subscription Plan"
            placeholder="Search a subscription plan"
          >
            {(plan: any) => (
              <AutocompleteItem key={plan._id}>{plan.name}</AutocompleteItem>
            )}
          </Autocomplete>
        </div>
        <div className="input-wrapper">
          <I18nProvider locale="en-GB">
            <DatePicker
              value={startDate}
              granularity="minute"
              onChange={setStartDate}
              label="Start Date"
              isDisabled={selectedItem === null}
            />
          </I18nProvider>
        </div>
        <div className="input-wrapper">
          <I18nProvider locale="en-GB">
            <DatePicker
              label="Renewal Date"
              granularity="minute"
              value={renewDate}
              onChange={setRenewDate}
              isDisabled={selectedItem === null}
            />
          </I18nProvider>
        </div>
        <div className="create-question-wrapper">
          <Button
            isDisabled={isFormInvalid}
            onClick={onAddSubscription}
            isLoading={isLoading}
            color="primary"
            variant="solid"
          >
            Add Subscription
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}
