import {
  IInProgressQuizQuestion,
  IQuestionBankQuestion,
} from "@/api/types/question/question"
import { AttemptTypeEnum, IQuizBank } from "@/api/types/quiz/quiz-bank"
import RichTextRender from "@/core/components/RichTextRender"
import SingleAccordion from "@/core/components/SingleAccordion"
import { Card, Spacer } from "@nextui-org/react"

interface Props {
  quizData: IQuizBank | null
  question: IInProgressQuizQuestion | null
  isActorMode?: boolean
}
export default function InstructionsDisplay({
  quizData,
  question,
  isActorMode,
}: Props) {
  return (
    <div>
      {/* Actor instructions card (if mode enabled) */}
      {isActorMode && quizData?.actorModeEnabled ? (
        <Card shadow="none" className="in-progress-quiz-answer-card">
          <h1>Actor Instructions</h1>
          {question?.actorModeEnabled === true ? (
            <RichTextRender observable={question?.actorInstructions ?? ""} />
          ) : (
            <p>No actor instructions available for this question.</p>
          )}
        </Card>
      ) : null}

      {/* Instructions refreshers */}
      {quizData?.attemptType === AttemptTypeEnum.QUESTION_PRACTICE ? (
        <>
          {question?.interviewerInstructionsEnabled && (
            <>
              <SingleAccordion variant="shadow" title="Examiner Instructions">
                <RichTextRender
                  observable={question?.interviewerInstructions ?? ""}
                />
              </SingleAccordion>
              <Spacer y={5} />
            </>
          )}

          {question?.intervieweeInstructionsEnabled && (
            <>
              <SingleAccordion variant="shadow" title="Candidate Instructions">
                <RichTextRender
                  observable={question?.intervieweeInstructions ?? ""}
                />
              </SingleAccordion>
              <Spacer y={5} />
            </>
          )}
          {question?.actorModeEnabled && (
            <>
              <SingleAccordion variant="shadow" title="Actor Instructions">
                <RichTextRender
                  observable={question?.actorInstructions ?? ""}
                />
              </SingleAccordion>
              <Spacer y={5} />
            </>
          )}
        </>
      ) : (
        <>
          {!isActorMode ? (
            <SingleAccordion variant="shadow" title="Candidate Instructions">
              <RichTextRender
                observable={quizData?.intervieweeInstructions ?? ""}
              />
            </SingleAccordion>
          ) : (
            <SingleAccordion variant="shadow" title="Actor Instructions">
              <RichTextRender observable={quizData?.actorInstructions ?? ""} />
            </SingleAccordion>
          )}
          <Spacer y={5} />
        </>
      )}
    </div>
  )
}
