export interface SubscriptionPlanFeature {
  title: string
  type: string
  attachedUnlimitedField?: string
}

export const keysToShowCreditBalanceFor = ["ai-marks", "interviews"]

export const subscriptionPlanFeaturesMap: Record<
  string,
  SubscriptionPlanFeature
> = {
  interviews: {
    title: "Interview Credits",
    type: "number",
    attachedUnlimitedField: "unlimited-interviews",
  },
  "unlimited-interviews": {
    title: "Unlimited Interviews",
    type: "boolean",
  },
  "category-interviews": {
    title: "Unlimited Interviews from Categories",
    type: "category-input",
  },
  "voice-recording-storage-limit": {
    title: "Voice Recording Storage (in GB)",
    type: "number",
  },
  "ai-marks": {
    title: "AI Mark Credits",
    type: "number",
  },
  "tutor-marks": {
    title: "Tutor Mark Credits",
    type: "number",
  },
  "learning-content": {
    title: "Exclusive Learning Content",
    type: "boolean",
  },
}
