import { useEffect, useRef, useState } from "react"
import {
  blockquote,
  image,
  link,
  audio,
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
} from "suneditor/src/plugins"
import SunEditor from "suneditor-react"
import SunEditorCore from "suneditor/src/lib/core"
import "suneditor/dist/css/suneditor.min.css"
import { getAuthTokenFromLocalStorage } from "../PageLoadActions"
import {
  UploadBeforeHandler,
  UploadBeforeReturn,
} from "suneditor-react/dist/types/upload"
import { uploadImage } from "@/api/routes/image/image"
import "./index.css"
import { defaultRichTextAreaStyle } from "../RichTextArea"

interface Props {
  observable: string
  onChange: (contents: string) => void
  label?: string
  placeholder?: string
  height?: string
}

export default function UserRichTextArea(props: Props) {
  const editor = useRef<SunEditorCore>()

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor

    const context = sunEditor.getContext()

    context.element.topArea.classList.add("user-rich-text-area-sun-editor")

    context.element.toolbar.classList.add("user-rich-text-area-toolbar")

    context.element.resizingBar.classList.add(
      "user-rich-text-area-resizing-bar",
    )

    context.element.editorArea.classList.add("user-rich-text-area-editor-area")
  }

  function onImageUploadBefore() {
    return (
      files: File[],
      info: object,
      uploadHandler: UploadBeforeHandler,
    ) => {
      ;(async () => {
        const data = await uploadImage(files[0])

        const res: any = {
          result: [
            {
              url: import.meta.env.VITE_API_URL + "/api/v1/image/" + data,
              name: "Image",
            },
          ],
        }

        uploadHandler(res)
      })()

      // called here for stop double image
      uploadHandler()
      return undefined
    }
  }

  useEffect(() => {
    editor.current?.setContents(props.observable)
  }, [props.observable])

  return (
    <>
      {props.label && <p className="rich-text-area-label">{props.label}</p>}

      <SunEditor
        setContents={props.observable}
        onChange={(contents) => props.onChange(contents)}
        setAllPlugins={true}
        height={props.height ?? "200px"}
        getSunEditorInstance={getSunEditorInstance}
        onImageUploadBefore={onImageUploadBefore()}
        placeholder={props.placeholder ?? ""}
        setOptions={{
          defaultStyle: defaultRichTextAreaStyle,
          fontSize: [12, 14, 16, 18, 20, 22, 24, 26, 28, 36],
          plugins: [
            blockquote,
            image,
            link,
            audio,
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            textStyle,
          ],
          charCounter: true,
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image" /*, "video", "math"*/], // You must add the 'katex' library at options to use the 'math' plugin.
            ["preview", "print"],
            ["dir_ltr", "dir_rtl"],
            ["fullScreen"],
            "/", // Line break
          ],
        }}
      />
    </>
  )
}
