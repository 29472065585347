import { addSubCategory } from "@/api/routes/admin/question-category/category-bank"
import { ISubQuestionCategory } from "@/api/types/question/question"
import BaseModal from "@/core/components/Modals/BaseModal"
import { isFieldFilledOut } from "@/core/helpers/utils"
import {
  Button,
  Input,
  Spacer,
  Textarea,
  UseDisclosureProps,
} from "@nextui-org/react"
import { current } from "@reduxjs/toolkit"
import { useState } from "react"

interface Props {
  currentCategoryPath: string[]
  setSubCategories: React.Dispatch<React.SetStateAction<any[]>>
  disclosure: UseDisclosureProps
}
export default function CreateSubCategoryModal({
  disclosure,
  currentCategoryPath,
  setSubCategories,
}: Props) {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const formValidate = () => {
    if (isFieldFilledOut(title) && isFieldFilledOut(description)) {
      return false
    }
    return true
  }

  const closeModal = () => {
    setTitle("")
    setDescription("")
    disclosure.onClose!()
  }

  const internalAddSubCategory = async () => {
    setIsLoading(true)

    // Send our API call to add the sub category
    const newSubCategoryId = await addSubCategory(
      title,
      description,
      currentCategoryPath,
    )

    // Update the sub categories list with the new sub category
    setSubCategories((prev) => {
      const next = [...prev]
      next.push({
        _id: newSubCategoryId,
        name: title,
        description,
      } as ISubQuestionCategory)

      return next
    })
    setIsLoading(false)
    closeModal()
  }

  return (
    <BaseModal
      isOpen={disclosure.isOpen!}
      onClose={disclosure.onClose!}
      title="Add Sub Category"
      size="xl"
      body={
        <div>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Sub Category Title"
          />
          <Spacer y={2} />
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            variant="bordered"
            placeholder="Sub Category Description"
          />
        </div>
      }
      buttons={
        <div className="add-video-modal-buttons">
          <Button
            onClick={() => {
              disclosure.onClose!()
            }}
            variant="light"
            color="danger"
          >
            Cancel
          </Button>
          <Button
            onClick={internalAddSubCategory}
            variant="solid"
            color="primary"
            isDisabled={formValidate()}
            isLoading={isLoading}
          >
            Add Sub Category
          </Button>
        </div>
      }
    />
  )
}
