import React from "react"
import "./index.css"
import { Checkbox, Link, User, Chip, cn, Card } from "@nextui-org/react"

interface Props {
  value: number
  answer: string
  className?: string
  onChange?: (isSelected: boolean) => void
}

export const AnswerCheckBox = ({
  answer,
  className,
  value,
  onChange,
}: Props) => {
  return (
    <Checkbox
      radius="full"
      classNames={{
        base: cn("answer-checkbox " + className),
        label: "w-full",
      }}
      onValueChange={onChange}
      value={value.toString()}
    >
      <Card shadow="sm" className="answer-checkbox-card">
        <div className="w-full flex justify-between gap-2">
          <div className="flex flex-col items-end gap-1">
            <span className="answer-content-text">{answer}</span>
          </div>
        </div>
      </Card>
    </Checkbox>
  )
}
