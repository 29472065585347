import { deleteQuiz } from "@/api/routes/admin/quiz-bank"
import {
  deleteSubscriptionPlan,
  restoreSubscriptionPlan,
} from "@/api/routes/admin/subscription-plan"
import ConfirmDeleteModal from "@/core/components/Modals/ConfirmDelete"
import {
  faArrowRotateLeft,
  faTrash,
  faTrashArrowUp,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Tooltip, useDisclosure } from "@nextui-org/react"

interface Props {
  subscriptionPlanId: string
  subscriptionPlanName: string
  listSubscriptionPlans: () => Promise<void>
}
export default function RestoreSubscriptionPlanButton({
  subscriptionPlanId,
  subscriptionPlanName,
  listSubscriptionPlans,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ConfirmDeleteModal
        isOpen={isOpen}
        onConfirm={async () => {
          await restoreSubscriptionPlan(subscriptionPlanId)
          await listSubscriptionPlans()
          onClose()
        }}
        onClose={onClose}
        title="Restore Subscription Plan"
        description={`Are you sure you want to restore the subscription plan '${subscriptionPlanName}'? This will add it back to the list of active subscription plans.`}
        deleteTextOverride="Restore"
      />
      <Tooltip
        aria-label="Details"
        color="success"
        content="Restore subscription plan"
      >
        <Link onClick={onOpen}>
          <span className="text-lg text-success cursor-pointer active:opacity-50">
            <FontAwesomeIcon icon={faArrowRotateLeft} />
          </span>
        </Link>
      </Tooltip>
    </>
  )
}
